import React, { useEffect, useState } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./MyOrder.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import OrderManagementComponent from "../../../components/orderManagement";
import Loader from "../../../components/loader/Loader";
import { formatDate, formatUsCurrency } from "../../../services/utills/helper";
import moment from "moment";

const MyOrder = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector((state) => state.AuthReducer.userData);
	const { userUpdated } = useSelector((state) => state.AuthReducer);

	const { order } = useSelector((state) => state.CustomerReducer);
	const [filter, setFilter] = useState("all");
	const [sort, setSort] = useState("startDate");

	const [formattedOrders, setFormattedOrder] = useState([]);

	const { loadingState } = useSelector((state) => state.CommonReducer);

	const [filterState, setFilterState] = useState({
		event: null,
		vendor: null,
		status: null,
	});
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		(async () => {
			if (user?._id && userUpdated) {
				await dispatch(CustomerAction.getAllOrder(user._id));
			}
		})();
	}, [user, userUpdated]);

	useEffect(() => {
		(async () => {
			setTable();
		})();
	}, [order]);

	const handleChangeFilter = async (type) => {
		console.log("handleChangeFilter", type);
		if (type === "reset") {
			setTable();
			return;
		}

		const filters = Object.keys(filterState).filter(
			(filter) => filterState[filter] != "" && filterState[filter] != null
		);

		console.log("filters", filters);
		if (filters.length > 0) setTableFilter(filters);
		else if (filter === "all") setTable();
	};

	const setTableFilter = (filters) => {
		console.log("setTableFilter");
		console.log(
			"start date, order date",
			formatDate(order[0].CreatedAt) >
				formatDate(order[0].eventData?.eventStartDate)
		);

		const formattedTableData = order
			// .slice(0, 1)
			.filter((order) => {
				let isAllowed = true;

				let filterList = filters.map((filter) => {
					if (filter === "vendor") {
						return order?.vendorId?.firstName === filterState["vendor"];
					}
					if (filter === "event") {
						return order?.eventData?.eventName === filterState["event"];
					}

					if (filter === "status" && filterState["status"] === "all")
						return true;

					return order[filter] === filterState[filter];
				});

				filterList.map((list) => {
					if (list === false) isAllowed = false;
				});

				return isAllowed;
			})
			.map((elm) => ({
				Id: elm._id,

				OrderId: elm.orderId,
				Venue: elm?.venueId?.firstName,
				Vendor: elm?.vendorId?.firstName,

				// Price: "$" + Number(elm.price).toFixed(2),
				Price: formatUsCurrency(elm?.price),
				Status: moment(elm.CreatedAt).isAfter(elm.eventData?.eventStartDate)
					? // formatDate(elm.CreatedAt) > formatDate(elm.eventData?.eventStartDate)
					  "Stale_Proposal"
					: elm.status,
				"Order Date": moment(elm.CreatedAt).format("MM/DD/YYYY"),
				"Start Date": moment(elm?.eventData?.eventStartDate).format(
					"MM/DD/YYYY"
				),
			}));

		console.log("formattedTableData", formattedTableData);
		setFormattedOrder(formattedTableData);
	};

	const setTable = () => {
		const formattedTableData = order.map((elm) => ({
			Id: elm._id,

			OrderId: elm.orderId,
			Venue: elm?.venueId?.firstName,
			Vendor: elm?.vendorId?.firstName,

			// Price: "$" + Number(elm.price).toFixed(2),
			Price: formatUsCurrency(elm?.price),
			Status: moment(elm.CreatedAt).isAfter(elm.eventData?.eventStartDate)
				? // formatDate(elm.CreatedAt) > formatDate(elm.eventData?.eventStartDate)
				  "Stale_Proposal"
				: elm.status,
			"Order Date": moment(elm.CreatedAt).format("MM/DD/YYYY"),
			"Start Date": moment(elm?.eventData?.eventStartDate).format("MM/DD/YYYY"),
		}));
		setFormattedOrder(formattedTableData);
	};

	// const tableData = order?.data?.map((ord) => {
	//   return {
	//     orderId: ord?._id,
	//     "Venue-Name": ord?.adspaces[0]?.adspace?.venueId?.firstName,
	//     Location: ord?.adspaces[0]?.adspace?.venueId?.city,
	//     Price: `$ ${ord.price}`,
	//     button: "details",
	//   };
	// });

	const handleClick = (item) => {
		dispatch({ type: ACTION_TYPE.CLEAR_ORDER_DETAILS });
		// item = {...item}

		console.log("item", item);
		const selectedItem = order.find((x) => x._id === item.Id);
		item = { ...item, ...selectedItem };
		console.log("selectedItem", selectedItem);
		// dispatch(AdminAction.orderDetails(item));
		history.push(`/order/details/${selectedItem["_id"]}`);
	};

	const renderFilterList = [
		{
			name: "All Orders",
			value: "all",
		},
		{
			name: "Proposed",
			value: "Proposed",
		},
		// {
		//   name: "Rejected_Proposal",
		//   value: "Rejected_Proposal",
		// },
		// {
		//   name: "Stale_Proposal",
		//   value: "Stale_Proposal",
		// },
		{
			name: "Pending",
			value: "Pending",
		},
		{
			name: "Approved",
			value: "Approved",
		},
		{
			name: "Rejected",
			value: "Rejected",
		},
		{
			name: "Completed",
			value: "Completed",
		},
	];

	const renderSortList = [
		{
			name: "Start Date",
			value: "startDate",
		},
		{
			name: "Start Date - Ascending",
			value: "startDateAsc",
		},
		{
			name: "Start Date - Descending",
			value: "startDateDesc",
		},
	];

	const handleChangeSort = (type) => {
		let sortedOrders = formattedOrders.sort((a, b) => {
			if (type === "startDateAsc")
				return moment(a["Start Date"], "MM/DD/YYYY").diff(
					moment(b["Start Date"], "MM/DD/YYYY")
				);
			else if (type === "startDateDesc")
				return moment(b["Start Date"], "MM/DD/YYYY").diff(
					moment(a["Start Date"], "MM/DD/YYYY")
				);
		});

		console.log("sortedOrders order", sortedOrders);
		setFormattedOrder(sortedOrders);
	};

	return (
		<div>
			<div className="start">
				{user?.role && (
					<RBAC
						userRole={user?.role} // User roles that are given to user in the backend
						initList={initList}
						renderNoAccess={() => <SideNav />}
					>
						<SideNav />
					</RBAC>
				)}
				<div className="screen">
					<HeaderBar title="My Orders" />
					{loadingState === true ? (
						<div
							className="d-flex justify-content-center align-items-center"
							style={{ height: "100%" }}
						>
							<Loader loading={loadingState} size={25} color={"#ffffff"} />
						</div>
					) : (
						<OrderManagementComponent
							filter={filter}
							setFilter={setFilter}
							sort={sort}
							setSort={setSort}
							handleChangeSort={handleChangeSort}
							sortList={renderSortList}
							handleChangeFilter={handleChangeFilter}
							filterList={renderFilterList}
							tableData={formattedOrders}
							tableHead={[
								"OrderId",
								"Venue",
								"Vendor",
								"Price",
								"Order Date",
								"Start Date",
								"Status",
							]}
							handleClick={handleClick}
							filterState={filterState}
							setFilterState={setFilterState}
							toggle={toggle}
							setToggle={setToggle}
							loadingState={loadingState}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyOrder;
// <div className='myOrder'>
// <div className='myOrderDiv'>
//   <div className="myOrderContent">
//     <div className='myOrdertitles'>
//       <h4 className='col-lg-3'>Venue Name</h4>
//       <h4 className='col-lg-3'>Location</h4>
//       <h4 className='col-lg-3'>Price</h4>
//     </div>
//     <div className='myOrdervalues'>
//       <p className='col-lg-3'>Alpha</p>
//       <p className='col-lg-3'>Las Vegas</p>
//       <p className='col-lg-3'>$3200</p>
//       <Button
//       className='col-lg-3'
//       background="primary"
//       borderRadius="15px"
//       color="#fff"
//       transition="background 0.3s"
//       display="inline-flex"
//       alignItem="center"
//       name='Details'
//     ></Button>
//     </div>
//     <div className='myOrdervalues'>
//       <p className='col-lg-3'>Alpha</p>
//       <p className='col-lg-3'>Las Vegas</p>
//       <p className='col-lg-3'>$3200</p>
//       <Button
//       className='col-lg-3'
//       background="primary"
//       borderRadius="15px"
//       color="#fff"
//       transition="background 0.3s"
//       display="inline-flex"
//       alignItem="center"
//       name='Details'
//       handleClick={() => {
//         history.push("/order-details");
//       }}
//     ></Button>
//     </div>
//     <div className='myOrdervalues'>
//       <p className='col-lg-3'>Alpha</p>
//       <p className='col-lg-3'>Las Vegas</p>
//       <p className='col-lg-3'>$3200</p>
//       <Button
//       className='col-lg-3'
//       background="primary"
//       borderRadius="15px"
//       color="#fff"
//       transition="background 0.3s"
//       display="inline-flex"
//       alignItem="center"
//       name='Details'
//     ></Button>
//     </div>
//   </div>
// </div>
// </div>
