import React, { useState } from "react";
import Button from "../../../components/button/Button";
import { ValidateLoginForm } from "./Validation";

import InputField from "../../../components/input/Input";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../Header";
import Toast from "../../../components/toast/Toast";
import { AuthAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { CustomerAction } from "../../../redux/actions/Index";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  ROUTES,
  APPLICATION_CONSTANTS,
  SITE_KEY,
} from "../../../services/constants/Index";
import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";
const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = React.useState();
  const [state, setState] = useState();
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = React.useState();
  const [role, setRole] = React.useState(APPLICATION_CONSTANTS.VENUE_ROLE);
  const [isShowPassword, setPasswordType] = React.useState(false);
  const { loadingState } = useSelector((state) => state.CommonReducer);

  const [botCheck, setBotCheck] = React.useState({
    human: false,
    humanKey: null,
  });
  const [isDisabled, setDisabled] = useState(true);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = async (res) => {
    if (res) {
      console.log("captcha status", res);

      // this.setState({ human: true, humanKey: res })
      setBotCheck({
        ...botCheck,
        human: true,
        humanKey: res,
      });
      setDisabled(false);
      const status = await dispatch(AuthAction.verifyCaptcha(res));

      // this.setState({ disabled: this.isDisabled() })
    }
  };

  // ReCAPTCHA Expired
  const expireCaptcha = () => {
    // this.setState({ human: false, humanKey: null })
    setBotCheck({
      ...botCheck,
      human: false,
      humanKey: null,
    });
    setDisabled(true);
    // this.setState({ disabled: this.isDisabled() })
  };

  async function onSubmit(event) {
    event.preventDefault();
    // if (isDisabled) return;

    const result = await ValidateLoginForm(state);
    if (result?.isFormValid) {
      setError({});
      const status = await dispatch(
        AuthAction.loginVenueVendor({ ...state, role })
      );
      console.log("LOGIN STATUS::", status);
      if (status && status.data && status.data.role) {
        const { role } = status.data;

        switch (role) {
          case "venue":
            history.push(ROUTES.VENUE_ORDER_MANAGEMENT);
            break;

          case "vendor":
            history.push(ROUTES.VENDOR_ORDER_MANAGEMENT);
            break;
          case "staff":
            history.push(ROUTES.STAFF_ORDER_MANAGEMENT);
            break;
          default:
            break;
        }
      }
    } else if (result?.isFormValid === false) {
      setError(result?.error);
      return;
    }
    setToast(true);
  }

  return (
    <div className="AuthBg">
      <Header type="type1" />
      {history.location.state?.previousPath === "Cart" ? (
        <>
          <div className="backarrow mt-5 ml-5 d-flex">
            <div className="backIcon mr-2" onClick={() => history.goBack()}>
              <ArrowBack className="icon" />
            </div>
            Ad-spaces
          </div>
          <div className="headCart mb-4 d-flex text-center ">
            <span className="hrline">
              <hr />
            </span>
            Registered User
            <span className="hrline">
              <hr />
            </span>
          </div>
        </>
      ) : (
        <div className="" style={{ marginTop: "50px" }}></div>
      )}

      <div className="Login Login-v mb-5 ">
        <div className="signInFormDiv signInmain">
          <div className="formDiv">
            <h1 className="signInHeading">Log In </h1>
            <div className="randomName">
              <div className="setUser d-flex mb-3">
                <button
                  className={
                    role === APPLICATION_CONSTANTS.VENUE_ROLE
                      ? "roleLoginVenueButton active"
                      : "roleLoginVenueButton"
                  }
                  name="Venue"
                  onClick={() => setRole(APPLICATION_CONSTANTS.VENUE_ROLE)}
                >
                  Venue
                </button>
                <button
                  name="Vendor"
                  className={
                    role === APPLICATION_CONSTANTS.VENDOR_ROLE
                      ? "roleLoginVendorButton active"
                      : "roleLoginVendorButton"
                  }
                  onClick={() => setRole(APPLICATION_CONSTANTS.VENDOR_ROLE)}
                >
                  Vendor
                </button>
                <button
                  name="Staff"
                  className={
                    role === APPLICATION_CONSTANTS.STAFF_ROLE
                      ? "roleLoginStaffButton active"
                      : "roleLoginStaffButton"
                  }
                  onClick={() => setRole(APPLICATION_CONSTANTS.STAFF_ROLE)}
                >
                  Staff
                </button>
              </div>
              <form className="signInForm" noValidate autoComplete="off">
                <div className="signInEmail">
                  <InputField
                    label="E-Mail"
                    type="text"
                    id="custom-css-outlined-input"
                    name="email"
                    value={state?.email}
                    handleChange={handleChange}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.email[0]}
                  </p>
                </div>
                <div className="signInPassword">
                  <InputField
                    label="Password"
                    type={isShowPassword == true ? "text" : "password"}
                    variant="outlined"
                    id="custom-css-outlined-input"
                    name="password"
                    value={state?.password}
                    handleChange={handleChange}
                    onSubmit={onSubmit}
                  />
                  {isShowPassword === true ? (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(false)}
                    >
                      <VisibilityIcon />
                    </a>
                  ) : (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(true)}
                    >
                      <VisibilityOffIcon />
                    </a>
                  )}

                  <p className="errorMsg">
                    {" "}
                    {errorData?.password && errorData.password[0]}
                  </p>
                </div>
                <div className="mb-2">
                  <ReCAPTCHA
                    sitekey={SITE_KEY}
                    onChange={onChange}
                    onExpired={expireCaptcha}
                  />
                </div>

                <div className="signInFormOptions">
                  <div className="rememberMeOption">
                    <input
                      type="checkbox"
                      onChange={(e) => setChecked(!checked)}
                    />
                    <label className="pt-1 mt-1">Remember Me</label>
                  </div>

                  <Link to={`/forgot-password/venue-vendor`}>
                    <h6>Forgot password?</h6>
                  </Link>
                </div>
                <div class="haveaccount text-center py-2">
                  <p className="mb-0">
                    Register your account for Vendor/Venue/Staff?{" "}
                    <Link to={ROUTES.VENUE_VENDOR_REGISTER}>
                      <a>Sign up</a>
                    </Link>
                  </p>
                </div>
                <div className="signInButton">
                  <Button
                    background="primary"
                    color="tertiary"
                    // disabled={isDisabled}
                    name="Sign In"
                    handleClick={onSubmit}
                    loading={loadingState}
                  />
                </div>
              </form>
            </div>
            {toast?.open ? (
              <Toast
                open={toast.open}
                message={toast.message}
                duration={toast.duration}
                type={toast.type}
                handleClose={() =>
                  setToast({
                    ...toast,
                    open: false,
                  })
                }
              ></Toast>
            ) : null}
          </div>
        </div>
        {/* <div>
          <p className="loginBelowLine">
            Visit the Epeius Consulting corporate &nbsp;
            <a
              className="loginHyperLink"
              target="_blank"
              href="https://epeius-consulting.com/brandevent/"
            >
              website
            </a>
            &nbsp; to learn about brandevent and our other solutions
          </p>
        </div> */}
        <div>
          <p className="loginBelowLine">
            For suppor, please call or text us: (209) 32-BRAND, (209) 322-7263
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
