import React, { Component } from "react";
import { v4 } from "uuid";
import { fabric } from "fabric";

class EventHandler extends Component {
  constructor(handler) {
    super(handler);
    this.handler = handler;
    this.initialize();
  }

  initialize = () => {
    // if (this.handler.editable) {
    this.handler.canvas.on({
      // 'object:modified': this.modified,
      // 'object:scaling': this.scaling,
      // 'object:scaled': this.scaled,
      // 'object:moving': this.moving,
      // 'object:moved': this.moved,
      // 'object:rotating': this.rotating,
      // 'object:rotated': this.rotated,
      // 'mouse:wheel': this.mousewheel,
      // 'mouse:down': this.mousedown,
      // 'mouse:move': this.mousemove,
      // 'mouse:up': this.mouseup,
      "selection:cleared": this.selection,
      "selection:created": this.selection,
      "selection:updated": this.selection,
      "mouse:down": this.selection,
      "mouse:over": this.mouseOver,
      "mouse:out": this.mouseOut,
    });
    // } else {
    // 	this.handler.canvas.on({
    // 		'mouse:move': this.mousemove,
    // 		'mouse:out': this.mouseout,
    // 		'mouse:up': this.mouseup,
    // 		'mouse:wheel': this.mousewheel,
    // 	});
    // }
  };
  // mouseout = (opt) => {
  //   const { onSelect, activeSelectionOption } = this.handler;
  //   const target = opt.target;
  //   if (target && target.type === "activeSelection") {
  //     target.set({
  //       ...activeSelectionOption,
  //     });
  //   }
  //   onSelect(target);
  // };
  // mouseOver = (opt) => {
  //   const target = opt.target;
  //   if (target) {
  //     // target?.set("fill", "#414257");
  //     // var p = canvas.getPointer(e.e);

  //     const params = {
  //       left: target?.left,
  //       top: target?.top,
  //       visible: true,
  //     };
  //     this.handler.onMouseOver(params, target.id);
  //   }
  // };
  // mouseOut = (opt) => {
  //   const target = opt.target;
  //   const params = {
  //     visible: false,
  //   };
  //   this.handler.onMouseOut(params);
  // };
  selection = (opt) => {
    const { onSelect, activeSelectionOption } = this.handler;
    const target = opt.target;
    console.log("data", onSelect, activeSelectionOption, target);
    if (target && target.type === "activeSelection") {
      target.set({
        ...activeSelectionOption,
      });
    }
    if (onSelect) {
      onSelect(target);
    }
  };
}

export default EventHandler;
