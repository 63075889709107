import React, { useEffect, useState } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./styles.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
// import Modal from "../../../components/detailModal/Modal";
import { AdminAction } from "../../../redux/actions/Index";
import { formatDate } from "../../../services/utills/helper";
// import Modal from "../../../components/AddSpaceModal/AddSpaceModal";
import { MenuItem, Select } from "@material-ui/core";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import OrderManagementComponent from "../../../components/orderManagement";
import { VendorDetailModal } from "../../../components/ObjectModal";
import { AddVendorModal } from "../../../components/Modals";
import { ValidateAddVendorForm } from "./components/addNewVendorModal/validation";

const VendorManagement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filter, setFilter] = useState("all");
  const [formattedOrders, setFormattedOrder] = useState([]);
  const { vendors, categories } = useSelector((state) => state.AdminReducer);

  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const [tableData, setTableData] = useState([]);
  const [openVendorModal, setVendorModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState();
  const [openAddVendorModal, setAddVendorModal] = useState(false);
  const [newVendorDetails, setNewVendorDetails] = useState({});
  const [categoryList, setCategoryList] = useState({});

  const [errorData, setError] = React.useState();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        dispatch(AdminAction.getAllVendors());
      }
    })();
  }, [user, userUpdated]);

  useEffect(() => {
    getTableData();
  }, [vendors]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const getTableData = () => {
    console.log("results getTableData :>> ", vendors);

    let finalData =
      vendors &&
      vendors.map((data) => {
        return {
          id: data["_id"],
          "Vendor Name": data["firstName"],
          Email: data["email"],
          "Vendor Id": data["vendorId"],
        };
      });

    setFormattedOrder(finalData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "Brand" ||
      // name === "Ownership" ||
      name === "Management" ||
      // name === "Chain" ||
      name === "Contract"
    )
      setCategoryList({
        ...categoryList,
        [name]: value,
      });
    else
      setNewVendorDetails({
        ...newVendorDetails,
        [name]: value,
      });
  };

  const handleClick = (item) => {
    console.log("item for vendor  :>> ", item);
    // // item = {...item}
    // const selectedItem = vendors.find((x) => x._id === item.id);
    // // item = { ...item, ...selectedItem };
    // console.log("selectedItem", selectedItem);
    // setSelectedVendor(selectedItem);
    // // dispatch(AdminAction.orderDetails(item));
    // setVendorModal(true);
    // // history.push(`${ROUTES.ORDER_MANAGEMENT_DETAILS}`);
    props.history.push(`/vendor/details/${item.id}`);
    // props.history.push(`/venue-management`);
  };

  const handleClickVendor = () => {
    props.history.push(`/add-new-vendor`);
  };

  const handleAddVendorSubmit = async (e) => {
    setloading(true);
    const result = await ValidateAddVendorForm({
      ...newVendorDetails,
      ...categoryList,
    });
    if (result?.isFormValid) {
      setError({});

      let params = {
        categoryList,
        newVendorDetails,
      };
      await dispatch(AdminAction.createVendor(params));
      setAddVendorModal(false);
      setNewVendorDetails({});

      setloading(false);
    } else if (result?.isFormValid === false) {
      console.log("err", result.error);

      setError(result?.error);
      setloading(false);

      return;
    }
  };

  return (
    <div>
      <div className="start vendorMgmt">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Vendor Management" />
          <div className="addNewVendorVenMgmt">
            <Button
              // className="col-lg-3"
              // background="primary"
              color="primary"
              name="Add New Vendor"
              // handleClick={() => {
              // 	setAddVendorModal(true);
              // }}
              handleClick={handleClickVendor}
            ></Button>
          </div>
          <OrderManagementComponent
            tableData={formattedOrders}
            tableHead={["Vendor Name", "Email", "Vendor Id"]}
            handleClick={handleClick}
          />
        </div>
        <VendorDetailModal
          open={openVendorModal}
          title="Header Title"
          handleClose={() => setVendorModal(false)}
          data={selectedVendor}
        />
        <AddVendorModal
          id={selectedVendor?.id}
          open={openAddVendorModal}
          handleClose={() => {
            setAddVendorModal(false);
            setNewVendorDetails(null);
            setError({});
            // setData();
          }}
          categories={categories}
          loading={loading}
          errorData={errorData}
          state={newVendorDetails}
          handleChange={handleChange}
          handleSave={handleAddVendorSubmit}
          handleDelete={() => {
            setAddVendorModal(false);
            // setData();
            setError({});

            setNewVendorDetails(null);
          }}
        />
      </div>
    </div>
  );
};

export default VendorManagement;
