import React, { useEffect, useState } from "react";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./HeaderBar.css";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Account from "../account/Account";
const HearderBar = (props) => {
  return (
    <div className=" topnavdivlist fixtopnavdivlist">
      <ul className="mb-0">
        <li className="firtLitop" style={{ listStyle: "none" }}>
          <ul className="d-flex flex-row listHeaderBar pt-4">
            <li className="listHeaderBar1">
              <h3>{props.title}</h3>
            </li>

            <li className="listHeaderBar3">
              <AccountCircleIcon className=" mr-2" />
              <Account className="headerBarIcon ml-1" />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
export default HearderBar;
