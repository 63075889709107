import React, { useEffect, useState } from "react";
import "./FAQ.css";
import Header from "../../Authentication/Header";
import { styled } from "@mui/material/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { data } from "./data";
import { ArrowBackSharp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../services/constants/Index";
import { SiteAction } from "../../../redux/actions/Index";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "7px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
  margin: "12px 2px",
  padding: "8px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownwardIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: "0 10px",
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255,0.5)"
      : // : "rgba(0, 0, 0, .03)",
        "rgba(255,255,255)",
  flexDirection: "row",
  // color: theme.palette.mode === "dark" ? "rgba(0,0,0)" : "red",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    color: "var(--main-bg-color)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "var(--main-bg-color)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#414257",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "18px",
    width: "128px",
    justifyContent: "space-between",
    margin: "20px auto 20px 20px",
    ["@media (max-width: 460px)"]: {
      // margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

const FAQ = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState("panel1");
  const { venueId } = useSelector((state) => state.CustomerReducer);
  const { faqContent } = useSelector((state) => state.SiteReducer);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(SiteAction.getFaqContent());
  }, []);
  return (
    <div className="FAQWrapper">
      <Header />
      <div
        className={classes.arrow}
        onClick={() => {
          if (venueId && venueId != "")
            history.push(`${ROUTES.VENUE}/${venueId}`);
          else history.goBack();
        }}
      >
        <ArrowBackSharp /> Back to Map
      </div>
      <p className="FAQHeading">FAQ's</p>
      <div className="FAQWrapperInner">
        <div />
        {faqContent &&
          faqContent.length > 0 &&
          faqContent.map((data) => {
            return (
              <Accordion
                expanded={expanded === `{panel${data._id}}`}
                onChange={handleChange(`{panel${data._id}}`)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{data.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{data.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
      <div className="FAQInfo">
        <p>
          We thank you for your interest and please direct any questions to{" "}
          <span style={{ color: "blue" }}>help@brandevent.store</span>
        </p>
      </div>
    </div>
  );
};

export default FAQ;
