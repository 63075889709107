import React, { useEffect, useState } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./styles.css";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackSharp, ArrowLeft } from "@material-ui/icons";
import dummyImage from "../../../images/NewIcons/NewIcon3.png";
import Canvas from "../../Story/NewDesign/index";
import { useParams } from "react-router-dom";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import InputField from "../../../components/input/Input";
import PhoneInput from "react-phone-input-2";
import states from "../../Story/CustomerInformation/data.json";
import { ValidateAddVenueForm } from "../addNewVenue/Validation";
import { ValidateCustomerForm } from "./validation";
import OrderManagementComponent from "../../../components/orderManagement";
import {
	formatDate,
	formatUsCurrency,
	formatUSNumber,
} from "../../../services/utills/helper";

const useStyles = makeStyles({
	root: {
		display: "flex",
	},
	arrow: {
		display: "flex",
		color: "#fff",
		alignItems: "center",
		cursor: "pointer",
		fontSize: "18px",
		width: "70px",
		justifyContent: "space-between",
		margin: "20px auto 20px 20px",
		["@media (max-width: 460px)"]: {
			// margin: "40px auto 20px 0px",
			border: "1px solid white",
			padding: "5px",
			justifyContent: "flex-end",
		},
	},
});

const CustomerDetails = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector((state) => state.AuthReducer.userData);
	const { userUpdated } = useSelector((state) => state.AuthReducer);

	const { customerDetails } = useSelector((state) => state.AdminReducer);

	const [state, setState] = useState({});
	const [newState, setNewState] = useState({});
	const [editable, setEditable] = useState(false);

	const [errorData, setErrorData] = useState({});
	const [prevOrder, setPrevOrder] = useState(false);
	const [formattedOrders, setFormattedOrder] = useState([]);

	const params = useParams();

	console.log("venue Match params", params);
	useEffect(() => {
		(async () => {
			if (user?._id && userUpdated) {
				dispatch(AdminAction.getCustomerDetails(params?.customerId));
			}
		})();
	}, [user, userUpdated]);

	useEffect(() => {
		const {
			adSpace,
			graphicData,
			createdAt,
			password,
			isActive,
			role,
			url,
			// vendorId,
			orders,
			...rest
		} = customerDetails;
		setState(rest);
		setTable();
	}, [customerDetails]);

	console.log("customerDetails", customerDetails);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setState({
			...state,
			[name]: value,
		});
	};

	const handleClick = (item) => {
		// item = {...item}
		const selectedItem = customerDetails?.orders?.find(
			(x) => x._id === item.Id
		);
		item = { ...item, ...selectedItem };
		console.log("selectedItem", selectedItem);
		// dispatch(AdminAction.orderDetails(item));
		dispatch({
			type: ACTION_TYPE.ADMIN_ALL_ORDERS_DETAILS,
			payload: {
				data: selectedItem,
			},
		});

		history.push(`/order-management/order-details/${item._id}`);

		// history.push(`${ROUTES.CUSTOMER_ORDER_DETAILS}/${item._id}`);
	};

	const handleSave = async (e) => {
		console.log("state", state);

		e.preventDefault();
		e.stopPropagation();
		// setloading(true);

		const result = await ValidateCustomerForm(state);
		console.log("result", result);
		if (!result?.isFormValid) {
			setErrorData(result?.error);
			// setloading(false);
			return;
		}
		setErrorData({});

		const { isEmailVerified, _id, ...rest } = state;

		let params = {
			...rest,
		};

		await dispatch(AdminAction.updateCustomer(params, _id));
		setEditable(false);
		// setloading(false);
		// props.history.push(ROUTES.VENUE_MANAGEMENT);
	};

	const setTable = () => {
		const formattedTableData = customerDetails?.orders?.map((elm) => ({
			Id: elm._id,

			OrderId: elm?.orderId,
			Venue: elm?.venueId[0]?.firstName,
			Vendor: elm?.vendorId[0]?.firstName,

			// Price: "$" + Number(elm?.price).toFixed(2),
			Price: formatUsCurrency(elm?.price),
			Status: elm?.status,
			"Order Date": formatDate(elm?.CreatedAt),
			"Start Date": formatDate(elm?.eventData?.eventStartDate),
		}));
		setFormattedOrder(formattedTableData);
	};

	return (
		<div>
			<div className="start">
				{user?.role && (
					<RBAC
						userRole={user?.role} // User roles that are given to user in the backend
						initList={initList}
						renderNoAccess={() => <SideNav />}
					>
						<SideNav />
					</RBAC>
				)}
				<div className="screen">
					<HeaderBar title="Customer Management / Customer Details" />
					<div className="customerDetailsWrapper ">
						<div className="d-flex align-items-center">
							<div
								className={classes.arrow}
								onClick={() => {
									history.goBack();
								}}
							>
								<ArrowBackSharp /> Back
							</div>
							<div className="edit">
								<Button
									background="secondary"
									color="secondary"
									name="Edit Details"
									handleClick={() => setEditable(true)}
								/>
							</div>
						</div>

						<div className="customerDetailsTab">
							<div
								className={
									prevOrder === false
										? "customerDetailsTabInner"
										: "customerDetailsTabInnerSelected"
								}
								onClick={() => setPrevOrder(false)}
							>
								Personal Information
							</div>
							<div
								className={
									prevOrder === true
										? "customerDetailsTabInner"
										: "customerDetailsTabInnerSelected"
								}
								onClick={() => setPrevOrder(true)}
							>
								Orders
							</div>
						</div>
						{prevOrder === false ? (
							<div className="customerDetailsWrapperInner">
								{/* <div className="customerDetailsUpper"> */}

								<div className="customerDetailsLower">
									<div className="customerDetailsDataDiv">
										<div className="customerDetailsReAlignTwo">
											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">First Name : </p>

												{editable === true ? (
													<div className="customerDetailsReAlignInput">
														<InputField
															className=""
															label=""
															type="text"
															id="custom-css-outlined-input"
															name="firstName"
															placeholder="Enter venue county"
															value={state?.firstName}
															handleChange={handleChange}
														/>
														{errorData?.firstName && errorData.firstName[0] ? (
															<p className="errorMsg">
																{" "}
																{errorData?.firstName && errorData.firstName[0]}
															</p>
														) : null}
													</div>
												) : (
													<p className="detail-key text-right mb-0">
														{customerDetails?.firstName}
													</p>
												)}
											</div>

											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">Last Name : </p>

												{editable === true ? (
													<div className="customerDetailsReAlignInput">
														<InputField
															className=""
															label=""
															type="text"
															id="custom-css-outlined-input"
															name="lastName"
															placeholder="Enter venue county"
															value={state?.lastName}
															handleChange={handleChange}
														/>
														{errorData?.lastName && errorData.lastName[0] ? (
															<p className="errorMsg">
																{" "}
																{errorData?.lastName && errorData.lastName[0]}
															</p>
														) : null}
													</div>
												) : (
													<p className="detail-key text-right mb-0">
														{customerDetails?.lastName}
													</p>
												)}
											</div>
										</div>
										<div className="customerDetailsReAlignTwo">
											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">Customer ID : </p>

												<p className="detail-key text-right mb-0">
													{customerDetails?._id}
												</p>
											</div>
										</div>

										<div className="customerDetailsReAlignTwo">
											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">Email : </p>
												{/* {editable === true ? (
                          <div className="customerDetailsReAlignInput">
                            <InputField
                              className=""
                              label=""
                              type="text"
                              id="custom-css-outlined-input"
                              name="email"
                              placeholder="Enter venue county"
                              value={state?.email}
                              handleChange={handleChange}
                            />
                            {errorData?.email && errorData.email[0] ? (
                              <p className="errorMsg">
                                {" "}
                                {errorData?.email && errorData.email[0]}
                              </p>
                            ) : null}
                          </div>
                        ) : ( */}
												<p className="detail-key text-right mb-0">
													{customerDetails?.email}
												</p>
												{/* )} */}
											</div>
											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">Phone : </p>
												{editable === true ? (
													<div className="customerDetailsReAlignInput">
														<PhoneInput
															className="addvenueInput"
															country={"us"}
															onlyCountries={["us"]}
															value={state?.phone}
															placeholder="Enter venue phone"
															inputClass="phoneInput"
															buttonClass="phoneButton"
															dropdownClass="dropdownClass"
															disableDropdown="true"
															onChange={(phone) =>
																setState({
																	...state,
																	phone,
																})
															}
														/>

														<p className="errorMsg">
															{" "}
															{errorData?.phone && errorData.phone[0]}
														</p>
													</div>
												) : (
													<p className="detail-key text-right mb-0">
														{formatUSNumber(customerDetails?.phone)}
													</p>
												)}
											</div>
										</div>
										<div className="customerDetailsReAlignTwo">
											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">Street : </p>
												{editable === true ? (
													<div className="customerDetailsReAlignInput">
														<InputField
															className=""
															label=""
															type="text"
															id="custom-css-outlined-input"
															name="Street"
															placeholder="Enter venue county"
															value={state?.Street}
															handleChange={handleChange}
														/>
														{errorData?.Street && errorData.Street[0] ? (
															<p className="errorMsg">
																{" "}
																{errorData?.Street && errorData.Street[0]}
															</p>
														) : null}
													</div>
												) : (
													<p className="detail-key text-right mb-0">
														{customerDetails?.Street}
													</p>
												)}
											</div>

											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">City : </p>
												{editable === true ? (
													<div className="customerDetailsReAlignInput">
														<InputField
															className=""
															label=""
															type="text"
															id="custom-css-outlined-input"
															name="city"
															placeholder="Enter venue county"
															value={state?.city}
															handleChange={handleChange}
														/>
														{errorData?.city && errorData.city[0] ? (
															<p className="errorMsg">
																{" "}
																{errorData?.city && errorData.city[0]}
															</p>
														) : null}
													</div>
												) : (
													<p className="detail-key text-right mb-0">
														{customerDetails?.city}
													</p>
												)}
											</div>
										</div>
										<div className="customerDetailsReAlignTwo">
											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">State : </p>
												{editable === true ? (
													<div className="customerDetailsReAlignInput">
														<div className="addVenueAddDataRight changeMaxWidth">
															<select
																value={state?.state}
																style={{
																	padding: "15px 10px",
																	height: "50px",
																	border: "1px solid",
																}}
																name="state"
																className="form-control "
																onChange={handleChange}
															>
																<option selected>Select State </option>
																{states &&
																	states.map((data) => {
																		return (
																			<option value={data.Code}>
																				{data.Code}
																			</option>
																		);
																	})}
															</select>

															<p className="errorMsg">
																{" "}
																{errorData?.state && errorData.state[0]}
															</p>
														</div>
													</div>
												) : (
													<p className="detail-key text-right mb-0">
														{customerDetails?.state}
													</p>
												)}
											</div>

											<div className="customerDetailsReAlignTwoInner">
												<p className="detail-label">Zip : </p>
												{editable === true ? (
													<div className="customerDetailsReAlignInput">
														<InputField
															className=""
															label=""
															type="text"
															id="custom-css-outlined-input"
															name="zipCode"
															placeholder="Enter venue zip"
															value={state?.zipCode}
															handleChange={handleChange}
														/>
														{errorData?.zipCode && errorData.zipCode[0] ? (
															<p className="errorMsg">
																{" "}
																{errorData?.zipCode && errorData.zipCode[0]}
															</p>
														) : null}
													</div>
												) : (
													<p className="detail-key text-right mb-0">
														{customerDetails?.zipCode}
													</p>
												)}
											</div>
										</div>

										<div className="customerDetailsReAlignTwo">
											{/* 
                      <div className="customerDetailsReAlignTwoInner">
                        <p className="detail-label">County : </p>
                        {editable === true ? (
                          <div className="customerDetailsReAlignInput">
                            <InputField
                              className=""
                              label=""
                              type="text"
                              id="custom-css-outlined-input"
                              name="country"
                              placeholder="Enter venue county"
                              value={state?.country}
                              handleChange={handleChange}
                            />
                            {errorData?.country && errorData.country[0] ? (
                              <p className="errorMsg">
                                {" "}
                                {errorData?.country && errorData.country[0]}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <p className="detail-key text-right mb-0">
                            {customerDetails?.country}
                          </p>
                        )}
                      </div> */}
										</div>
									</div>
								</div>
								{editable === true ? (
									<div className="d-flex justify-content-around pb-3">
										<Button
											background="secondary"
											color="secondary"
											name="Save"
											handleClick={handleSave}
										/>

										<Button
											name="Cancel"
											handleClick={() => setEditable(false)}
										/>
									</div>
								) : null}
							</div>
						) : (
							<div className="">
								<div>
									<OrderManagementComponent
										// filter={filter}
										// handleChangeFilter={handleChangeFilter}
										// filterList={renderFilterList}
										tableData={formattedOrders}
										tableHead={[
											"OrderId",
											"Venue",
											"Vendor",
											"Price",
											"Order Date",
											"Start Date",
											"Status",
										]}
										handleClick={handleClick}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomerDetails;
