function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateCustomerForm = (formData) => {
  console.log("formmmmmmmmmm", formData);
  const {
    firstName,
    lastName,
    email,

    city,
    groupName,
    eventName,
    eventSize,
    eventStartDate,
    phone,
    state,
    Street,
    zipCode,
  } = formData || {};
  let error = {
    firstName: [],
    lastName: [],
    email: [],
    companyName: [],
    city: [],
    country: [],
    phone: [],
    state: [],
    Street: [],
    zipCode: [],
    groupName: [],
    eventName: [],
    eventSize: [],
    eventStartDate: [],
  };
  let isFormValid = true;
  // console.log("Sd", zipCode.length, zipCode.length > 0, zipCode.length > 7);
  if (isBlank(firstName) === true) {
    isFormValid = false;
    error.firstName = ["This field is required."];
  } else if (firstName?.trim().length > 0 && firstName?.trim().length > 50) {
    isFormValid = false;
    error.firstName = ["Name cannot be greater than 50."];
  }
  if (isBlank(lastName) === true) {
    isFormValid = false;
    error.lastName = ["This field is required."];
  } else if (lastName?.trim().length > 0 && lastName?.trim().length > 50) {
    isFormValid = false;
    error.lastName = ["Last Name cannot be greater than 50."];
  }

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  } else if (
    email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["This must be a valid email address."];
  }

  if (isBlank(city) === true) {
    isFormValid = false;
    error.city = ["This field is required."];
  } else if (city?.trim().length > 0 && city?.trim().length > 50) {
    isFormValid = false;
    error.city = ["City name cannot be greater than 50."];
  }
  // if (isBlank(companyName) === true) {
  //   isFormValid = false;
  //   error.companyName = ["This field is required."];
  // } else if (
  //   companyName?.trim().length > 0 &&
  //   companyName?.trim().length > 50
  // ) {
  //   isFormValid = false;
  //   error.companyName = ["companyName name cannot be greater than 50."];
  // }
  // if (isBlank(country) === true) {
  //   isFormValid = false;
  //   error.country = ["This field is required."];
  // } else if (country?.trim().length > 0 && country?.trim().length > 50) {
  //   isFormValid = false;
  //   error.country = ["Country name cannot be greater than 50."];
  // }
  if (isBlank(phone) === true) {
    isFormValid = false;
    error.phone = ["This field is required."];
  } else if (isNumber(phone) === false) {
    isFormValid = false;
    error.phone = ["This field should be number."];
  }

  if (isBlank(state) === true) {
    isFormValid = false;
    error.state = ["This field is required."];
  } else if (state?.trim().length > 0 && state?.trim().length > 50) {
    isFormValid = false;
    error.state = ["phone name cannot be greater than 50."];
  }
  if (isBlank(zipCode) === true) {
    isFormValid = false;
    error.zipCode = ["This field is required."];
  } else if (isNumber(zipCode) === false) {
    isFormValid = false;
    error.zipCode = ["This field is should be number."];
  } else if (zipCode.length > 0 && zipCode.length > 7) {
    isFormValid = false;
    error.zipCode = ["Zip code name cannot be greater than 6."];
  }
  if (isBlank(Street) === true) {
    isFormValid = false;
    error.Street = ["This field is required."];
  } else if (Street?.trim().length > 0 && Street?.trim().length > 50) {
    isFormValid = false;
    error.Street = ["phone name cannot be greater than 50."];
  }
  if (isBlank(eventName) === true) {
    isFormValid = false;
    error.eventName = ["This field is required."];
  }
  if (isBlank(eventSize) === true) {
    isFormValid = false;
    error.eventSize = ["This field is required."];
  }
  if (isBlank(eventStartDate) === true) {
    isFormValid = false;
    error.eventStartDate = ["This field is required."];
  }
  if (isBlank(groupName) === true) {
    isFormValid = false;
    error.groupName = ["This field is required."];
  }

  return { isFormValid, error };
};
