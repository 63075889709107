import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import logo from "../../images/commonImage/LOGO2.png";
import MenuIcon from "@material-ui/icons/Menu";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// import { initList, initListAdmin } from "./InitList";
import "./SideNav.css";
const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  activeClass: {
    "& .MuiTypography-body1": {
      color: "#ffffff  !important",
      fontFamily: "Whitney Medium , Sans-Serif",
    },

    "&": {
      background: " #414257 ",
      borderRadius: "4px",
      color: "#ffffff !important",
      textDecoration: "none !important",
      fontFamily: "Whitney Medium , Sans-Serif",

      " & svg": {
        color: "white !important",
      },
      " & li": {
        color: "white !important",
      },

      "& .MuiListItem-button": {
        background: " #414257",
        borderRadius: "4px",
        color: "#fff !important",
        fontFamily: "Whitney Medium , Sans-Serif",

        // margin: "4px 16px 4px 4px"
      },
      "& .MuiButtonBase-root.MuiListItem-button": {
        padding: "6px 8px",
        margin: "2px 0",
      },
    },
  },
  listitem: {
    cursor: "pointer",
    fontFamily: "Whitney Medium , Sans-Serif",

    "& svg": {
      color: "#130C2D",
    },
    "&:hover": {
      background: " #414257 ",
      borderRadius: "4px",
      color: "#ffffff !important",
      textDecoration: "none !important",
      "& svg": {
        color: "white",
      },
      "& li": {
        color: "white",
      },
      "& .MuiListItem-button:hover": {
        background: " #414257",
        borderRadius: "4px",
        color: "#fff !important",
      },
    },
  },

  drawer: {
    flexShrink: 0,
    height: "100%",
    whiteSpace: "nowrap",
    "& .MuiListItemIcon-root": {
      minWidth: "auto",
      margin: "0 auto",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      display: "flex",
      flexDirection: "column",
      // padding:"0px 8px"
    },
  },
  drawerOpen: {
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "& .MuiListItem-gutters": {
      paddingLeft: " 8px",
      paddingRight: "8px",
    },
  },
  drawerClose: {
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    overflowX: "revert",
    overflowY: "revert",
    position: "relative",
    minWidth: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      minWidth: theme.spacing(9) + 1,
    },
    width: "100%",
    "& .MuiListItemText-root": {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icon: {
    maxWidth: "100%",
    width: "150px",
    margin: "0 auto",
  },
  closeIcon: {
    width: "45px",
    marginBottom: "20px",
  },
  title: {
    //color: "#130C2D",
    overflow: "hidden",
    fontWeight: "600",
    marginLeft: "10px",
    whiteSpace: "initial",
    fontFamily: "Whitney Medium , Sans-Serif",

    "& .MuiTypography-body1": {
      fontSize: "14px",
      fontWeight: "500",

      "&:hover": {
        color: "#ffffff",
      },
    },
  },
  tagLine: {
    backgroundColor: "rgba(65, 66, 87, 0.1)",
    borderRadius: "4px",
    padding: "6px 15px",
    marginBottom: "25px",
    fontWeight: "600",
    marginTop: "15px",
    whiteSpace: "pre-line",
    textAlign: "center",
    color: "#414257",
    // border: "1px solid #414257",
    fontFamily: "Whitney Medium , Sans-Serif",
  },

  list: {
    height: "100%",

    "& ul": {
      height: "100%",
      position: "relative",
    },
    "& .MuiTypography-body1": {
      fontFamily: "Spartan !important",
    },
  },

  button: {
    outLine: "unset",
  },
  "& .MuiButtonBase-root:focus": {
    outLine: "unset !important",
  },
}));

function MiniDrawer(props) {
  let initList = props[0];
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(true);
  const [name, setName] = useState();
  const { userData } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    setName(userData?.firstName);
  }, [userData?.firstName]);
  return (
    <div className="sideNavExpand">
      <div className="showNavParentRoot">
        <div className="menuItems">
          <MenuIcon onClick={() => setShow(!show)} />
          <img alt="logo" src={logo} className="logoMobile" />
        </div>
        {show ? (
          <div>
            {" "}
            <CssBaseline />
            <Drawer
              variant="permanent"
              //className={classes.drawer}
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx(
                  {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  },
                  "drawerfixed"
                ),
              }}
            >
              <div className={classes.list}>
                <List>
                  <ListItem>
                    <ListItemIcon
                      className={classes.top}
                      style={{ maxWidth: "100%" }}
                    >
                      {/* <div style={{ width: "100%" }}> */}
                      <img
                        src={logo}
                        alt="sidenavImg"
                        className={classes.icon}
                      />
                      {/* </div> */}
                    </ListItemIcon>
                    <span style={{}} className={classes.tagLine}>
                      {/* Logged in as shivani solanki */}
                      Logged in as {userData["firstName"]}
                      {/*  {!name === "" || !name === null || !name === undefined
                        ? name
                        : "ADMIN TEST"} */}
                    </span>
                  </ListItem>

                  {initList.map((text, index) => (
                    <Link
                      to={text.route}
                      // activeClassName={classes.activeClass}
                      // className={classes.listitem}
                      className={clsx({
                        [classes.listitem]: true, //always applies
                        [classes.activeClass]: text.active.includes(
                          props.match.path
                        ), //only when open === true
                      })}
                    >
                      <ListItem button key={text.index}>
                        <ListItemText
                          className={clsx(classes.title)}
                          primary={text.title}
                        />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </div>
            </Drawer>
            <main className={classes.content}>
              <div className={classes.toolbar} />
            </main>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(MiniDrawer);
