import React, { useEffect, useState } from "react";
import { CardComponent } from "../../../components/card/Card";
import SideNav from "../../../components/sideNav/SideNav";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { CustomerAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./MyOrderDetails.css";
import Button from "../../../components/button/Button";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import {
	VenueDetailModal,
	CustomerDetailModal,
	AdSpaceDetailModal,
	VendorDetailModal,
} from "../../../components/ObjectModal";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { styled } from "@mui/material/styles";
import {
	AdSpaceAccordian,
	CustomerDetailAccordian,
	VendorDetailAccordian,
	VenueDetailAccordian,
} from "../../../components/ObjectAccordian";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { formatUsCurrency } from "../../../services/utills/helper";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderRadius: "7px",
	boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
	margin: "12px 2px",
	padding: "8px",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowDownwardIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === "dark"
			? "rgba(255, 255, 255,0.5)"
			: // : "rgba(0, 0, 0, .03)",
			  "rgba(255,255,255)",
	flexDirection: "row",
	// color: theme.palette.mode === "dark" ? "rgba(0,0,0)" : "red",

	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(180deg)",
		color: "var(--main-bg-color)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
	"& .MuiAccordionSummary-content.Mui-expanded": {
		color: "var(--main-bg-color)",
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(3),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const OrderDetailsPage = (props) => {
	const history = useHistory();
	const { orderDetail } = useSelector((state) => state.CustomerReducer);
	const user = useSelector((state) => state.AuthReducer.userData);
	const [openCustomerModal, setCustomerModal] = useState(false);
	const [openVendorModal, setVendorModal] = useState(false);

	const [open, setOpen] = useState(false);
	const [expanded, setExpanded] = React.useState({});

	const handleChange = (panel) => {
		setExpanded({
			...expanded,
			[panel]: !expanded[panel] || false,
		});
	};

	const [detailsModel, setDetailsModel] = useState(false);
	const [id, setId] = useState();
	const [modalDetails, setModalDetails] = useState({
		type: null,
		// message: null,
		// data: {},
		// action: null,
	});

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			console.log("props.match.params", props.match.params);
			if (props.match.params.id) {
				const res = await dispatch(
					CustomerAction.getOrderDetails(props.match.params.id)
				);
			}
		})();
	}, []);

	return (
		<div className="start">
			{user?.role && (
				<RBAC
					userRole={user?.role} // User roles that are given to user in the backend
					initList={initList}
					renderNoAccess={() => <SideNav />}
				>
					<SideNav />
				</RBAC>
			)}
			<div className="screen">
				<HeaderBar title="My Orders/ Order Details" />

				<div className="cardProfile ordercardProfile">
					<div className="fullDiv d-flex">
						<div className="backIcon mr-2" onClick={() => history.goBack()}>
							<ArrowBackIcon className="icon" />
						</div>
						Back
					</div>
					<div className="myOrderDetails">
						<div className="myOrderDetailsDiv">
							<div className="myOrderDetailsCard">
								<div className="myOrderDetailsCardItem">
									<h3>Order ID</h3>
									<p>{orderDetail?.orderId}</p>
								</div>
								<div className="myOrderDetailsCardItem SideBorder">
									<h3>Price</h3>
									{/* <p>${Number(orderDetail?.price).toFixed(2)}</p> */}
									<p>{formatUsCurrency(orderDetail?.price)}</p>
								</div>
								<div className="myOrderDetailsCardItem">
									<h3>Status</h3>
									<p>{orderDetail?.status}</p>
								</div>
							</div>
						</div>

						<div className="eventDataDetailsWrapp">
							{orderDetail && orderDetail.eventData && (
								<div className="eventDataDetails">
									{" "}
									<div className="eventDataDetailsStart">
										<p>Event Start date</p>
										{moment(orderDetail.eventData.eventStartDate).format(
											"MM/DD/YYYY"
										)}
									</div>
									<div className="eventDataDetailsGname">
										<p>Group Name</p>
										{orderDetail.eventData.groupName}
									</div>
									<div className="eventDataDetailsEname">
										<p>Event Name</p>
										{orderDetail.eventData.eventName}
									</div>
								</div>
							)}
						</div>

						<div className="adminOrderDetailsLower">
							<div
								className=" m-2 mr-3 align-self-end"
								style={{
									cursor: "pointer",
									borderBottom: " 1px solid #ff0000",
									fontSize: "12px",
									color: "#ff0000",
								}}
								onClick={() => {
									setExpanded({
										...expanded,
										panel1: true,
										panel2: true,
										panel3: true,
										panel4: true,
									});
								}}
							>
								<span
									style={{
										fontSize: "15px",
									}}
									// className=" "
								>
									Expand all
								</span>
							</div>
							<div className="adminOrderDetailsLowerWrapper">
								<div />
								<Accordion
									expanded={expanded["panel1"] === true}
									onChange={() => handleChange("panel1")}
								>
									<AccordionSummary
										aria-controls="panel1d-content"
										id="panel1d-header"
									>
										<Typography>
											Venue - {orderDetail?.venueId?.firstName}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<VenueDetailAccordian orderDetails={orderDetail} />
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={expanded["panel2"] === true}
									onChange={() => handleChange("panel2")}
								>
									<AccordionSummary
										aria-controls="panel1d-content"
										id="panel1d-header"
									>
										<Typography>Ad Space Names</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<AdSpaceAccordian orderDetails={orderDetail} />
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={expanded["panel3"] === true}
									onChange={() => handleChange("panel3")}
								>
									<AccordionSummary
										aria-controls="panel1d-content"
										id="panel1d-header"
									>
										<Typography>
											Customer - {orderDetail?.customerInfo?.firstName}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<CustomerDetailAccordian orderDetails={orderDetail} />
									</AccordionDetails>
								</Accordion>

								<Accordion
									expanded={expanded["panel4"] === true}
									onChange={() => handleChange("panel4")}
								>
									<AccordionSummary
										aria-controls="panel1d-content"
										id="panel1d-header"
									>
										<Typography>
											Vendor - {orderDetail?.vendorId?.firstName}{" "}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<VendorDetailAccordian orderDetails={orderDetail} />
									</AccordionDetails>
								</Accordion>
								<div />
							</div>
						</div>
						{/* <div className="myOrderDetailsInfo">
								<div className="myOrderDetailsBox">
									<div className="myOrderDetailsDetail">
										<h4>Venue name:</h4>
										<p
											style={{ color: "blue", cursor: "pointer" }}
											onClick={() =>
												setModalDetails({
													...modalDetails,
													type: "venue",
												})
											}
										>
											{orderDetail?.adspaces &&
												orderDetail?.adspaces[0]?.adspace?.venueId?.firstName}
										</p>
									</div>
									<div className="myOrderDetailsDetail myOrderDetailsDetailad ">
										<h4>Ad-Space:</h4>
										<p>
											{orderDetail?.adspaces?.map((data, index) => {
												return (
													<span
														className="adminOrderDetailsLowerWrapperRightPHover"
														style={{ color: "blue", cursor: "pointer" }}
														onClick={() => {
															console.log("data :>> ", data);
															setId(data._id);

															setDetailsModel(true);
														}}
													>
														{data?.adspace?.title}{" "}
													</span>
												);
											})}
										</p>
									</div>
									<div className="myOrderDetailsDetail">
										<h4>Customer Name:</h4>

										<p
											className="adminOrderDetailsLowerWrapperRightPHover"
											style={{ color: "blue", cursor: "pointer" }}
											onClick={() => setCustomerModal(!openCustomerModal)}
										>
											{orderDetail?.customerInfo?.firstName}{" "}
											{orderDetail?.customerInfo?.lastName}{" "}
										</p>
									</div>
								</div>
							</div> */}
						{/* <div className="adminOrderDetailsButtonGroup">
                  <Button
                    background="secondary" // two types
                    fontSize="15px"
                    border="1px solid #fff"
                    color="secondary"
                    name="Approve"
                    handleClick={() => {
                      handleAcceptRejectOrder("Approved");
                    }}
                  />
                  <Button
                    background="secondary" // two types
                    fontSize="15px"
                    border="1px solid #fff"
                    // disabled={item[1] == "Accepted" ? false : true}
                    color="secondary" //two types
                    name="Reject"
                    handleClick={() => {
                      handleAcceptRejectOrder("Rejected");
                    }}
                  />
                </div> */}

						<VenueDetailModal
							open={modalDetails["type"] === "venue"}
							title="Header Title"
							handleClose={() => {
								setModalDetails({
									...modalDetails,
									type: null,
								});
							}}
							data={orderDetail.venueId}
						/>
						<VendorDetailModal
							open={openVendorModal}
							title="Header Title"
							handleClose={() => setVendorModal(false)}
							data={orderDetail?.vendorId}
						/>
						<CustomerDetailModal
							open={openCustomerModal}
							title="Header Title"
							handleClose={() => setCustomerModal(false)}
							data={orderDetail.customerInfo}
						/>
						<AdSpaceDetailModal
							id={id}
							open={detailsModel}
							// previousPage="orderDetails"
							handleClose={() => {
								setDetailsModel(false);
							}}
							adSpaceData={orderDetail?.adspaces}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderDetailsPage;

// <CardComponent
//   title={[
//     "Order ID",
//     "Venue Name",
//     "Adspace Name",
//     "Price",
//     "Customer Name",
//     "Vendor Name",
//     "Status",
//   ]}
//   data={[
//     orderDetail?.data?._id,
//     orderDetail?.data?.adspaces[0]?.adspace?.venueId?.firstName,
//     adspaceName,
//     "$" + orderDetail?.data?.price,
//     orderDetail?.data?.customerId?.firstName,
//     orderDetail?.data?.adspaces[0]?.adspace?.venueId?.vendorId
//       ?.firstName,
//     orderDetail?.data?.status,
//   ]}
// />
