import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StaffAction } from "../../../../redux/actions/Index";
import NewDesign from "../../NewDesign";

const ChooseZone = ({ adSpaces, setAdSpaces }) => {
  const dispatch = useDispatch();

  const { _id, venueId } = useSelector((state) => state.AuthReducer.userData);
  const { maps } = useSelector((state) => state.StaffReducer);

  useEffect(() => {
    if (_id && venueId) {
      dispatch(StaffAction.getVenueMaps({ venueId }));
    }
  }, [_id]);

  const renderMaps = maps && maps.map((map) => <>Zone</>);

  return (
    <div>
      {maps && maps.length > 0
        ? maps
            // .slice(2, 3)
            .map((map, index) => (
              <div
                className="customercanvas newCustomerCanvas"
                key={map.mapId}
                style={{
                  paddingBottom: 20,
                  paddingTop: 10,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <NewDesign
                  map={map}
                  mapNum={index + 1}
                  adSpaces={adSpaces}
                  setAdSpaces={setAdSpaces}
                  venueId={venueId}
                />
              </div>
            ))
        : null}
    </div>
  );
};

export default ChooseZone;
