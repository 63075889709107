import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import "../AddDepartment/style.css";
import Button from "../../button/Button";
import { Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { saveSpecifyZone } from "../../../redux/actions/VenueAction";

const CreateZone = (props) => {
  const [state, setstate] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (state) {
      setError("");
    }
  }, [state]);

  const onCreateZone = (e) => {
    if (state) {
      console.log(
        "🚀 ~ file: createZone.js ~ line 27 ~ onCreateZone ~ state",
        state
      );
      props.handleAddZone(state);
      setError("");
      // setstate("")
      props.handleClose();
      return;
    }
    setError("Please enter zone");
  };

  return (
    <div className="addVendorWrapper">
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className="adSpaceModal"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div className="adSpacePopUp">
            <h1>Create Zone</h1>
          </div>
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <Divider />
          <div className="d-flex">
            <div className="addSpaceInputs adSpace">
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">Add Zone</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  <input
                    label="Department Name"
                    type="text"
                    id="custom-css-outlined-input"
                    name="zone"
                    className="my-2"
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    style={{ width: "80%" }}
                  />

                  {error ? <p className="errorMsg"> {error}</p> : null}
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="adspacebuttonGroup">
          <Button
            background="secondary"
            fontSize="15px"
            border="1px solid #fff"
            color="secondary"
            name="Save "
            // loading={props.loading}
            handleClick={(e) => {
              onCreateZone(e);
            }}
          />
          <Button
            background="secondary"
            fontSize="15px"
            border="1px solid #fff"
            color="secondary" //two types
            name="Cancel"
            handleClick={() => {
              props.handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CreateZone;
