export const getCurrentStepTitle = (step) => {
  switch (step) {
    case 1:
      return "Input Customer Information";

    case 2:
      return "Select zones/addpsaces to generate link";
    case 3:
      return "Order Generated";
    case 4:
      return "Confirmation Email";
    case 5:
      return "Mail Sent";
    default:
      break;
  }
};
