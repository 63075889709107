import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./AddNewVendor.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { Link, useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackSharp, ArrowLeft, PinDropSharp } from "@material-ui/icons";
import dummyImage from "../../../images/NewIcons/NewIcon3.png";
import InputField from "../../../components/input/Input";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Divider,
} from "@material-ui/core";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ValidateAddVendorForm, ValidateAddVenueForm } from "./Validation";
import axios from "axios";
import {
  AddVendorModal,
  AddBrand,
  AddOwnerShip,
  AddManagement,
  AddContract,
  AddChain,
} from "../../../components/Modals";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
import states from "../../Story/CustomerInformation/data.json";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    width: "70px",
    justifyContent: "space-between",
    margin: "20px auto 0px 20px",
    cursor: "pointer",
    fontSize: "18px",
    ["@media (max-width: 460px)"]: {
      margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

const AddNewVendor = (props) => {
  const venueImageUpload = useRef();
  const logoUpload = useRef();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);
  const { categories } = useSelector((state) => state.AdminReducer);
  // const {  } = useSelector((state) => state.AdminReducer);

  const [vendorDetails, setVendorDetails] = useState({});

  const [loading, setloading] = useState(false);
  const [phone, setPhone] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [errorData, setError] = useState();

  const [openAddBrandModal, setAddBrandModal] = useState(false);
  const [openAddManagementModal, setAddManagementModal] = useState(false);
  const [openContractModal, setAddContractModal] = useState(false);

  const [categoryList, setCategoryList] = useState({});
  const [vendorCategoryList, setVendorCategoryList] = useState({});

  const [newDetails, setNewDetails] = useState({});

  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        dispatch(AdminAction.getCategoriesByRole("vendor"));
      }
    })();
  }, [user, userUpdated]);

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    console.log("name, value handleCategoryChange :>> ", name, value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name, value :>> ", name, value);
    // if (value == null) return;
    if (name === "Brand" || name === "Management" || name === "Contract")
      setCategoryList({
        ...categoryList,
        [name]: value,
      });
    else
      setVendorDetails({
        ...vendorDetails,
        [name]: value,
      });
  };

  //   const handleVendorFormChange = (e) => {
  //     const { name, value } = e.target;
  //     if (
  //       name === "Brand" ||
  //       name === "Ownership" ||
  //       name === "Management" ||
  //       name === "Chain" ||
  //       name === "Contract"
  //     )
  //       setVendorCategoryList({
  //         ...vendorCategoryList,
  //         [name]: value,
  //       });
  //     else
  //       setNewVendorDetails({
  //         ...newVendorDetails,
  //         [name]: value,
  //       });
  //   };

  const handleSave = async (e) => {
    setloading(true);
    const result = await ValidateAddVendorForm({
      ...vendorDetails,
      ...categoryList,
    });
    if (result?.isFormValid) {
      setError({});
      let params = {
        newVendorDetails: vendorDetails,
        categoryList,
      };
      const respp = await dispatch(AdminAction.createVendor(params));

      setloading(false);

      if (respp) props.history.push(ROUTES.VENDOR_MANAGEMENT);

      // props.history.push(ROUTES.VENDOR_MANAGEMENT);
    } else if (result?.isFormValid === false) {
      console.log("err", result.error);

      setError(result?.error);
      setloading(false);

      return;
    }
  };

  const handleSaveCategory = async (category) => {
    console.log("category", category, newDetails[category]);

    let params = {
      name: category,
      value: newDetails[category],
    };
    await dispatch(AdminAction.addCategory(params));
    switch (category) {
      case "Brand":
        setAddBrandModal(false);
        break;

      case "Management":
        setAddManagementModal(false);
        break;

      case "Contract":
        setAddContractModal(false);
        break;

      default:
        break;
    }
  };

  const handleNewDetailsChange = (e) => {
    const { name, value } = e.target;

    setNewDetails({
      ...newDetails,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Vendor Management / Add Vendor " />
          <div className="vendorDetailsWrapper">
            <div className={classes.arrow} onClick={() => history.goBack()}>
              <ArrowBackSharp /> Back
            </div>
            <div className="addNewVenueHeading">
              <p>Add New Vendor</p>
            </div>
            <div className="addNewVendorWrapper">
              <div className="">
                <div className="">
                  <div className="addVendorReAlign">
                    <p>Vendor Name</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="firstName"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.firstName && errorData.firstName[0]}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="addVendorRealignTwoFields ">
                  <div className="">
                    <p>Last Name</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="lastName"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.lastName && errorData.lastName[0]}
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="">
                <div className="">
                  <div className="addVendorReAlign">
                    <p>Vendor Email</p>
                    <div className="">
                      <InputField
                        className=""
                        label=""
                        type="text"
                        pattern="[A-Za-z]"
                        id="custom-css-outlined-input"
                        name="email"
                        placeholder="Enter venue email"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.email && errorData.email[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div className="addVendorReAlign">
                    <p>Vendor Phone</p>
                    <div className="">
                      <PhoneInput
                        // className="addvenueInput"
                        country={"us"}
                        onlyCountries={["us"]}
                        value={phone}
                        placeholder="Enter venue phone"
                        inputClass="phoneInput"
                        buttonClass="phoneButton"
                        dropdownClass="dropdownClass"
                        disableDropdown="true"
                        onChange={(phone) =>
                          setVendorDetails({
                            ...vendorDetails,
                            phone,
                          })
                        }
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.phone && errorData.phone[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="addVendorReAlign">
                    <p>Street</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        pattern="[A-Za-z]"
                        id="custom-css-outlined-input"
                        name="street"
                        placeholder="Enter venue first name"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.street && errorData.street[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="addVendorRealignTwo">
                <div
                  className="addVendorRealignTwoFields"
                  style={{ width: "100%" }}
                >
                  <div className="">
                    <p>City</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="city"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.city && errorData.city[0]}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="addVendorRealignTwoFields"
                  style={{ width: "100%" }}
                >
                  <div className="">
                    <p>State</p>
                    <div className="addVenueAddDataRight changeMaxWidth">
                      <select
                        // value={state?.state}
                        style={{
                          padding: "15px 10px",
                          height: "56px",
                          border: "1px solid",
                        }}
                        name="state"
                        className="form-control "
                        onChange={handleChange}
                      >
                        <option selected>Select State </option>
                        {states &&
                          states.map((data) => {
                            return (
                              <option value={data.Code}>{data.Code}</option>
                            );
                          })}
                      </select>

                      <p className="errorMsg">
                        {" "}
                        {errorData?.state && errorData.state[0]}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="addVendorRealignTwoFields"
                  style={{ width: "100%" }}
                >
                  <div className="">
                    <p>Zip</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="zip"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.zip && errorData.zip[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="addVendorRealignTwo">
                {/* <div className="addVendorRealignTwoFields">
                  <div className="">
                    <p>County</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="country"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.country && errorData.country[0]}
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>

              <Divider className="m-3" />
              {/* Contact Fields */}

              <div className="addVendorRealignTwo ">
                <div className="addVendorRealignTwoFields">
                  <div className="">
                    <p>Contact First Name</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="contactFirstName"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactFirstName &&
                          errorData.contactFirstName[0]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="addVendorRealignTwoFields">
                  <div className="">
                    <p>Contact Last Name</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="contactLastName"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactLastName &&
                          errorData.contactLastName[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="addVendorReAlign">
                    <p>Contact Email</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        pattern="[A-Za-z]"
                        id="custom-css-outlined-input"
                        name="contactEmail"
                        placeholder="Enter venue first name"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactEmail && errorData.contactEmail[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="addVendorReAlign">
                    <p>Contact Phone</p>
                    <div className="">
                      <PhoneInput
                        // className="addvenueInput"
                        country={"us"}
                        onlyCountries={["us"]}
                        value={contactPhone}
                        placeholder="Enter venue phone"
                        inputClass="phoneInput"
                        buttonClass="phoneButton"
                        dropdownClass="dropdownClass"
                        disableDropdown="true"
                        onChange={(contactPhone) =>
                          setVendorDetails({
                            ...vendorDetails,
                            contactPhone,
                          })
                        }
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactPhone && errorData.contactPhone[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <Divider className="m-3" />

              <AddBrand
                open={openAddBrandModal}
                handleClose={() => {
                  setAddBrandModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newDetails}
                id="Brand"
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddBrandModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />

              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft">
                  <p>Brand</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Brand}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Brand"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Brand{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Brand,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Brand && errorData.Brand[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddBrandModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Brand
                    </span>{" "}
                  </div>
                </div>
              </div>

              <AddManagement
                open={openAddManagementModal}
                handleClose={() => {
                  setAddManagementModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                id="Management"
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newVendorDetails}
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddManagementModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />
              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft">
                  <p>Management</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Management}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Management"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Management{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Management,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Management && errorData.Management[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddManagementModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Management
                    </span>{" "}
                  </div>
                </div>
              </div>
              <AddContract
                open={openContractModal}
                handleClose={() => {
                  setAddContractModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                id="Contract"
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newVendorDetails}
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddContractModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />
              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft ">
                  <p>Contract</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Contract}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Contract"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Contract{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Contract,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Contract && errorData.Contract[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddContractModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Contract
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className="addVenueUploadButtonGroup mt-3">
                <div className="addVenueUploadDataButtons">
                  <Button
                    background="secondary" // two types
                    fontSize="15px"
                    border="1px solid #fff"
                    // disabled={item[1] == "Accepted" ? false : true}
                    color="secondary" //two types
                    name=" Cancel"
                    handleClick={() => {
                      props.history.goBack();
                    }}
                  />
                  <Button
                    background="secondary" // two types
                    fontSize="15px"
                    border="1px solid #fff"
                    // disabled={item[1] == "Accepted" ? false : true}
                    color="secondary" //two types
                    loading={loading}
                    name="Save"
                    handleClick={handleSave}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewVendor;
