import React from 'react'
import { withStyles, TextareaAutosize } from "@material-ui/core";
const CssTextareaAutosize = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000",
      },
    },
    width: "100%",
  },
})(TextareaAutosize);

const Textarea = (props) => {
    return (
        <CssTextareaAutosize 
        className={props.className}
        label={props.label}
        name = {props.name}
        value={props.value}
        onChange={props.handleChange}
        minRows={props.heightRow}
        />
    )
}

export default Textarea;
