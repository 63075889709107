import { Card } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Button from "../../../components/button/Button";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import "./styles.css";
import Canvas from "../canvas/index";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction } from "../../../redux/actions/Index";
import Table from "../../../components/table/Table";
import { ArrowBackSharp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "18px",
    width: "70px",
    justifyContent: "space-between",
    margin: "20px auto 20px 20px",
    ["@media (max-width: 460px)"]: {
      // margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

function MapList(props) {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const { venueId } = useParams();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const [showMap, setShowMap] = useState(false);
  const [edit, setEdit] = useState(false);

  const { venues, maps } = useSelector((state) => state.AdminReducer);
  const [tableData, setTableData] = useState([]);

  const handleChange = (type) => {
    // setShowMap(true);
    // if (type === "edit") {
    //   setEdit(true);
    // }

    history.push(`${ROUTES.CONFIGURE_VENUE}/${venueId}/${type}`);
  };
  useEffect(() => {
    return () => {
      dispatch({
        type: ACTION_TYPE.GET_VENUE_DETAILS,
        payload: "",
      });
    };
  }, []);

  useEffect(() => {
    if (user?._id && userUpdated) {
      dispatch(AdminAction.getMapList(venueId));
    }
  }, [user, userUpdated]);

  useEffect(() => {
    getTableData();
  }, [maps]);
  const getTableData = () => {
    console.log("results getTableData :>> ", maps);

    let finalData = maps.map((data, index) => {
      return {
        id: data["_id"],
        "Map ID": data["mapId"],
        Zone: data["zone"] || "-",
        "Ad-Spaces": data["adSpace"]?.length,
      };
      // return [data["_id"], data["firstName"], data["adSpace"]?.length];
    });

    setTableData(finalData);
  };

  return (
    <div className="start">
      {user?.role && (
        <RBAC
          userRole={user?.role} // User roles that are given to user in the backend
          initList={initList}
          renderNoAccess={() => <SideNav />}
        >
          <SideNav />
        </RBAC>
      )}
      <div className="screen addMap">
        <HeaderBar title="Venue Management / Choose Map" />
        <div className="addMapBtn">
          <div
            className={classes.arrow}
            onClick={() => {
              history.push(ROUTES.VENUE_MANAGEMENT);
            }}
          >
            <ArrowBackSharp /> Back
          </div>
          <Button
            // className="col-lg-3"
            background="primar"
            color="primary"
            name="Add New Map"
            handleClick={() => handleChange("new")}
          ></Button>
        </div>
        <div className="tableDiv">
          <Table
            tableHead={["Map ID", "Ad-Spaces"]}
            configure={true}
            tableData={tableData}
            // button="yes"
            // buttonTwo="yes"
            handleBtnClick1={async (item) => {
              console.log("item :>> ", item);
              handleChange(item.id);
              // history.push(`${ROUTES.CONFIGURE_VENUE}/${item.id}`);
            }}
            handleBtnClick={(item) => {
              console.log("itemmm", item);
              history.push(`${ROUTES.VENUE_DETAILS}/${item?.id}`);
            }}
            // handleClickIcon={handleClickIcon}
          />
        </div>
      </div>
    </div>
  );
}

export default MapList;
