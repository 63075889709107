function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

function testFormat(url) {
  const allowedFormats = [
    "image/jpg",
    "image/jpeg",
    "image/tiff",
    "image/png",
    "image/raw",
  ];
  console.log("allowedFormats", url);
  if (!url.type) return true;
  console.log(
    "allowedFormats.includes(url.type)",
    url,
    url?.type?.toLowerCase(),
    allowedFormats.includes(url?.type?.toLowerCase())
  );
  if (allowedFormats.includes(url.type.toLowerCase())) return true;
  else return false;
}

function isNumeric(str) {
  console.log("isNumeric", typeof str);
  str = str.toString();
  // if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const ValidateAdSpaceForm = (formData) => {
  console.log("formData", formData);
  const { title, info, description, price, url, sqFeet, vendorCost, zone } =
    formData || {};
  let error = {
    title: [],
    info: [],
    description: [],
    price: [],
    sqFeet: [],
    vendorCost: [],
    // zone: [],
    // category: [],
    img: [],
  };
  let isFormValid = true;
  if (isBlank(title) === true) {
    isFormValid = false;
    error.title = ["This field is required."];
  }
  if (isBlank(sqFeet) === true) {
    isFormValid = false;
    error.sqFeet = ["This field is required."];
  }
  if (isBlank(vendorCost) === true) {
    isFormValid = false;
    error.vendorCost = ["This field is required."];
  }
  // if (isBlank(zone) === true) {
  //   isFormValid = false;
  //   error.zone = ["This field is required."];
  // }

  if (isBlank(info) === true) {
    isFormValid = false;
    error.info = ["This field is required."];
  } else if (info?.trim().length > 0 && info?.trim().length > 50) {
    isFormValid = false;
    error.info = ["Description cannot be greater than 50."];
  }

  if (isBlank(description) === true) {
    isFormValid = false;
    error.description = ["This field is required."];
  } else if (
    description?.trim().length > 0 &&
    description?.trim().length > 50
  ) {
    isFormValid = false;
    error.description = ["Package cannot be greater than 50."];
  }

  console.log("price validation", price);
  if (isBlank(price) || isNumeric(price) === false) {
    isFormValid = false;
    error.price = ["Enter price in proper format"];
  }
  // if (isBlank(category) === true) {
  //   isFormValid = false;
  //   error.category = ["This field is required."];
  // }

  console.log("url validation", url);
  if (isBlank(url) === true) {
    isFormValid = false;
    error.url = ["This field is required."];
  } else if (testFormat(url) === false) {
    isFormValid = false;
    error.url = ["Image format not supported"];
  }
  return { isFormValid, error };
};
