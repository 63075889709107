import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import "./style.css";
import Button from "../../button/Button";
import PhoneInput from "react-phone-input-2";
import { Divider } from "@material-ui/core";

const AddContract = (props) => {
  const inputEl = useRef(null);

  const [state, setstate] = useState();
  const { handleChange, errorData } = props;
  console.log("state", state);
  useEffect(() => {
    setstate(props.state);
  }, [props.state]);
  return (
    <div className="addVendorWrapper">
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className="adSpaceModal"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div className="adSpacePopUp">
            <h1>Add New {props.id}</h1>
          </div>
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <Divider />
          <div className="d-flex">
            <div className="addSpaceInputs adSpace">
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">Name</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  <input
                    label="Name"
                    type="text"
                    id="custom-css-outlined-input"
                    name="Contract"
                    className="my-2"
                    // value={state?.name}
                    onChange={handleChange}
                    style={{ width: "80%" }}

                    //   handleChange={(e) => props.handleChange(e, "name")}
                  />
                  {errorData?.name && errorData.name[0] ? (
                    <p className="errorMsg"> {errorData.name[0]}</p>
                  ) : null}
                </div>
              </div>

              {/* <div className="addSpaceInputsIndividual ">
								<div className="addSpaceInputsLeft2">Email</div>
								<div
									className="addSpaceInputsRight"
									style={{ textAlign: "left" }}
								>
									<input
										label="E-Package"
										type="text"
										id="custom-css-outlined-input"
										name="email"
										className="my-2"
										value={state?.email}
										onChange={handleChange}
										style={{ width: "80%" }}

										//   handleChange={handleChange}
										//   handleChange={(e) => props.handleChange(e, "package")}
									/>
									{errorData?.email && errorData.email[0] ? (
										<p className="errorMsg"> {errorData.email[0]}</p>
									) : null}
								</div>
							</div> */}
              {/* <div className="addSpaceInputsIndividual pb-2">
								<div className="addSpaceInputsLeft2">Phone</div>
								<div
									className="addSpaceInputsRight"
									style={{ textAlign: "left" }}
								>
									<PhoneInput
										country={"us"}
										onlyCountries={["us"]}
										value={state?.phone}
										inputStyle={{ width: "80%" }}
										placeholder="Enter contact phone"
										// inputClass="phoneInputSmall"
										// buttonClass="phoneButton"
										dropdownClass="dropdownClass"
										disableDropdown="true"
										onChange={(phone) => {
											let target = {
												name: "phone",
												value: phone,
											};
											let phoneE = {
												target,
											};
											handleChange(phoneE);
										}}
									/>
									{errorData?.phone && errorData.phone[0] ? (
										<p className="errorMsg"> {errorData.phone[0]}</p>
									) : null}
								</div>
							</div> */}

              {/* <div className="addSpaceInputsIndividual pb-2">
								<div className="addSpaceInputsLeft2">Brand</div>
								<div
									className="addSpaceInputsRight"
									style={{ textAlign: "left" }}
								>
									<select
										// value={state?.state}
										style={{
											// padding: "15px 10px",
											height: "35px",
											width: "80%",
											border: "1px solid",
										}}
										name="Brand"
										className="form-control"
										onChange={handleChange}
									>
										<option selected value="null">
											Select Brand{" "}
										</option>
										{props.categories &&
											[
												...props.categories,
												// { _id: "Other", path: ",Ownership," },
											].map((data) => {
												if (data["path"] && data["path"] === ",Brand,")
													return <option value={data._id}>{data._id}</option>;
											})}
									</select>
									{errorData?.Brand && errorData.Brand[0] ? (
										<p className="errorMsg"> {errorData.Brand[0]}</p>
									) : null}
								</div>
							</div> */}

              {/* <div className="addSpaceInputsIndividual pb-2">
								<div className="addSpaceInputsLeft2">Management</div>
								<div
									className="addSpaceInputsRight"
									style={{ textAlign: "left" }}
								>
									<select
										// value={state?.state}
										style={{
											// padding: "15px 10px",
											height: "35px",
											width: "80%",
											border: "1px solid",
										}}
										name="Management"
										className="form-control"
										onChange={handleChange}
									>
										<option selected value="null">
											Select Management{" "}
										</option>
										{props.categories &&
											[
												...props.categories,
												// { _id: "Other", path: ",Ownership," },
											].map((data) => {
												if (data["path"] && data["path"] === ",Management,")
													return <option value={data._id}>{data._id}</option>;
											})}
									</select>
									{errorData?.Management && errorData.Management[0] ? (
										<p className="errorMsg"> {errorData.Management[0]}</p>
									) : null}
								</div>
							</div> */}

              {/* <div className="addSpaceInputsIndividual pb-2">
								<div className="addSpaceInputsLeft2">Contract</div>
								<div
									className="addSpaceInputsRight"
									style={{ textAlign: "left" }}
								>
									<select
										// value={state?.state}
										style={{
											// padding: "15px 10px",
											height: "35px",
											width: "80%",
											border: "1px solid",
										}}
										name="Contract"
										className="form-control"
										onChange={handleChange}
									>
										<option selected value="null">
											Select Contract{" "}
										</option>
										{props.categories &&
											[
												...props.categories,
												// { _id: "Other", path: ",Ownership," },
											].map((data) => {
												if (data["path"] && data["path"] === ",Contract,")
													return <option value={data._id}>{data._id}</option>;
											})}
									</select>
									{errorData?.Contract && errorData.Contract[0] ? (
										<p className="errorMsg"> {errorData.Contract[0]}</p>
									) : null}
								</div>
							</div> */}
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="adspacebuttonGroup">
          <Button
            background="secondary" // two types
            fontSize="15px"
            border="1px solid #fff"
            // disabled={item[1] == "Accepted" ? false : true}
            color="secondary" //two types
            name="Save"
            loading={props.loading}
            handleClick={() => {
              props.handleSave(props.id);
            }}
          />
          <Button
            background="secondary"
            fontSize="15px"
            border="1px solid #fff"
            color="secondary" //two types
            name="Cancel"
            handleClick={() => {
              props.handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddContract;
