import React, { useState, useEffect } from "react";
import Header from "../../Authentication/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputField from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import "./CustomerInfo.css";
import Textarea from "../../../components/textarea/Textarea";
import Footer from "../../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import FormSection from "./Card/FormSection";
import { useHistory } from "react-router-dom";
import {
  ROUTES,
  APPLICATION_CONSTANTS,
} from "../../../services/constants/Index";
import { ValidateCustomerForm } from "./Validation";
import states from "./data.json";

import "./CustomerInfo.css";
import { CustomerAction } from "../../../redux/actions/Index";
import {
  addChargeTax,
  calculateCartItems,
} from "../../../services/utills/helper";
import { useQuery } from "../../../hooks/useQueryHook";
const CustomerInfo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { loadingState } = useSelector((state) => state.CommonReducer);
  const query = useQuery();
  const orderId = query.get("orderId");
  const {
    cart,
    venueId,
    customerInfo,
    eventData,
    venueData,
    orderDetailsById,
  } = useSelector((state) => state.CustomerReducer);
  const [cartData, setCartData] = useState();

  const [errorData, setError] = React.useState();

  console.log("json data", states);

  useEffect(() => {
    console.log("user before", orderDetailsById?.customerId);

    if (user && Object.keys(user).length !== 0) {
      console.log("user after", customerInfo, user);
      setState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        companyName: user.companyName,
        country: user.country,
        zipCode: user.zipCode,
        state: user.state,
        city: user.city,
        street: user.Street,
        Ordernotes: user.Ordernotes,
      });
    } else if (Object.keys(customerInfo).length !== 0) {
      const result = customerInfo;
      console.log(
        "🚀 ~ file: CustomerInfo.js ~ line 68 ~ useEffect ~ result",
        result
      );
      setState({
        firstName: result.firstName,
        lastName: result.lastName,
        email: result.email,
        phone: result.phone,
        companyName: result.companyName,
        country: result.country,
        zipCode: result.zipCode,
        state: result.state,
        city: result.city,
        street: result.street,
        Ordernotes: result.Ordernotes,
      });
    } else if (
      orderDetailsById &&
      Object.keys(orderDetailsById?.customerId).length !== 0
    ) {
      const result = orderDetailsById?.customerId;
      console.log("result??>>", orderDetailsById);
      setState({
        firstName: result.firstName,
        lastName: result.lastName,
        email: result.email,
        phone: result.phone,
        companyName: result.companyName,
        country: result.country,
        zipCode: result.zipCode,
        state: result.state,
        city: result.city,
        street: result.Street,
        Ordernotes: result.Ordernotes,
      });
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      if (cart) {
        const venueCartItems = await calculateCartItems(cart, venueId, orderId);
        setCartData(venueCartItems);
      }
    })();
  }, [cart]);

  const handleSave = async () => {
    const { salesTax, serviceCharge } = venueData;
    setError();
    const result = await ValidateCustomerForm(state);
    if (!result?.isFormValid) {
      setError(result?.error);
      return;
    } else if (result?.isFormValid) {
      dispatch(CustomerAction.addCustomerInfo(state));

      const cartItems = cart.map((item) => {
        return { adspace: item.id, quantity: item.quantity };
      });

      let subTotal = cart?.reduce((a, b) => a + b["price"] * b["quantity"], 0);

      let serviceChargeTotal = addChargeTax(subTotal, venueData?.serviceCharge);
      let salesTaxTotal = addChargeTax(subTotal, venueData?.salesTax);

      let totalAmount = subTotal + serviceChargeTotal + salesTaxTotal;

      let params = {
        customerInfo: state,
        customerId: user?._id,
        cartItems,
        subTotal,
        serviceChargeTotal,
        salesTaxTotal,
        serviceCharge: venueData?.serviceCharge,
        salesTax: venueData?.salesTax,
        price: totalAmount,
        venueId,
        eventData,
        orderId,
      };

      console.log("params", params);
      var res = await dispatch(CustomerAction.sendProposal(params));

      console.log("res proposal", res);

      dispatch(CustomerAction.clearCart());

      history.push({
        pathname: ROUTES.PROPOSALCONFIRMATION,
        search: `?id=${res?.data?._id}`, //{ id: res.data._id },
      });

      if (user?._id) {
        dispatch(
          CustomerAction.updateProfile({
            userId: user._id,
            updateBody: state,
            callback: (res) => {
              console.log("res", res);
            },
          })
        );
      }
    }
  };
  const handleChange = (e) => {
    console.log("e", e);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <Header type="type1" />

      <div className="backarrow ml-5 d-flex">
        <div className="backIcon mr-2">
          <ArrowBackIcon
            className="icon"
            onClick={() => {
              localStorage.getItem("token")
                ? orderId
                  ? history.push(`${ROUTES.CART}?orderId=${orderId}`)
                  : history.push(ROUTES.CART)
                : history.goBack();
            }}
          />
        </div>
        Checkout
      </div>

      <div className="OrderConfirmHeading customerInfo">
        <h1>Customer Information</h1>
      </div>
      <div className="customerInfoDiv">
        <div className="signInFormDiv customerInfoFormDiv fixingsignInFormDiv">
          <div className="formDiv">
            <form
              className="signInForm customerInfoForm"
              noValidate
              autoComplete="off"
            >
              <div className="CustomerInfo">
                {/* {console.log("state out",state)} */}
                {state?.firstName ||
                state?.lastName ||
                props?.history?.location?.state?.previousPath === "geustUser" ||
                customerInfo ? (
                  <FormSection
                    state={state}
                    setState={setState}
                    handleChange={handleChange}
                    errorData={errorData}
                    states={states}
                  />
                ) : null}

                <div className="additionalInfo">
                  <h4
                    style={{ fontFamily: "Whitney Semibold" }}
                    className="mb-4 mt-4"
                  >
                    Additional Information
                  </h4>
                  <h6 style={{ fontFamily: "Whitney Book" }}>
                    Order notes (Optional)
                  </h6>
                  <Textarea
                    name="Ordernotes"
                    label="Order notes (Optional)"
                    className="customerInfoTextarea"
                    heightRow={4}
                    value={state?.Ordernotes}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <Button
                background="primary"
                color="tertiary"
                name="Send Proposal"
                handleClick={handleSave}
                loading={loadingState}
                disabled={loadingState}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
