import {
  Assessment,
  CardMembership,
  FiberManualRecord,
  Category,
  Group,
  Person,
} from "@material-ui/icons";
import { ROUTES } from "../../services/constants/Index";

export const initList = [
  //superAdmin Routes
  {
    title: "My Orders",
    route: ROUTES.MYORDER,
    role: ["customer"],
    active: ["/order/details/:id", "/my-order"],
  },
  {
    title: "My Profile",
    route: ROUTES.MYPROFILE,
    role: ["customer"],
    active: ["/my-profile"],
  },

  //admin Routes
  {
    title: "Venue Management",
    route: ROUTES.VENUE_MANAGEMENT,
    role: ["admin"],
    active: [
      "/venue-management",
      "/venue-details/:venueId",
      "/add-new-venue",
      "/configure-venue/:venueId/:mapId",
      `${ROUTES.MAP_LIST}/:venueId`,
    ],
  },
  {
    title: "Order Management",
    route: ROUTES.ORDER_MANAGEMENT,
    role: ["admin"],
    active: ["/order-management", ROUTES.ORDER_MANAGEMENT_DETAILS],
  },
  {
    title: "Vendor Management",
    route: ROUTES.VENDOR_MANAGEMENT,
    role: ["admin"],
    active: [
      "/vendor-management",
      "/vendor/details/:orderId",
      "/add-new-vendor",
    ],
  },
  {
    title: "Customer Management",
    route: ROUTES.CUSTOMER_MANAGEMENT,
    role: ["admin"],
    active: [
      "/customer-management",
      ROUTES.CUSTOMER_DETAILS,
      `${ROUTES.CUSTOMER_ORDER_DETAILS}/:orderId`,
    ],
  },

  // Venue Routes

  {
    title: "Order Management",
    route: ROUTES.VENUE_ORDER_MANAGEMENT,
    role: ["venue"],
    active: [
      ROUTES.VENUE_ORDER_MANAGEMENT,
      `${ROUTES.VENUE_ORDER_MANAGEMENT_DETAILS}/:orderId`,
    ],
  },
  {
    title: "Department Management",
    route: ROUTES.VENUE_DEPARTMENT_MANAGEMENT,
    role: ["venue"],
    active: [ROUTES.VENUE_DEPARTMENT_MANAGEMENT],
  },
  {
    title: "Staff Management",
    route: ROUTES.VENUE_STAFF_MANAGEMENT,
    role: ["venue"],
    active: [ROUTES.VENUE_STAFF_MANAGEMENT],
  },
  // Vendor Routes

  {
    title: "Order Management",
    route: ROUTES.VENDOR_ORDER_MANAGEMENT,
    role: ["vendor"],
    active: [
      ROUTES.VENDOR_ORDER_MANAGEMENT,
      `${ROUTES.VENDOR_ORDER_MANAGEMENT_DETAILS}/:orderId`,
    ],
  },

  // Staff Items
  {
    title: "Order Management",
    route: ROUTES.STAFF_ORDER_MANAGEMENT,
    role: ["staff"],
    active: [
      ROUTES.STAFF_ORDER_MANAGEMENT,
      `${ROUTES.STAFF_ORDER_DETAILS}/:orderId`,
    ],
  },
  {
    title: "Order Creation",
    route: ROUTES.CUSTOMER_LINK_MANAGEMENT,
    role: ["staff"],
    active: [ROUTES.CUSTOMER_LINK_MANAGEMENT, ROUTES.STAFF_CUSTOMER_INFO],
  },
  // {
  //   title: "Discount Creation",
  //   route: ROUTES.DISCOUNT_CREATION,
  //   role: ["staff"],
  //   active: [
  //     ROUTES.DISCOUNT_CREATION,
  //     `${ROUTES.DISCOUNT_CREATION}/:discountId`,
  //   ],
  // },
];
