import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import Canvas from "./components/canvas";
import ImageMapConfigurations from "./ImageMap/ImageMapConfigurations";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction } from "../../../redux/actions/Index";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
  ROUTES,
  ACTION_TYPE,
  s3UploadUrl,
  s3Bucket,
} from "../../../services/constants/Index";
import GuideModal from "../../../components/guideModal/GuideModal";
import { Prompt } from "react-router";
import Loader from "../../../components/loader/Loader";
import AlertDialog from "../../../components/AlertDialog/alertDialog";
const NewDesign = (props) => {
  const dispatch = useDispatch();
  const canvasRef = useRef();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const [styles, setStyles] = useState({});
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [adSpace, setAdSpace] = useState();
  const [backgroungImg, setBackgroundImg] = useState();
  let { venueId, mapId } = useParams();
  const { venueDetails } = useSelector((state) => state.AdminReducer);
  const [loading, setloading] = useState(false);
  const [exit, setExit] = useState(true);
  const [jsonData, setJsonData] = useState();
  const [adSpaceData, setAdSpaceData] = useState();
  const [alertModel, setAlertModel] = useState(false);
  const [zone, setZone] = useState("");
  const [newMapId, setNewMapId] = useState("new");

  const [dimension, setDimension] = useState({
    height: 540,
    width: 960,
    title: "16:9",
  });
  const [display, setDisplay] = useState({
    first: "Block",
    second: "none",
  });

  // useEffect(() => {}, [props]);

  useEffect(() => {
    if (props.type === "edit") {
      dispatch({
        type: ACTION_TYPE.GET_VENUE_DETAILS,
        payload: "",
      });
      setNewMapId(mapId);
      // dispatch(AdminAction.getVenueDetails(venueId));
      dispatch(AdminAction.getMapDetails(venueId, mapId));
    } else if (props.type === "new") {
      setShowModal(true);
      setJsonData(true);
    }
  }, []);

  useEffect(() => {
    if (venueDetails && venueDetails._id === venueId) {
      setJsonData({
        adAdSpaces: venueDetails?.ads,
        data: venueDetails?.maps?.graphicData,
        zones: venueDetails?.maps?.zones || [],
        newZones: [],
      });
    }
  }, [venueDetails]);

  const canvasHandlers = {
    onSetBg: (value) => {
      setBackgroundImg(value);
    },
    onAddAdSpace: (value) => {
      setAdSpace(value);
    },
    onAdd: (target) => {
      canvasRef.current.handler.select(target);
    },
    onImage: (url) => {
      canvasRef.current.handler.onImage(url);
    },
    onBgImage: (url) => {
      canvasRef.current.handler.onBgImage(url);
    },
    onSelect: (target) => {
      if (target && target.id) {
        setSelectedItem(target);
        return;
      }
      setSelectedItem(null);
      return;
    },
    onModified: (key, value, target) => {
      //  setStyles({...styles, [key]:value})
      // this.setState({ styleValues });
    },
    onChange: (selectedItem, changedValues, allValues) => {
      // const { editing } = this.state;
      // if (!editing) {
      // 	this.changeEditing(true);
      // }
      const changedKey = Object.keys(changedValues)[0];
      const changedValue = changedValues[changedKey];
      handler.onChangeStyles(changedKey, changedValue);

      if (changedKey === "textAlign") {
        canvasRef.current.handler.set(changedKey, Object.keys(changedValue)[0]);
        return;
      }
      canvasRef.current.handler.set(changedKey, changedValue);
    },
    onSave: async (data, file, templateName) => {
      setExit(false);
      var formData = new FormData();
      const map = "map0";
      console.log(
        "🚀 ~ file: index.js ~ line 125 ~ onSave: ~ backgroungImg",
        backgroungImg
      );

      if (backgroungImg) {
        axios
          .get(
            `${s3UploadUrl}?object_key=venue/${Date.now()}&bucket=${s3Bucket}`
          )
          .then((resp) => {
            var options = {
              headers: {
                "Content-Type": backgroungImg.type,
              },
            };
            axios
              .put(resp.data.presigned_url, backgroungImg, options)
              .then(async (resps, err) => {
                if (err) console.log("err :>> ", err);
                console.log("resps :>> ", resps);

                let params = {
                  bgUrl: `https://${s3Bucket}.s3.us-east-2.amazonaws.com/${resp.data.key}`,
                  graphicdata: data,
                  venueId,
                  adSpaceData,
                  mapId: newMapId,
                  newZones: jsonData.newZones,
                  zones: jsonData?.zones || [],
                };
                const response = await dispatch(
                  AdminAction.configureVenue(params)
                );

                history.push(`${ROUTES.MAP_LIST}/${venueId}`);

                // history.push(ROUTES.VENUE_MANAGEMENT);
              })
              .catch((err) => {
                console.log("err", err);
              });
          });
      } else if (!backgroungImg && props.type == "edit") {
        let params = {
          bgUrl: venueDetails?.maps?.url,
          graphicdata: data,
          venueId,
          adSpaceData,
          mapId: newMapId,
          zones: jsonData?.zones || [],
          newZones: jsonData.newZones,
        };
        const response = await dispatch(AdminAction.configureVenue(params));

        setloading(false);

        history.push(`${ROUTES.MAP_LIST}/${venueId}`);

        // history.push(ROUTES.VENUE_MANAGEMENT);
      }
    },
  };

  const handler = {
    onChangeStyles: (key, value) => {
      setStyles({ ...styles, [key]: value });
    },

    deleteMap: async () => {
      setExit(false);
      setTimeout(async () => {
        await dispatch(AdminAction.deleteMap(venueId, newMapId));
        history.push(`${ROUTES.MAP_LIST}/${venueId}`);
      }, 2000);
    },

    saveZone: async (name) => {
      console.log(
        "🚀 ~ file: index.js ~ line 208 ~ saveZone: ~ jsonData?.zones before",
        jsonData?.zones
      );
      const savedZoneResult = await dispatch(
        AdminAction.saveZone({
          name,
          venueId,
          mapId: newMapId,
        })
      );
      console.log(
        "🚀 ~ file: index.js ~ line 196 ~ saveZone: ~ savedZoneResult",
        savedZoneResult
      );
      console.log(
        "🚀 ~ file: index.js ~ line 208 ~ saveZone: ~ jsonData?.zones",
        jsonData?.zones
      );

      if (savedZoneResult?.data[0]) {
        console.log(
          "🚀 ~ file: index.js ~ line 215 ~ saveZone: ~ savedZoneResult.data[0].isNewMapId",
          savedZoneResult.data[0].isNewMapId
        );

        if (savedZoneResult.data[0].isNewMapId) {
          setNewMapId(savedZoneResult.data[0].mapId);
          console.log(
            "🚀 ~ file: index.js ~ line 222 ~ saveZone: ~ mapId",
            newMapId
          );

          const { isNewMapId, ...rest } = savedZoneResult.data[0];

          if (jsonData.zones)
            setJsonData({
              ...jsonData,
              zones: [...jsonData.zones, rest],
            });
          else
            setJsonData({
              ...jsonData,
              zones: [rest],
            });
        } else {
          setJsonData({
            ...jsonData,
            zones: [...jsonData?.zones, savedZoneResult.data[0]],
          });
        }
      }
    },
  };
  console.log(adSpaceData, "mypersonal target");
  return (
    <div className="d-flex align-items-center newDesign wrapper AdminCanvas ">
      <Prompt when={exit} message="Changes you made may not be saved" />
      <Loader loading={!jsonData} color="#fff" size="22px"></Loader>

      {((jsonData && jsonData?.data) || props.type === "new") && (
        <>
          <div className="canvas-wrapper">
            <Canvas
              jsonData={jsonData}
              loading={loading}
              setloading={setloading}
              location={props.type}
              backgroungImg={backgroungImg}
              ref={canvasRef}
              minZoom={30}
              adSpaceData={adSpaceData}
              maxZoom={500}
              designNo={props?.match?.params?.id}
              templateName={props?.location?.state}
              onSelect={canvasHandlers.onSelect}
              onModified={canvasHandlers.onModified}
              onSave={canvasHandlers.onSave}
              // onRemove={canvasHandlers.onRemove}
              handleExit={() => setExit(false)}
              handleClose={() => history.push(ROUTES.VENUE_MANAGEMENT)}
              onDeleteMap={() => setAlertModel(true)}
            />
          </div>
          <div className="canvas-properties">
            <ImageMapConfigurations
              setSelectedItem={setSelectedItem}
              jsonData={jsonData}
              location={props.type}
              canvasRef={canvasRef}
              setAdSpaceData={setAdSpaceData}
              adSpaceData={adSpaceData}
              // zone={zone}
              setZone={
                (newZones) => handler.saveZone(newZones)

                // setJsonData({
                //   ...jsonData,
                //   newZones: [...jsonData.newZones, { name: newZones }],
                // })
              }
              setAdSpace={canvasHandlers.onAddAdSpace}
              setBackgroundImg={canvasHandlers.onSetBg}
              onChange={canvasHandlers.onChange}
              selectedItem={selectedItem}
              designNo={props?.match?.params?.id}
              onChangeStyles={handler.onChangeStyles}
              // onDeleteMap={() => setAlertModel(true)}
              styles={styles}
            />
            <GuideModal
              open={showModal}
              handleClose={() => {
                setShowModal(false);
              }}
            />
          </div>
          <AlertDialog
            open={alertModel}
            handleClose={() => setAlertModel(false)}
            handleDelete={handler.deleteMap}
            message="Are you sure you want to delete this map?"
            button1="Delete"
            button2="Cancel"
          />
        </>
      )}
    </div>
  );
};

export default NewDesign;
