import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../../../components/button/Button";
import { StaffAction } from "../../../../redux/actions/Index";
import { ROUTES } from "../../../../services/constants/Index";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ConformationEmail = ({ handleStep, discountData }) => {
  const dispatch = useDispatch();
  const { userData, venueInfo } = useSelector((state) => state.AuthReducer);
  const { customerInfo, createdLinkInfo } = useSelector(
    (state) => state.StaffReducer
  );

  const [message, setMessage] = React.useState(` <p>Dear ${
    customerInfo?.firstName && customerInfo?.firstName
  } </p>
  <p>
    We’re excited to welcome you to
    ${userData?.venueInfo?.firstName && userData?.venueInfo?.firstName}
    and to maximize your attendees experience we have partnered with
    brandevent as our exclusive temporary branding provider for spaces
    outside of the expo floor within the property.
  </p>
  <p>
    Please see your unique link for the upcoming
    ${customerInfo?.eventName && customerInfo?.eventName}  for
    ${customerInfo?.groupName && customerInfo?.groupName} that allows
    you to order branding at the relevant locations to your meeting space.
  </p>
  <p>${createdLinkInfo?.customerLink}</p>
  <p>
    Should you have any questions, please let me know. Thanks,
    ${userData?.firstName && userData?.firstName} ${
    userData?.lastName && userData?.lastName
  }
  </p>
  <p>${userData?.firstName && userData?.firstName} ${
    userData?.lastName && userData?.lastName
  }</p>
  <p>${userData?.venueInfo?.firstName && userData?.venueInfo?.firstName}</p>
  <p>${userData?.phoneNumber && userData?.phoneNumber}</p>
  <p> ${userData?.email && userData?.email}</p>`);
  // const [messagePart2, setMessagePart2] = React.useState(`
  // `)
  const [editorData, setEditorData] = React.useState(message);
  const [isEditable, setIsEditable] = React.useState(false);

  useEffect(() => {
    const regex = /(<p id="discountPara">)(.*)(<\/p>)/;

    if (discountData.discount && !isEditable) {
      // if(message.find("#discountPara")){
      //   setEditorData(message.replace("#discountPara", `<p id="discountPara">${discountData.discount && discountData.discount} ${discountData.discountType == 0 ? "%" : "$"}</p>`));
      // }
      // document.getElementById("discountPara").innerHTML = `Discount Added ${discountData.discount && discountData.discount} ${discountData.discountType == 0 ? "%" : "$"}`;
      if (message.search(regex) != -1) {
        const newMessage = message.replace(
          regex,
          `<p id="discountPara"> Discount Added ${
            discountData.discount && discountData.discount
          } ${discountData.discountType == 0 ? "%" : "$"}</p>`
        );
        setMessage(newMessage);
      } else {
        setMessage(
          message +
            `<p id="discountPara"> Discount Added ${
              discountData.discount && discountData.discount
            } ${discountData.discountType == 0 ? "%" : "$"}</p>`
        );
      }
    }

    if (discountData.discount && isEditable) {
      const newMessage = message.replace(regex, "");
      setEditorData(newMessage);
    }
  }, [discountData.discount, discountData.discountType, isEditable]);
  const sendMail = async () => {
    const data = {
      customerId: createdLinkInfo?.customerId,
      message,
    };
    console.log("data", data);
    await dispatch(StaffAction.sendConfirmationEmail(data));
    handleStep(5, "customerInfo");
  };

  const saveEmail = () => {
    setMessage(editorData);
    setIsEditable(false);
  };

  console.log("discountData", discountData);
  return (
    <div className="confirmEmail">
      {isEditable ? (
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          // disabled={!isEditable}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: message }}></div>
      )}
      <div className="btnDiv">
        <Button
          name={isEditable ? "Save" : "Edit"}
          background="primary"
          color="secondary"
          handleClick={
            isEditable ? saveEmail : () => setIsEditable(!isEditable)
          }
        ></Button>
        <Link to={ROUTES.CUSTOMER_LINK_MANAGEMENT}>
          <Button
            name="Back to Dashboard"
            background="primary"
            color="secondary"
          ></Button>
        </Link>
        {isEditable ? null : (
          <Button
            name="Send email"
            background="primary"
            color="secondary"
            handleClick={sendMail}
          ></Button>
        )}
        {isEditable ? (
          <Button
            name="Cancel"
            background="primary"
            color="secondary"
            handleClick={() => {
              setIsEditable(!isEditable);
              // setEditorData(message);
            }}
          ></Button>
        ) : null}
      </div>
    </div>
  );
};

export default ConformationEmail;
