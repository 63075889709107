import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import Header from "../../Authentication/Header";

import "./OrderConfirm.css";
import CheckIcon from "@material-ui/icons/Check";
import { CustomerAction, AuthAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import {
	ROUTES,
	APPLICATION_CONSTANTS,
} from "../../../services/constants/Index";
import { useHistory } from "react-router-dom";
import { formatUsCurrency } from "../../../services/utills/helper";
const OrderConfirm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [result, setResult] = useState();
	const [venueID, setVenueID] = useState();
	const { orderDetail, venueId } = useSelector(
		(state) => state.CustomerReducer
	);
	console.log("Resultttttttttt", venueID);

	const [setIsBackButtonClicked, isBackButtonClicked] = useState(false); //Did this mistake on purpose
	useEffect(() => {
		if (orderDetail?.venueId && orderDetail?.venueId?.venueId)
			setVenueID(orderDetail?.venueId?.venueId);
	}, [orderDetail]);

	useEffect(() => {
		const search = props.location.search;
		const id = new URLSearchParams(search).get("id");
		(async () => {
			const res = await dispatch(CustomerAction.getOrderDetails(id));
			console.log(" IDDDDDD res", id);
			APPLICATION_CONSTANTS?.SECURE_WEB_STORAGE?.removeItem(
				APPLICATION_CONSTANTS.CUSTOMER_INFO
			);
			setResult(res?.data?.data?.result[0]); // This should be coming from redux

			// window.addEventListener("popstate", onBackButtonEvent);
		})();
		return () => {
			// window.removeEventListener("popstate", onBackButtonEvent);
			// history.replace(ROUTES.VENUE + '/'+venueId)
		};
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// const onBackButtonEvent = (e) => {
	//   e.preventDefault();

	//   if (!isBackButtonClicked) {
	//     if (window.confirm("Thank you for shoping")) {
	//       history.push(ROUTES.LOGIN);
	//       setIsBackButtonClicked(true);
	//     } else {
	//       history.push(ROUTES.LOGIN);
	//       setIsBackButtonClicked(false);
	//     }
	//   }
	// };

	return (
		<>
			<Header type="type1" />
			<div className="OrderConfirm ">
				<div className="OrderConfirmHeading">
					<h1>Order Confirmation</h1>
					<h4>{result?.customerInfo?.firstName}</h4>
				</div>
				<div className="OrderConfirmBox">
					<div className="OrderConfirmBoxhead">
						<div>
							{" "}
							<CheckIcon className="OrderConfirmIcon" />{" "}
						</div>
						<p>Thank you. Your order has been received.</p>
						<p>A confirmation mail has been sent on your email.</p>
					</div>
					<hr />
					<div className="OrderConfirmBoxFoot">
						<div className="OrderConfirmDetail">
							<h4>Venue name:</h4>
							<p>{result?.venueId?.firstName}</p>
						</div>
						<div className="OrderConfirmDetail">
							<h4>Ad-Space:</h4>
							<p>
								{" "}
								{result?.adspaces?.map((ad) => ad?.adspace?.title).join(", ")}
							</p>
						</div>
						<div className="OrderConfirmDetail">
							<h4>Email:</h4>
							<p>{result?.customerInfo?.email}</p>
						</div>
						<div className="OrderConfirmDetail">
							<h4>Order number:</h4>
							<p>{result?.orderId}</p>
						</div>
						<div className="OrderConfirmDetail">
							<h4>Date:</h4>
							<p>{result?.CreatedAt?.split("T")[0]}</p>
						</div>
						<div className="OrderConfirmDetail">
							<h4>Price:</h4>
							{/* <p>{"$" + Number(result?.price).toFixed(2)}</p> */}
							<p>{formatUsCurrency(result?.price)}</p>
						</div>
					</div>
				</div>
				{localStorage.getItem("token") ? (
					<div className="OrderConfirmbutton">
						<Button
							background="primary"
							color="tertiary"
							name="Customer Dashboard"
							handleClick={() => {
								history.push(ROUTES.MYORDER);
							}}
						/>
						<Button
							background="primary"
							color="#fff"
							name="Log out"
							handleClick={() => {
								dispatch(AuthAction.logOut());
								history.push(ROUTES.LOGINREGISTER);
							}}
						></Button>
					</div>
				) : (
					<div className="OrderConfirmbutton">
						<Button
							background="primary"
							color="#fff"
							name="Back to venue"
							handleClick={() => {
								history.push(`venue/${venueID}`);
							}}
						></Button>
					</div>
				)}
			</div>
		</>
	);
};

export default OrderConfirm;
