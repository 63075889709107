import { ACTION_TYPE } from "../../services/constants/Index";
import { toast } from "../../services/utills/toast";
import Api from "../../services/utills/Axios";

export const getFaqContent = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    const res = await Api.get(`/site/faqContent`);

    dispatch({
      type: ACTION_TYPE.GET_FAQ_CONTENT,
      payload: res?.data?.data,
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    console.log("error", error);
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getAboutUsContent = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    const res = await Api.get(`/site/aboutUsContent`);

    dispatch({
      type: ACTION_TYPE.GET_ABOUTUS_CONTENT,
      payload: res?.data?.data,
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    console.log("error", error);
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getContactUsContent = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    const res = await Api.get(`/site/contactUsContent`);

    dispatch({
      type: ACTION_TYPE.GET_CONTACTUS_CONTENT,
      payload: res?.data?.data,
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    console.log("error", error);
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const contactUsForm = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    const res = await Api.post(`/site/contact-form`, body);

    // dispatch({
    //   type: ACTION_TYPE.GET_CONTACTUS_CONTENT,
    //   payload: res?.data?.data,
    // });
    toast.success("Our Team will contact you shortly!");

    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    console.log("error", error);
    dispatch({ type: "LOADING_FAILURE" });
  }
};
