function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export const ValidateLoginForm = (formData) => {
  const { email } = formData || {};
  let error = { email: [] };
  let isFormValid = true;

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  } else if (
    email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,8})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["Please enter a valid email address"];
  }

  return { isFormValid, error };
};
