import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

import "./style.css";
import { WEB_APP_URL } from "../../../services/constants/Index";

const Modal = (props) => {
  const { data } = props;
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div>
            <h1>Venue Details</h1>
          </div>

          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <div className="addSpaceInputs venuedetailPopup">
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">Name</div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.firstName} {data?.lastName}
              </div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Email
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.email}
              </div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Phone
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.phone}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Street
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.street}
              </div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">City</div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.city}
              </div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                State
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.state}
              </div>
            </div>
            {/* <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Country
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.country}
              </div>
            </div> */}

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">ID</div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.venueId}
              </div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                No. of ad spaces
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {String(data?.adSpace?.length)}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Code Link
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">{`${WEB_APP_URL}/venue/${data?._id}`}</div>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};
export default Modal;
