function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

function isNull(str) {
  return str === "null";
}
export const ValidateAddVendorForm = (formData) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    Brand,

    Management,

    Contract,
  } = formData || {};
  let error = {
    firstName: [],
    lastName: [],
    email: [],
    phone: [],
    Brand: [],
    Management: [],
    Contract: [],
  };
  let isFormValid = true;
  if (isBlank(firstName) === true) {
    isFormValid = false;
    error.firstName = ["This field is required."];
  }

  if (isBlank(lastName) === true) {
    isFormValid = false;
    error.lastName = ["This field is required."];
  }

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  }

  if (isBlank(Brand) === true || isNull(Brand) === true) {
    isFormValid = false;
    error.Brand = ["This field is required."];
  }
  if (isBlank(Management) === true || isNull(Management) === true) {
    isFormValid = false;
    error.Management = ["This field is required."];
  }
  if (isBlank(Contract) === true || isNull(Contract) === true) {
    isFormValid = false;
    error.Contract = ["This field is required."];
  }

  if (isBlank(phone) === true) {
    isFormValid = false;
    error.phone = ["This field is required."];
  } else if (isNumber(phone) !== true) {
    isFormValid = false;
    error.phone = ["This field should be number."];
  } else if (phone?.length < 11) {
    isFormValid = false;
    error.phone = ["Please enter a valid phone number."];
  }

  return { isFormValid, error };
};
