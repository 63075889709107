import React, { Component } from "react";

class EventHandler extends Component {
  constructor(handler) {
    super(handler);
    this.handler = handler;
    this.initialize();
  }

  initialize = () => {
    this.handler.canvas.on({
      // "object:moving": null,
      // "object:moved": null,
      // "object:rotating": undefined,
      // "object:rotated": null,
      // 'mouse:wheel': this.mousewheel,
      "mouse:over": this.mouseOver,
      "mouse:down": this.selection,
      "mouse:out": this.mouseOut,
      // 'mouse:up': this.mouseup,
      "selection:cleared": this.selection,
      "selection:created": this.selection,
      "selection:updated": this.selection,
    });
    // } else {
    // 	this.handler.canvas.on({
    // 		'mouse:down': this.mousedown,
    // 		'mouse:move': this.mousemove,
    // 		'mouse:out': this.mouseout,
    // 		'mouse:up': this.mouseup,
    // 		'mouse:wheel': this.mousewheel,
    // 	});
    // }
  };

  // mouseOver = (opt) => {
  //   const target = opt.target;

  //   target?.set("fill", "#414257");
  //   this.handler.onMouseOut(target);
  // };
  // mouseOut = (opt) => {
  //   const target = opt.target;
  //   target?.set("fill", "#ED464B");
  //   this.handler.onMouseOver(target);
  // };

  mouseOver = (opt) => {
    const target = opt.target;
    console.log("targetttttt", target);
    target?.set("fill", "#414257");
    if (target) {
      // target?.set("fill", "#414257");
      // var p = canvas.getPointer(e.e);

      const params = {
        left: target?.left,
        top: target?.top,
        visible: true,
        type: "tooltip",
      };
      this.handler.onMouseOver(params, target.id, target);
    }
  };

  mouseOut = (opt) => {
    const target = opt.target;
    target?.set("fill", "#FA460E");
    const params = {
      visible: false,
    };
    this.handler.onMouseOut(params);
  };
  selection = (opt) => {
    console.log(
      "this. :>> ",
      opt,
      this.handler.canvas.getActiveObject()?.get("type")
    );
    console.log("opt :>> ", opt);
    console.log("this.handler :>> ", this.handler.activeSelectionOption);
    const { onSelect, activeSelectionOption } = this.handler;
    const target = opt.target;
    if (target && target.type === "activeSelection") {
      target.set({
        ...activeSelectionOption,
      });
    }
    if (onSelect) {
      onSelect(target);
    }
  };
}

export default EventHandler;
