import React, { useEffect } from "react";
// import _Firebase from "../../Services/Utils/firebase";
import { useSelector, useDispatch } from "react-redux";
// import { getUserDetailsJWT } from "../../Services/Utils/utils";

function RBAC({ userRole, children, renderNoAccess, initList }) {
  // let user = useSelector((state) => state.AuthReducer.userData);
  console.log("RBAC userRole, initList :>> ", userRole, initList);
  if (userRole && userRole?.includes("customer")) {
    const customer = initList.filter((list) => {
      if (list.role.some((role) => userRole.includes(role))) {
        return list;
      }
    });

    const newchildren = React.cloneElement(children, [customer], [children]);
    return newchildren;
  } else if (userRole && userRole?.includes("admin")) {
    const student = initList.filter((list) => {
      if (list.role.some((role) => role === "admin")) {
        return list;
      }
    });
    console.log("student :>> ", student);

    const newchildren = React.cloneElement(children, [student], [children]);
    console.log("newchildren", newchildren);
    return newchildren;
  } else if (userRole && userRole?.includes("venue")) {
    const student = initList.filter((list) => {
      if (list.role.some((role) => role === "venue")) {
        return list;
      }
    });
    console.log("RBAC venue :>> ", student);

    const newchildren = React.cloneElement(children, [student], [children]);
    console.log("newchildren", newchildren);
    return newchildren;
  } else if (userRole && userRole?.includes("staff")) {
    const student = initList.filter((list) => {
      if (list.role.some((role) => role === "staff")) {
        return list;
      }
    });
    console.log("RBAC staff :>> ", student);

    const newchildren = React.cloneElement(children, [student], [children]);
    console.log("newchildren", newchildren);
    return newchildren;
  } else if (userRole && userRole?.includes("vendor")) {
    const student = initList.filter((list) => {
      if (list.role.some((role) => role === "vendor")) {
        return list;
      }
    });
    console.log("student :>> ", student);

    const newchildren = React.cloneElement(children, [student], [children]);
    return newchildren;
  }
}

export default RBAC;
