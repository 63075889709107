import React, { useEffect, useState } from "react";
import Header from "../../Authentication/Header";
import Footer from "../../../components/Footer/Footer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "../../../components/button/Button";
import "./OrderDetails.css";
import {
  ROUTES,
  APPLICATION_CONSTANTS,
} from "../../../services/constants/Index";
import queryString from "query-string";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CustomerAction } from "../../../redux/actions/Index";
import Modal from "../modal/modal";
// import ItemCard from "../cart/component/ItemCard";

// import PriceCard from "../cart/component/PriceCard";
import { calculateCartItems } from "../../../services/utills/helper";
import PriceCard from "../../../components/priceCard/PriceCard";
import ItemCard from "../../../components/itemCard/ItemCard";
import { useQuery } from "../../../hooks/useQueryHook";

const OrderDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery()

  const [detailsModel, setDetailsModel] = useState(false);
  const [id, setId] = useState();
  const [cartData, setCartData] = useState();
  const [errorData, setErrorData] = useState({});
  const [isChecked, setChecked] = useState(false);
  const { cart, venueId, customerInfo } = useSelector(
    (state) => state.CustomerReducer
  );
  const { loadingState } = useSelector((state) => state.CommonReducer);
  const userData = useSelector((state) => state.AuthReducer.userData);
  const { eventData, checkoutOrderDetails } = useSelector(
    (state) => state.CustomerReducer
  );
  const orderId = query.get("orderId");

  useEffect(() => {
    const token = queryString.parse(props.location.search)?.token;
    console.log("token", token);

    if (token) dispatch(CustomerAction.getOrderDetailsFromToken(token));
  }, []);

  useEffect(() => {
    (async () => {
      if (cart) {
        const venueCartItems = await calculateCartItems(cart, venueId, orderId);
        setCartData(venueCartItems);
      }
    })();
  }, [cart]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOrder = async () => {
    // const cartitems = cart.map((item) => {
    //   return { adspace: item.id, quantity: item.quantity };
    // });
    // const totalAmount = cart?.reduce(
    //   (a, b) => a + b["price"] * b["quantity"],
    //   0
    // );
    // const result = customerInfo;
    // // console.log("resu", result);
    // let body = {
    //   result,
    //   customerId: userData._id,
    //   cartitems,
    //   price: totalAmount,
    //   status: "Pending",
    //   venueId,
    //   eventData,
    // };

    setErrorData({});
    if (isChecked === true)
      // if (cartData?.length > 0) {
      dispatch(
        CustomerAction.placeOrder({
          orderId: checkoutOrderDetails?._id,
          history,
        })
      );
    else setErrorData({ isChecked: ["This field is required."] });

    // }
  };
  const handleadSpaceClick = (id) => {
    setId(id);
    console.log("is", id);
    setDetailsModel(true);
  };

  let cartLength;
  let totalAmount;

  if (cartData) {
    cartLength = cartData.reduce((prev, current) => {
      console.log("prev, current", prev, current);
      return prev + current.quantity;
    }, 0);

    console.log("cartLength", cartLength);
    totalAmount = cartData?.reduce((a, b) => a + b["price"] * b["quantity"], 0);
  }

  return (
    <div className="od">
      <Header />
      {/* <div className="topSpace backarrow ml-5 d-flex">
        <div className="backIcon mr-2">
          <ArrowBackIcon
            className="icon"
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
        Customer information
      </div> */}
      <div className="OrderConfirmHeading">
        <h1>Order Details</h1>
      </div>
      <div className="orderDetailsRow">
        <div className="col-lg-7 col-md-12 itemCardDiv">
          {checkoutOrderDetails?.adspaces &&
            checkoutOrderDetails?.adspaces?.map((cartDetails) => {
              return (
                <ItemCard
                  data={{
                    ...cartDetails?.adspace,
                    quantity: cartDetails?.quantity,
                  }}
                // notThisOption
                />
              );
            })}
        </div>
        {checkoutOrderDetails?.adspaces?.length > 0 && (
          <PriceCard
            cartLength={checkoutOrderDetails?.adspaces?.reduce(
              (prev, current) => {
                console.log("prev, current", prev, current);
                return prev + Number(current.quantity);
              },
              0
            )}
            subTotal={checkoutOrderDetails?.subTotal}
            serviceChargeTotal={checkoutOrderDetails?.serviceChargeTotal}
            salesTaxTotal={checkoutOrderDetails?.salesTaxTotal}
            serviceCharge={checkoutOrderDetails?.serviceCharge}
            salesTax={checkoutOrderDetails?.salesTax}
            totalAmount={checkoutOrderDetails?.price}
            venueData={checkoutOrderDetails?.venueId}
            eventData={checkoutOrderDetails?.eventData}
            buttonName="Place Your Order"
            handleOrder={() => {
              handleOrder();
            }}
            title
            loading={loadingState}
            isChecked={isChecked}
            setChecked={() => setChecked(!isChecked)}
            errorData={errorData}
          />
        )}
      </div>

      {/* <div className="orderDetailButton">
				<Button
					background="secondary"
					borderRadius="15px"
					color="#fff"
					transition="background 0.3s"
					display="none"
					alignItem="center"
					name="Place your Order"
					handleClick={() => {
						handleOrder();
					}}
					loading={loadingState}
				></Button>
			</div> */}
      {cart && (
        <Modal
          id={id}
          open={detailsModel}
          previousPage="orderDetails"
          handleClose={() => {
            setDetailsModel(false);
          }}
          adSpaceData={{ adSpaces: cart }}
        />
      )}
      {/* {cartData?.length > 0 ? (
				<div className="fixFooterForNow">
					<div
						style={{
							height: "150px",
						}}
					/>
					<div style={{ height: "150px" }} />
					<Footer></Footer>
				</div>
			) : (
				<Footer></Footer>
			)} */}

      <div className="fixFooterForNow">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default OrderDetails;
