import { CircularProgress } from "@material-ui/core";

const Loader = ({ loading, size, color }) => {
  console.log("loader", loading, size, color);
  return (
    <CircularProgress
      // color={color || "#fff"}
      style={{ color: color || "#000" }}
      hidden={!loading}
      size={size || 15}
    />
  );
};
export default Loader;
