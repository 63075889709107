import React, { useEffect, useState } from "react";
import "./styles.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import Button from "../../../components/button/Button";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerAction,
  AdminAction,
  VenueAction,
} from "../../../redux/actions/Index";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import moment from "moment";
import AlertDialog from "../../../components/AlertDialog/alertDialog";
import AddDepartment from "../../../components/Modals/AddDepartment/adddbrand";
const VenueManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  // const { userUpdated } = useSelector((state) => state.AuthReducer);

  const { allDepartments } = useSelector((state) => state.VenueReducer);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState();
  // const { order } = useSelector((state) => state.CustomerReducer);

  // useEffect(() => {
  //   (async () => {
  //     if (user?._id && userUpdated) {
  //       dispatch(VenueAction.getStaffDepartments());
  //     }
  //   })();
  // }, [user, userUpdated]);

  useEffect(() => {
    (async () => {
      dispatch(
        VenueAction.getStaffDepartments({ filter: null, venueId: user._id })
      );
    })();
  }, []);

  useEffect(() => {
    getTableData();
  }, [allDepartments]);

  const getTableData = () => {
    console.log("results getTableData :>> ", allDepartments);

    let finalData =
      allDepartments &&
      allDepartments.length > 0 &&
      allDepartments.map((data, index) => {
        return {
          "S. No": index + 1,
          "Department Name": data["title"],
          "Department Id": data["departmentId"],
          "Staff Count": data && data.staffMemberIds && data.staffMemberIds.length > 0 ? String(data.staffMemberIds.length) : "0",
          "Created At": moment(data["createdAt"]).format("MM/DD/YYYY"),
        };
        // return [data["_id"], data["firstName"], data["adSpace"]?.length];
      });

    setTableData(finalData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const addDepartment = async (e) => {
    e.preventDefault();
    console.log("addDepartment", state);
    setOpen(false);
    dispatch(
      VenueAction.addDepartmentAction({ payload: state, venueId: user._id })
    );
  };

  const handleClickIcon = () => {
    setOpen(!open);
    setState();
  };
  return (
    <div>
      <div className="start venueM">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Department Management" />
          <div className="addNewVendorVenMgmt">
            <Button
              // className="col-lg-3"
              background="primar"
              color="primary"
              name="Add New Department"
              handleClick={handleClickIcon}
            ></Button>
          </div>
          <div className="tabelOrder">
            <Table
              tableHead={[
                "S. No",
                "Department Name",
                "Department Id",
                "Staff Count",
                "Created At",
              ]}
              action={false}
              tableData={tableData}
            />
          </div>
        </div>
      </div>
      <AddDepartment
        addDepartment={addDepartment}
        state={state}
        handleChange={handleChange}
        open={open}
        handleClose={handleClickIcon}
      ></AddDepartment>
    </div>
  );
};

export default VenueManagement;
