function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export const ValidateEventForm = (formData) => {
  const { groupName, eventName, eventStartDate } = formData || {};
  let error = { groupName: [], eventName: [], eventStartDate: [] };
  let isFormValid = true;

  if (isBlank(groupName) === true) {
    isFormValid = false;
    error.groupName = ["This field is required."];
  }

  if (isBlank(eventName) === true) {
    isFormValid = false;
    error.eventName = ["This field is required."];
  }

  if (isBlank(eventStartDate) === true) {
    isFormValid = false;
    error.eventStartDate = ["This field is required."];
  }

  return { isFormValid, error };
};
