import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import Header from "../../Authentication/Header";
import Button from "../../../components/button/Button";
import { MailOutline } from "@material-ui/icons";
import InputField from "../../../components/input/Input";
import PhoneInput from "react-phone-input-2";
import states from "../../Story/CustomerInformation/data.json";
import { ValidateContactUsForm } from "./Validation";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTES } from "../../../services/constants/Index";
import { ArrowBackSharp } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "../../../services/utills/toast";
import { SiteAction } from "../../../redux/actions/Index";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#414257",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "18px",
    width: "128px",
    justifyContent: "space-between",
    margin: "20px auto 20px 20px",
    ["@media (max-width: 460px)"]: {
      // margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

const ContactUs = () => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState();
  const dispatch = useDispatch();

  const [errorData, setError] = React.useState();
  const { venueId } = useSelector((state) => state.CustomerReducer);

  const { contactUsContent } = useSelector((state) => state.SiteReducer);
  useEffect(() => {
    dispatch(SiteAction.getContactUsContent());
  }, []);

  const handleSend = async () => {
    setError();
    const result = await ValidateContactUsForm(state);
    if (!result?.isFormValid) {
      setError(result?.error);
      return;
    } else if (result?.isFormValid) {
      await dispatch(SiteAction.contactUsForm(state));
      setState({
        name: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        comments: "",
      });
    }
  };
  const handleChange = (e) => {
    console.log("e", e.target.value);
    console.log("STATE", state);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Header />
      <div
        className={classes.arrow}
        onClick={() => {
          if (venueId && venueId != "")
            history.push(`${ROUTES.VENUE}/${venueId}`);
          else history.goBack();
        }}
      >
        <ArrowBackSharp /> Back to Map
      </div>
      <div className="contactUsWrapper">
        <div className="contactUsLeft">
          <div className="contactus__heading">
            <p>{contactUsContent?.title}</p>
          </div>
          <div className="contactus__subheading">
            <p>{contactUsContent?.subTitle1}</p>

            <p>{contactUsContent?.subTitle2}</p>
          </div>
          <p className="loginBelowLine">
            Please complete the contact form or call/text us:
          </p>
          <p className="loginBelowLine" style={{ margin: 0, padding: 0 }}>
            (209) 32-BRAND
          </p>
          <p className="loginBelowLine" style={{ margin: 0, padding: 0 }}>
            (209) 322-7263
          </p>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <MailOutline className="mailIcon" />
            <p className="mb-1 ml-3">contact@brandevent.store</p>
          </div> */}
        </div>
        <div className="contactUsRight">
          <div>
            <form className="signInForm" noValidate autoComplete="off">
              <div className="contactFields">
                <InputField
                  label="Name"
                  type="text"
                  id="custom-css-outlined-input"
                  name="name"
                  value={state?.name}
                  handleChange={handleChange}
                  required={true}
                />
                <p className="errorMsg">
                  {" "}
                  {errorData?.name && errorData.name[0]}
                </p>
              </div>
              <div className="contactFields">
                <InputField
                  label="Email"
                  variant="outlined"
                  id="custom-css-outlined-input"
                  name="email"
                  value={state?.email}
                  handleChange={handleChange}
                  required={true}
                  // onSubmit={onSubmit}
                />

                <p className="errorMsg">
                  {" "}
                  {errorData?.email && errorData.email[0]}
                </p>
              </div>
              <div className="contactFields">
                <PhoneInput
                  inputProps={{
                    // name: 'phone',
                    required: true,
                    // autoFocus: true
                  }}
                  country={"us"}
                  onlyCountries={["us"]}
                  value={state?.phone}
                  placeholder="Enter phone*"
                  inputClass="phoneInput"
                  buttonClass="phoneButton"
                  dropdownClass="dropdownClass"
                  disableDropdown="true"
                  onChange={(phone) =>
                    setState({
                      ...state,
                      phone,
                    })
                  }
                />
                <p className="errorMsg">
                  {" "}
                  {errorData?.phone && errorData.phone[0]}
                </p>
              </div>
              <div className="optionalFields">
                <div className="optionalFieldsCity">
                  <InputField
                    label="City"
                    variant="outlined"
                    id="custom-css-outlined-input"
                    name="city"
                    value={state?.city}
                    handleChange={handleChange}
                    required={true}

                    // onSubmit={onSubmit}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.city && errorData.city[0]}
                  </p>
                </div>
                <div className="optionalFieldsState">
                  <select
                    value={state?.state}
                    style={{
                      padding: "15px 10px",
                      height: "56px",
                      border: "1px solid",
                    }}
                    name="state"
                    className="form-control "
                    onChange={handleChange}
                    required
                  >
                    <option disabled selected value="">
                      Select State*{" "}
                    </option>
                    {states &&
                      states.map((data) => {
                        return <option value={data.Code}>{data.Code}</option>;
                      })}
                  </select>
                  <p className="errorMsg">
                    {" "}
                    {errorData?.state && errorData.state[0]}
                  </p>
                </div>
              </div>
              <div className="contactFields textarea-field">
                <textarea
                  required
                  onChange={handleChange}
                  rows="3"
                  className="form-control"
                  name="comments"
                  value={state?.comments}
                  placeholder="Comments*"
                ></textarea>
                {errorData?.comments && errorData.comments[0] && (
                  <p className="errorMsg">
                    {" "}
                    {errorData?.comments && errorData.comments[0]}
                  </p>
                )}
              </div>
              <div className="mb-2"></div>

              <div class="haveaccount text-center py-2"></div>
              <div className="signInButton">
                <Button
                  background="primary"
                  color="tertiary"
                  name="Send"
                  handleClick={handleSend}
                  // loading={loadingState}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
