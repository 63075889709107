import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import { VisibilityOff, Visibility, ArrowBack } from "@material-ui/icons";
import Button from "../../../components/button/Button";
import Header from "../Header";
import Toast from "../../../components/toast/Toast";
import InputField from "../../../components/input/Input";
import { AuthAction } from "../../../redux/actions/Index";
import { ValidateSignupForm } from "./Validation";
import {
	ROUTES,
	APPLICATION_CONSTANTS,
} from "../../../services/constants/Index";
import { Link, useHistory } from "react-router-dom";

const Register = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [emailDisabled, setEmailDisabled] = useState(false);
	const [state, setState] = useState();
	const [toast, setToast] = useState();
	const [role, setRole] = React.useState(APPLICATION_CONSTANTS.VENUE_ROLE);

	const [isShowPassword, setPasswordType] = React.useState(false);
	const [isShowConfirmPassword, setConfirmPasswordType] = React.useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [errorData, setError] = React.useState();
	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value,
		});
	};
	const onSubmit = async (event) => {
		event.preventDefault();
		setError();

		const result = await ValidateSignupForm(state);
		if (!result?.isFormValid) {
			setError(result?.error);
			console.log("resultttt", result?.error);
			return;
		} else if (result?.isFormValid) {
			const resp = await dispatch(
				AuthAction.signUpVenueVendor({ ...state, role })
			);
			console.log("resp", resp);

			if (resp) history.goBack();
			// if (result) {
			//   setToast({
			//     open: true,
			//     message: "Sign up success",
			//     type: "error",
			//     duration: 5000,
			//   });
			// } else if (!result) {
			//   setToast({
			//     open: true,
			//     message: "User already exits",
			//     type: "error",
			//     duration: 5000,
			//   });
			// }
			// if (result) {
			//   let params = { email: state?.email, password: state?.password };
			//   const status = await dispatch(AuthAction.login(params));
			//   console.log("status", status);
			//   if (status && history?.location?.state?.previousPath === "Cart") {
			//     history.push(ROUTES.CUSTOMER_INFO);
			//     return;
			//   } else if (status && history?.location?.state === undefined) {
			//     history.push(ROUTES.MYORDER);
			//   }
			// }
		}
	};

	return (
		<div className="AuthBg">
			<Header type="type1" />

			<div className="signIn">
				<div className="signInFormDiv ">
					<div className="formDiv registerrr">
						<h2>Register</h2>
						<div className="randomName">
							<div className="setUser d-flex mb-3">
								<button
									className={
										role === APPLICATION_CONSTANTS.VENUE_ROLE
											? "roleLoginVenueButton active"
											: "roleLoginVenueButton"
									}
									name="Venue"
									onClick={() => setRole(APPLICATION_CONSTANTS.VENUE_ROLE)}
								>
									Venue
								</button>
								<button
									name="Vendor"
									className={
										role === APPLICATION_CONSTANTS.VENDOR_ROLE
											? "roleLoginVendorButton active"
											: "roleLoginVendorButton"
									}
									onClick={() => setRole(APPLICATION_CONSTANTS.VENDOR_ROLE)}
								>
									Vendor
								</button>
								<button
									name="Staff"
									className={
										role === APPLICATION_CONSTANTS.STAFF_ROLE
											? "roleLoginStaffButton active"
											: "roleLoginStaffButton"
									}
									onClick={() => setRole(APPLICATION_CONSTANTS.STAFF_ROLE)}
								>
									Staff
								</button>

							</div>
							<form className="signInForm" noValidate autoComplete="off">
								<div className="signInEmail">
									<InputField
										label={
											role === APPLICATION_CONSTANTS.VENUE_ROLE
												? "Venue Id"
												: role === APPLICATION_CONSTANTS.VENDOR_ROLE
													? "Vendor Id"
													: role === APPLICATION_CONSTANTS.STAFF_ROLE ? "Staff Id" : null
										}
										variant="outlined"
										type="text"
										id="custom-css-outlined-input"
										name="registerId"
										value={state?.registerId}
										handleChange={handleChange}
									/>
									<p className="errorMsg">
										{" "}
										{errorData?.registerId && errorData.registerId[0]}
									</p>
								</div>
								<div className="signInEmail">
									<InputField
										label="Password"
										variant="outlined"
										type={isShowPassword == true ? "text" : "password"}
										id="custom-css-outlined-input"
										disabled={emailDisabled}
										name="password"
										value={state?.password}
										handleChange={handleChange}
									/>
									<p className="errorMsg">
										{" "}
										{errorData?.password && errorData.password[0]}
									</p>
									{isShowPassword === true ? (
										<a
											className="eyeicon"
											onClick={() => setPasswordType(false)}
										>
											<Visibility />
										</a>
									) : (
										<a
											className="eyeicon"
											onClick={() => setPasswordType(true)}
										>
											<VisibilityOff />
										</a>
									)}
								</div>

								<div className="signInEmail">
									<InputField
										placeholder="Confirm Password"
										label="Confirm Password"
										variant="outlined"
										type={isShowConfirmPassword == true ? "text" : "password"}
										name="confirmPassword"
										value={state?.confirmPassword}
										handleChange={handleChange}
									/>

									<p className="errorMsg">
										{" "}
										{errorData?.confirmPassword && errorData.confirmPassword[0]}
									</p>
									{isShowConfirmPassword === true ? (
										<a
											className="eyeicon"
											onClick={() => setConfirmPasswordType(false)}
										>
											<Visibility />
										</a>
									) : (
										<a
											className="eyeicon"
											onClick={() => setConfirmPasswordType(true)}
										>
											<VisibilityOff />
										</a>
									)}
								</div>
								<div class="haveaccount text-center py-2">
									<p className="mb-0">
										Login as Vendor/Venue/Staff?{" "}
										<Link to={ROUTES.VENUE_VENDOR_LOGIN}>
											<a>Login</a>
										</Link>
									</p>
								</div>
								<div className="signUpButton">
									<Button
										background="primary"
										color="tertiary"
										name="Register"
										handleClick={onSubmit}
									/>
								</div>
							</form>

							{toast?.open ? (
								<Toast
									open={toast.open}
									message={toast.message}
									duration={toast.duration}
									type={toast.type}
									handleClose={() =>
										setToast({
											...toast,
											open: false,
										})
									}
								></Toast>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
