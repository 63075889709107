import React, { useEffect, useState } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./OrderManagement.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
// import Modal from "../../../components/detailModal/Modal";
import { AdminAction } from "../../../redux/actions/Index";
import {
  formatDate,
  formatUsCurrency,
  formatUSNumber,
} from "../../../services/utills/helper";
// import Modal from "../../../components/AddSpaceModal/AddSpaceModal";
import { MenuItem, Select } from "@material-ui/core";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import OrderManagementComponent from "../../../components/orderManagement";
import Loader from "../../../components/loader/Loader";
import moment from "moment";

const OrderManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filter, setFilter] = useState("all");
  const [sort, setSort] = useState("startDate");
  const [formattedOrders, setFormattedOrder] = useState([]);
  const { orders } = useSelector((state) => state.AdminReducer);
  const user = useSelector((state) => state.AuthReducer.userData);
  const { loadingState } = useSelector((state) => state.CommonReducer);

  const [filterState, setFilterState] = useState({
    event: null,
    vendor: null,
    status: null,
  });

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    (async () => {
      setTable();
    })();
  }, [orders]);

  useEffect(() => {
    (async () => {
      await dispatch(AdminAction.getAllOrder(filter));
    })();
  }, []);

  const handleClick = (item) => {
    // item = {...item}
    const selectedItem = orders.find((x) => x._id === item.Id);
    item = { ...item, ...selectedItem };
    console.log("selectedItem", selectedItem);
    // dispatch(AdminAction.orderDetails(item));
    history.push(`/order-management/order-details/${selectedItem["_id"]}`);
  };

  const handleChangeFilter = async (type) => {
    console.log("handleChangeFilter");
    if (type === "reset") {
      setTable();
      return;
    }

    const filters = Object.keys(filterState).filter(
      (filter) => filterState[filter] != "" && filterState[filter] != null
    );

    console.log("filters", filters);
    if (filters.length > 0) setTableFilter(filters);
    else if (filter === "all") setTable();
  };

  const setTableFilter = (filters) => {
    console.log("setTableFilter");

    const formattedTableData = orders
      // .slice(0, 1)
      .filter((order) => {
        let isAllowed = true;

        let filterList = filters.map((filter) => {
          if (filter === "vendor") {
            return order?.vendorId?.firstName === filterState["vendor"];
          }
          if (filter === "event") {
            return order?.eventData?.eventName === filterState["event"];
          }

          if (filter === "status" && filterState["status"] === "all")
            return true;

          return order[filter] === filterState[filter];
        });

        filterList.map((list) => {
          if (list === false) isAllowed = false;
        });

        console.log(
          "in filter,  filterList, isAllowed, filterState",
          filterList,
          isAllowed,
          filterState
        );

        return isAllowed;
      })
      .map((elm) => ({
        Id: elm._id,

        OrderId: elm.orderId,
        Venue: elm?.venueId?.firstName,
        Vendor: elm?.vendorId?.firstName,

        // Price: "$" + Number(elm.price).toFixed(2),
        Price: formatUsCurrency(elm?.price),
        Status: elm.status,
        "Order Date": moment(elm.CreatedAt.split("T")[0]).format("MM/DD/YYYY"),
        "Start Date": moment(elm?.eventData?.eventStartDate).format(
          "MM/DD/YYYY"
        ),
      }));

    console.log("formattedTableData", formattedTableData);
    setFormattedOrder(formattedTableData);
  };

  const setTableDateFilter = (params) => {
    console.log("setTableDateFilter", params);
    let { startDate, endDate } = params;

    const formattedTableData = orders
      // .slice(0, 1)
      .filter((order) => {
        let isAllowed = false;
        console.log(
          "setTableDateFilter order.CreatedAt",
          order.CreatedAt,
          moment(order.CreatedAt.split("T")[0]).format("MM/DD/YYYY")
          // moment(startDate).format("YYYY-MM-DDTHH:MM:SS")
        );

        if (
          moment(order.CreatedAt.split("T")[0]).isBetween(
            startDate,
            endDate + "T23:59:59",
            undefined,
            "[]"
          )
        )
          isAllowed = true;

        console.log(
          "setTableDateFilter isAllowed",

          isAllowed
        );

        return isAllowed;
      })
      .map((elm) => ({
        Id: elm._id,

        OrderId: elm.orderId,
        Venue: elm?.venueId?.firstName,
        Vendor: elm?.vendorId?.firstName,

        // Price: "$" + Number(elm.price).toFixed(2),
        Price: formatUsCurrency(elm?.price),
        Status: elm.status,
        "Order Date": moment(elm.CreatedAt.split("T")[0]).format("MM/DD/YYYY"),
        "Start Date": moment(elm?.eventData?.eventStartDate).format(
          "MM/DD/YYYY"
        ),
      }));

    console.log("formattedTableData", formattedTableData);
    setFormattedOrder(formattedTableData);
  };

  const setTable = () => {
    const formattedTableData = orders.map((elm) => ({
      Id: elm._id,

      OrderId: elm.orderId,
      Venue: elm?.venueId?.firstName,
      Vendor: elm?.vendorId?.firstName,

      // Price: "$" + Number(elm.price).toFixed(2),
      Price: formatUsCurrency(elm?.price),
      Status: elm.status,
      "Order Date": moment(elm.CreatedAt.split("T")[0]).format("MM/DD/YYYY"),
      "Start Date": moment(elm?.eventData?.eventStartDate).format("MM/DD/YYYY"),
    }));
    setFormattedOrder(formattedTableData);
  };

  const renderFilterList = [
    {
      name: "All Orders",
      value: "all",
    },
    {
      name: "Proposed",
      value: "Proposed",
    },
    {
      name: "Rejected_Proposal",
      value: "Rejected_Proposal",
    },
    {
      name: "Pending",
      value: "Pending",
    },
    {
      name: "Approved",
      value: "Approved",
    },
    {
      name: "Rejected",
      value: "Rejected",
    },
    {
      name: "Completed",
      value: "Completed",
    },
    {
      name: "Cancelled",
      value: "Cancelled",
    },
  ];

  const renderSortList = [
    {
      name: "Start Date",
      value: "startDate",
    },
    {
      name: "Start Date - Ascending",
      value: "startDateAsc",
    },
    {
      name: "Start Date - Descending",
      value: "startDateDesc",
    },
  ];

  const handleChangeSort = (type) => {
    let sortedOrders = formattedOrders.sort((a, b) => {
      if (type === "startDateAsc")
        return moment(a["Start Date"], "MM/DD/YYYY").diff(
          moment(b["Start Date"], "MM/DD/YYYY")
        );
      else if (type === "startDateDesc")
        return moment(b["Start Date"], "MM/DD/YYYY").diff(
          moment(a["Start Date"], "MM/DD/YYYY")
        );
    });

    console.log("sortedOrders order", sortedOrders);
    setFormattedOrder(sortedOrders);
  };

  return (
    <div>
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Order Management" />

          {loadingState === true ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <Loader loading={loadingState} size={25} color={"#ffffff"} />
            </div>
          ) : (
            <OrderManagementComponent
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              handleChangeSort={handleChangeSort}
              handleChangeFilter={handleChangeFilter}
              filterList={renderFilterList}
              sortList={renderSortList}
              tableData={formattedOrders}
              tableHead={[
                "OrderId",
                "Venue",
                "Vendor",
                "Price",
                "Order Date",
                "Start Date",
                "Status",
              ]}
              handleClick={handleClick}
              filterState={filterState}
              setFilterState={setFilterState}
              toggle={toggle}
              setToggle={setToggle}
              loadingState={loadingState}
              hasExport
              setTableDateFilter={setTableDateFilter}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderManagement;
