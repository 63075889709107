import React from "react";
import Button from "@mui/material/Button";
import "./Button.css";
import Loader from "../loader/Loader";

const CustomButton = (props) => {
  return (
    <div style={{ textAlign: props.textAlign }}>
      <Button
        className={
          props.background === "primary" ? "signOutbtnP" : "signOutbtnS"
        }
        style={{
          cursor: "pointer",

          color:
            props.color === "primary"
              ? "#116A9A"
              : props.color === "secondary"
              ? "#fff"
              : props.color === "tertiary"
              ? "#fff"
              : props.color,
          background:
            props.background === "primary"
              ? "#414257 "
              : props.background === "secondary"
              ? "var(--main-bg-color)"
              : "#ffffff",
          borderRadius: "4px",
          fontSize: "14px",
          textTransform: "initial",
          padding: "5px 25px",
          border: props.border || "1px solid",
          transition: "all 0.5s ease",
          display: "inline-flex",
          alignItem: "center",
          marginRight: props.marginRight,
        }}
        disabled={props?.disabled}
        onClick={props.handleClick}
      >
        <div className="iconButton">{props.icon ? props.icon : ""}</div>
        <span style={{ fontFamily: "Whitney Semibold" }}> {props.name}</span>

        <Loader loading={props.loading}></Loader>
      </Button>
    </div>
  );
};
export default CustomButton;
