import React, { useState, useEffect } from "react";
import Button from "../../../components/button/Button";

import InputField from "../../../components/input/Input";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../Header";
import Toast from "../../../components/toast/Toast";
import { AuthAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { CustomerAction } from "../../../redux/actions/Index";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  ROUTES,
  APPLICATION_CONSTANTS,
} from "../../../services/constants/Index";
import "./style.css";
import { useLocation } from "react-router-dom";
import {
  checkEmailVerified,
  resentValidationEmail,
} from "../../../redux/actions/AuthAction";
import successImg from "../../../images/commonImage/Correct.png";
import failedImg from "../../../images/commonImage/Wrong.png";

const EmailVerify = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [response, setResponse] = useState({});
  const [isResentEmail, setIsResentEmail] = useState(null);
  const loading = useSelector((state) => state.CommonReducer.loadingState);
  useEffect(() => {
    (async () => {
      let token = new URLSearchParams(location.search).get("token");
      let resentEmail = new URLSearchParams(location.search).get("resentEmail");
      let userEmail = new URLSearchParams(location.search).get("email");
      setIsResentEmail(resentEmail);
      if (resentEmail == "false") {
        let res = await dispatch(checkEmailVerified({ token }));
        if (res && res.data) {
          setResponse(res.data);
        }
      } else if (resentEmail) {
        let res = await dispatch(resentValidationEmail({ email: userEmail }));
        if (res && res.data) {
          setResponse(res.data);
        }
      }
    })();
  }, []);

  const renderEmailValidationDiv = () => {
    if (response?.isExpire == false && response?.status) {
      return (
        <div className="d-flex flex-column align-items-center">
          <img style={{ width: 200 }} src={successImg}></img>
          <p style={{ fontSize: 18 }}>Your email has been verified</p>
          <div style={{ padding: 20 }}>
            <Button
              name="Login"
              handleClick={() => {
                props.history.push(ROUTES.LOGIN);
              }}
            ></Button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <img style={{ width: 200 }} src={failedImg}></img>
          <p style={{ fontSize: 18 }}>
            Email verification link expired. Please try again by resent new
            verification email.
          </p>
        </div>
      );
    }
  };
  const renderResentEmail = () => {
    if (response?.isEmailSent) {
      return (
        <div>
          <img style={{ width: 150 }} src={successImg}></img>
          <p style={{ fontSize: 18 }}>Email Verification Link Sent!</p>
        </div>
      );
    } else {
      return (
        <div>
          <img style={{ width: 150 }} src={failedImg}></img>
          <p style={{ fontSize: 18 }}>Something went wrong!</p>
        </div>
      );
    }
  };
  return (
    <div className="AuthBg">
      <Header type="type1" />
      <div style={{ height: "100%" }}>
        <div className="Login mt-5 ">
          <div className="signInFormDiv signInmain">
            <div className="formDiv">
              <h1 className="signInHeading p-3">
                {/* {console.log("response isResentEmail",isResentEmail)} */}
                {isResentEmail == "false"
                  ? "Email Verification"
                  : "Resent Validation Email"}
              </h1>

              {loading ? (
                <p>loading...</p>
              ) : isResentEmail == "false" ? (
                renderEmailValidationDiv()
              ) : (
                renderResentEmail()
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerify;
