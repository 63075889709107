import React from "react";
import { formatUsCurrency } from "../../../services/utills/helper";
import "./style.css";

const index = ({ orderDetails }) => {
	return (
		<div>
			<div>
				{orderDetails &&
					orderDetails?.adspaces?.map((adspace) => {
						return (
							<div className="orderDetailsAdSpace">
								<div
									style={{ backgroundImage: `url(${adspace?.adspace?.url})` }}
									className="orderDetailsAdSpaceImages"
								>
									{/* <img
                    style={{ width: "150px", height: "90px" }}
                    src={adspace?.adspace?.url}
                    alt=""
                  /> */}
								</div>
								<div className="orderDetailsAdSpaceInfo">
									<strong>{adspace?.adspace?.title}</strong>
									<div>{adspace?.adspace?.info}</div>
									<div>
										<strong>Item ID : </strong> {adspace?.adspace?.itemId}
									</div>
									<div>
										<strong>Package : </strong> {adspace?.adspace?.description}
									</div>
									<div>
										<strong>Price : </strong>
										{/* $ {Number(adspace?.adspace?.price).toFixed(2)} */}
										{formatUsCurrency(adspace?.adspace?.price)}
									</div>
									<div>
										<strong>Quantity : </strong> {adspace?.quantity}
									</div>
									<div>Includes graphics support, print and install</div>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default index;
