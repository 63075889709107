import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import logger from "redux-logger";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import {
  AuthReducer,
  AdminReducer,
  CustomerReducer,
  VenueReducer,
  StaffReducer,
  CommonReducer,
  VendorReducer,
  SiteReducer,
} from "./reducers/Index";
// import { AuthReducer } from "./reducers/AuthReducer";
// import { CustomerReducer } from "./reducers/CustomerReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxReset from "redux-reset";
import purgeStoredState from "redux-persist/es/purgeStoredState";

const adminConfig = {
  key: "AdminReducer",
  storage: storage,
  blacklist: ["venueDetails"],
};

const authConfig = {
  key: "AuthReducer",
  storage: storage,
  blacklist: ["userUpdated"],
};

const appReducer = combineReducers({
  AuthReducer: persistReducer(authConfig, AuthReducer),
  CustomerReducer,
  AdminReducer: persistReducer(adminConfig, AdminReducer),
  VenueReducer,
  StaffReducer,
  VendorReducer,
  CommonReducer,
  SiteReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    // localStorage.removeItem("token");
    // localStorage.removeItem("user");
    // localStorage.removeItem("persist:root");
    // localStorage.removeItem("persist:AdminReducer");
    localStorage.clear();

    // persistStore(STORE).purge();
    // purgeStoredState(persistConfig);
    state = undefined;
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["AdminReducer", "CommonReducer", "AuthReducer"],
};

const middleware = applyMiddleware(thunk, logger);

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const persistedReducer = persistReducer(persistConfig, rootReducer);
const STORE = createStore(persistedReducer, composeEnhancers(middleware));

const PERSISTOR = persistStore(STORE);

export { STORE, PERSISTOR };
