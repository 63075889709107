import { ACTION_TYPE } from "../../services/constants/Index";

export const initialState = {
  orders: [],
  orderDetails: {},
  allDepartments: [],
  allVenueStaffMembers: [],
};
export const VenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.VENUE_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
      };
    case ACTION_TYPE.VENUE_ALL_DEPARTMENTS:
      return {
        ...state,
        allDepartments: action.payload.data,
      };
    case ACTION_TYPE.VENUE_ALL_STAFF_MEMBERS:
      return {
        ...state,
        allVenueStaffMembers: action.payload.data,
      };

    case ACTION_TYPE.VENUE_ALL_ORDERS_DETAILS:
      return {
        ...state,
        orderDetails: action.payload.data,
      };

    case ACTION_TYPE.VENUE_UPDATE_STAFF_MEMBERS:
      const index = state.allVenueStaffMembers.findIndex(
        (venueStaffMember) => venueStaffMember._id === action.payload.data._id
      );
      console.log(
        "🚀 ~ file: VenueReducer.js ~ line 45 ~ VenueReducer ~ action.payload.data",
        action.payload.data,
        index,
        [
          ...state.allVenueStaffMembers.slice(0, index), // everything before current post
          {
            ...state.allVenueStaffMembers[index],
            ...action.payload.data,
          },
          ...state.allVenueStaffMembers.slice(index + 1), // everything after current post
        ]
      );

      return {
        ...state,
        allVenueStaffMembers: [
          ...state.allVenueStaffMembers.slice(0, index), // everything before current post
          {
            ...state.allVenueStaffMembers[index],
            ...action.payload.data,
          },
          ...state.allVenueStaffMembers.slice(index + 1), // everything after current post
        ],
      };

    default:
      return state;
  }
};
