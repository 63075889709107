import React, { useEffect, useState } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./CustomerManagement.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import { CustomerDetailModal } from "../../../components/ObjectModal";
import { formatUSNumber } from "../../../services/utills/helper";

const CustomerManagement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const { customers } = useSelector((state) => state.AdminReducer);

  const [filter, setFilter] = useState("all");
  const [formattedOrders, setFormattedOrder] = useState([]);
  const [openCustomerModal, setCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();

  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        await dispatch(AdminAction.getAllCustomers());
      }
    })();
  }, [user, userUpdated]);

  useEffect(() => {
    (async () => {
      console.log("customers :>> ", customers);
      const formattedTableData = await customers.map((elm) => ({
        _id: elm?._id,
        Name: elm?.firstName,
        Email: elm?.email,
        Phone: formatUSNumber(elm?.phone),
      }));
      setFormattedOrder(formattedTableData);
    })();
  }, [customers]);

  const handleClick = (item) => {
    console.log("item :>> ", item);
    // // item = {...item}
    // const selectedItem = customers.find((x) => x._id === item._id);
    // // item = { ...item, ...selectedItem };
    // console.log("selectedItem", selectedItem);
    // setSelectedCustomer(selectedItem);
    // // dispatch(AdminAction.orderDetails(item));
    // setCustomerModal(true);
    // // history.push(`${ROUTES.ORDER_MANAGEMENT_DETAILS}`);
    props.history.push(`/customer/details/${item._id}`);
  };

  return (
    <div>
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Customer Management" />
          {/* <div className="tabelOrder customerTableOrder"> */}
          <div className="tabelOrder">
            <Table
              tableHead={["Name", "Email", "Phone"]}
              // action={true}
              tableData={formattedOrders}
              button="yes"
              // buttonTwo="yes"
              handleBtnClick={handleClick}
            />
          </div>
          <CustomerDetailModal
            open={openCustomerModal}
            title="Header Title"
            handleClose={() => setCustomerModal(false)}
            data={selectedCustomer}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerManagement;
