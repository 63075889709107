import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../button/Button";
import "./style.css";
import InputField from "../../input/Input";

const Modal = (props) => {
  const [editable, setEditable] = useState(false);

  const { data } = props;

  const handleChange = () => {
    //
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div>
            <h1>Customer Details</h1>
          </div>
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <div className="addSpaceInputs customerdetailPopup">
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">Name</div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.firstName} {data?.lastName}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Email
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.email}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Phone
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.phone}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Street
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.street}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">City</div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.city}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                State
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.state}
              </div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">ZIP</div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.zipCode}
              </div>
            </div>
            {/* <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft venuedetailleftside">
                Country
              </div>
              <div className="addSpaceInputsRight venuedetailrightside">
                {data?.country}
              </div>
            </div> */}
            {/* <div className="addSpaceInputsIndividual">
							<div className="addSpaceInputsLeft venuedetailleftside">
								Prior Orders
							</div>
							<div className="addSpaceInputsRight venuedetailrightside">
								{data && data?.priorOrders
									? data.priorOrders
									: "no prior orders"}
							</div>
						</div> */}
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};
export default Modal;
