import { Card } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Button from "../../../components/button/Button";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import "./ConfigureVenue.css";
import Canvas from "../canvas/index";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction } from "../../../redux/actions/Index";
import Table from "../../../components/table/Table";

function ConfigureVenue(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { venueId, mapId } = useParams();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { venues } = useSelector((state) => state.AdminReducer);

  const [showMap, setShowMap] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleChange = (type) => {
    setShowMap(true);
    if (type === "edit") {
      setEdit(true);
    }
  };
  useEffect(() => {
    // (async () => {
    //   const res = await dispatch(AdminAction.configuredBefore(venueId));
    //   console.log("res isConfigured", props);
    //   if (res && res.data && res.data?.data === false) setShowMap(true);
    // })();
    console.log("params in configure venue", venueId, mapId);
    return () => {
      dispatch({
        type: ACTION_TYPE.GET_VENUE_DETAILS,
        payload: "",
      });
    };
  }, []);

  useEffect(() => {
    getTableData();
  }, [venues]);

  const getTableData = () => {
    console.log("results getTableData :>> ", venues);

    let finalData = venues.map((data, index) => {
      return {
        id: data["_id"],

        "Map Name": `Map ${index}`,

        "Ad-Spaces": data["adSpace"]?.length,
      };
      // return [data["_id"], data["firstName"], data["adSpace"]?.length];
    });

    setTableData(finalData);
  };

  return (
    <div className="start">
      {user?.role && (
        <RBAC
          userRole={user?.role} // User roles that are given to user in the backend
          initList={initList}
          renderNoAccess={() => <SideNav />}
        >
          <SideNav />
        </RBAC>
      )}
      <div className="screen ">
        <HeaderBar title="Venue Management / Configure Venue" />

        {mapId === "new" ? (
          <div className="adminCanvas">
            <div style={{ position: "relative", marginBottom: "40px" }}>
              <Canvas type="new" />
            </div>
          </div>
        ) : (
          <div className="adminCanvas">
            <div style={{ position: "relative", marginBottom: "40px" }} />
            <Canvas type="edit" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ConfigureVenue;
