import moment from "moment";
import { useQuery } from "../../hooks/useQueryHook";
import { APPLICATION_CONSTANTS } from "../constants/Index";

const formatDate = (date) => {
  let current_datetime = new Date(date);
  let formatted_date =
    current_datetime.getMonth() +
    1 +
    "/" +
    current_datetime.getDate() +
    "/" +
    current_datetime.getFullYear();
  console.log(formatted_date);
  return formatted_date;
};

const formatDateForCsv = ({ startDate, endDate }) => {
  console.log("year", startDate, moment(startDate, "YYYY-MM-DD").format("YY"));

  let momentStartDate = moment(startDate, "YYYY-MM-DD");
  let momentEndDate = moment(endDate, "YYYY-MM-DD");

  let formattedStartDate =
    momentStartDate.format("D") +
    momentStartDate.format("M") +
    momentStartDate.format("YY");

  let formattedEndDate =
    momentEndDate.format("D") +
    momentEndDate.format("M") +
    momentEndDate.format("YY");

  console.log("formatted date", formattedStartDate, formattedEndDate);

  return `brandevent_${formattedStartDate}-${formattedEndDate}`;
};

const formatUsCurrency = (price) => {
  console.log(`price`, price);
  if (!price) return;
  let dollarUSLocale = Intl.NumberFormat("en-US");
  dollarUSLocale = dollarUSLocale.format(Number(price).toFixed(2));
  console.log(`totalAmount helper`, `${dollarUSLocale}`);

  return `$${dollarUSLocale}`;
};

// .toFixed(2)

const formatUSNumber = (entry = "") => {
  console.log("entry", entry);
  if (!entry.length) return null;
  const match = entry
    .replace(/\D+/g, "")
    .replace(/^1/, "")
    .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
  const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
  return `${part1}${part2}${part3}`;
};

const calculateCartItems = (cartItems, venueId, orderId) => {
  if (cartItems) {
    if (orderId && venueId) {
      const venueCartItems = cartItems.filter(
        (cart) => cart.venueId === venueId && cart.orderId === orderId
      );
      return venueCartItems;
    }

    const venueCartItems = cartItems.filter((cart) => cart.venueId === venueId);
    return venueCartItems;
  }
  return [];
};

const percentage = (num, per) => {
  return (num / 100) * per;
};

const addChargeTax = (totalAmount, tax = 0) => {
  const finalAmount = percentage(totalAmount, tax);
  console.log("final amount", finalAmount);

  return finalAmount;
};

export {
  formatDate,
  calculateCartItems,
  formatUSNumber,
  formatUsCurrency,
  formatDateForCsv,
  addChargeTax,
};
