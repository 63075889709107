import React from "react";
import "./DetailsDesign.css";
function DetailsDesign(props) {
	return (
		<div className="top top2">
			<div className="start start2">
				<h4 className="noOfDetails">
					{props.icon ? props.icon : <img src={props.url} />}
				</h4>
				<h5 className="textOfDetails">{props.text1}</h5>
			</div>
		</div>
	);
}

export default DetailsDesign;
