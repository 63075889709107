import React, { useState, useEffect } from "react";
import Button from "../../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import FormSection from "../../../../components/customerInfoCard";

import states from "../../../../services/constants/states.json";

import "../styles.css";
import EventInfoSection from "../../../../components/eventInfoCard";
import DiscountSection from "../../../../components/discountSection";

const StaffCustomerInfo = ({
  state,
  handleChange,
  setState,
  handleNext,
  errorData,
}) => {
  const user = useSelector((state) => state.AuthReducer.userData);
  const { loadingState } = useSelector((state) => state.CommonReducer);
  let events = [
    ">10",
    "10-50",
    "51-100",
    "101-200",
    "201-500",
    "501-1000",
    "1001-2000",
    ">2000",
  ];

  console.log("state after refresh", state);

  return (
    <div>
      <div className="staffCustomer">
        <div className=" ">
          <h1>Customer Contact Information</h1>
        </div>
        <div className="customerInfoDiv">
          <div className="CustomerInfo">
            {/* {console.log("state out",state)} */}

            <FormSection
              state={state}
              setState={setState}
              handleChange={handleChange}
              errorData={errorData}
              states={states}
            />

            <div className="meetingInfo">
              <EventInfoSection
                state={state}
                events={events}
                setState={setState}
                handleChange={handleChange}
                errorData={errorData}
              />
            </div>

            <div className="meetingInfo">
              <DiscountSection
                state={state}
                events={events}
                setState={setState}
                handleChange={handleChange}
                errorData={errorData}
              />
            </div>
          </div>
          <Button
            background="primary"
            color="tertiary"
            name="Next"
            handleClick={() => handleNext(2)}
            loading={loadingState}
            disabled={loadingState}
          />
        </div>
      </div>
    </div>
  );
};

export default StaffCustomerInfo;
