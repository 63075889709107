function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignupForm = (formData) => {
  const { registerId, password, confirmPassword } = formData || {};
  let error = {
    registerId: [],
    password: [],
    confirmPassword: [],
  };
  let isFormValid = true;
  if (isBlank(registerId) === true) {
    isFormValid = false;
    error.registerId = ["This field is required."];
  }
  if (isBlank(password) === true) {
    isFormValid = false;
    error.password = ["This field is required."];
  } else if (
    password?.trim().length > 0 &&
    (password?.trim().length < 6 || password?.trim().length > 15)
  ) {
    isFormValid = false;
    error.password = ["Password length must be between 6 to 15 characters."];
  }
  if (isBlank(confirmPassword) === true) {
    isFormValid = false;
    error.confirmPassword = ["This field is required."];
  } else if (
    confirmPassword?.trim().length > 0 &&
    confirmPassword?.trim().length > 0 &&
    password?.trim() !== confirmPassword?.trim()
  ) {
    isFormValid = false;
    error.confirmPassword = ["Passwords Don’t Match!"];
  }
  return { isFormValid, error };
};
