import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../../components/button/Button";
// import mailSent from "../../../../images/commonImage/Mailsent.png";
import { ROUTES } from "../../../../services/constants/Index";
import CheckMarkImage from "../../../../images/commonImage/checkMark.png";

const ConfirmInfo = ({ handleStep }) => {
  return (
    <div className="mailSent">
      <img
        src={CheckMarkImage}
        style={{ width: "200px", marginBottom: 20 }}
        alt="successImage"
      />
      <h4>Your order has been created .</h4>

      <Button
        name="Preview Email"
        background="primary"
        color="secondary"
        handleClick={() => handleStep(4)}
      ></Button>
    </div>
  );
};

export default ConfirmInfo;
