import React, { useEffect, useState } from "react";
import "./styles.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import Button from "../../../components/button/Button";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerAction,
  AdminAction,
  VenueAction,
} from "../../../redux/actions/Index";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import moment from "moment";
import AlertDialog from "../../../components/AlertDialog/alertDialog";
import AddStaffMember from "../../../components/Modals/AddStaffMember/adddbrand";
const StaffManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  // const { userUpdated } = useSelector((state) => state.AuthReducer);

  const { allVenueStaffMembers, allDepartments } = useSelector(
    (state) => state.VenueReducer
  );
  const [tableData, setTableData] = useState([]);
  const [editStaff, setEditStaff] = useState();
  const [open, setOpen] = useState(false);

  // const { order } = useSelector((state) => state.CustomerReducer);

  // useEffect(() => {
  //   (async () => {
  //     if (user?._id && userUpdated) {
  //       dispatch(VenueAction.getStaffDepartments());
  //     }
  //   })();
  // }, [user, userUpdated]);

  useEffect(() => {
    (async () => {
      dispatch(VenueAction.getStafMembers({ filter: null, venueId: user._id }));
      dispatch(
        VenueAction.getStaffDepartments({ filter: null, venueId: user._id })
      );
    })();
  }, []);

  useEffect(() => {
    getTableData();
  }, [allVenueStaffMembers]);

  const getTableData = () => {
    console.log("results getTableData :>> ", allVenueStaffMembers);

    let finalData =
      allVenueStaffMembers &&
      allVenueStaffMembers.length > 0 &&
      allVenueStaffMembers.map((data, index) => {
        return {
          _id: data._id,
          "S. No": index + 1,
          Status: data["status"] ? "Active" : "Inactive",
          // isActive: data["isActive"],
          "Staff Id": data["staffMemberId"],
          "Staff Name": data["firstName"] + " " + data["lastName"],
          Email: data["email"],
          Department: data["department"],
          "Order Permit": data["isPermitforOrderApproval"]
            ? String(data["isPermitforOrderApproval"])
            : "-",
          // Status: String(data["isActive"]),
          "Created At": moment(data["createdAt"]).format("MM/DD/YYYY"),
        };
        // return [data["_id"], data["firstName"], data["adSpace"]?.length];
      });

    setTableData(finalData);
  };

  const handleClickIcon = () => {
    setOpen(!open);
    setEditStaff();
  };

  const onEditStaff = (item) => {
    console.log("item", item);

    setEditStaff(
      allVenueStaffMembers
        .filter((data) => data._id === item._id)
        .map((data) => ({
          ...data,
          isPermitted: data.isPermitforOrderApproval,
        }))[0]
    );
    setOpen(true);
  };

  const actionsMenu = [
    // {
    //   id: "edit",
    //   name: "Edit",
    //   onAction: onEditStaff,
    // },
    {
      id: "activeOrInactive",
      name: "Set Active",
      onAction: (item) => {
        console.log("active", item);
        dispatch(
          VenueAction.updateStaffMember({
            staffId: item._id,
            updateBody: {
              status: item?.Status == "Active" ? false : true,
            },
          })
        );
      },
    },
  ];
  return (
    <div>
      <div className="start venueM">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Staff Management" />
          <div className="addNewVendorVenMgmt">
            <Button
              // className="col-lg-3"
              background="primar"
              color="primary"
              name="Add New Staff"
              handleClick={() => setOpen(true)}
            ></Button>
          </div>
          <div className="tabelOrder">
            <Table
              tableHead={[
                "S. No",
                // "Active",
                "Staff Id",
                "Staff Name",
                "Email",
                "Department",
                "Order Permit",
                "Status",
                "Created At",
              ]}
              actionsMenu={actionsMenu}
              action={false}
              tableData={tableData}
            />
          </div>
        </div>
      </div>
      {open && (
        <AddStaffMember
          departments={allDepartments}
          open={open}
          editStaff={editStaff}
          user={user}
          handleClose={handleClickIcon}
        />
      )}
    </div>
  );
};

export default StaffManagement;
