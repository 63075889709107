import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import elevator from "../../images/commonImage/elevator.png";
import "./GuideModal.css";
import InputField from "../input/Input";
import Button from "../button/Button";

const Modal = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="adspacebuttonGroup">
          <div>
            <h4
              className="addscreenhead"
              style={{
                lineHeight: 1.7,
                color: "#6b6550",
                marginBottom: "1em",
                textAlign: "center",
              }}
            >
              {" "}
              {/* Get started by uploading a map from the right side Menu. Then
              click on "Add AdSpace" to insert an Ad Space on the map. You can
              edit an AdSpace by choosing an Ad Space and clicking on Edit on
              the right side. */}
              You can upload a map through Change Map button on the right. Add
              Ad Space with the Add Ad Space button on the right or Choose an Ad
              Space to edit.
            </h4>
            <div className="text-center">
              <Button
                background="secondary"
                fontSize="15px"
                border="1px solid #fff"
                color="secondary"
                name="Get Started"
                handleClick={props.handleClose}
              ></Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Modal;

/* <Modal.Header closeButton>
<Modal.Title
  className="screen-modal-1"
  id="contained-modal-title-vcenter"
  style={{ width: "100%", textAlign: "center" }}
>
  Editor
</Modal.Title>
</Modal.Header>
<Modal.Body className="screen-modal-body">
{/* {props?.match?.params?.id ? (

) : (
  <h4
    className="addscreenhead"
    style={{ lineHeight: 1.7, color: "#6b6550" }}
  >
    Choose the aspect ratio and get started.
  </h4>
)} */
