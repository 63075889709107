import React, { useEffect, useState } from "react";
import "./VenueManagement.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import Button from "../../../components/button/Button";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAction, AdminAction } from "../../../redux/actions/Index";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";

import AlertDialog from "../../../components/AlertDialog/alertDialog";
const VenueManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const { venues } = useSelector((state) => state.AdminReducer);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState({
    state: false,
    id: null,
  });
  // const { order } = useSelector((state) => state.CustomerReducer);

  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        dispatch(AdminAction.getAllVenues());
      }
    })();
  }, [user, userUpdated]);

  useEffect(() => {
    getTableData();
  }, [venues]);

  const getTableData = () => {
    console.log("results getTableData :>> ", venues);

    let finalData = venues.map((data) => {
      return {
        id: data["_id"],
        venueId: data["venueId"],
        "Venue Name": data["firstName"],
        "Vendor Name": data?.vendorId?.firstName,
        Maps: data["maps"]?.length,
        Status: data["status"],
      };
      // return [data["_id"], data["firstName"], data["adSpace"]?.length];
    });

    setTableData(finalData);
  };

  const handleClick = () => {
    history.push(`${ROUTES.ADD_NEW_VENUE}`);
  };
  const handleClickIcon = (item) => {
    console.log("itewm ,", item);
    setOpen({
      state: true,
      id: item.id,
    });
  };
  return (
    <div>
      <div className="start venueM">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Venue Management" />
          <div className="addNewVendorVenMgmt">
            <Button
              // className="col-lg-3"
              background="primar"
              color="primary"
              name="Add New Venue"
              handleClick={handleClick}
            ></Button>
          </div>
          <div className="tabelOrder">
            <Table
              tableHead={["Venue Name", "Vendor Name", "Maps"]}
              action={true}
              tableData={tableData}
              // button="yes"
              // buttonTwo="yes"
              handleBtnClick1={async (item) => {
                console.log("item :>> ", item);
                history.push(`${ROUTES.MAP_LIST}/${item.id}`);
              }}
              handleBtnClick={(item) => {
                console.log("itemmm", item);
                history.push(`${ROUTES.VENUE_DETAILS}/${item?.id}`);
              }}
              handleClickIcon={handleClickIcon}
              handleStatusChange={async (item) => {
                console.log("item", item);
                let { id, statusAction } = item;

                await dispatch(
                  AdminAction.changeVenueVendorStatus({
                    id,
                    status: statusAction,
                    type: "venue",
                  })
                );
              }}
            />
            <AlertDialog
              open={open.state}
              handleClose={() =>
                setOpen({
                  state: false,
                  id: null,
                })
              }
              message="Are you sure you want to delete?"
              button1="Yes"
              button2="No"
              handleDelete={() => {
                dispatch(AdminAction.deleteVenue(open.id));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueManagement;
