import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const TableActionMenu = ({ actionData, item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id={"long-button"}
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={"long-menu"}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        className={"menuAction"}
        onClose={handleClose}
      >
        {actionData.map((menu) => (
          <MenuItem
            key={menu.id}
            onClick={() => {
              menu.onAction(item);
              handleClose();
            }}
            className={"menuItemAction"}
          >
            {menu.id == "activeOrInactive"
              ? item.Status == "Active"
                ? "Set Inactive"
                : "Set Active"
              : menu.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TableActionMenu;
