import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import { ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAction } from "../../../redux/actions/Index";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./modal.css";
import Button from "../../../components/button/Button";
import { APPLICATION_CONSTANTS } from "../../../services/constants/Index";
import { LensTwoTone } from "@material-ui/icons";
import { withRouter } from "react-router";
import testImage from "../../../images/commonImage/elevator.png";
import { formatUsCurrency } from "../../../services/utills/helper";
const FormDialog = (props) => {
  const { venueId } = useParams();
  const history = useHistory();
  const [data, setdata] = useState({});
  const user = useSelector((state) => state.AuthReducer.userData);
  const cart = useSelector((state) => state.CustomerReducer.cart);
  const dispatch = useDispatch();
  console.log("id", props);

  useEffect(() => {
    props?.adSpaceData?.adSpaces?.map((item) => {
      if (item.id === props.id) {
        setdata(item);
      }
    });
  }, [props.id]);

  const handleAddToCart = async () => {
    console.log("cart data :>> ", data);
    let body = {
      customerId: user._id ? user._id : null,
      venueId,
      price: data.price,
      quantity: 1,
      info: data.info,
      url: data.url,
      name: data.title,
      id: data._id,
      description: data?.description,
      adSpaceId: data?._id,
      itemId: data?.itemId,
      mapId: data?.mapId,
    };
    let finalData;
    if (cart?.length > 0) {
      finalData = [...cart, body];
    } else {
      finalData = [body];
    }

    await dispatch(CustomerAction.addToCart(finalData));
    // history.push({
    //   pathname: ROUTES.CART,
    // });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        className="details"
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center model-head">
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="box">
          {cart?.some((item) => item?.id === data?._id) ? (
            <div>
              <h3 style={{ textAlign: "center" }} className="m-3">
                Item Added Successfully.
              </h3>
              <div
                className="d-flex justify-content-center newDialogButtonBox
              "
              >
                <Button
                  marginRight="13px"
                  background="primary"
                  color="tertiary"
                  name="View cart"
                  handleClick={() => {
                    history.push(ROUTES.CART);
                  }}
                />

                <Button
                  background="primary"
                  color="tertiary"
                  name="Add another Item"
                  handleClick={() => {
                    props.handleClose();
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="left">
                <img src={data?.url} />
                {/* <img src={testImage} /> */}
              </div>
              <div className="modalRight mybox flex-column  ">
                <p
                  style={{
                    fontFamily: "Whitney Semibold",
                    fontSize: "22px",
                    marginBottom: "0",
                    fontWeight: "800",
                  }}
                >
                  {data?.title}
                </p>
                <span style={{ fontFamily: "Whitney Book" }}>{data?.info}</span>
                {/* <h5 className="mb-3">
									<strong> {"Name "}:</strong>
									{data?.title || data?.name}
								</h5> */}
                <h5
                  className="mt-3 mb-3"
                  style={{
                    fontFamily: "Whitney Semibold",
                    marginBottom: "0px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "Whitney Semibold",
                      marginBottom: "0",
                      // fontWeight: "800",
                    }}
                  >
                    <strong
                      style={{
                        fontSize: "18px",
                        fontFamily: "Whitney Semibold",
                        marginBottom: "0",
                        fontWeight: "800",
                      }}
                    >
                      Item ID :
                    </strong>
                  </span>
                  {/* <strong>
										</strong>{" "} */}
                  <span
                    style={{
                      fontFamily: "Whitney Book",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {data?.itemId}{" "}
                  </span>
                </h5>
                <h5
                  className="mb-3"
                  style={{
                    fontFamily: "Whitney Semibold",
                    fontFamily: "Whitney Book",
                  }}
                >
                  <strong
                    style={{
                      fontSize: "18px",
                      fontFamily: "Whitney Semibold",
                      marginBottom: "0",
                      fontWeight: "800",
                    }}
                  >
                    {"Package :"}
                  </strong>
                  {/* <strong>
										</strong>{" "} */}
                  <span
                    style={{
                      fontFamily: "Whitney Book",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {data?.description}
                  </span>
                </h5>

                <h5
                  className="mt-2"
                  style={{
                    fontFamily: "Whitney Semibold",
                    marginBottom: "0px",
                  }}
                >
                  <strong
                    style={{
                      fontSize: "18px",
                      fontFamily: "Whitney Semibold",
                      marginBottom: "0",
                      fontWeight: "800",
                    }}
                  >
                    Price :
                  </strong>
                  {/* <strong>
										</strong>{" "} */}
                  <span
                    style={{
                      fontFamily: "Whitney Book",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {formatUsCurrency(data?.price)}
                    {/* ${Number(data?.price).toFixed(2)}{" "} */}
                  </span>
                </h5>

                <h5
                  className="mt-2"
                  style={{
                    fontFamily: "Whitney Semibold",
                    marginBottom: "0px",
                  }}
                >
                  <strong
                    style={{
                      fontSize: "18px",
                      fontFamily: "Whitney Semibold",
                      marginBottom: "0",
                      fontWeight: "800",
                    }}
                  >
                    Zone :
                  </strong>
                  {/* <strong>
										</strong>{" "} */}
                  <span
                    style={{
                      fontFamily: "Whitney Book",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {data?.zone || "-"}
                    {/* ${Number(data?.price).toFixed(2)}{" "} */}
                  </span>
                </h5>
                {/* 
                <h5
                  className="mt-3 mb-3"
                  style={{
                    fontFamily: "Whitney Semibold",
                    marginBottom: "0px",
                  }}
                > */}
                {/* <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "Whitney Semibold",
                      marginBottom: "0",
                      // fontWeight: "800",
                    }}
                  >
                    <strong
                      style={{
                        fontSize: "18px",
                        fontFamily: "Whitney Semibold",
                        marginBottom: "0",
                        fontWeight: "800",
                      }}
                    >
                      Map ID :
                    </strong>
                  </span> */}
                {/* <strong>
										</strong>{" "} */}
                {/* <span
                    style={{
                      fontFamily: "Whitney Book",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {data?.mapId}{" "}
                  </span> */}
                {/* </h5> */}
                {data?.realEstateSale && data.realEstateSale === true ? null : (
                  <span>Includes graphics support, print and install</span>
                )}
                {console.log(
                  "props.history.location.pathname",
                  props.match.path
                )}
                {/* <div className="buttonM mt-3 mb-3">
									{props.match.path !== "/venue-details/:venueId" && (
										<Button
											background="primary"
											color="tertiary"
											name="Add to Cart"
											handleClick={handleAddToCart}
										/>
									)}
								</div> */}
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default withRouter(FormDialog);
