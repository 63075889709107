import { ACTION_TYPE } from "../../services/constants/Index";

export const initialState = {
  orders: [],
  orderDetails: {},
  maps: [],
  mapData: [],
  customerInfo: {},
  customerLink: "",
  createdLinkInfo: {},
  discountCoupons: [],
};
export const StaffReducer = (state = initialState, action) => {
  console.log(
    "🚀 ~ file: StaffReducer.js ~ line 9 ~ StaffReducer ~ action",
    action
  );
  switch (action.type) {
    case ACTION_TYPE.STAFF_MAP_DATA:
      return {
        ...state,
        mapData: [...state.mapData, action.payload],
      };
    case ACTION_TYPE.SAVE_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload.data,
      };

    case ACTION_TYPE.STAFF_VENUE_MAPS:
      return {
        ...state,
        maps: action.payload.data,
      };

    case ACTION_TYPE.STAFF_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
      };
    case ACTION_TYPE.STAFF_ALL_ORDERS_DETAILS:
      return {
        ...state,
        orderDetails: action.payload.data,
      };

    case ACTION_TYPE.SAVE_CUSTOMER_LINK:
      return {
        ...state,
        createdLinkInfo: action.payload.data,
      };
    case ACTION_TYPE.GET_DISCOUNT_COUPONS:
      return {
        ...state,
        discountCoupons: action.payload.data,
      };

    default:
      return state;
  }
};
