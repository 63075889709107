import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import RouteRBAC from "../components/RBAC/RouteRBAC";
import { ROUTES } from "../services/constants/Index";
import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  VenueVendorRegister,
  VenueVendorLogin,
  EmailVerify,
} from "../Pages/Authentication";
import {
  Cart,
  Checkout,
  OrderDetailsPage,
  CustomerInfo,
  LoginRegister,
  MyOrder,
  MyProfile,
  OrderConfirmation,
  ProposalConfirmation,
  OrderDetails,
  SeeVenue,
  FAQ,
  ContactUs,
  AboutUs,
} from "../Pages/Story";
import {
  VenueManagement,
  VendorManagement,
  OrderManagement,
  ConfigureVenue,
  CustomerManagement,
  AddNewVenue,
  VenueDetails,
  AdminOrderDetails,
} from "../Pages/Admin/index";

import {
  OrderManagement as VenueOrderManagement,
  DepartmentManagement,
  StaffManagementVendor,
} from "../Pages/Venue";
import VenueOrderDetails from "../Pages/Venue/orderDetails";

import {
  OrderManagement as VendorOrderManagement,
  VendorOrderDetails,
} from "../Pages/Vendor";
import {
  OrderManagement as StaffOrderManagement,
  // VendorOrderDetails,
} from "../Pages/StaffManagement";
import DiscountManagement from "../Pages/DiscountMangement";
import CustomerDetails from "../Pages/Admin/customerDetails";
import VendorDetails from "../Pages/Admin/vendorDetails";
import AddNewVendor from "../Pages/Admin/addNewVendor/AddNewVendor";
import CustomerOrderDetails from "../Pages/Admin/customerOrderDetails";
import MapList from "../Pages/Admin/mapList";
import CustomerLinkManagement from "../Pages/StaffManagement/CustomerLinkManagement";
import StaffCustomerInfo from "../Pages/StaffManagement/CustomerLinkManagement/components/customerInformation";
import StepComponents from "../Pages/StaffManagement/CustomerLinkManagement/components";
import StaffOrderDetails from "../Pages/StaffManagement/orderDetails";

// import AdminOrderDetails from "../Pages/Admin/adminOrderDetails/AdminOrderDetails";

const Routing = () => {
  return (
    <Switch>
      <RouteRBAC renderNoAccess={() => <Redirect to={{ pathname: "/" }} />}>
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.EMAIL_VERIFY}
          component={EmailVerify}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.REGISTER}
          component={Register}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.VENUE_VENDOR_REGISTER}
          component={VenueVendorRegister}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.LOGIN}
          component={Login}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.VENUE_VENDOR_LOGIN}
          component={VenueVendorLogin}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.FORGOT_PASSWORD}
          component={ForgotPassword}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.RESET_PASSWORD}
          component={ResetPassword}
          exact
        />

        <Route
          authorisedUsers={["customer"]}
          path={ROUTES.MYORDER}
          component={MyOrder}
          exact
        />
        <Route
          authorisedUsers={["customer"]}
          path={ROUTES.MYPROFILE}
          component={MyProfile}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.FAQ}
          component={FAQ}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.CONTACTUS}
          component={ContactUs}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.ABOUTUS}
          component={AboutUs}
          exact
        />
        <Route
          authorisedUsers={["customer"]}
          path={ROUTES.ORDERDETAILSPAGE}
          component={OrderDetailsPage}
          exact
        />

        <Route
          authorisedUsers={["guest", "customer"]}
          path={`${ROUTES.VENUE}/:venueId`}
          component={SeeVenue}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer"]}
          path={`${ROUTES.VENUE}/:venueId/:orderId`}
          component={SeeVenue}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.CART}
          component={Cart}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.CUSTOMER_INFO}
          component={CustomerInfo}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.PROPOSALCONFIRMATION}
          component={ProposalConfirmation}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.ORDERCONFIRMATION}
          component={OrderConfirmation}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.LOGINREGISTER}
          component={LoginRegister}
          exact
        />
        <Route
          authorisedUsers={["guest"]}
          path={ROUTES.CHECKOUT}
          component={Checkout}
          exact
        />
        <Route
          authorisedUsers={["guest", "customer", "venue", "vendor", "admin"]}
          path={ROUTES.ORDERDETAIL}
          component={OrderDetails}
          exact
        />

        {/* Admin */}
        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.VENDOR_MANAGEMENT}
          component={VendorManagement}
          exact
        />

        <Route
          authorisedUsers={["admin"]}
          path={`${ROUTES.VENDOR_DETAILS}/:orderId`}
          component={VendorDetails}
          exact
        />

        <Route
          // authorisedUsers={["customer", "venue", "vendor", "admin"]}
          authorisedUsers={["admin"]}
          path={`${ROUTES.VENUE_DETAILS}/:venueId`}
          component={VenueDetails}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.ADD_NEW_VENUE}
          component={AddNewVenue}
          exact
        />

        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.ADD_NEW_VENDOR}
          component={AddNewVendor}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={`${ROUTES.MAP_LIST}/:venueId`}
          component={MapList}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={`${ROUTES.CONFIGURE_VENUE}/:venueId/:mapId`}
          component={ConfigureVenue}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.VENUE_MANAGEMENT}
          component={VenueManagement}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.ORDER_MANAGEMENT_DETAILS}
          component={AdminOrderDetails}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.ORDER_MANAGEMENT}
          component={OrderManagement}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.CUSTOMER_MANAGEMENT}
          component={CustomerManagement}
          exact
        />

        <Route
          authorisedUsers={["admin"]}
          path={ROUTES.CUSTOMER_DETAILS}
          component={CustomerDetails}
          exact
        />
        <Route
          authorisedUsers={["admin"]}
          path={`${ROUTES.CUSTOMER_ORDER_DETAILS}/:orderId`}
          component={CustomerOrderDetails}
          exact
        />

        {/* Venue */}

        <Route
          authorisedUsers={["venue"]}
          path={ROUTES.VENUE_ORDER_MANAGEMENT}
          component={VenueOrderManagement}
          exact
        />

        <Route
          authorisedUsers={["venue"]}
          path={`${ROUTES.VENUE_ORDER_MANAGEMENT_DETAILS}/:orderId`}
          component={VenueOrderDetails}
          exact
        />
        <Route
          authorisedUsers={["venue"]}
          path={ROUTES.VENUE_DEPARTMENT_MANAGEMENT}
          component={DepartmentManagement}
          exact
        />
        <Route
          authorisedUsers={["venue"]}
          path={ROUTES.VENUE_STAFF_MANAGEMENT}
          component={StaffManagementVendor}
          exact
        />

        {/* Vendor */}

        <Route
          authorisedUsers={["vendor"]}
          path={ROUTES.VENDOR_ORDER_MANAGEMENT}
          component={VendorOrderManagement}
          exact
        />

        <Route
          authorisedUsers={["vendor"]}
          path={`${ROUTES.VENDOR_ORDER_MANAGEMENT_DETAILS}/:orderId`}
          component={VendorOrderDetails}
          exact
        />

        {/* STAFF */}
        <Route
          authorisedUsers={["staff"]}
          path={`${ROUTES.STAFF_ORDER_MANAGEMENT}`}
          component={StaffOrderManagement}
          exact
        />

        <Route
          authorisedUsers={["staff"]}
          path={`${ROUTES.STAFF_ORDER_DETAILS}/:orderId`}
          component={StaffOrderDetails}
          exact
        />

        <Route
          authorisedUsers={["staff"]}
          path={ROUTES.CUSTOMER_LINK_MANAGEMENT}
          component={CustomerLinkManagement}
          exact
        />
        <Route
          authorisedUsers={["staff"]}
          path={ROUTES.STAFF_CUSTOMER_INFO}
          component={StepComponents}
          exact
        />
        {/* <Route
          authorisedUsers={["staff"]}
          path={ROUTES.DISCOUNT_CREATION}
          component={DiscountManagement}
          exact
        /> */}
      </RouteRBAC>
    </Switch>
  );
};
export default Routing;
