function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

function isNull(str) {
  return str === "null";
}

export const ValidateContactUsForm = (formData) => {
  const { name, email, phone, city, state, comments } = formData || {};

  let error = {
    name: [],
    email: [],
    phone: [],
    city: [],
    state: [],
    comments: [],
  };
  let isFormValid = true;
  if (isBlank(name) === true) {
    isFormValid = false;
    error.name = ["This field is required."];
  } else if (name?.trim().length > 0 && name?.trim().length > 50) {
    isFormValid = false;
    error.name = ["Name cannot be greater than 50."];
  }
  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  } else if (
    email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["This must be a valid email address."];
  }
  if (isBlank(phone) === true) {
    isFormValid = false;
    error.phone = ["This field is required."];
  } else if (isNumber(phone) === false) {
    isFormValid = false;
    error.phone = ["This field should be number."];
  } else if (phone?.length < 11) {
    isFormValid = false;
    error.phone = ["Please enter a valid phone number."];
  }

  if (isBlank(city) === true) {
    isFormValid = false;
    error.city = ["This field is required."];
  }
  if (isBlank(state) === true || isNull(state) === true) {
    isFormValid = false;
    error.state = ["This field is required."];
  }

  if (isBlank(comments) === true) {
    isFormValid = false;
    error.comments = ["This field is required."];
  }

  return { isFormValid, error };
};
