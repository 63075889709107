// /orders/get
import { ACTION_TYPE } from "../../services/constants/Index";
import Api from "../../services/utills/Axios";
import { CommonAction } from "./Index";

export const getVenueOrders =
  ({ filter, venueId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(`/venue/orders/get/${filter}`, { venueId });
      console.log("Venue all order", res);
      await dispatch({
        type: ACTION_TYPE.VENUE_ALL_ORDERS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };

export const addDepartmentAction =
  ({ payload, venueId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(`/venue/add/departments/${venueId}`, {
        payload,
      });
      console.log("Venue all depar", res);
      await dispatch({
        type: ACTION_TYPE.VENUE_ALL_DEPARTMENTS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };
export const addStaffMemberAction =
  ({ payload, venueId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(`/venue/add/staffMember/${venueId}`, payload);
      console.log("Venue all depar", res);
      await dispatch({
        type: ACTION_TYPE.VENUE_ALL_STAFF_MEMBERS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };

export const getStaffDepartments =
  ({ filter, venueId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(`/venue/staffDepartment/get/${filter}`, {
        venueId,
      });
      console.log(" VENUE_ALL_DEPARTMENTS", res);
      await dispatch({
        type: ACTION_TYPE.VENUE_ALL_DEPARTMENTS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };
export const getStafMembers =
  ({ filter, venueId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(`/venue/staffmembers/get/${filter}`, {
        venueId,
      });
      console.log(" VENUE_ALL_DEPARTMENTS", res);
      await dispatch({
        type: ACTION_TYPE.VENUE_ALL_STAFF_MEMBERS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };

export const updateStaffMember =
  ({ updateBody, staffId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(
        `/venue/staffMember/update/${staffId}`,
        updateBody
      );
      console.log(" VENUE_ALL_DEPARTMENTS", res);
      await dispatch({
        type: ACTION_TYPE.VENUE_UPDATE_STAFF_MEMBERS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };

export const venueOrderDetails = (item) => async (dispatch) => {
  try {
    await dispatch({
      type: ACTION_TYPE.VENUE_ALL_ORDERS_DETAILS,
      payload: { data: item },
    });
  } catch (error) {}
};

export const getVenueOrderDetails = (orderId) => async (dispatch) => {
  try {
    const res = await Api.get(`/venue/orders/details/get/${orderId}`);
    console.log("Venue all order", res);
    await dispatch({
      type: ACTION_TYPE.VENUE_ALL_ORDERS_DETAILS,
      payload: { data: res.data.data.result },
    });
  } catch (error) {}
};

export const orderAcceptReject =
  (payload, orderDetails, venueId) => async (dispatch) => {
    try {
      console.log("payload :>> ", payload);
      dispatch(CommonAction.startLoading());

      const res = await Api.post(`/venue/orders/changeStatus`, payload);
      console.log("venueId :>> ", venueId);
      // dispatch(getVenueOrders({ filter: "all", venueId }));

      console.log("Venue all order", res);
      // await dispatch({
      //   type: ACTION_TYPE.ADMIN_ALL_ORDERS,
      //   payload: { data: res.data.data.result },
      // });
      orderDetails["status"] = payload["status"];
      await dispatch({
        type: ACTION_TYPE.VENUE_ALL_ORDERS_DETAILS,
        payload: { data: orderDetails },
      });
      dispatch(CommonAction.completeLoading({}, false));
    } catch (error) {}
  };

export const changeOrderDiscount =
  ({ orderId, discount, discountType }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/venue/order/changeDiscount`, {
        orderId,
        discount,
        discountType,
      });

      // await dispatch({
      //   type: ACTION_TYPE.UPDATE_VENUE_VENDOR,
      //   payload: res.data.data,
      // });
      return res;
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      console.log("error", error);
      dispatch({ type: "LOADING_FAILURE" });
    }
  };

export const saveSpecifyZone = (data) => async (dispatch) => {
  try {
    // const res = await Api.post(`/venue/orders/changeStatus`, payload);
    console.log("save specify zone", data);
    // await dispatch({
    //   type: ACTION_TYPE.VENUE_ALL_ORDERS_DETAILS,
    //   payload: { data: res.data.data.result },
    // });
  } catch (error) {}
};
