import React, { useEffect } from "react";
import Header from "../../Authentication/Header";
import "./AboutUs.css";
import { ArrowBackSharp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { list1, list2, list3 } from "./data";
import { ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { SiteAction } from "../../../redux/actions/Index";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#414257",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "18px",
    width: "128px",
    justifyContent: "space-between",
    margin: "20px auto 20px 20px",
    ["@media (max-width: 460px)"]: {
      // margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

const AboutUs = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { venueId } = useSelector((state) => state.CustomerReducer);
  const { aboutUsContent } = useSelector((state) => state.SiteReducer);

  useEffect(() => {
    dispatch(SiteAction.getAboutUsContent());
  }, []);

  return (
    <div>
      <Header />
      <div
        className={classes.arrow}
        onClick={() => {
          if (venueId && venueId != "")
            history.push(`${ROUTES.VENUE}/${venueId}`);
          else history.goBack();
        }}
      >
        <ArrowBackSharp /> Back to Map
      </div>
      <div className="aboutUsWrapper">
        <div className="aboutUsHeading">
          <p>{aboutUsContent?.title}</p>
          <p>{aboutUsContent?.subTitle}</p>
          {/* <p>and is a product of Epeius Consulting, LLC.</p> */}
        </div>
        <div className="benefitsSection">
          <div className="benefitsForClientsWrapper">
            {aboutUsContent?.cards?.map((card) => (
              <div className="benefitsToClients">
                <h5>{card?.title}</h5>
                {card?.points?.map((list) => {
                  return (
                    <div className="listforClients">
                      <ul>
                        <li>
                          <p>{list?.text}</p>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
