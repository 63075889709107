import React, { useEffect, useState } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./styles.css";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackSharp, ArrowLeft } from "@material-ui/icons";
import dummyImage from "../../../images/NewIcons/NewIcon3.png";
import Canvas from "../../Story/NewDesign/index";
import { useParams } from "react-router-dom";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import InputField from "../../../components/input/Input";
import PhoneInput from "react-phone-input-2";
import states from "../../Story/CustomerInformation/data.json";
import {
  ValidateAddVendorForm,
  ValidateAddVenueForm,
} from "../addNewVenue/Validation";
import { Divider } from "@material-ui/core";
import { formatUSNumber } from "../../../services/utills/helper";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "18px",
    width: "70px",
    justifyContent: "space-between",
    margin: "20px auto 20px 20px",
    ["@media (max-width: 460px)"]: {
      // margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

const VendorDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);
  const { vendorDetails, categories } = useSelector(
    (state) => state.AdminReducer
  );

  const [state, setState] = useState({});
  const [categoryList, setCategoryList] = useState({});

  const [editable, setEditable] = useState(false);

  const [errorData, setErrorData] = useState({});

  const params = useParams();

  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        dispatch(AdminAction.getVendorDetails(params?.orderId));
        dispatch(AdminAction.getCategoriesByRole("vendor"));
      }
    })();
  }, [user, userUpdated]);

  useEffect(() => {
    const {
      adSpace,
      graphicData,
      createdAt,
      password,
      isActive,
      role,
      url,
      categories,
      // vendorId,
      orders,
      ...rest
    } = vendorDetails;
    setState(rest);

    let categoryObject = {};
    categories &&
      categories.length > 0 &&
      categories.map((category) => {
        categoryObject[category?.split("/")[1]] = category?.split("/")[2];
      });
    console.log("categoryObject", categoryObject);
    setCategoryList(categoryObject);
  }, [vendorDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "Brand" ||
      name === "Ownership" ||
      name === "Management" ||
      name === "Chain" ||
      name === "Contract"
    )
      setCategoryList({
        ...categoryList,
        [name]: value,
      });
    else
      setState({
        ...state,
        [name]: value,
      });
  };

  const handleSave = async (e) => {
    console.log("state", state, categoryList);

    e.preventDefault();
    e.stopPropagation();
    // setloading(true);
    let newState = {
      ...state,
      ...categoryList,
    };

    const result = await ValidateAddVendorForm(newState);
    if (!result?.isFormValid) {
      setErrorData(result?.error);
      // setloading(false);
      return;
    }
    setErrorData({});

    const { vendorId, venueId, ...updateBody } = state;

    let params = {
      ...updateBody,
      categoryList,
    };

    await dispatch(AdminAction.updateVendor(params, state._id));
    setEditable(false);
    // setloading(false);
    // props.history.push(ROUTES.VENUE_MANAGEMENT);
  };

  return (
    <div>
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Vendor Management / Vendor Details" />
          <div className="customerDetailsWrapper ">
            <div className="d-flex align-items-center">
              <div
                className={classes.arrow}
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackSharp /> Back
              </div>
              <div className="edit">
                <Button
                  background="secondary"
                  color="secondary"
                  name="Edit Details"
                  handleClick={() => setEditable(true)}
                />
              </div>
            </div>
            <div className="customerDetailsWrapperInner">
              {/* <div className="customerDetailsUpper"> */}

              <div className="customerDetailsLower">
                <div className="customerDetailsDataDiv">
                  <div className="customerDetailsData">
                    <p className="detail-label">Vendor Name : </p>

                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="firstName"
                          placeholder="Enter venue county"
                          value={state?.firstName}
                          handleChange={handleChange}
                        />
                        {errorData?.firstName && errorData.firstName[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.firstName && errorData.firstName[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {
                          vendorDetails?.firstName
                          // +
                          //   " " +
                          //   vendorDetails?.lastName
                        }
                      </p>
                    )}
                  </div>

                  {/* <div className="customerDetailsData">
                    <p className="detail-label">Last Name : </p>

                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="lastName"
                          placeholder="Enter venue county"
                          value={state?.lastName}
                          handleChange={handleChange}
                        />
                        {errorData?.lastName && errorData.lastName[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.lastName && errorData.lastName[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.lastName}
                      </p>
                    )}
                  </div> */}

                  <div className="customerDetailsData">
                    <p className="detail-label">Email : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="email"
                          placeholder="Enter vendor email"
                          value={state?.email}
                          handleChange={handleChange}
                        />
                        {errorData?.email && errorData.email[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.email && errorData.email[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.email}
                      </p>
                    )}
                  </div>

                  <div className="customerDetailsData">
                    <p className="detail-label">Phone : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <PhoneInput
                          className="addvenueInput"
                          country={"us"}
                          onlyCountries={["us"]}
                          value={state?.phone}
                          placeholder="Enter venue phone"
                          inputClass="phoneInput"
                          buttonClass="phoneButton"
                          dropdownClass="dropdownClass"
                          disableDropdown="true"
                          onChange={(phone) =>
                            setState({
                              ...state,
                              phone,
                            })
                          }
                        />

                        {errorData?.phone && errorData.phone[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.phone && errorData.phone[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {formatUSNumber(vendorDetails?.phone)}
                      </p>
                    )}
                  </div>

                  <div className="customerDetailsData">
                    <p className="detail-label">Street : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="street"
                          placeholder="Enter venue county"
                          value={state?.street}
                          handleChange={handleChange}
                        />
                        {errorData?.street && errorData.street[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.street && errorData.street[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.street}
                      </p>
                    )}
                  </div>

                  <div className="customerDetailsData">
                    <p className="detail-label">City : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="city"
                          placeholder="Enter venue county"
                          value={state?.city}
                          handleChange={handleChange}
                        />
                        {errorData?.city && errorData.city[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.city && errorData.city[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.city}
                      </p>
                    )}
                  </div>

                  <div className="customerDetailsData">
                    <p className="detail-label">State : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="state"
                          placeholder="Enter venue county"
                          value={state?.state}
                          handleChange={handleChange}
                        />
                        {errorData?.state && errorData.state[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.state && errorData.state[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.state}
                      </p>
                    )}
                  </div>

                  <div className="customerDetailsData">
                    <p className="detail-label">Zip : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="zip"
                          placeholder="Enter venue county"
                          value={state?.zip}
                          handleChange={handleChange}
                        />
                        {errorData?.zip && errorData.zip[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.zip && errorData.zip[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.zip}
                      </p>
                    )}
                  </div>

                  {/* <div className="customerDetailsData">
                    <p className="detail-label">Country : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="country"
                          placeholder="Enter venue county"
                          value={state?.country}
                          handleChange={handleChange}
                        />
                        {errorData?.country && errorData.country[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.country && errorData.country[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.country}
                      </p>
                    )}
                  </div> */}

                  <div className="customerDetailsData">
                    <p className="detail-label">Vendor ID : </p>

                    <p className="detail-key text-right mb-0">
                      {vendorDetails?.vendorId}
                    </p>
                  </div>

                  <Divider className="m-2" />

                  <div className="customerDetailsData">
                    <p className="detail-label">Contact Name : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="contactFirstName"
                          placeholder="Enter venue county"
                          value={state?.contactFirstName}
                          handleChange={handleChange}
                        />
                        {/* {errorData?.country && errorData.country[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.country && errorData.country[0]}
                          </p>
                        ) : null} */}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.contactFirstName}
                      </p>
                    )}
                  </div>

                  <div className="customerDetailsData">
                    <p className="detail-label">Contact Email : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="contactEmail"
                          placeholder="Enter venue county"
                          value={state?.contactEmail}
                          handleChange={handleChange}
                        />
                        {/* {errorData?.contactEmail && errorData.country[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.country && errorData.country[0]}
                          </p>
                        ) : null} */}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {vendorDetails?.contactEmail}
                      </p>
                    )}
                  </div>

                  <div className="customerDetailsData">
                    <p className="detail-label">Contact Phone : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <PhoneInput
                          className="addvenueInput"
                          country={"us"}
                          onlyCountries={["us"]}
                          value={state?.contactPhone}
                          placeholder="Enter venue phone"
                          inputClass="phoneInput"
                          buttonClass="phoneButton"
                          dropdownClass="dropdownClass"
                          disableDropdown="true"
                          onChange={(contactPhone) =>
                            setState({
                              ...state,
                              contactPhone,
                            })
                          }
                        />

                        {/* {errorData?.phone && errorData.phone[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.phone && errorData.phone[0]}
                          </p>
                        ) : null} */}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {formatUSNumber(vendorDetails?.contactPhone)}
                      </p>
                    )}
                  </div>

                  <Divider className="m-2" />

                  <div className="customerDetailsData">
                    <p className="detail-label">Brand : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Brand"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Brand"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Brand </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Brand,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Brand"]}
                      </p>
                    )}
                  </div>
                  <div className="customerDetailsData">
                    <p className="detail-label">Management : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Management"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Management"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Management </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Management,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Management"]}
                      </p>
                    )}
                  </div>
                  <div className="customerDetailsData">
                    <p className="detail-label">Contract : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Contract"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Contract"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Contract </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Contract,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Contract"]}
                      </p>
                    )}
                  </div>
                  {editable === true ? (
                    <div className="d-flex justify-content-around pb-3">
                      <Button
                        background="secondary"
                        color="secondary"
                        name="Save"
                        handleClick={handleSave}
                      />

                      <Button
                        name="Cancel"
                        handleClick={() => setEditable(false)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
