import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import Canvas from "./components/canvas";
import ImageMapConfigurations from "./ImageMap/ImageMapConfigurations";
import { CustomerAction, StaffAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const NewDesign = ({ adSpaces, setAdSpaces, mapNum, ...props }) => {
  const canvasRef = useRef();
  const [selectedItem, setSelectedItem] = useState(null);
  const [styles, setStyles] = useState({});
  const [jsonData, setJsonData] = useState();

  const [zoneName, setZoneName] = useState();
  const [zones, setZones] = useState([]);

  // const [tempMapData, setTempMapData] = useSTate({})
  const dispatch = useDispatch();

  // console.log("ljhgf", venueId, useParams());
  const [exit, setExit] = useState(true);

  const handleChange = (event, type, id, _id, zoneOrAdSpace) => {
    let { checked } = event.target;

    let mapId = props.map.mapId;

    if (type === "adSpace") {
      if (!checked) {
        setAdSpaces((prev) => ({
          ...prev,
          [mapId]: {
            ...prev[mapId],
            adSpaceData: prev[mapId].adSpaceData.filter(
              (data) => data.id !== id
            ),
          },
        }));
      } else {
        setAdSpaces((prev) => ({
          ...prev,
          [mapId]: {
            ...prev[mapId],
            adSpaceData: [...prev[mapId].adSpaceData, zoneOrAdSpace],
          },
        }));
      }
      canvasHandlers.disableOrEnableAdSpace(id, checked);
    } else if (type === "zone") {
      if (!checked) {
        setAdSpaces((prev) => ({
          ...prev,
          [mapId]: {
            ...prev[mapId],
            zones: prev[mapId].zones.filter((data) => data._id !== _id),
            adSpaceData: prev[mapId].adSpaceData.filter(
              (data) => data.zoneId !== _id
            ),
          },
        }));
      } else {
        setAdSpaces((prev) => ({
          ...prev,
          [mapId]: {
            ...prev[mapId],
            zones: [...prev[mapId].zones, zoneOrAdSpace],
            adSpaceData: [
              ...prev[mapId].adSpaceData,
              ...jsonData.adspaceList.filter((data) => data.zoneId === _id),
            ],
          },
        }));
      }
      jsonData.adspaceList.map((data) => {
        if (data.zoneId === _id)
          canvasHandlers.disableOrEnableAdSpace(data["id"], checked);
      });
    }
  };
  useEffect(() => {
    (async () => {
      let response;

      if (props.venueId)
        response = await dispatch(
          StaffAction.getMapById(props.venueId, props.map._id)
        );

      if (response && response.data) {
        response.adSpace.map(
          (ad) => (ad["mapId"] = response.mapData?.maps?.mapId)
        );

        const validAdspaceList = response.adSpace.map((ad) => ad["id"]);
        console.log("valid adpsace listnnnn", response);

        // setZoneName(response?.mapData?.maps?.zones);

        let filteredList = response.data.objects.filter((obj) => {
          if (validAdspaceList.findIndex((id) => id === obj.id) !== -1)
            return obj;
        });

        let zone = response?.mapData?.maps?.zone;
        let adspaceList = response?.mapData?.adSpace.map((data) => {
          return {
            id: data.id,
            _id: data._id,
            title: data?.title,
            zoneName: data?.zone,
            zoneId: data?.zoneId,
          };
        });
        console.log(
          "🚀 ~ file: index.js ~ line 105 ~ adspaceList",
          adspaceList
        );
        let mapId = props.map.mapId;

        setAdSpaces((prev) => ({
          ...prev,
          [mapId]: {
            zones:
              response.mapData.maps?.zones?.map((zone) => {
                return { ...zone, isChecked: true };
              }) || [],
            // zoneName: zone || "nozone",
            // isZoneChecked: true,
            adSpaceData: adspaceList,
            venueId: props.venueId,
          },
        }));
        response.data["objects"] = filteredList;

        response?.data?.objects?.map((element) => {
          element.lockMovementY = true;
          element.lockMovementX = true;
          element.hasControls = false;
          element.hasBorders = false;
          element.isSelected = true;
          // element.selectable = true;
        });
        setJsonData({
          adSpaces: response?.adSpace,
          adspaceList,
          zones:
            response.mapData.maps?.zones?.map((zone) => {
              return { ...zone, isChecked: true };
            }) || [],
          data: response?.data,
        });
      }
    })();
  }, []);

  const canvasHandlers = {
    onAdd: (target) => {
      canvasRef.current.handler.select(target);
    },
    onImage: (url) => {
      canvasRef.current.handler.onImage(url);
    },
    onBgImage: (url) => {
      canvasRef.current.handler.onBgImage(url);
    },
    disableOrEnableAdSpace: (targetId, enable) => {
      canvasRef.current.handler.disableOrEnableAdSpace(targetId, enable);
    },

    onSelect: async (target) => {
      // const { selectedItem } = this.state;

      // canvasRef.current.setActiveObject(target)

      if (target && target.id) {
        setSelectedItem(target);

        return;
      }
      setSelectedItem(null);
      return;
    },
    onModified: (key, value, target) => {},
    onSave: () => {},
  };

  const handler = {
    onChangeStyles: (key, value) => {
      setStyles({ ...styles, [key]: value });
    },
  };
  return (
    <>
      <h3 style={{ color: "white" }}>Map - {mapNum}</h3>
      <div className="d-flex mainCanvasFlex">
        <div className="d-flex justify-content-center ml-1 mr-1 canvasConfigFlex">
          {jsonData?.data && (
            <Canvas
              ref={canvasRef}
              jsonData={jsonData}
              minZoom={30}
              maxZoom={500}
              designNo={props?.match?.params?.id}
              state={props?.location?.state}
              onSelect={canvasHandlers.onSelect}
              onModified={canvasHandlers.onModified}
              disableOrEnableAdSpace={canvasHandlers.disableOrEnableAdSpace}
              onSave={canvasHandlers.onSave}
              handleExit={() => setExit(false)}
            />
          )}

          <div className="canvas-properties">
            <ImageMapConfigurations
              jsonData={jsonData}
              canvasRef={canvasRef}
              setSelectedItem={setSelectedItem}
              onChange={canvasHandlers.onChange}
              selectedItem={selectedItem}
              designNo={props?.match?.params?.id}
              onChangeStyles={handler.onChangeStyles}
              styles={styles}
            />
          </div>
        </div>
        <div className="stickyContainer">
          <div className="stickyContainerChild">
            {jsonData && jsonData.zones && jsonData.zones.length > 0
              ? jsonData.zones.map((zone) => {
                  if (
                    jsonData &&
                    jsonData.adspaceList &&
                    jsonData.adspaceList.filter(
                      (adSpace) => adSpace.zoneId === zone._id
                    ).length > 0
                  )
                    return (
                      <div className="stickyContainerHead">
                        <div className="stickyContainerHeadBar">
                          <input
                            type="checkbox"
                            checked={
                              adSpaces &&
                              adSpaces[props.map.mapId] &&
                              adSpaces[props.map.mapId].zones
                                .map((data) => data["_id"])
                                .includes(zone._id)
                            }
                            name=""
                            id=""
                            onChange={(e) =>
                              handleChange(e, "zone", null, zone._id, zone)
                            }
                          />
                          {zone?.name}
                        </div>
                        <ul className="stickyContainerBody">
                          {jsonData &&
                            jsonData.adspaceList &&
                            jsonData.adspaceList.map(
                              (adSpace) =>
                                adSpace.zoneId === zone._id && (
                                  <li>
                                    <input
                                      type="checkbox"
                                      checked={
                                        adSpaces &&
                                        adSpaces[props.map.mapId] &&
                                        adSpaces[props.map.mapId].adSpaceData
                                          .map((data) => data["id"])
                                          .includes(adSpace.id)
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "adSpace",
                                          adSpace.id,
                                          adSpace._id,
                                          adSpace
                                        );
                                      }}
                                      name=""
                                      id=""
                                    />{" "}
                                    <span>{adSpace["title"]}</span>
                                  </li>
                                )
                            )}
                        </ul>
                      </div>
                    );
                })
              : null}

            {
              <div className="stickyContainerHead">
                <div
                  className="stickyContainerHeadBar"
                  style={{ paddingLeft: "20px" }}
                >
                  Other AdSpaces
                </div>
                <ul>
                  {jsonData &&
                    jsonData.adspaceList &&
                    jsonData.adspaceList.map(
                      (adSpace) =>
                        !adSpace.zoneId && (
                          <li className="otherAdspace">
                            <input
                              type="checkbox"
                              checked={
                                adSpaces &&
                                adSpaces[props.map.mapId] &&
                                adSpaces[props.map.mapId].adSpaceData
                                  .map((data) => data["id"])
                                  .includes(adSpace.id)
                              }
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  "adSpace",
                                  adSpace.id,
                                  adSpace._id,
                                  adSpace
                                );
                              }}
                              name=""
                              id=""
                            />
                            <span> {adSpace["title"]}</span>
                          </li>
                        )
                    )}
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDesign;
