import { ACTION_TYPE } from "../../services/constants/Index";

export const initialState = {
  loadingState: false,
  status: null,
  errorMessage: "",
};
export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.API_CALL_STARTED:
      return {
        ...state,
        loadingState: true,
      };
    case ACTION_TYPE.API_CALL_FAILURE:
      return {
        ...state,
        loadingState: false,
      };
    case ACTION_TYPE.API_CALL_COMPLETED:
      return {
        ...state,
        loadingState: false,
      };

    case "ERROR":
      return {
        ...state,
        status: action.payload?.status,
        errorMessage: action.payload?.error,
      };
    case "CLEAN_ERROR":
      return {
        ...state,
        status: null,
        errorMessage: "",
      };

    default:
      return state;
  }
};
