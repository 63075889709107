import React, { useState, useEffect } from "react";
import "./Account.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { useDispatch, useSelector } from "react-redux";
// import { ROUTES } from "../../Services/Constants";
import { useHistory } from "react-router-dom";
// import { AuthActions, OrgActions } from "../../ReduxStore/Actions";
// import { ACTION_TYPE } from "../../Services/Constants";
// import { checkRole } from "../../Services/Utils/utils";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { AuthAction } from "../../redux/actions/Index";
import { ROUTES } from "../../services/constants/Index";

const Account = (props) => {
  // console.log("props", props);
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState(true);
  const [role, setRole] = useState(null);
  const { userData } = useSelector((state) => state.AuthReducer);
  const [dropDown, setDropDown] = useState(false);
  const handleClick = () => {
    setDropDown(!dropDown);
  };

  const logout = () => {
    console.log("user", userData);
    if (userData?.role === "venue" || userData?.role === "vendor") {
      dispatch(AuthAction.logOut());
      history.push(ROUTES.VENUE_VENDOR_LOGIN);
    } else {
      dispatch(AuthAction.logOut());
      history.push(ROUTES.LOGIN);
    }
  };

  return (
    <div className="dropdownMain">
      <ArrowDropDownIcon
        className="  headerBarIcon"
        onClick={() => {
          handleClick();
        }}
      />

      {dropDown ? (
        <div className="dropdown accountIcon text-center ">
          <ul>
            <li
              // onClick={() => {
              // 	dispatch(AuthAction.logOut());
              // 	history.push(ROUTES.LOGIN);
              // }}
              onClick={logout}
            >
              Sign Out
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};
export default Account;
