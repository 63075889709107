import React from "react";
import { formatUSNumber } from "../../../services/utills/helper";

const index = ({ orderDetails }) => {
  return (
    <div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>Name </p>
          <p>
            {orderDetails?.customerInfo?.firstName}{" "}
            {orderDetails?.customerInfo?.lastName}
          </p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Email</p>
          <p>{orderDetails?.customerInfo?.email} </p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>Phone</p>
          <p> {formatUSNumber(orderDetails?.customerInfo?.phone)} </p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Street</p>
          <p>{orderDetails?.customerInfo?.street} </p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>City</p>
          <p>{orderDetails?.customerInfo?.city} </p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>State</p>
          <p>{orderDetails?.customerInfo?.state} </p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>ZIP</p>
          <p>{orderDetails?.customerInfo?.zipCode} </p>
        </div>
        {/* <div className="venueDetailsAccordianRightNew">
          <p>Country</p>
          <p>{orderDetails?.customerInfo?.country} </p>
        </div> */}
      </div>
    </div>
  );
};

export default index;
