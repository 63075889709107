import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import Canvas from "./components/canvas";
import ImageMapConfigurations from "./ImageMap/ImageMapConfigurations";
import { CustomerAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const NewDesign = (props) => {
  const canvasRef = useRef();
  const [selectedItem, setSelectedItem] = useState(null);
  const [styles, setStyles] = useState({});
  const [jsonData, setJsonData] = useState();
  // const [tempMapData, setTempMapData] = useSTate({})
  const dispatch = useDispatch();
  const { venueId } = useParams();

  const { mapData } = useSelector((state) => state.CustomerReducer);

  // console.log("ljhgf", venueId, useParams());
  const [exit, setExit] = useState(true);
  useEffect(() => {
    (async () => {
      let response;
      console.log("story props", props);
      // if (props.venueId) {
      //   response = await dispatch(CustomerAction.getVenueById(props.venueId));

      // } else {
      //   response = await dispatch(CustomerAction.getVenueById(venueId));
      // }
      if (props.venueId)
        response = await dispatch(
          CustomerAction.getMapById(props.venueId, props.map._id, props.orderId)
        );
      else
        response = await dispatch(
          CustomerAction.getMapById(venueId, props.map._id, props.orderId)
        );

      console.log(
        "story canvas mapData",
        response,
        // response.data,
        // response?.adSpace,
        response?.mapData
      );

      if (response && response.data) {
        response.adSpace.map(
          (ad) => (ad["mapId"] = response.mapData?.maps?.mapId)
        );

        const validAdspaceList = response.adSpace.map((ad) => ad["id"]);
        console.log("valid adpsace list", validAdspaceList);

        let filteredList = response.data.objects.filter((obj) => {
          if (validAdspaceList.findIndex((id) => id === obj.id) !== -1)
            return obj;
        });

        // console.log("story filteredList", filteredList);

        response.data["objects"] = filteredList;

        response?.data?.objects?.map((element) => {
          element.lockMovementY = true;
          element.lockMovementX = true;
          element.hasControls = false;
          element.hasBorders = false;
        });
        setJsonData({
          adSpaces: response?.adSpace,
          data: response?.data,
        });
      }
    })();
  }, []);

  const canvasHandlers = {
    onAdd: (target) => {
      canvasRef.current.handler.select(target);
    },
    onImage: (url) => {
      canvasRef.current.handler.onImage(url);
    },
    onBgImage: (url) => {
      canvasRef.current.handler.onBgImage(url);
    },

    onSelect: async (target) => {
      // const { selectedItem } = this.state;

      // canvasRef.current.setActiveObject(target)

      if (target && target.id) {
        setSelectedItem(target);

        return;
      }
      setSelectedItem(null);
      return;
    },
    onModified: (key, value, target) => {},
    onSave: () => {},
  };

  const handler = {
    onChangeStyles: (key, value) => {
      setStyles({ ...styles, [key]: value });
    },
  };
  return (
    <div className="d-flex justify-content-center ml-1 mr-1  ">
      {jsonData?.data && (
        <Canvas
          ref={canvasRef}
          jsonData={jsonData}
          minZoom={30}
          maxZoom={500}
          designNo={props?.match?.params?.id}
          state={props?.location?.state}
          onSelect={canvasHandlers.onSelect}
          onModified={canvasHandlers.onModified}
          onSave={canvasHandlers.onSave}
          handleExit={() => setExit(false)}
        />
      )}

      <div className="canvas-properties">
        <ImageMapConfigurations
          jsonData={jsonData}
          canvasRef={canvasRef}
          setSelectedItem={setSelectedItem}
          onChange={canvasHandlers.onChange}
          selectedItem={selectedItem}
          designNo={props?.match?.params?.id}
          onChangeStyles={handler.onChangeStyles}
          styles={styles}
        />
      </div>
    </div>
  );
};

export default NewDesign;
