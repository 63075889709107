import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import { ROUTES } from "../../services/constants/Index";

function RouteRBAC({ userRole, children, renderNoAccess, location, ...props }) {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user?.role) {
    userRole = [user.role];
  }

  console.log("userRole , user:>> ", userRole, user);

  //Role : Admin, customer,vendor, venue
  //method to get admin routes
  const adminRoute = () => {
    console.log("children", children);

    const adminRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("admin")) {
        console.log("child", child);

        return child;
      }
    });

    console.log("adminRoutes", adminRoutes);

    return adminRoutes;
  };

  //method to get vendor routes
  const vendorRoute = () => {
    const vendorRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("vendor")) {
        return child;
      }
    });
    return vendorRoutes;
  };

  //method to get venue routes
  const venueRoute = () => {
    const venueRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("venue")) {
        return child;
      }
    });

    return venueRoutes;
  };

  //method to get staff routes
  const staffRoute = () => {
    const staffRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("staff")) {
        return child;
      }
    });

    return staffRoutes;
  };

  //method to get customer routes
  const customerRoute = () => {
    const customerRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("customer")) {
        console.log("child", child);

        return child;
      }
    });

    return customerRoutes;
  };

  //method to get unauthorized routes
  const UnAuthorizedRoutes = () => {
    const unauthorizedRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("guest")) {
        return child;
      }
    });

    return unauthorizedRoutes;
  };

  // console.log("userrole", !userRole);

  if (!userRole) {
    const unAuthorizedRoutes = UnAuthorizedRoutes();
    // console.log(
    //   "userrole=unAuthorized",
    //   unAuthorizedRoutes[2].props.path,
    //   location.pathname
    // );

    if (
      !unAuthorizedRoutes.some(
        (route) =>
          route.props.path.split("/")[1] === location.pathname.split("/")[1]
      )
    ) {
      // return renderNoAccess();
      return (
        <Redirect
          to={{
            pathname: ROUTES.LOGIN,
          }}
        />
      );
    }
    return unAuthorizedRoutes;
  } else {
    // console.log("user.role elsee", user);

    if (userRole && userRole.includes("admin")) {
      console.log("user.role ===  admin", user);

      const adminRoutes = adminRoute();

      if (location.pathname === "/") {
        return (
          <Redirect
            to={{
              pathname: ROUTES.VENUE_MANAGEMENT,
            }}
          />
        );
      }

      if (
        !adminRoutes.some(
          (route) =>
            route.props.path.split("/")[1] === location.pathname.split("/")[1]
        )
      ) {
        return renderNoAccess();
      }
      return adminRoutes;
    } else if (userRole && userRole.includes("staff")) {
      console.log("user.role ===  staff", user);

      const staffRoutes = staffRoute();

      if (
        !staffRoutes.some(
          (route) =>
            route.props.path.split("/")[1] === location.pathname.split("/")[1]
        )
      ) {
        return (
          <Redirect
            to={{
              pathname: ROUTES.STAFF_ORDER_MANAGEMENT,
              state: { from: location.pathname },
            }}
          />
        );
      }

      return staffRoutes;
    } else if (userRole && userRole.includes("venue")) {
      console.log("user.role ===  venue", user);

      const venueRoutes = venueRoute();

      if (
        !venueRoutes.some(
          (route) =>
            route.props.path.split("/")[1] === location.pathname.split("/")[1]
        )
      ) {
        return (
          <Redirect
            to={{
              pathname: ROUTES.VENUE_ORDER_MANAGEMENT,
              state: { from: location.pathname },
            }}
          />
        );
      }

      return venueRoutes;
    } else if (userRole && userRole.includes("vendor")) {
      console.log("user.role ===  vendor", user);

      const vendorRoutes = vendorRoute();

      if (
        !vendorRoutes.some(
          (route) =>
            route.props.path.split("/")[1] === location.pathname.split("/")[1]
        )
      ) {
        return (
          <Redirect
            to={{
              pathname: ROUTES.VENDOR_ORDER_MANAGEMENT,
              state: { from: location.pathname },
            }}
          />
        );
      }

      return vendorRoutes;
    } else if (userRole && userRole.includes("customer")) {
      // console.log("user.role ===  customer", user);

      const customerRoutes = customerRoute();

      if (location.pathname === "/") {
        return (
          <Redirect
            to={{
              pathname: ROUTES.MYPROFILE,
            }}
          />
        );
      }

      if (
        !customerRoutes.some(
          (route) =>
            route.props.path.split("/")[1] === location.pathname.split("/")[1]
        )
      ) {
        return (
          <Redirect
            to={{
              pathname: ROUTES.MYPROFILE,
            }}
          />
        );
      }

      return customerRoutes;
    }
  }
}

export default withRouter(RouteRBAC);
