import { Icon } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import React from "react";
const NavButton = ({ type, text, step, handleStep, handleNext, reset }) => {
  return (
    <div>
      <button
        onClick={() =>
          type === "back" ? handleStep(step - 1, reset) : handleNext(step + 1)
        }
        class="nav-button"
      >
        {type === "back" ? <ArrowBack /> : null}
        {text} {type !== "back" ? <ArrowForward /> : null}
      </button>
    </div>
  );
};
export default NavButton;
