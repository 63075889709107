import React, { useRef, useState } from "react";
import { v4 } from "uuid";
import CreateZone from "../../../../components/Modals/CreateZone/createZone";
const ImageMapItems = ({ canvasRef, setBackgroundImg, zone, setZone }) => {
  const bgRef = useRef();
  const imgRef = useRef();
  const [createZoneModal, setCreateZoneModal] = useState(false);
  const handlers = {
    onAddItem: (item, centered) => {
      console.log("item :>> ", item);
      let opt = {
        option: { type: item },
      };
      const id = v4();
      const option = Object.assign({}, opt.option, { id });
      console.log("option :>> ", option);
      canvasRef.current.handler.add(option, centered);
    },
    onAddImage: (url, imgType, centered) => {
      let opt = {
        option: { type: "image", imgType, url },
      };
      const id = v4();
      const option = Object.assign({}, opt.option, { id });
      console.log("b4 add");
      canvasRef.current.handler.add(option, centered);
    },
  };

  const handleInputChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log("e.target :>> ", e.target.id);
    let id = e.target.id;
    let file = e.target.files[0];
    setBackgroundImg(e.target.files[0]);
    console.log("file :>> ", file);
    let url = URL.createObjectURL(file);
    e.target.value = null;

    handlers.onAddImage(url, id);
  };

  return (
    <ul className="properties__nav d-flex flex-column sideBar">
      <div className="sideMap" onClick={() => bgRef.current.click()}>
        Change Map
        <input
          type="file"
          id="bg"
          ref={bgRef}
          style={{ display: "none" }}
          onChange={handleInputChange}
        />
      </div>
      <div className="sideMap" onClick={() => setCreateZoneModal(true)}>
        Add Zone
      </div>
      <div className="sideMap" onClick={() => handlers.onAddItem("path")}>
        Add Adspace
      </div>
      {createZoneModal && (
        <CreateZone
          open={createZoneModal}
          zone={zone}
          handleClose={() => setCreateZoneModal(false)}
          handleAddZone={async (zone) => {
            setZone(zone);

            setCreateZoneModal(false);
          }}
        />
      )}
    </ul>
  );
};

export default ImageMapItems;
