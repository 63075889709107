import React, { useEffect, useState } from "react";
import "../styles.css";

import HeaderBar from "../../../../components/headerBar/HeaderBar";
import SideNav from "../../../../components/sideNav/SideNav";

import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/button/Button";

import RBAC from "../../../../components/RBAC/RBAC";
import { initList } from "../../../../components/sideNav/InitList";
import CustomerInfo from "./customerInformation";
import ChooseZone from "./chooseZone";
import { ArrowBack } from "@material-ui/icons";
import NavButton from "./navButton";
import { ValidateCustomerForm } from "./validation";

import ConformationEmail from "./ConformationEmail";
import Mailsent from "./Mailsent";
import {
  constSaveCustomerLink,
  customerInfoSave,
} from "../../../../redux/actions/StaffAction";
import ConfirmInfo from "./confirmInfo";
import { getCurrentStepTitle } from "./util";

import AddDiscount from "../../../../components/Modals/AddDiscount";

const StepComponents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);
  const { customerInfo } = useSelector((state) => state.StaffReducer);

  const [step, setStep] = useState(1);

  const [errorData, setError] = React.useState();
  const [state, setState] = useState();
  const [adSpaces, setAdSpaces] = useState({});
  const [open, setOpen] = useState({
    state: false,
    id: null,
  });
  const [openDiscountModal, setOpenDiscountModal] = useState(false);

  const [discountForm, setDiscountForm] = useState({
    discountType: "",
    discount: "",
  });
  const [finalDiscount, setFinalDiscount] = useState({
    discountType: "",
    discount: "",
  });

  const [discountModalErrorData, setDiscountModaErrorData] = useState({
    discountType: "",
    discount: "",
  });

  const handleDiscountModalChange = (e) => {
    setDiscountForm({
      ...discountForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleDiscountModalSave = () => {
    console.log("discountForm", discountForm);

    if (discountForm.discount == "" || discountForm.discountType == "") {
      setDiscountModaErrorData({
        discountType:
          discountForm.discountType == "" ? "Discount Type is required" : "",
        discount: discountForm.discount == "" ? "Discount is required" : "",
      });

      return;
    }

    if (isNaN(discountForm.discount)) {
      setDiscountModaErrorData({
        discountType: "",
        discount: "Discount should be a number",
      });
      return;
    }

    setFinalDiscount({
      ...discountForm,
    });
    setOpenDiscountModal(false);

    console.log(errorData);
  };

  //   const { orders } = useSelector((state) => state.StaffReducer);
  // const { order } = useSelector((state) => state.CustomerReducer);

  const [finishStatus, setfinishStatus] = useState(false);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  useEffect(() => {
    if (customerInfo) {
      console.log("yyyyyyyyy", customerInfo);
      setState(customerInfo);
    }
  }, [customerInfo]);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm("All of your data will be lost.Do you want to go back?")
      ) {
        setfinishStatus(true);
        // your logic
        history.push(ROUTES.CUSTOMER_LINK_MANAGEMENT);
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };
  const handleStep = (value, reset = "") => {
    console.log("handle step VALUEEE", value);
    if (value < 1 || reset === "dashboard") {
      history.push(ROUTES.CUSTOMER_LINK_MANAGEMENT);
      return;
    }
    if (reset === "customerInfo") {
      dispatch(customerInfoSave({}));
    }
    setStep(value);
  };
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    console.log("State State State", state);
  };
  const handleSave = async () => {
    let eventData = {
      eventSize: state.eventSize,
      eventName: state.eventName,
      groupName: state.groupName,
      eventStartDate: state.eventStartDate,
    };
    let customerInfo = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phone: state.phone,
      Street: state.Street,
      state: state.state,
      city: state.city,
      zipCode: state.zipCode,
    };
    console.log(
      "🚀 ~ file: index.js ~ line 107 ~ handleSave ~ adSpaces",
      adSpaces
    );

    // get all id from adSpaceData array from adSpaces

    let adSpaceIds = [];
    for (let key in adSpaces) {
      console.log("🚀 ~ file: index.js ~ line 115 ~ handleSave ~ key", key);
      adSpaces[key]["adSpaceData"].map((item) => {
        adSpaceIds.push({ adspace: item._id });
      });
    }
    console.log(
      "🚀 ~ file: index.js ~ line 114 ~ handleSave ~ adSpaceIds",
      adSpaceIds
    );

    // let adSpaceIds = Object.keys(adSpaces).map((key) =>
    //   adSpaces[key].adSpaceData.reduce((total, curr) => {

    //   }, {})
    // );

    // adSpaceIds = [].concat.apply([], adSpaceIds);
    await dispatch(
      constSaveCustomerLink({
        eventInfo: eventData,
        customerInfo,
        adSpaceIds,
        staffId: user._id,
        venue_id: user.venueId,
        venueId: user.venueInfo.venueId,
        vendorId: user.venueInfo.vendorId,
        discount: state.discount,
        discountType: state.discountType,
      })
    );

    setState();
    handleStep(3);
  };
  const handleNext = async (type) => {
    console.log("adspaceee", adSpaces, type);
    if (type === 2) {
      let validation = ValidateCustomerForm(state);
      if (!validation.isFormValid) {
        setError(validation.error);
        return;
      }
      handleStep(2);
      dispatch(customerInfoSave(state));
    } else if (type === 3) {
      await handleSave();
      // dispatch(customerInfoSave({}));
    } else {
      handleStep(type);
    }
  };

  const renderComponent = () => {
    switch (step) {
      case 1:
        return (
          <CustomerInfo
            state={state}
            handleChange={handleChange}
            setState={setState}
            handleNext={handleNext}
            errorData={errorData}
          />
        );

      case 2:
        return <ChooseZone adSpaces={adSpaces} setAdSpaces={setAdSpaces} />;
      case 3:
        return <ConfirmInfo handleStep={handleStep}></ConfirmInfo>;
      case 4:
        return (
          <ConformationEmail
            handleStep={handleStep}
            discountData={finalDiscount}
          />
        );
      case 5:
        return <Mailsent />;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="start venueM">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title={`Step ${step}: ${getCurrentStepTitle(step)}`} />

          <div className="nav-buttons-div">
            <NavButton
              type={"back"}
              step={step}
              text={"Go Back"}
              handleStep={handleStep}
              reset={step === 3 || step === 4 || step === 5 ? "dashboard" : ""}
            ></NavButton>

            {step >= 3 ? null : (
              <NavButton
                type={"forward"}
                step={step}
                text={"Next Step"}
                handleNext={handleNext}
                handleStep={handleStep}
              ></NavButton>
            )}

            {/* {step === 4 ? (
              <div className="addNewVendorVenMgmt">
                <Button
                  // className="col-lg-3"
                  background="primar"
                  color="primary"
                  name="Add Discount"
                  handleClick={() => setOpenDiscountModal(true)}
                ></Button>
              </div>
            ) : null} */}
          </div>
          <div className="addNewVendorVenMgmt"></div>
          <div className="tabelOrder">{renderComponent()}</div>
        </div>
      </div>
      <AddDiscount
        open={openDiscountModal}
        handleClose={() => {
          setOpenDiscountModal(false);
          setDiscountForm({
            ...finalDiscount,
          });
          setDiscountModaErrorData({
            discount: "",
            discountType: "",
          });
        }}
        handleChange={handleDiscountModalChange}
        handleSave={handleDiscountModalSave}
        state={discountForm}
        errorData={discountModalErrorData}
      />
    </div>
  );
};

export default StepComponents;
