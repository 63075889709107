import React, { useEffect, useState } from "react";
import "./Cart.css";
import Header from "../../Authentication/Header";
import PriceCard from "./component/PriceCard";
import ItemCard from "./component/ItemCard";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_TYPE, ROUTES } from "../../../services/constants/Index";
import { useHistory } from "react-router-dom";
import { APPLICATION_CONSTANTS } from "../../../services/constants/Index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CustomerAction } from "../../../redux/actions/Index";
import Footer from "../../../components/Footer/Footer";
import emptyCart from "../../../images/commonImage/emptyCart.png";
import {
  addChargeTax,
  calculateCartItems,
} from "../../../services/utills/helper";
import InputField from "../../../components/input/Input";
import { ValidateEventForm } from "./Validation";
import { useQuery } from "../../../hooks/useQueryHook";

function Cart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [cartData, setCartData] = useState();
  const [errorData, setError] = React.useState();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { cart, venueId, venueData, orderDetailsById } = useSelector(
    (state) => state.CustomerReducer
  );
  const getQuery = useQuery();
  const orderId = getQuery.get("orderId");
  const venue = useSelector((state) => state.CustomerReducer?.venue);

  useEffect(() => {
    (async () => {
      if (cart) {
        const venueCartItems = await calculateCartItems(cart, venueId, orderId);
        setCartData(venueCartItems);
      }
    })();
  }, [cart]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let cartLength;
  let subTotal;
  let serviceChargeTotal = 0;
  let salesTaxTotal = 0;
  let totalAmount;
  let discount = 0;
  if (cartData) {
    cartLength = cartData.reduce((prev, current) => {
      console.log("prev, current", prev, current);
      return prev + Number(current.quantity);
    }, 0);

    // console.log("carttt", carttt);
    // cartLength = cartData?.length;
    subTotal = cartData?.reduce((a, b) => a + b["price"] * b["quantity"], 0);
    console.log("🚀 ~ file: Cart.js ~ line 64 ~ Cart ~ subTotal", subTotal);

    if (orderDetailsById?.discount) {
      let discountType = orderDetailsById?.discountType;

      if (discountType === "percentage") {
        discount = subTotal * (orderDetailsById?.discount / 100);
        console.log("🚀 ~ file: Cart.js ~ line 72 ~ Cart ~ discount", discount);
        // totalAmount = subTotal - discount;
      } else if (discountType === "fixed") {
        discount = orderDetailsById?.discount;
        // totalAmount = subTotal - orderDetailsById?.discount;
      }
    }

    subTotal = subTotal;

    serviceChargeTotal = addChargeTax(
      subTotal - discount,
      venueData?.serviceCharge
    );
    salesTaxTotal = addChargeTax(subTotal - discount, venueData?.salesTax);

    // let totalWithoutDiscount = subTotal + serviceChargeTotal + salesTaxTotal;

    totalAmount = subTotal - discount + serviceChargeTotal + salesTaxTotal;
  }

  const handleRemoveFromCart = async (item) => {
    const newCart = cartData.filter((data) => data.id !== item.id);
    dispatch(CustomerAction.addToCart(newCart));
    // APPLICATION_CONSTANTS.SECURE_WEB_STORAGE.setItem(
    //   APPLICATION_CONSTANTS.BOOKING_DETAILS,
    //   newCart
    // );
  };
  const decreaseQuantity = async (item) => {
    if (item.quantity > 1) {
      const newCart = cartData.map((data) => {
        if (data.id === item.id) {
          return { ...data, quantity: data.quantity - 1 };
        } else {
          return data;
        }
      });

      dispatch(CustomerAction.addToCart(newCart));
      // APPLICATION_CONSTANTS.SECURE_WEB_STORAGE.setItem(
      //   APPLICATION_CONSTANTS.BOOKING_DETAILS,
      //   newCart
      // );
    }
  };
  const increaseQuantity = async (item) => {
    if (item.quantity < 10) {
      const newCart = cartData.map((data) => {
        if (data.id === item.id) {
          return { ...data, quantity: data.quantity + 1 };
        } else {
          return data;
        }
      });
      console.log("newCart :>> ", newCart);
      dispatch(CustomerAction.addToCart(newCart));
      // APPLICATION_CONSTANTS.SECURE_WEB_STORAGE.setItem(
      //   APPLICATION_CONSTANTS.BOOKING_DETAILS,
      //   newCart
      // );
      // setLoading(true);
      // await dispatch(
      //   CustomerAction.changeQuantity(
      //     item.customerId,
      //     item._id,
      //     item.quantity + 1
      //   )
      // );

      setLoading(false);
    }
  };
  const handleCheckout = async (state) => {
    if (getQuery.get("orderId")) {
      console.log("HAND:E CHAECKOUT", ROUTES.CHECKOUT + `?orderId=${orderId}`);
      if (user._id) {
        history.push(`${ROUTES.CUSTOMER_INFO}?orderId=${orderId}`);
      } else {
        history.push(ROUTES.CHECKOUT + `?orderId=${orderId}`);
      }
      return;
    } else {
      const result = await ValidateEventForm(state);
      if (result?.isFormValid === false) {
        setError(result?.error);
        return;
      }
      if (user._id) {
        history.push({
          pathname: ROUTES.CUSTOMER_INFO,
        });
      } else {
        history.push({
          pathname: ROUTES.CHECKOUT,
        });
      }
    }
  };
  return (
    <div className="cart-height">
      <Header type="type2" />

      <div className={cart === 1 ? "cartTwo" : "cart"}>
        <div className="backarrow d-flex">
          <div
            className="backIcon mr-2"
            onClick={() => {
              getQuery.get("orderId")
                ? history.push(
                    `/venue/${venueId}?orderId=${getQuery.get("orderId")}`
                  )
                : history.push(`/venue/${venueId}`);
            }}
          >
            <ArrowBackIcon className="icon" />
          </div>
          Venue
        </div>
        {cartData?.length > 0 && (
          <div className="headCart  d-flex text-center ">
            <span className="hrline">
              <hr />
            </span>
            <span style={{ fontFamily: "Whitney Semibold" }}>Cart</span>
            <span className="hrline">
              <hr />
            </span>
          </div>
        )}
        {cartData?.length === 0 && (
          <div className="imgOfCart">
            <img src={emptyCart} />
          </div>
        )}

        <div className="d-flex row cartResponsive ">
          <div className="col-lg-7 col-md-12 itemCardDiv">
            {cartData &&
              cartData?.length > 0 &&
              cartData?.map((cartDetails) => {
                return (
                  <ItemCard
                    data={cartDetails}
                    handleRemoveFromCart={handleRemoveFromCart}
                    decreaseQuantity={decreaseQuantity}
                    increaseQuantity={increaseQuantity}
                    loading={loading}
                  />
                );
              })}
          </div>
          {cartData?.length > 0 && (
            <PriceCard
              cartLength={cartLength}
              subTotal={subTotal}
              serviceChargeTotal={serviceChargeTotal}
              salesTaxTotal={salesTaxTotal}
              discount={orderDetailsById?.discount}
              discountType={orderDetailsById?.discountType}
              reducedAmount={discount}
              totalAmount={totalAmount}
              serviceCharge={venueData?.serviceCharge}
              salesTax={venueData?.salesTax}
              handleCheckout={handleCheckout}
              errorData={errorData}
            />
          )}
        </div>
      </div>
      {cartData?.length > 0 ? (
        <div className="fixFooterForNow">
          <div
            style={{
              height: "60px",
            }}
          />
          <Footer></Footer>
        </div>
      ) : (
        <Footer></Footer>
      )}
    </div>
  );
}

export default Cart;
