function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

function isNull(str) {
  return str === "null";
}

export const ValidateAddVenueForm = (formData) => {
  const {
    firstName,
    lastName,
    // contactfirstName,
    // contactlastName,
    location,
    email,
    phone,
    contactName,
    contactEmail,
    contactPhone,
    country,
    state,
    city,
    street,
    zip,
    logo,
    serviceCharge,
    salesTax,
    venueImage,
    vendorId,
    Brand,
    Ownership,
    Management,
    Chain,
    Contract,
    realEstateSale,
  } = formData || {};
  let error = {
    firstName: [],
    // lastName: [],
    // contactfirstName: [],
    // contactlastName: [],
    location: [],
    email: [],
    contactName: [],
    contactEmail: [],
    contactPhone: [],
    country: [],
    state: [],
    city: [],
    street: [],
    zip: [],
    logo: [],
    serviceCharge: [],
    salesTax: [],
    venueImage: [],
    vendorId: [],
    Brand: [],
    Ownership: [],
    Management: [],
    Chain: [],
    Contract: [],
  };
  let isFormValid = true;
  if (isBlank(firstName) === true) {
    isFormValid = false;
    error.firstName = ["This field is required."];
  } else if (firstName?.trim().length > 0 && firstName?.trim().length > 50) {
    isFormValid = false;
    error.firstName = ["Name cannot be greater than 50."];
  }

  // if (isBlank(lastName) === true) {
  // 	isFormValid = false;
  // 	error.lastName = ["This field is required."];
  // } else if (lastName?.trim().length > 0 && lastName?.trim().length > 50) {
  // 	isFormValid = false;
  // 	error.lastName = ["Name cannot be greater than 50."];
  // }

  // if (isBlank(contactfirstName) === true) {
  // 	isFormValid = false;
  // 	error.contactfirstName = ["This field is required."];
  // } else if (
  // 	contactfirstName?.trim().length > 0 &&
  // 	contactfirstName?.trim().length > 50
  // ) {
  // 	isFormValid = false;
  // 	error.contactfirstName = ["Name cannot be greater than 50."];
  // }

  // if (isBlank(contactlastName) === true) {
  // 	isFormValid = false;
  // 	error.contactlastName = ["This field is required."];
  // } else if (
  // 	contactlastName?.trim().length > 0 &&
  // 	contactlastName?.trim().length > 50
  // ) {
  // 	isFormValid = false;
  // 	error.contactlastName = ["Name cannot be greater than 50."];
  // }

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  }

  //   if (isBlank(contactName) === true) {
  //     isFormValid = false;
  //     error.contactName = ["This field is required."];
  //   }

  if (isBlank(contactEmail) === true) {
    isFormValid = false;
    error.contactEmail = ["This field is required."];
  }
  // if (isBlank(country) === true) {
  //   isFormValid = false;
  //   error.country = ["This field is required."];
  // }
  if (isBlank(city) === true) {
    isFormValid = false;
    error.city = ["This field is required."];
  }
  if (isBlank(state) === true) {
    isFormValid = false;
    error.state = ["This field is required."];
  }
  if (isBlank(street) === true) {
    isFormValid = false;
    error.street = ["This field is required."];
  }

  if (isBlank(zip) === true) {
    isFormValid = false;
    error.zip = ["This field is required."];
  }

  if (!realEstateSale || realEstateSale == "false")
    if (isBlank(vendorId) === true || isNull(vendorId) === true) {
      isFormValid = false;
      error.vendorId = ["This field is required."];
    }

  if (isBlank(Ownership) === true || isNull(Ownership) === true) {
    isFormValid = false;
    error.Ownership = ["This field is required."];
  }

  if (isBlank(Brand) === true || isNull(Brand) === true) {
    isFormValid = false;
    error.Brand = ["This field is required."];
  }

  if (isBlank(Management) === true || isNull(Management) === true) {
    isFormValid = false;
    error.Management = ["This field is required."];
  }

  if (isBlank(Chain) === true || isNull(Chain) === true) {
    isFormValid = false;
    error.Chain = ["This field is required."];
  }

  if (isBlank(Contract) === true || isNull(Contract) === true) {
    isFormValid = false;
    error.Contract = ["This field is required."];
  }

  if (isBlank(logo) === true) {
    isFormValid = false;
    error.logo = ["This field is required."];
  }
  if (isBlank(venueImage) === true) {
    isFormValid = false;
    error.venueImage = ["This field is required."];
  }

  if (isBlank(serviceCharge) === true) {
    isFormValid = false;
    error.serviceCharge = ["This field is required."];
  }

  if (isBlank(salesTax) === true) {
    isFormValid = false;
    error.salesTax = ["This field is required."];
  }
  //  else if (
  //   name.trim().length > 0 &&
  //   /^([a-zA-Z\.\-])$/.test(name) === false
  // ) {
  //   isFormValid = false;
  //   error.name = ["Please enter a valid name"];
  // }

  // if (isBlank(location) === true) {
  //   isFormValid = false;
  //   error.location = ["This field is required."];
  // } else if (location?.trim().length > 0 && location?.trim().length > 100) {
  //   isFormValid = false;
  //   error.location = ["Name cannot be greater than 50."];
  // }

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  } else if (
    email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,8})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["Please enter a valid email address"];
  }

  if (isBlank(contactPhone) === true) {
    isFormValid = false;
    error.contactPhone = ["This field is required."];
  } else if (isNumber(contactPhone) !== true) {
    isFormValid = false;
    error.contactPhone = ["This field should be number."];
  } else if (contactPhone?.length < 11) {
    isFormValid = false;
    error.contactPhone = ["Please enter a valid phone number."];
  }

  if (isBlank(phone) === true) {
    isFormValid = false;
    error.phone = ["This field is required."];
  } else if (isNumber(phone) !== true) {
    isFormValid = false;
    error.phone = ["This field should be number."];
  } else if (phone?.length < 11) {
    isFormValid = false;
    error.phone = ["Please enter a valid phone number."];
  }

  return { isFormValid, error };
};

export const ValidateAddVendorForm = (formData) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    Brand,

    Management,

    Contract,
  } = formData || {};
  let error = {
    firstName: [],
    lastName: [],
    email: [],
    phone: [],
    Brand: [],
    Management: [],
    Contract: [],
  };
  let isFormValid = true;
  if (isBlank(firstName) === true) {
    isFormValid = false;
    error.firstName = ["This field is required."];
  }

  // if (isBlank(lastName) === true) {
  //   isFormValid = false;
  //   error.lastName = ["This field is required."];
  // }

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  }

  if (isBlank(Brand) === true || isNull(Brand) === true) {
    isFormValid = false;
    error.Brand = ["This field is required."];
  }
  if (isBlank(Management) === true || isNull(Management) === true) {
    isFormValid = false;
    error.Management = ["This field is required."];
  }
  if (isBlank(Contract) === true || isNull(Contract) === true) {
    isFormValid = false;
    error.Contract = ["This field is required."];
  }

  if (isBlank(phone) === true) {
    isFormValid = false;
    error.phone = ["This field is required."];
  } else if (isNumber(phone) !== true) {
    isFormValid = false;
    error.phone = ["This field should be number."];
  } else if (phone?.length < 11) {
    isFormValid = false;
    error.phone = ["Please enter a valid phone number."];
  }

  return { isFormValid, error };
};
