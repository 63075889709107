import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import "./style.css";
import Button from "../../button/Button";
import PhoneInput from "react-phone-input-2";
import { Divider } from "@material-ui/core";

const AddDepartment = (props) => {
  const [state, setstate] = useState();
  const [error, setError] = useState();
  const { handleChange, errorData } = props;

  useEffect(() => {
    setstate(props.state);

    if (props.state && props.state?.title) {
      setError("");
    }
  }, [props.state]);

  const onAddDepartment = (e) => {
    if (props.state && props.state?.title) {
      props.state.title && props.addDepartment(e);
      setError("");
      return;
    }
    setError("Please enter department title");
  };

  return (
    <div className="addVendorWrapper">
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className="adSpaceModal"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div className="adSpacePopUp">
            <h1>Add New Department</h1>
          </div>
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <Divider />
          <div className="d-flex">
            <div className="addSpaceInputs adSpace">
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">Name</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  <input
                    label="Department Name"
                    type="text"
                    id="custom-css-outlined-input"
                    name="title"
                    className="my-2"
                    value={state?.title || ""}
                    onChange={handleChange}
                    style={{ width: "80%" }}
                  />
                  {error?.name && errorData.name[0] ? (
                    <p className="errorMsg"> {errorData.name[0]}</p>
                  ) : null}
                  {error ? <p className="errorMsg"> {error}</p> : null}
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="adspacebuttonGroup">
          <Button
            background="secondary" // two types
            fontSize="15px"
            border="1px solid #fff"
            // disabled={item[1] == "Accepted" ? false : true}
            color="secondary" //two types
            name="Submit "
            loading={props.loading}
            handleClick={(e) => {
              onAddDepartment(e);
            }}
          />
          <Button
            background="secondary"
            fontSize="15px"
            border="1px solid #fff"
            color="secondary" //two types
            name="Cancel"
            handleClick={() => {
              props.handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AddDepartment;
