import Button from "../button/Button";
import "./Table.css";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Delete } from "@material-ui/icons";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableActionMenu from "./TableActionMenu";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  tableStart: {
    boxShadow: "none",
  },
  table: {
    minWidth: 650,
    textAlign: "center",

    "& thead.MuiTableHead-root": {
      position: "sticky",
      top: 0,
      background: "#ffffff",
      zIndex: 1,
      borderBottom: "2px solid grey",
    },
  },
  icon: {
    color: "#ED4B34",
    cursor: "pointer",
    "&   .MuiSvgIcon-root:hover": {
      color: "grey",
    },
  },
  buttonTwo: {
    border: "1px solid #ED4B34",
    backgroundColor: "transparent",
    color: "#414257",
  },
});

const Tabletab = ({
  handleDeleteUser,
  handleBtnClick,
  tableData,
  handleEmailClick,
  ...props
}) => {
  const classes = useStyles();

  const { tableHead } = props; //tableHead = array of columns, tableData = array of array of row data
  if (tableHead == null || tableData == null) {
    return null;
  }

  console.log("table", tableData);

  return (
    <div className="tableCover">
      <TableContainer
        style={{ height: props.height }}
        className={classes.tableStart}
        component={Paper}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHead?.map((key) => (
                <TableCell>{key}</TableCell>
              ))}
              {props.button ? <TableCell></TableCell> : null}{" "}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData?.map((item, index) => (
                <TableRow
                  key={item.id}
                  style={
                    index % 2
                      ? { background: "white" }
                      : { background: "#F2FBFE" }
                  }
                >
                  {tableHead.map((val, index) => {
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        // onClick={(e) => props.handleDropdownToggle(e, val, item)}
                      >
                        {item[val]}
                      </TableCell>
                    );
                  })}

                  {props.button ? (
                    <TableCell>
                      <Button
                        background="secondary" // two types
                        fontSize="15px"
                        border="1px solid #fff"
                        // disabled={item[1] == "Accepted" ? false : true}
                        color="secondary" //two types
                        name="Details"
                        handleClick={() => {
                          handleBtnClick(item);
                        }}
                      />
                    </TableCell>
                  ) : null}

                  {props.action ? (
                    <TableCell className="adminActionBtn">
                      {/* <Button
                      background="secondary" // two types
                      fontSize="15px"
                      border="1px solid #fff"
                      // disabled={item[1] == "Accepted" ? false : true}
                      color="secondary" //two types
                      name={
                        item["Status"] === "active"
                          ? "Deactivate"
                          : item["Status"] === "inactive"
                          ? "Activate"
                          : null
                      }
                      handleClick={() => {
                        let statusAction =
                          item["Status"] === "active"
                            ? "inactive"
                            : item["Status"] === "inactive"
                            ? "active"
                            : null;

                        let newItem = { ...item, statusAction };
                        props.handleStatusChange(newItem);
                      }}
                    /> */}
                      <Button
                        background="secondary" // two types
                        fontSize="15px"
                        border="1px solid #fff"
                        // disabled={item[1] == "Accepted" ? false : true}
                        color="secondary" //two types
                        name="Configure"
                        handleClick={() => {
                          props.handleBtnClick1(item);
                        }}
                      />
                      <Button
                        background="secondary" // two types
                        fontSize="15px"
                        border="1px solid #fff"
                        // disabled={item[1] == "Accepted" ? false : true}
                        color="secondary" //two types
                        name="Details"
                        handleClick={() => {
                          handleBtnClick(item);
                        }}
                      />

                      {/* <Delete
                      className={classes.icon}
                      onClick={() => props.handleClickIcon(item)}
                    /> */}
                    </TableCell>
                  ) : null}

                  {props.configure ? (
                    <TableCell className="adminActionBtn">
                      <Button
                        background="secondary" // two types
                        fontSize="15px"
                        border="1px solid #fff"
                        // disabled={item[1] == "Accepted" ? false : true}
                        color="secondary" //two types
                        name="Configure"
                        handleClick={() => {
                          props.handleBtnClick1(item);
                        }}
                      />

                      {/* <Delete
                      className={classes.icon}
                      onClick={() => props.handleClickIcon(item)}
                    /> */}
                    </TableCell>
                  ) : null}

                  {props.actionsMenu && (
                    <TableCell>
                      <TableActionMenu
                        item={item}
                        actionData={props.actionsMenu}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className="text-right">
        <ArrowLeftIcon
          className="pageIcon"
          onClick={(e) => getCurrentData(tableData, "less", e)}
          style={{ color: "black" }}
        />
        <ArrowRightIcon
          className="pageIcon"
          onClick={(e) => getCurrentData(tableData, "more", e)}
          style={{ color: "black" }}
        />
      </div> */}
    </div>
  );
};
export default Tabletab;
