import Table from "../table/Table";
import { Divider, MenuItem, Select } from "@material-ui/core";
import "./style.css";
import AutoComplete from "../autoComplete/AutoComplete";
// Autocomplete
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { AdminAction } from "../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader/Loader";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 120,
    marginRight: "10px",
    // "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    //   // Default transform is "translate(14px, 20px) scale(1)""
    //   // This lines up the label with the initial cursor position in the input
    //   // after changing its padding-left.
    //   transform: "translate(34px, 20px) scale(1);",
    // },
    "@media (max-width:460px)": {
      width: "60px",
    },
  },
  inputRoot: {
    // color: "purple",
    backgroundColor: "white",
    paddingLeft: 5,
    borderRadius: "4px",

    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
    },
    "& .MuiInputBase-input": {
      height: "1.4375em",
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "green",
    // },
    // "&:hover .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "red",
    // },
    // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "purple",
    // },
  },
  endAdornment: {
    top: "0 !important",
    height: "100%",

    "& button": {
      height: "100%",
    },
  },
}));

// const options = ["Option 1", "Option 2"];

const OrderManagementComponent = ({
  filter,
  setFilter,
  handleChangeFilter,
  filterList,
  tableData,
  tableHead,
  handleClick,
  filterState,
  setFilterState,
  toggle,
  setToggle,
  loadingState,
  sort,
  setSort,
  sortList,
  handleChangeSort,
  hasExport,
  setTableDateFilter,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const applyFilters = (type) => {
    // Apply Flters Here
    handleChangeFilter(type);
  };

  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");

  const { vendorList, eventList } = useSelector((state) => state.AdminReducer);

  const [exportDate, setExportDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    dispatch(AdminAction.getOrderFilters());
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setExportDate({
      ...exportDate,
      [name]: value,
    });
  };

  return (
    <div className="orderComponentWrapper">
      {filter ? (
        <div className="orderFilters">
          <div className="orderFiltersLeft">
            <span className="filterIconOrderManagement">
              <FilterListIcon />
            </span>
            <div className="showFilterDiv">
              <div className="orderFilterAutoComplete">
                <Autocomplete
                  value={filterState.vendor == null ? "" : filterState.vendor}
                  onChange={(event, newValue) => {
                    setFilterState({
                      ...filterState,
                      vendor: newValue,
                    });
                    // setValue(newValue);
                  }}
                  value={filterState.vendor == null ? "" : filterState.vendor}
                  onInputChange={(event, newInputValue) => {
                    setFilterState({
                      ...filterState,
                      vendor: newInputValue,
                    });
                    // setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={vendorList}
                  classes={classes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      //   label="controlled"
                      variant="standard"
                      placeholder={"Vendors"}
                    />
                  )}
                />
              </div>
              <div className="orderFilterAutoComplete">
                <Autocomplete
                  value={filterState.event == null ? "" : filterState.event}
                  onChange={(event, newValue) => {
                    setFilterState({
                      ...filterState,
                      event: newValue,
                    });
                    // setValue(newValue);
                  }}
                  inputValue={
                    filterState.event == null ? "" : filterState.event
                  }
                  onInputChange={(event, newInputValue) => {
                    setFilterState({
                      ...filterState,
                      event: newInputValue,
                    });
                    // setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={eventList}
                  classes={classes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      //   label="controlled"
                      variant="standard"
                      placeholder={"Events"}
                    />
                  )}
                />
              </div>
              <div className="orderManagementStatusFilter">
                <Select
                  labelId="Choose vendor"
                  id="demo-simple-select"
                  value={filter}
                  label="Choose vendor"
                  onChange={(e) => {
                    setFilter(e.target.value);
                    setFilterState({
                      ...filterState,
                      status: e.target.value,
                    });
                  }}
                >
                  {filterList.length > 0 &&
                    filterList.map((item) => (
                      <MenuItem value={item["value"]}>{item["name"]}</MenuItem>
                    ))}
                </Select>{" "}
              </div>

              {/* <span className="CloseIconOrderManagement">
                <CloseIcon
                  onClick={() => {
                    setFilterState({
                      ...filterState,
                      event: null,
                      vendor: null,
                      status: null,
                    });

                    setFilter("all");
                    // setValue("");
                    // setInputValue("");
                    setToggle(!toggle);
                  }}
                />
              </span> */}

              <span
                onClick={() => {
                  setFilterState({
                    ...filterState,
                    event: null,
                    vendor: null,
                    status: null,
                  });

                  setFilter("all");
                  applyFilters("reset");
                  // setValue("");
                  // setInputValue("");
                  setToggle(!toggle);
                }}
                className="filtersApply"
                style={{ cursor: "pointer" }}
              >
                <p>Reset</p>
              </span>
            </div>
            <span
              onClick={applyFilters}
              className="filtersApply"
              style={{ cursor: "pointer" }}
            >
              <p>Apply</p>
            </span>
          </div>
          <div className="orderFiltersRight">
            <p className="mr-1">Sort By : </p>{" "}
            <Select
              labelId="Choose vendor"
              id="demo-simple-select"
              value={sort}
              onChange={(e) => {
                setSort(e.target.value);
                handleChangeSort(e.target.value);
                // setFilterState({
                //   ...filterState,
                //   status: e.target.value,
                // });
              }}
              label="Choose vendor" // onChange={handleChangeFilter}
            >
              {sortList.length > 0 &&
                sortList.map((item) => (
                  <MenuItem value={item["value"]}>{item["name"]}</MenuItem>
                ))}
            </Select>{" "}
          </div>

          {hasExport ? (
            <>
              <div className="divider">
                <Divider />
              </div>
              <div className="orderExportLeft">
                <div>
                  <label for="start" className="mr-1">
                    Start Date :&nbsp;{" "}
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    placeholder=""
                    value={exportDate.startDate}
                    className="mr-2"
                    onChange={handleChange}
                    // readOnly={location.pathname === "/cart" ? false : true}
                  />
                </div>
                <div>
                  <label for="endDate" className="mr-1">
                    End Date :&nbsp;{" "}
                  </label>

                  <input
                    type="date"
                    name="endDate"
                    placeholder="dd-mm-yyyy"
                    value={exportDate.endDate}
                    // className="mr-1"
                    onChange={handleChange}
                    // readOnly={location.pathname === "/cart" ? false : true}
                  />
                </div>
                <span
                  onClick={() => {
                    let params = {
                      startDate: exportDate.startDate,
                      endDate: exportDate.endDate,
                    };
                    setTableDateFilter(params);
                    // dispatch(AdminAction.exportOrders(params));
                  }}
                  className="exportBtn"
                  style={{ cursor: "pointer" }}
                >
                  <p>View</p>
                </span>
                <span
                  onClick={() => {
                    let params = {
                      startDate: exportDate.startDate,
                      endDate: exportDate.endDate,
                    };
                    dispatch(AdminAction.exportOrders(params));
                  }}
                  className="exportBtn"
                  style={{ cursor: "pointer" }}
                >
                  <p>Export</p>
                </span>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {/* {loadingState === true ? (
        <Loader loading={loadingState} />
      ) : ( */}
      <div className="tabelOrdersList customertabelOrdersList">
        <Table
          tableHead={tableHead}
          tableData={tableData}
          button="yes"
          handleBtnClick={handleClick}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default OrderManagementComponent;
