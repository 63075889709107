import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../../components/button/Button";
import mailSent from "../../../../images/commonImage/Mailsent.png";
import { ROUTES } from "../../../../services/constants/Index";

const Mailsent = () => {
  return (
    <div className="mailSent">
      <h4>Mail sent</h4>
      <img src={mailSent} alt="" />
      <Link to={ROUTES.CUSTOMER_LINK_MANAGEMENT}>
      <Button
        name="Back to Dashboard"
        background="primary"
        color="secondary"
      ></Button></Link>
    </div>
  );
};

export default Mailsent;
