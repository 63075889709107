import React, { useState } from "react";
import InputField from "../input/Input";

import "./styles.css";
import PhoneInput from "react-phone-input-2";

const EventInfoSection = ({ state, handleChange, errorData, events }) => {
  console.log("state", state);

  return (
    <div>
      <div className="d-flex flex-row row">
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <InputField
            label="Group Name"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="groupName"
            value={state?.groupName}
            handleChange={handleChange}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.groupName && errorData.groupName[0]}
          </p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <InputField
            className=""
            label="Meeting/Event Name (CODE)"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="eventName"
            value={state?.eventName}
            handleChange={handleChange}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.eventName && errorData.eventName[0]}
          </p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <select
            value={state?.eventSize}
            style={{
              padding: "15px 10px",
              height: "56px",
              border: "1px solid",
            }}
            name="eventSize"
            className="form-control"
            onChange={handleChange}
          >
            <option selected>Event Size </option>
            {events &&
              events.map((data) => {
                return <option value={data}>{data}</option>;
              })}
          </select>
          <p className="errorMsg">
            {" "}
            {errorData?.eventSize && errorData.eventSize[0]}
          </p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <InputField
            label="Meeting or Event Start Date"
            variant="outlined"
            type="date"
            placeholder=""
            id="custom-css-outlined-input"
            name="eventStartDate"
            value={state?.eventStartDate}
            handleChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.eventStartDate && errorData.eventStartDate[0]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventInfoSection;
