import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <h4 className="mr-2" style={{ fontWeight: 800 }}>
        <a href="https://epeius-consulting.com/privacy-policy/" target="_blank">
          {" "}
          Privacy Policy{" "}
        </a>
      </h4>
      <h4 className="mr-2" style={{ fontWeight: 800 }}>
        <a
          href="https://epeius-consulting.com/terms-and-conditions/"
          target="_blank"
        >
          Terms of Use
        </a>
      </h4>
      <h4 className="mr-2" style={{ fontWeight: 800 }}>
        © 2021 Epeius Consulting, LLC
      </h4>
    </div>
  );
};

export default Footer;
