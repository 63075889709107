import React, { useState, useEffect } from "react";
import Button from "../../../../components/button/Button";
import "./imageMap.css";
import ImageMapItems from "./ImageMapItems";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction } from "../../../../redux/actions/Index";
import { useHistory, useParams } from "react-router-dom";
import Modal from "../../../../components/adSpaceModal/AdSpaceModal";
import { ValidateAdSpaceForm } from "./Validation";
import Handler from "../handlers/handler";
import {
  ROUTES,
  s3Bucket,
  s3UploadUrl,
} from "../../../../services/constants/Index";
import AlertDialog from "../../../../components/AlertDialog/alertDialog";

const ImageMapConfigurations = ({
  canvasRef,
  selectedItem,
  onChange,
  setAdSpace,
  setBackgroundImg,
  location,
  jsonData,
  setSelectedItem,
  setAdSpaceData,
  adSpaceData,
  // zone,
  setZone,
}) => {
  console.log(
    "🚀 ~ file: ImageMapConfigurations.js ~ line 33 ~ jsonData",
    jsonData
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [detailsModel, setDetailsModel] = useState(false);
  const [stateInfo, setStateInfo] = useState([]);
  const [data, setData] = useState({});
  // const [specifyZone, setSpecifyZone] = useState("");
  const { venueId, mapId } = useParams();
  const [errorData, setError] = React.useState();
  console.log("venueId", venueId);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (location === "edit") {
      setStateInfo(jsonData.adAdSpaces);
    }
  }, []);
  useEffect(() => {
    setAdSpaceData(stateInfo);
  }, [stateInfo]);

  useEffect(() => {
    if (selectedItem === null) {
      setData({});
    }
  }, [selectedItem]);

  useEffect(() => {
    console.log("in edit", location);

    // if (location === "edit" && selectedItem) {
    //   console.log("in edit json Data", jsonData.adAdSpaces);
    //   setStateInfo(jsonData.adAdSpaces);
    //   // jsonData?.adAdSpaces.map((item) => {
    //   //   console.log("in edit", item);

    //   //   if (item.id === selectedItem.id) {
    //   //     setData({
    //   //       ...data,
    //   //       ...item,
    //   //     });
    //   //     // } else if (item.id != selectedItem.id) {
    //   //     //   setData();
    //   //   }
    //   // });
    // }
    if (selectedItem) {
      console.log("in edit", stateInfo, selectedItem.id);

      stateInfo.map((item) => {
        if (item.id === selectedItem.id) {
          setData({
            ...data,
            ...item,
          });
          // } else if (item.id != selectedItem.id) {
          //   setData();
        }
      });
    }
  }, [selectedItem]);

  const handleSubmit = async (id) => {
    setloading(true);
    console.log("data", data);
    const result = await ValidateAdSpaceForm(data);
    if (result?.isFormValid) {
      console.log("ad space form data", data);
      setError({});
      let params = {};
      if (data?.tempUrl)
        axios
          .get(
            `${s3UploadUrl}?object_key=adSpace/${Date.now()}&bucket=${s3Bucket}`
          )
          .then((resp) => {
            var options = {
              headers: {
                "Content-Type": data?.url?.type,
              },
            };
            axios
              .put(resp.data.presigned_url, data.url, options)
              .then(async (resps, err) => {
                if (err) console.log("err :>> ", err);
                params = {
                  url: `https://${s3Bucket}.s3.us-east-2.amazonaws.com/${resp.data.key}`,
                  title: data.title,
                  info: data.info,
                  id: id,

                  description: data.description,
                  price: data.price,
                  category: data.category,
                  vendorCost: data?.vendorCost,
                  sqFeet: data?.sqFeet,
                  // realEstateSale: data?.realEstateSale,
                  zone: data?.zone,
                  zoneId: data?.zoneId,
                  venueId,
                };

                console.log("params", params);
                const response = await dispatch(
                  AdminAction.saveAddSpace(params)
                );
                console.log("response from save adspace", response);
                const { _id } = response.data;
                setDetailsModel(false);
                setSelectedItem(null);
                setloading(false);
                setData();

                let dummyState = [...stateInfo];
                if (dummyState.filter((item) => item.id === id).length > 0) {
                  let dataState = dummyState.map((item) => {
                    if (item.id === id) {
                      console.log("dummySTate in if", dummyState, data);
                      item._id = _id;
                      item.title = data?.title;
                      item.info = data?.info;
                      item.price = data?.price;
                      item.url = `https://${s3Bucket}.s3.us-east-2.amazonaws.com/${resp.data.key}`;
                      item.category = data.category;
                      item.description = data.description;
                      item.vendorCost = data?.vendorCost;
                      item.sqFeet = data?.sqFeet;
                      // item.realEstateSale = data?.realEstateSale;
                      item.zone = data?.zone;
                      item.zoneId = data?.zoneId;
                    }
                  });
                  setAdSpace(dummyState);
                  setStateInfo([...dummyState]);
                } else {
                  console.log("dummySTate in else", dummyState);

                  dummyState.push({
                    id,
                    _id,
                    ...data,
                  });
                  setAdSpace(dummyState);
                  setStateInfo([...dummyState]);
                }
                console.log("dummySTate", dummyState);
                setTimeout(() => {
                  // setAdSpace(dummyState);
                  // setStateInfo([...dummyState]);
                }, 2000);
              })
              .catch((err) => {
                console.log("err", err);
                setData();
              });
          });
      else {
        params = {
          // url: `https://epeiusdevimages.s3.us-east-2.amazonaws.com/${resp.data.key}`,
          title: data.title,
          info: data.info,
          id: id,

          description: data.description,
          price: data.price,
          category: data.category,
          vendorCost: data?.vendorCost,
          sqFeet: data?.sqFeet,
          // realEstateSale: data?.realEstateSale,

          zone: data?.zone,
          zoneId: data?.zoneId,
          venueId,
        };

        console.log("params", params);
        const response = await dispatch(AdminAction.saveAddSpace(params));
        setDetailsModel(false);
        setSelectedItem(null);
        setloading(false);
        setData();

        let dummyState = [...stateInfo];
        if (dummyState.filter((item) => item.id === id).length > 0) {
          let dataState = dummyState.map((item) => {
            if (item.id === id) {
              item.title = data?.title;
              item.info = data?.info;
              item.price = data?.price;
              item.url = data.url;
              item.category = data.category;
              item.description = data.description;
              item.vendorCost = data?.vendorCost;
              item.sqFeet = data?.sqFeet;
              // item.realEstateSale = data?.realEstateSale;
              item.zone = data?.zone;
              item.zoneId = data?.zoneId;
            }
          });
        } else {
          dummyState.push({
            id,
            ...data,
          });
        }
        setAdSpace(dummyState);
        setStateInfo([...dummyState]);
      }
    } else if (result?.isFormValid === false) {
      console.log("err", result.error);

      setError(result?.error);
      setloading(false);

      return;
    }
    console.log("err", errorData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // console.log("e.target.files", e.target.files[0].type);

    if (name === "zone") {
      let zoneName = jsonData.zones.reduce((t, c) => {
        if (c["_id"] === value) t = c["name"];

        return t;
      }, "");

      setData({
        ...data,
        zone: zoneName,
        zoneId: value,
      });
    } else if (name === "url") {
      console.log("url state", e.target.files, e.target);
      setData({
        ...data,
        [name]: e.target.files[0],
        tempUrl: URL.createObjectURL(e.target.files[0]),
      });
    } else {
      setData({
        ...data,
        [name]: e.target.value,
      });
    }
  };

  // const handleAddZone = async (id) => {

  if (selectedItem == null)
    return (
      <div className=" rightNav imageMapConf">
        <div className="sideBar-wrapper">
          <ImageMapItems
            canvasRef={canvasRef}
            setBackgroundImg={setBackgroundImg}
            // specifyZone={specifyZone}
            // zone={jsonData?.newZones}
            zones={jsonData?.zones}
            setZone={setZone}
          />
        </div>
      </div>
    );
  else {
    const { type } = selectedItem;
    return (
      <div className=" rightNav imageMapConf">
        <div className="sideBar-wrapper">
          <ImageMapItems
            canvasRef={canvasRef}
            setBackgroundImg={setBackgroundImg}
            // specifyZone={specifyZone}
            zones={jsonData?.zones}
            setZone={setZone}
          />
        </div>

        {/* <h3 className=" headingEdit text-center ">Properties</h3> */}
        {console.log("path", type)}
        {type === "path" && (
          <>
            <div className="d-flex imageButton justify-content-center">
              <Button
                background="secondary"
                fontSize="15px"
                border="1px solid #fff"
                color="secondary"
                name="Edit"
                handleClick={() => {
                  setDetailsModel(true);
                }}
              />
              <Button
                background="secondary"
                fontSize="15px"
                border="1px solid #fff"
                color="secondary"
                name="Delete"
                handleClick={() => {
                  // console.log(
                  //   "in handleDelete",
                  //   canvasRef.current,

                  //   canvasRef.current.canvas._activeObject
                  // );
                  // console.log(
                  //   "in handleDelete",

                  //   canvasRef.current.canvas.getActiveObject()
                  // );

                  const activeObject =
                    canvasRef.current.canvas.getActiveObject();

                  let selectedAdspace;

                  stateInfo.some((adSpace) => {
                    if (adSpace?.id === activeObject?.id) {
                      selectedAdspace = adSpace;
                      return true;
                    }
                  });
                  console.log("selectedAdSPace", selectedAdspace);
                  let respp;
                  if (selectedAdspace && selectedAdspace["_id"])
                    dispatch(AdminAction.deleteAddSpace(selectedAdspace));

                  console.log("adSPace, data", adSpaceData);

                  let filteredAdSpaceData = adSpaceData.filter(
                    (dataa) => dataa?._id != selectedAdspace?._id
                  );

                  console.log("filtered ad space dtata", filteredAdSpaceData);
                  setAdSpaceData(filteredAdSpaceData);

                  canvasRef.current.handler.onRemove(selectedItem);
                }}
              />
            </div>

            <Modal
              id={selectedItem?.id}
              open={detailsModel}
              handleClose={() => {
                setDetailsModel(false);
                setSelectedItem(null);
                setData();
              }}
              loading={loading}
              errorData={errorData}
              state={data}
              stateInfo={stateInfo}
              handleChange={handleChange}
              handleSave={handleSubmit}
              handleDelete={() => {
                setDetailsModel(false);
                setData();
                canvasRef.current.handler.onRemove(selectedItem);
                setSelectedItem(null);
              }}
              zones={jsonData?.zones}
            />
          </>
        )}
      </div>
    );
  }
};

export default ImageMapConfigurations;
