import { ACTION_TYPE } from "../../services/constants/Index";

export const initialState = {
  orders: [],
  orderDetails: {},
};
export const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.VENDOR_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
      };
    case ACTION_TYPE.VENDOR_ALL_ORDERS_DETAILS:
      return {
        ...state,
        orderDetails: action.payload.data,
      };

    default:
      return state;
  }
};
