import React, { useEffect, useState, useRef } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./VenueDetails.css";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { useHistory } from "react-router-dom";
import {
  ACTION_TYPE,
  ROUTES,
  s3Bucket,
  s3UploadUrl,
} from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackSharp, ArrowLeft } from "@material-ui/icons";
import dummyImage from "../../../images/NewIcons/NewIcon3.png";
import Canvas from "../../Story/NewDesign/index";
import { useParams } from "react-router-dom";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import InputField from "../../../components/input/Input";
import PhoneInput from "react-phone-input-2";
import states from "../../Story/CustomerInformation/data.json";
import { ValidateAddVenueForm } from "../addNewVenue/Validation";
import { Divider } from "@material-ui/core";
import { formatUSNumber } from "../../../services/utills/helper";
import { WEB_APP_URL } from "../../../services/constants/Index";
import axios from "axios";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "18px",
    width: "70px",
    justifyContent: "space-between",
    margin: "20px auto 20px 20px",
    ["@media (max-width: 460px)"]: {
      // margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

const VenueDetails = (props) => {
  const classes = useStyles();
  const venueImageUpload = useRef();
  const logoUpload = useRef();

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const { venueDetails, vendors, categories } = useSelector(
    (state) => state.AdminReducer
  );

  const [state, setState] = useState({});
  const [categoryList, setCategoryList] = useState({});
  const [editable, setEditable] = useState(false);

  const [errorData, setErrorData] = useState({});

  const [venueImageEdit, setVenueImageEdit] = useState(false);
  const [logoEdit, setLogoEdit] = useState(false);

  const params = useParams();

  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        dispatch(AdminAction.getVenueDetails(params?.venueId));
        dispatch(AdminAction.getAddVenueReqList());
      }
    })();
  }, [user, userUpdated]);

  useEffect(() => {
    const {
      adSpace,
      categories,
      graphicData,
      createdAt,
      password,
      isActive,
      role,
      url,
      vendorId,
      orders,
      ...rest
    } = venueDetails;
    setState({
      ...rest,
      vendorId: vendorId?._id,
    });

    let categoryObject = {};
    categories &&
      categories.length > 0 &&
      categories.map((category) => {
        categoryObject[category.split("/")[1]] = category.split("/")[2];
      });
    console.log("categoryObject", categoryObject);
    setCategoryList(categoryObject);
  }, [venueDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "venueImage" || name === "logo") {
      console.log("url state", e.target.files, e.target);
      setState({
        ...state,
        [name]: e.target.files[0],
        [name + "Url"]: URL.createObjectURL(e.target.files[0]),
      });
      if (name === "venueImage") setVenueImageEdit(true);
      else if (name === "logo") setLogoEdit(true);
    } else if (
      name === "Brand" ||
      name === "Ownership" ||
      name === "Management" ||
      name === "Chain" ||
      name === "Contract"
    )
      setCategoryList({
        ...categoryList,
        [name]: value,
      });
    else
      setState({
        ...state,
        [name]: value,
      });
  };

  const handleSave = async (e) => {
    console.log("state", state, categoryList);

    e.preventDefault();
    e.stopPropagation();
    // setloading(true);
    let newState = {
      ...state,
      ...categoryList,
    };

    const result = await ValidateAddVenueForm(newState);
    if (!result?.isFormValid) {
      setErrorData(result?.error);
      // setloading(false);
      return;
    }
    setErrorData({});

    const { venueId, ...updateBody } = state;

    let params = {
      ...updateBody,
      categoryList,
    };

    await dispatch(AdminAction.updateVenue(params, state._id));
    setEditable(false);
    // setloading(false);
    // props.history.push(ROUTES.VENUE_MANAGEMENT);
  };

  const uploadLogo = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${s3UploadUrl}?object_key=venue/venueLogo/${Date.now()}&bucket=${s3Bucket}`
        )
        .then((resp) => {
          var options = {
            headers: {
              "Content-Type": state?.logo?.type,
            },
          };
          axios
            .put(resp.data.presigned_url, state?.logo, options)
            .then(async (resps, err) => {
              resolve(resp.data.key);
            });
        });
    });
  };

  const uploadVenueImage = () => {
    const { venueImage, venueImageUrl } = venueDetails;
    console.log("in uploadVenue image", venueImage, venueImageUrl);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${s3UploadUrl}?object_key=venue/venueImage/${Date.now()}&bucket=${s3Bucket}`
        )
        .then((resp) => {
          console.log("resp :>> ", resp);

          var options = {
            headers: {
              "Content-Type": state?.venueImage?.type,
            },
          };
          axios
            .put(resp.data.presigned_url, state?.venueImage, options)
            .then(async (resps, err) => {
              if (err) console.log("err :>> ", err);

              resolve(resp.data.key);
            });
        });
    });
  };

  const handleMediaSave = async (mediaType) => {
    let mediaKey;
    if (mediaType === "venueImage") {
      mediaKey = await uploadVenueImage();
    } else if (mediaType === "logo") {
      mediaKey = await uploadLogo();
    }

    let mediaUrl = `https://${s3Bucket}.s3.us-east-2.amazonaws.com/${mediaKey}`;
    await dispatch(
      AdminAction.updateVenueMedia(mediaType, mediaUrl, venueDetails._id)
    );
    if (mediaType === "venueImage") setVenueImageEdit(false);
    else if (mediaType === "logo") setLogoEdit(false);

    // console.log("venueImageKey, logoKey :>> ", venueImageKey, logoKey);
  };

  return (
    <div>
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Venue Management / Venue Details" />
          <div className="venueDetailsWrapper ">
            <div className="d-flex align-items-center">
              <div
                className={classes.arrow}
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackSharp /> Back
              </div>
              <div className="edit">
                <Button
                  background="secondary"
                  color="secondary"
                  name="Edit Details"
                  handleClick={() => setEditable(true)}
                />
              </div>
            </div>
            <div className="venueDetailsWrapperInner">
              {/* <div className="venueDetailsUpper"> */}

              <div className="d-flex">
                <div className="addVenueUploadData">
                  <div className="addVenue__upload">
                    <div
                      className={
                        venueDetails?.venueImageUrl
                          ? "newaddVenue__image"
                          : "addVenue__image"
                      }
                    >
                      <h1>Venue image</h1>
                      <div className={"imageWrapper"}>
                        <img
                          src={
                            venueImageEdit === true
                              ? state?.venueImageUrl
                              : venueDetails?.venueImage
                          }
                          alt=""
                        />
                      </div>
                      <div className="mt-3 d-flex justify-content-center">
                        <Button
                          background="secondary" // two types
                          fontSize="11px"
                          border="1px solid #fff"
                          // disabled={item[1] == "Accepted" ? false : true}
                          color="secondary" //two types
                          name={venueImageEdit ? "Change" : "Edit"}
                          handleClick={() => venueImageUpload.current.click()}
                        />
                        {venueImageEdit === true ? (
                          <Button
                            background="secondary" // two types
                            fontSize="11px"
                            border="1px solid #fff"
                            // disabled={item[1] == "Accepted" ? false : true}
                            color="secondary" //two types
                            name={"Save"}
                            handleClick={() => handleMediaSave("venueImage")}
                          />
                        ) : null}
                        {/* <p className="errorMsg">
                          {" "}
                          {errorData?.venueImage && errorData.venueImage[0]}
                        </p> */}
                      </div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      name="venueImage"
                      ref={venueImageUpload}
                      onChange={handleChange}
                      hidden
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                    <div
                      className={
                        venueDetails?.logoUrl
                          ? "addVenue__image"
                          : "addVenue__image"
                      }
                    >
                      <h1>Venue Logo</h1>
                      <div className={"imageWrapper"}>
                        <img
                          src={
                            logoEdit === true
                              ? state?.logoUrl
                              : venueDetails?.logo
                          }
                          alt=""
                        />
                      </div>
                      <div className="mt-3 d-flex justify-content-center">
                        <Button
                          background="secondary" // two types
                          fontSize="11px"
                          border="1px solid #fff"
                          // disabled={item[1] == "Accepted" ? false : true}
                          color="secondary" //two types
                          name={logoEdit ? "Change" : "Edit"}
                          handleClick={() => logoUpload.current.click()}
                        />
                        {logoEdit === true ? (
                          <Button
                            background="secondary" // two types
                            fontSize="11px"
                            border="1px solid #fff"
                            // disabled={item[1] == "Accepted" ? false : true}
                            color="secondary" //two types
                            name={"Save"}
                            handleClick={() => handleMediaSave("logo")}
                          />
                        ) : null}
                        {/* <p className="errorMsg">
                          {" "}
                          {errorData?.logo && errorData.logo[0]}
                        </p> */}
                      </div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={logoUpload}
                      name="logo"
                      onChange={handleChange}
                      hidden
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* {venueDetails.graphicData &&
              venueDetails._id === params.venueId ? (
                <div className="venueDetailsRight">
                  <Canvas venueId={venueDetails?.venueId} />
                </div>
              ) : (
                <div className="venueDetailsRight text-center">
                  <h5>Map not yet configured</h5>
                </div>
              )} */}
              {/* </div> */}
              <div className="venueDetailsLower">
                <div className="venueDetailsDataDiv">
                  <div className="venueDetailsData">
                    <p className="detail-label">Venue Name : </p>

                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="firstName"
                          placeholder="Enter venue county"
                          value={state?.firstName}
                          handleChange={handleChange}
                        />
                        {errorData?.firstName && errorData.firstName[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.firstName && errorData.firstName[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.firstName}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Email : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="email"
                          placeholder="Enter venue county"
                          value={state?.email}
                          handleChange={handleChange}
                        />
                        {errorData?.email && errorData.email[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.email && errorData.email[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.email}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Phone : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <PhoneInput
                          className="addvenueInput"
                          country={"us"}
                          onlyCountries={["us"]}
                          value={state?.phone}
                          placeholder="Enter venue phone"
                          inputClass="phoneInput"
                          buttonClass="phoneButton"
                          dropdownClass="dropdownClass"
                          disableDropdown="true"
                          onChange={(phone) =>
                            setState({
                              ...state,
                              phone,
                            })
                          }
                        />
                        {errorData?.phone && errorData.phone[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.phone && errorData.phone[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {formatUSNumber(venueDetails?.phone)}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Street : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="street"
                          placeholder="Enter venue county"
                          value={state?.street}
                          handleChange={handleChange}
                        />
                        {errorData?.street && errorData.street[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.street && errorData.street[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.street}
                      </p>
                    )}
                  </div>
                  <div className="venueDetailsData">
                    <p className="detail-label">City : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="city"
                          placeholder="Enter venue county"
                          value={state?.city}
                          handleChange={handleChange}
                        />
                        {errorData?.city && errorData.city[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.city && errorData.city[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.city}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">State : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={state?.state}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="state"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select State </option>
                            {states &&
                              states.map((data) => {
                                return (
                                  <option value={data.Code}>{data.Code}</option>
                                );
                              })}
                          </select>

                          <p className="errorMsg">
                            {" "}
                            {errorData?.state && errorData.state[0]}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.state}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Zip : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="zip"
                          placeholder="Enter venue zip"
                          value={state?.zip}
                          handleChange={handleChange}
                        />
                        {errorData?.zip && errorData.zip[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.zip && errorData.zip[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.zip}
                      </p>
                    )}
                  </div>

                  {/* <div className="venueDetailsData">
                    <p className="detail-label">County : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="country"
                          placeholder="Enter venue county"
                          value={state?.country}
                          handleChange={handleChange}
                        />
                        {errorData?.country && errorData.country[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.country && errorData.country[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.country}
                      </p>
                    )}
                  </div> */}

                  <div className="venueDetailsData">
                    <p className="detail-label">Venue ID : </p>

                    <p className="detail-key text-right mb-0">
                      {venueDetails?.venueId}
                    </p>
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Venue Link : </p>

                    <p className="detail-key text-right mb-0">
                      {`${WEB_APP_URL}/venue/${venueDetails?.venueId}`}
                    </p>
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Real Estate Sale : </p>

                    <p className="detail-key text-right mb-0">
                      {`${venueDetails?.realEstateSale || "false"}`}
                    </p>
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Vendor Name : </p>

                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={state?.vendorId}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="vendorId"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Vendor </option>
                            {vendors.length &&
                              vendors.map((data) => {
                                return (
                                  <option value={data._id}>
                                    {data.firstName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.vendorId?.firstName}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">No. of Ad-Spaces : </p>

                    <p className="detail-key text-right mb-0">
                      {venueDetails?.adSpace?.length}
                    </p>
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">
                      Service/Administration Charge :{" "}
                    </p>

                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="serviceCharge"
                          placeholder="Enter venue county"
                          value={state?.serviceCharge}
                          handleChange={handleChange}
                        />
                        {errorData?.serviceCharge &&
                        errorData.serviceCharge[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.serviceCharge &&
                              errorData.serviceCharge[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.serviceCharge}%
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Sales Tax : </p>

                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="salesTax"
                          placeholder="Enter venue county"
                          value={state?.salesTax}
                          handleChange={handleChange}
                        />
                        {errorData?.salesTax && errorData.salesTax[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.salesTax && errorData.salesTax[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.salesTax}%
                      </p>
                    )}
                  </div>

                  <Divider />
                  <br />

                  <div className="venueDetailsData">
                    <p className="detail-label">Contact Name : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="contactFirstName"
                          placeholder="Enter venue county"
                          value={state?.contactFirstName}
                          handleChange={handleChange}
                        />
                        {errorData?.contactFirstName &&
                        errorData.contactFirstName[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.contactFirstName &&
                              errorData.contactFirstName[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.contactFirstName}
                      </p>
                    )}
                  </div>
                  <div className="venueDetailsData">
                    <p className="detail-label">Contact Email : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <InputField
                          className=""
                          label=""
                          type="text"
                          id="custom-css-outlined-input"
                          name="contactEmail"
                          placeholder="Enter venue county"
                          value={state?.contactEmail}
                          handleChange={handleChange}
                        />
                        {errorData?.contactEmail &&
                        errorData.contactEmail[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.contactEmail &&
                              errorData.contactEmail[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {venueDetails?.contactEmail}
                      </p>
                    )}
                  </div>
                  <div className="venueDetailsData">
                    <p className="detail-label">Contact Phone : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <PhoneInput
                          className="addvenueInput"
                          country={"us"}
                          onlyCountries={["us"]}
                          value={state?.contactPhone}
                          placeholder="Enter venue phone"
                          inputClass="phoneInput"
                          buttonClass="phoneButton"
                          dropdownClass="dropdownClass"
                          disableDropdown="true"
                          onChange={(contactPhone) =>
                            setState({
                              ...state,
                              contactPhone,
                            })
                          }
                        />
                        {errorData?.contactPhone &&
                        errorData.contactPhone[0] ? (
                          <p className="errorMsg">
                            {" "}
                            {errorData?.contactPhone &&
                              errorData.contactPhone[0]}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {formatUSNumber(venueDetails?.contactPhone)}
                      </p>
                    )}
                  </div>

                  <Divider />
                  <br />

                  <div className="venueDetailsData">
                    <p className="detail-label">Brand : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Brand"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Brand"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Brand </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Brand,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                          {errorData?.Brand && errorData.Brand[0] ? (
                            <p className="errorMsg">
                              {" "}
                              {errorData?.Brand && errorData.Brand[0]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Brand"]}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Ownership : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Ownership"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Ownership"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Ownership </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Ownership,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                          {errorData?.Ownership && errorData.Ownership[0] ? (
                            <p className="errorMsg">
                              {" "}
                              {errorData?.Ownership && errorData.Ownership[0]}
                            </p>
                          ) : null}
                          {/* <p className="errorMsg">
                       {" "}
                       {errorData?.state && errorData.state[0]}
                     </p> */}
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Ownership"]}
                      </p>
                    )}
                  </div>
                  <div className="venueDetailsData">
                    <p className="detail-label">Management : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Management"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Management"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Management </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Management,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                          {errorData?.Management && errorData.Management[0] ? (
                            <p className="errorMsg">
                              {" "}
                              {errorData?.Management && errorData.Management[0]}
                            </p>
                          ) : null}
                          {/* <p className="errorMsg">
                       {" "}
                       {errorData?.state && errorData.state[0]}
                     </p> */}
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Management"]}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Contract : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Contract"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Contract"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Contract </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Contract,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                          {errorData?.Contract && errorData.Contract[0] ? (
                            <p className="errorMsg">
                              {" "}
                              {errorData?.Contract && errorData.Contract[0]}
                            </p>
                          ) : null}
                          {/* <p className="errorMsg">
                       {" "}
                       {errorData?.state && errorData.state[0]}
                     </p> */}
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Contract"]}
                      </p>
                    )}
                  </div>

                  <div className="venueDetailsData">
                    <p className="detail-label">Chain : </p>
                    {editable === true ? (
                      <div className="detail-key">
                        <div className="addVenueAddDataRight changeMaxWidth">
                          <select
                            value={categoryList["Chain"]}
                            style={{
                              padding: "15px 10px",
                              height: "50px",
                              border: "1px solid",
                            }}
                            name="Chain"
                            className="form-control "
                            onChange={handleChange}
                          >
                            <option selected>Select Chain </option>
                            {categories.length &&
                              categories.map((data) => {
                                if (data["path"] === ",Chain,")
                                  return (
                                    <option value={data.id}>{data.id}</option>
                                  );
                              })}
                          </select>
                          {errorData?.Chain && errorData.Chain[0] ? (
                            <p className="errorMsg">
                              {" "}
                              {errorData?.Chain && errorData.Chain[0]}
                            </p>
                          ) : null}
                          {/* <p className="errorMsg">
                       {" "}
                       {errorData?.state && errorData.state[0]}
                     </p> */}
                        </div>
                      </div>
                    ) : (
                      <p className="detail-key text-right mb-0">
                        {categoryList["Chain"]}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {editable === true ? (
                <div className="d-flex justify-content-around pb-3">
                  <Button
                    background="secondary"
                    color="secondary"
                    name="Save"
                    handleClick={handleSave}
                  />

                  <Button
                    name="Cancel"
                    handleClick={() => setEditable(false)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueDetails;
