import React, { useState, useEffect } from "react";
import "./imageMap.css";
import Modal from "../../modal/modal";
const ImageMapConfigurations = ({
  selectedItem,
  jsonData,
  setSelectedItem,
}) => {
  const [detailsModel, setDetailsModel] = useState(false);
  useEffect(() => {
    console.log("selectedItem", selectedItem);
    if (selectedItem?.id) {
      setDetailsModel(true);
    }
  }, [selectedItem]);
  if (selectedItem == null) return null;
  else {
    const { type } = selectedItem;
    return (
      <div className=" rightNav imageMapConf">
        <Modal
          id={selectedItem?.id}
          open={detailsModel}
          handleClose={() => {
            setSelectedItem(null);
            setDetailsModel(false);
          }}
          adSpaceData={jsonData}
        />
      </div>
    );
  }
};

export default ImageMapConfigurations;
