import secureWebStorage from "../utills/secureWebStorage";

// export const BASE_URL = "http://54.204.116.192:5000/v1";
// export const BASE_URL = "https://epeius-backend.uc.r.appspot.com/v1";
// export const BASE_URL = "http://localhost:5000/v1";
// export const BASE_URL =
//   "https://njtzj8f3cd.execute-api.us-east-1.amazonaws.com/development/v1";
export const BASE_URL = "https://www.brandevent.store/api/v1"; // PRODUCTION
// export const BASE_URL =
// 	"https://txyjhxhny7.execute-api.us-east-2.amazonaws.com/development/api";

// export const BASE_URL =
// "http://gz2wl39zu4.execute-api.us-east-2.amazonaws.com/development/api";
// "https://5tx2djrb1f.execute-api.us-east-1.amazonaws.com/development/api";
//   "https://txyjhxhny7.execute-api.us-east-2.amazonaws.com/development/api";

export const WEB_APP_URL = "https://www.brandevent.store";
// export const WEB_APP_URL = "http://localhost:3000";
// export const WEB_APP_URL = "https://epeius-frontend.uc.r.appspot.com";
export const s3UploadUrl =
  "https://478ewovk6e.execute-api.us-east-2.amazonaws.com/dev/s3upload";
// "https://aq6fbtxxp1.execute-api.us-east-2.amazonaws.com/dev/s3upload"

export const s3Bucket = "brandeventimages";

export const SITE_KEY = "6LdCGfEcAAAAACh5jvhT9bXwOE3iJxk9rOhSE9Mi";

export const APPLICATION_CONSTANTS = {
  //Local Storage Constant
  TOKEN: "token",
  USER_DATA: "userData",
  REMEMBER_ME: "rememberme",
  BOOKING_DETAILS: "bookingDetails",
  CUSTOMER_INFO: "customer_info",
  //KEYS
  REMEMBER_ME_SECRETE_KEY: "remembermesecret$#",
  REMEMBER_USER_SECRETE_KEY: "UserSecretKey",
  //Profile Tab View Constants
  SECURE_WEB_STORAGE: secureWebStorage,
  VENUE_ROLE: "venue",
  VENDOR_ROLE: "vendor",
  STAFF_ROLE: "staff",
  SERVICE_CHARGE: 25,
  SALES_TAX: 8.25,
};

export const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  VENUE_VENDOR_REGISTER: "/secure-access/register",
  VENUE_VENDOR_LOGIN: "/secure-access/login",
  EMAIL_VERIFY: "/verify",
  MYORDER: "/my-order",
  MYPROFILE: "/my-profile",
  ORDERDETAILSPAGE: "/order/details/:id",
  ORDERDETAIL: "/order-details",
  VENUE: "/venue",
  CART: "/cart",
  CUSTOMER_INFO: "/customer-info",
  // CUSTOMER_INFO: "/customer-info2",
  CHECKOUT: "/checkout",
  FAQ: "/faq",
  CONTACTUS: "/contact-us",
  ABOUTUS: "/about-us",
  ORDERCONFIRMATION: "/order-confirmation",
  PROPOSALCONFIRMATION: "/proposal-confirmation",
  LOGINREGISTER: "/login-register",
  FORGOT_PASSWORD: "/forgot-password/:type",
  // FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  // ORDERDETAIL: "/order-details",
  VENUE_MANAGEMENT: "/venue-management",
  VENUE_DETAILS: "/venue-details",
  ADD_NEW_VENUE: "/add-new-venue",
  ADD_NEW_VENDOR: "/add-new-vendor",
  CONFIGURE_VENUE: "/configure-venue",
  MAP_LIST: "/maps",
  EDIT_VENUE: "/edit-venue",
  ORDER_MANAGEMENT: "/order-management",
  ORDER_MANAGEMENT_DETAILS: "/order-management/order-details/:orderId",
  VENDOR_MANAGEMENT: "/vendor-management",
  CUSTOMER_MANAGEMENT: "/customer-management",
  CUSTOMER_DETAILS: "/customer/details/:customerId",
  CUSTOMER_ORDER_DETAILS: "/customer/order/details",
  VENDOR_DETAILS: "/vendor/details",

  // Venue Routes

  VENUE_ORDER_MANAGEMENT: "/dashboard/venue/order-management",
  VENUE_ORDER_MANAGEMENT_DETAILS: "/dashboard/venue/order-management/details",

  VENUE_DEPARTMENT_MANAGEMENT: "/dashboard/venue/department-management",
  VENUE_STAFF_MANAGEMENT: "/dashboard/venue/staff-management",
  // Vendor Routes

  VENDOR_ORDER_MANAGEMENT: "/dashboard/vendor/order-management",
  VENDOR_ORDER_MANAGEMENT_DETAILS: "/dashboard/vendor/order-management/details",

  // Staff Member Routes
  STAFF_ORDER_MANAGEMENT: "/dashboard/staff/order-management",
  STAFF_ORDER_DETAILS: "/dashboard/staff/order-management/details",
  CUSTOMER_LINK_MANAGEMENT: "/dashboard/staff/customer-link-management",
  STAFF_CUSTOMER_INFO:
    "/dashboard/staff/customer-link-management/customer-info",

  DISCOUNT_CREATION: "/dashboard/staff/discount-management",
};
export const ACTION_TYPE = {
  SAVE_CUSTOMER_INFO: "SAVE_CUSTOMER_INFOS",
  LOADING_STARTED: "LOADING_STARTED",
  LOADING_COMPLETED: "LOADING_COMPLETED",
  LOADING_FAILURE: "LOADING_FAILURE",
  //auth
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  SAVE_ADSPACE: "SAVE_ADSPACE",
  SAVE_ZONE: "SAVE_ZONE",
  LOGOUT_USER: "LOGOUT_USER",
  MATCHTOKEN: "MATCHTOKEN",
  ORDER_ALL: "ORDER_ALL",
  PROFILE: "PROFILE",
  ORDER_DETAIL: "ORDER_DETAIL",
  CHECKOUT_ORDER_DETAILS: "CHECKOUT_ORDER_DETAILS",
  VENUE_DETAIL: "VENUE_DETAIL",
  MAP_DATA: "MAP_DATA",
  EMPTY_MAP_DATA: "EMPTY_MAP_DATA",
  ADSPACE_DETAIL: "ADSPACE_DETAIL",
  CART: "CART",
  ADD_TO_CART: "ADD_TO_CART",
  ADD_CUSTOMER_INFO: "ADD_CUSTOMER_INFO",
  EVENT_DATA: "EVENT_DATA",
  PLACE_ORDER: "PLACE_ORDER",
  CLEAR_ORDER_DETAILS: "CLEAR_ORDER_DETAILS",
  CLEAR_CART: "CLEAR_CART",
  VENUE_NAME: "VENUE_NAME",
  VENUE_ID: "VENUE_ID",
  ORDER_DETAILS_BY_ID: "ORDER_DETAILS_BY_ID",

  //  Venue

  VENUE_ALL_ORDERS: "VENUE_ALL_ORDERS",
  VENUE_ALL_ORDERS_DETAILS: "VENUE_ALL_ORDERS_DETAILS",
  DELETE_VENUE: "DELETE_VENUE",
  VENUE_ALL_DEPARTMENTS: "VENUE_ALL_DEPARTMENTS",
  VENUE_ALL_STAFF_MEMBERS: "VENUE_ALL_STAFF_MEMBERS",
  VENUE_UPDATE_STAFF_MEMBERS: "VENUE_UPDATE_STAFF_MEMBERS",

  // Staff
  STAFF_VENUE_MAPS: "STAFF_VENUE_MAPS",
  STAFF_MAP_DATA: "STAFF_MAP_DATA",
  SAVE_CUSTOMER_LINK: "SAVE_CUSTOMER_LINK",
  SAVE_CUSTOMER_INFO: "SAVE_CUSTOMER_INFOS",
  STAFF_ALL_ORDERS: "STAFF_ALL_ORDERS",
  STAFF_ALL_ORDERS_DETAILS: "STAFF_ALL_ORDERS_DETAILS",

  //  Vendor

  VENDOR_ALL_ORDERS: "VENDOR_ALL_ORDERS",
  VENDOR_ALL_ORDERS_DETAILS: "VENDOR_ALL_ORDERS_DETAILS",

  // Admin
  GET_VENUES: "GET_VENUES",
  GET_VENUE_DETAILS: "GET_VENUE_DETAILS",
  CREATE_VENUE: "CREATE_VENUE",
  GET_MAP_DETAILS_BY_VENUE: "GET_MAP_DETAILS_BY_VENUE",

  // Admin-Vendor
  GET_VENDORS: "GET_VENDORS",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_VENDOR_DETAILS: "GET_VENDOR_DETAILS",
  CREATE_VENDOR: "CREATE_VENDOR",

  // Admin Orders

  ADMIN_ALL_ORDERS: "ADMIN_ALL_ORDERS",
  ADMIN_ORDER_FILTERS: "ADMIN_ORDER_FILTERS",
  ADMIN_ALL_ORDERS_DETAILS: "ADMIN_ALL_ORDERS_DETAILS",
  IS_CONFIGURED_BEFORE: "IS_CONFIGURED_BEFORE",

  // Admin Customer

  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_CUSTOMER_DETAILS: "GET_CUSTOMER_DETAILS",

  // Common
  UPDATE_VENUE_VENDOR: "UPDATE_VENUE_VENDOR",
  API_CALL_STARTED: "API_CALL_STARTED",
  API_CALL_COMPLETED: "API_CALL_COMPLETED",
  API_CALL_FAILURE: "API_CALL_FAILURE",

  // Site
  GET_FAQ_CONTENT: "GET_FAQ_CONTENT",
  GET_ABOUTUS_CONTENT: "GET_ABOUTUS_CONTENT",
  GET_CONTACTUS_CONTENT: "GET_CONTACTUS_CONTENT",

  GET_DISCOUNT_COUPONS: "GET_DISCOUNT_COUPONS",
};
export const ENDPOINTS = {
  LOGIN: "/auth/login",
  SIGNUP: "/auth/signUp",
  CHECK_EMAIL_VALIDATE: "/auth/checkEmailValidate",
  CAPTCHA_VALIDATE: "/auth/verifyCaptcha",
  RESENT_VALIDATE_EMAIL: "/auth/resentValidationEmail",
  GET_ALL_ORDER: "/order/all",
  GET_CUSTOMER_ORDERS: "/customer/:customerId",
  RESET_PASSWORD: "/auth/resetPassword",
  MATCH_TOKEN: "/auth/matchToken",
  FORGOT_PASSWORD: "/auth/forgotPassword",
  SIGNUP_VENUE_VENDOR: "/auth/signUp-vendor-venue",
  LOGIN_VENUE_VENDOR: "/auth/login-vendor-venue",
};
