import React, { useEffect, useState } from "react";
import Header from "../../Authentication/Header";
import DetailsDesign from "./component/DetailsDesign";
import "./SeeVenue.css";
import NewDesign from "../NewDesign/index";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAction } from "../../../redux/actions/Index";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import Modal from "../../../components/modal/Modal";
import { useParams } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import { APPLICATION_CONSTANTS } from "../../../services/constants/Index";
import { Container } from "react-bootstrap";
import NewIcon1 from "../../../images/NewIcons/NewIcon1.png";
import NewIcon2 from "../../../images/NewIcons/NewIcon2.png";
import NewIcon3 from "../../../images/NewIcons/NewIcon3.png";
import {
  calculateCartItems,
  formatUSNumber,
} from "../../../services/utills/helper";
import { useQuery } from "../../../hooks/useQueryHook";
function SeeVenue(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  // const [venue, setVenue] = useState();
  const [show, setShow] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [cartData, setCartData] = useState();
  const { venueId } = props.match.params;
  const { cart, venueData } = useSelector((state) => state.CustomerReducer);
  const getQuery = useQuery();
  const orderId = getQuery.get("orderId");
  useEffect(() => {
    (async () => {
      if (venueId) {
        localStorage.setItem("venueId", true);
        console.log("cart adasd", cart);
        const venueCartItems = await calculateCartItems(cart, venueId, orderId);
        console.log("venue cartitems", venueCartItems);
        setCartData(venueCartItems);

        dispatch(CustomerAction.addToCart(venueCartItems));
        const response = await dispatch(CustomerAction.getVenueName(venueId));
        // setVenue(response?.data);
        dispatch(CustomerAction.getVenueId(venueId));

        if (orderId) {
          dispatch(CustomerAction.getOrderDetailsById(orderId));
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 500) {
      setShowMobile(true);
    }
  });
  return (
    <div>
      <Header type="type2" />

      <Container className="newCustomerCanvasWrapper">
        <div className="newCustomCanvasLeft">
          <div className="sec1">
            {/* <h1 className="textsec1">Venue: {venueName}</h1> */}
            <img
              className="newImage venuePage"
              src={venueData?.venueImage}
              alt="Hilton"
            />
            <img
              className="newLogo venuePage"
              src={venueData?.logo}
              alt="Hilton"
            />
            {/* <div className="horizontalLine" /> */}
            <div className="venueDetails align-items-start ml-3">
              <p
                style={{ textAlign: "left" }}
              >{`${venueData?.street}, ${venueData?.city} ${venueData?.state} ${venueData?.zip}`}</p>

              <p>T : {formatUSNumber(venueData?.phone)}</p>
              <p>E : {venueData?.email}</p>
            </div>
          </div>
          <div className="details newDetails">
            <h3
              className="text2sec1 howit text-center"
              onClick={() => setShow((prev) => !prev)}
            >
              How it Works?
            </h3>
            <div className={show ? "detailBox" : "hideDetailBox"}>
              <DetailsDesign
                url={NewIcon1}
                text1="1. Click on a location icon"
              />
              <DetailsDesign
                icon={<ShoppingCartIcon />}
                text1=" 2. Add to cart where desired"
              />
              <DetailsDesign url={NewIcon3} text1="3. View cart to checkout" />{" "}
              <DetailsDesign url={NewIcon2} text1=" 4. Complete checkout" />
            </div>
          </div>
        </div>

        {showMobile && (
          <div className={show ? " showinMobile" : "hideDetailBox"}>
            <DetailsDesign url={NewIcon1} text1="1. Click on a location icon" />
            <DetailsDesign
              icon={<ShoppingCartIcon />}
              text1=" 2. Add to cart where desired"
            />
            <DetailsDesign url={NewIcon3} text1="3. View cart to checkout" />{" "}
            <DetailsDesign url={NewIcon2} text1=" 4. Complete checkout" />
          </div>
        )}
        {venueData.maps && venueData.maps.length > 0
          ? venueData.maps
              // .slice(2, 3)
              .map((map) => (
                <div
                  className="customercanvas newCustomerCanvas"
                  key={map.mapId}
                  style={{
                    paddingBottom: 20,
                    paddingTop: 10,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <NewDesign map={map} orderId={orderId} />
                </div>
              ))
          : null}
        <hr style={{ borderTopWidth: 1, width: "100%" }}></hr>
        <p style={{ textAlign: "center", margin: 0 }}>
          Please complete the contact form or call/text us:
        </p>
        <p style={{ textAlign: "center", marginTop: 0 }}>
          (209) 32-BRAND, (209) 322-7263
        </p>
        {/* <p style={{ textAlign: "center" }}>(209) 322-7263</p> */}
      </Container>
      <Modal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}

export default SeeVenue;
