import React, { useEffect, useState } from "react";
import { CardComponent } from "../../../components/card/Card";
import SideNav from "../../../components/sideNav/SideNav";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { CustomerAction } from "../../../redux/actions/Index";
import "./MyProfile.css";
import Button from "../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../../components/AlertDialog/alertDialog";
import Toast from "../../../components/toast/Toast";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import states from "../../Story/CustomerInformation/data.json";
import PhoneInput from "react-phone-input-2";
import { formatUSNumber } from "../../../services/utills/helper";

const MyProfile = () => {
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState();
  const [openn, setOpenn] = useState(false);
  const [enable, setEnable] = useState(true);
  const [toast, setToast] = useState(false);

  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const profile = useSelector((state) => state.CustomerReducer.profile);
  console.log("profile", profile);
  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        await dispatch(CustomerAction.getProfile(user._id, user.email));
      }
    })();
  }, [user?._id, userUpdated]);

  useEffect(() => {
    setProfileData(profile);
  }, [profile]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const updateProfile = () => {
    setEnable(true);
    setToast(true);
    dispatch(
      CustomerAction.updateProfile({
        userId: user._id,
        updateBody: profileData,
        callback: () => {
          setEnable(true);
        },
      })
    );
    setTimeout(() => {
      setToast(false);
    }, 4000);
  };

  return (
    <div className="myProfilePage">
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="My Profile " />
        </div>
      </div>

      <div className="myProfileTopBtn">
        <Button
          // className="col-lg-3"
          background="primary"
          color="#fff"
          name="Edit Profile"
          handleClick={() => setEnable(!enable)}
        ></Button>
      </div>
      <div className="myProfile">
        <div className="myProfileDiv">
          <form className="ProfileInfoForm">
            <div className="rowFormInput">
              <div className="formInput col-lg-6 ">
                <label htmlFor="firstName">First name</label>
                <input
                  disabled={enable}
                  id="firstName"
                  type="text"
                  value={profileData?.firstName}
                  name="firstName"
                  placeholder="First name"
                  onChange={handleChange}
                />
              </div>
              <AlertDialog
                open={openn}
                handleClose={() => setOpenn(false)}
                message="Are you sure you want to update profile?"
                button1="Yes"
                button2="No"
                handleDelete={updateProfile}
              />
              <div className="formInput col-lg-6">
                <label htmlFor="LastName">Last name</label>
                <input
                  disabled={enable}
                  id="LastName"
                  type="text"
                  value={profileData?.lastName}
                  name="lastName"
                  placeholder="Last name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="rowFormInput">
              <div className="formInput col-lg-6">
                <label htmlFor="email">Email</label>
                <input
                  disabled={enable}
                  id="email"
                  type="email"
                  value={profileData?.email}
                  name="email"
                  placeholder="email"
                  onChange={handleChange}
                  readOnly="true"
                />
              </div>
              <div className="formInput col-lg-6">
                <label htmlFor="Phone">Phone</label>
                <input
                  disabled={enable}
                  id="Phone"
                  type="text"
                  value={formatUSNumber(profileData?.phone)}
                  name="phone"
                  placeholder="phone"
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <div className="formInput col-lg-12">
              <label htmlFor="Companyname">Company name</label>
              <input
                  disabled={enable}
                id="Companyname"
                type="text"
                value={profileData?.companyName}
                name="companyName"
                placeholder="Company name"
                onChange={handleChange}
              />
            </div> */}
            {/* <div className="formInput col-lg-12">
              <label htmlFor="CountryRegion">County / Region</label>
              <input
                disabled={enable}
                id="CountryRegion"
                type="text"
                value={profileData?.country}
                name="country"
                placeholder="County"
                onChange={handleChange}
              />
            </div> */}
            <div className="formInput col-lg-12">
              <label htmlFor="StreetAddress">Street address</label>
              <input
                disabled={enable}
                id="StreetAddress"
                value={profileData?.Street}
                type="text"
                name="Street"
                placeholder="Street address"
                onChange={handleChange}
              />
            </div>
            <div className="rowFormInput">
              <div className="formInput col-lg-4">
                <label htmlFor="City">City</label>
                <input
                  disabled={enable}
                  id="City"
                  type="text"
                  value={profileData?.city}
                  name="city"
                  placeholder="City"
                  onChange={handleChange}
                />
              </div>
              <div className="formInput col-lg-4">
                <label htmlFor="State">State</label>
                {/* <input
                  disabled={enable}
                  id="State"
                  type="text"
                  value={profileData?.state}
                  name="state"
                  placeholder="State"
                  onChange={handleChange}
                /> */}
                <select
                  value={profileData?.state}
                  disabled={enable}
                  id="State"
                  style={{
                    padding: "10px 10px",
                    height: "40px",
                    border: "1px solid",
                  }}
                  name="state"
                  className="form-control"
                  onChange={handleChange}
                >
                  <option selected>Select State </option>
                  {states &&
                    states.map((data) => {
                      return <option value={data.Code}>{data.Code}</option>;
                    })}
                </select>
              </div>
              <div className="formInput col-lg-4">
                <label htmlFor="ZipCode">Zip Code</label>
                <input
                  disabled={enable}
                  id="ZipCode"
                  type="text"
                  value={profileData?.zipCode}
                  name="zipCode"
                  placeholder="Zip Code"
                  onChange={handleChange}
                />
              </div>
            </div>
            {!enable ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  // className="col-lg-3"
                  background="primary"
                  color="#fff"
                  name="Save"
                  handleClick={() => {
                    updateProfile();
                  }}
                ></Button>
              </div>
            ) : null}
            {toast ? (
              <Toast
                open={toast}
                message="Profile Updated Successfully!!!"
                duration={5000}
                type="Success"
                handleClose={() =>
                  setToast({
                    ...toast,
                    open: false,
                  })
                }
              ></Toast>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
