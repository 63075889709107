import React from "react";
import { formatUSNumber } from "../../../services/utills/helper";

const index = ({ orderDetails }) => {
  return (
    <div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>First Name</p>
          <p>{orderDetails?.vendorId?.firstName} </p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Last Name</p>
          <p>{orderDetails?.vendorId?.lastName}</p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>ID</p>
          <p> {orderDetails?.vendorId?.vendorId} </p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Email</p>
          <p>{orderDetails?.vendorId?.email} </p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>Phone</p>
          <p>{formatUSNumber(orderDetails?.vendorId?.phone)} </p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Brand</p>
          <p>
            {orderDetails?.vendorId?.categories?.map((category) => {
              if (category.split("/")[1] === "Brand")
                return category.split("/")[2];
            })}
          </p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>Management</p>
          <p>
            {orderDetails?.vendorId?.categories?.map((category) => {
              if (category.split("/")[1] === "Management")
                return category.split("/")[2];
            })}{" "}
          </p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Contract</p>
          <p>
            {orderDetails?.vendorId?.categories?.map((category) => {
              if (category.split("/")[1] === "Contract")
                return category.split("/")[2];
            })}
          </p>
        </div>
      </div>
      {/* <div className="venueDetailsAccordian"> */}
      {/* <div className="venueDetailsAccordianLeftNew">
					<p>ZIP</p>
					<p>{orderDetails?.vendorId?.zip} </p>
				</div> */}
      {/* <div className="venueDetailsAccordianRightNew">
          <p>Country</p>
          <p>{orderDetails?.vendorId?.country} </p>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default index;
