import React, { useState, useEffect } from "react";
import Header from "../../Authentication/Header/index";
import { useDispatch, useSelector } from "react-redux";
import {
  APPLICATION_CONSTANTS,
  SITE_KEY,
} from "../../../services/constants/Index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./Checkout.css";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CardInfo from "./CardInfo/CardInfo";
import Footer from "../../../components/Footer/Footer";
import { CustomerAction } from "../../../redux/actions/Index";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../../services/constants/Index";
import Button from "../../../components/button/Button";
import InputField from "../../../components/input/Input";
import { ValidateLoginForm } from "../../../Pages/Authentication/Login/Validation";
import { AuthAction } from "../../../redux/actions/Index";
import { calculateCartItems } from "../../../services/utills/helper";
import ReCAPTCHA from "react-google-recaptcha";
import { useQuery } from "../../../hooks/useQueryHook";

const Checkout = () => {
  const [state, setState] = useState();
  const [venueName, setVenueName] = useState();
  const [isShowPassword, setPasswordType] = useState(false);
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = React.useState();
  const [cartData, setCartData] = useState();
  const [botCheck, setBotCheck] = React.useState({
    human: false,
    humanKey: null,
  });
  const [isDisabled, setDisabled] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const { cart, venueId } = useSelector((state) => state.CustomerReducer);

  const { loadingState } = useSelector((state) => state.CommonReducer);
  const query = useQuery();
  const orderId = query.get("orderId");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  async function onSubmit(event) {
    event.preventDefault();
    // if (isDisabled) return;

    const result = await ValidateLoginForm(state);

    if (result?.isFormValid) {
      console.log("in true", state);
      setError({});
      const status = await dispatch(AuthAction.login(state));
      console.log("status", status);
      if (status && status.data && status.data.role)
        if (orderId) history.push(`${ROUTES.CUSTOMER_INFO}?orderId=${orderId}`);
        else history.push(ROUTES.CUSTOMER_INFO);
    } else if (result?.isFormValid === false) {
      setError(result?.error);
      return;
    }
    setToast(true);
  }

  const handleLoginCheckout = () => {
    history.push({
      pathname: ROUTES.LOGIN,
      state: {
        previousPath: "Cart",
      },
    });
  };

  const handleSignUpCheckout = () => {
    history.push({
      pathname: ROUTES.REGISTER,
      search: `?orderId=${orderId}`,
      state: {
        previousPath: "Cart",
      },
    });
  };

  const handleGuestCheckout = () => {
    if (orderId)
      history.push({
        pathname: ROUTES.CUSTOMER_INFO,
        search: `?orderId=${orderId}`,
        state: {
          previousPath: "geustUser",
        },
      });
    else
      history.push({
        pathname: ROUTES.CUSTOMER_INFO,
        state: {
          previousPath: "geustUser",
        },
      });
  };

  const onChange = async (res) => {
    if (res) {
      console.log("captcha status", res);

      // this.setState({ human: true, humanKey: res })
      setBotCheck({
        ...botCheck,
        human: true,
        humanKey: res,
      });
      setDisabled(false);
      const status = await dispatch(AuthAction.verifyCaptcha(res));

      // this.setState({ disabled: this.isDisabled() })
    }
  };

  // ReCAPTCHA Expired
  const expireCaptcha = () => {
    // this.setState({ human: false, humanKey: null })
    setBotCheck({
      ...botCheck,
      human: false,
      humanKey: null,
    });
    setDisabled(true);
    // this.setState({ disabled: this.isDisabled() })
  };

  return (
    <div>
      <Header />

      <div className="infopage">
        <div className="backarrow ml-5 d-flex">
          <div className="backIcon mr-2" onClick={() => history.goBack()}>
            <ArrowBackIcon className="icon" />
          </div>
          Cart
        </div>
        <div className="checkoutDiv">
          <div className="CheckoutOrder">
            <div className="checkoutDiv__inner">
              <div className="checkoutDiv__left">
                <h3>New Customer</h3>
                <p>You can continue as guest or you can register yourself.</p>
                <div className="checkoutDiv__left__ButtonWrapper">
                  <Button
                    background="primary"
                    color="tertiary"
                    name="Continue as Guest"
                    handleClick={handleGuestCheckout}

                    // handleClick={onSubmit}
                    // loading={loading}
                  />{" "}
                  <strong> or </strong>
                  <Button
                    background="primary"
                    color="tertiary"
                    name="Register as a new user"
                    handleClick={handleSignUpCheckout}
                    // handleClick={onSubmit}
                    // loading={loading}
                  />
                </div>
              </div>
              <div className="checkoutDiv__seperator" />
              <div className="checkoutDiv__right">
                <h3>Returning Customer</h3>
                <p>
                  Sign in to speed up the checkout process and save the order to
                  your account.
                </p>
                <div className="checkoutDiv__right__signIn">
                  {/* <label htmlFor="email">E-Mail</label> */}
                  <InputField
                    label="E-Mail"
                    type="text"
                    id="custom-css-outlined-input"
                    name="email"
                    className="my-2"
                    value={state?.email}
                    handleChange={handleChange}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.email[0]}
                  </p>
                  {/* <label htmlFor="email">Password</label> */}
                  <div className="checkoutDiv__right__signInPassword">
                    <InputField
                      label="Password"
                      type={isShowPassword == true ? "text" : "password"}
                      id="custom-css-outlined-input"
                      name="password"
                      value={state?.password}
                      handleChange={handleChange}
                    />
                    {isShowPassword === true ? (
                      <a
                        className="eyeicon"
                        onClick={() => setPasswordType(false)}
                      >
                        <VisibilityIcon />
                      </a>
                    ) : (
                      <a
                        className="eyeicon"
                        onClick={() => setPasswordType(true)}
                      >
                        <VisibilityOffIcon />
                      </a>
                    )}
                    <p className="errorMsg">
                      {" "}
                      {errorData?.password && errorData.password[0]}
                    </p>
                  </div>
                  <div className="mb-2">
                    <ReCAPTCHA
                      sitekey={SITE_KEY}
                      onChange={onChange}
                      onExpired={expireCaptcha}
                    />
                  </div>
                  <div className="checkoutDiv__signIn__options">
                    <div>
                      <input
                        type="checkbox"
                        // onChange={(e) => setChecked(!checked)}
                      />
                      <label className="pt-1 mt-1 ml-1">Remember Me</label>
                    </div>
                    <Link to={ROUTES.FORGOT_PASSWORD}>
                      <h6 className="pt-1 mt-1">Forgot password?</h6>
                    </Link>
                  </div>
                  <div className="checkoutDiv__login__button">
                    <Button
                      background="primary"
                      color="tertiary"
                      name="Login"
                      // disabled={isDisabled}
                      handleClick={onSubmit}
                      loading={loadingState}
                    />
                  </div>
                  <p>
                    By signing in, you are agreeing to our Terms of Use and
                    Privacy Policy.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

// <div className="CheckoutOrder">
// <h3>Your Order</h3>
// <div className="productOrderDetail">
//   <div className="productInfo">
//     <h4>Venue name:</h4>
//     <p>{venueName}</p>
//   </div>
//   <div className="productInfo">
//     <h4>Ad-Space:</h4>
//     <p>{adspaces}</p>
//   </div>
//   <div className="productInfo">
//     <h4>Price:</h4>
//     <p>${totalAmount} </p>
//   </div>
// </div>
// </div>
// <div className="headCart  d-flex text-center ">
//           <span className="hrline">
//             <hr />
//           </span>
//           Checkout
//           <span className="hrline">
//             <hr />
//           </span>
//         </div>
