function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

function isNull(str) {
  return str === "null";
}

export const ValidateCustomerForm = (formData) => {
  const {
    firstName,

    email,
    phone,
    Street,
    city,
    state,
    zipCode,
    country,
  } = formData || {};
  let error = {
    firstName: [],

    email: [],
    phone: [],
    Street: [],
    city: [],
    state: [],
    zipCode: [],
    country: [],
  };
  let isFormValid = true;

  console.log("phone length", phone?.length);
  console.log("zip", zipCode);
  if (isBlank(firstName) === true) {
    isFormValid = false;
    error.firstName = ["This field is required."];
  }

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  }

  if (isBlank(phone) === true) {
    isFormValid = false;
    error.phone = ["This field is required."];
  } else if (isNumber(phone) !== true) {
    isFormValid = false;
    error.phone = ["This field should be number."];
  } else if (phone?.length < 11) {
    isFormValid = false;
    error.phone = ["Please enter a valid phone number."];
  }

  if (isBlank(Street) === true) {
    isFormValid = false;
    error.Street = ["This field is required."];
  }
  if (isBlank(city) === true) {
    isFormValid = false;
    error.city = ["This field is required."];
  }
  if (isBlank(state) === true) {
    isFormValid = false;
    error.state = ["This field is required."];
  }
  if (isBlank(zipCode) === true) {
    isFormValid = false;
    error.zipCode = ["This field is required."];
  }

  // if (isBlank(country) === true) {
  //   isFormValid = false;
  //   error.country = ["This field is required."];
  // }

  return { isFormValid, error };
};
