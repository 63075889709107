import axios from "axios";
import { useDispatch } from "react-redux";
import { handleError } from "../../redux/actions/CommonAction";
import { BASE_URL } from "../constants/Index";

const axiosInstance = axios.create({ baseURL: BASE_URL });

const errHandling = (error) => {
  console.log("error in axios", error, error.response);
  // dispatch(handleError(error));
  // handleError(error);
  return Promise.reject(error);
};

const reqHandling = (config) => {
  const obj = { ...config };
  const token = localStorage.getItem("token");

  if (token) obj.headers["Authorization"] = token;
  return obj;
};

const resHandling = (response) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
