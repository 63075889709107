import React, { useState } from "react";
import InputField from "../input/Input";

import "./styles.css";
import PhoneInput from "react-phone-input-2";

const FormSection = ({ state, handleChange, errorData, setState, states }) => {
  console.log("state", state);
  const [phone, setPhone] = useState();

  return (
    <div>
      <div className="d-flex flex-row row">
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <InputField
            label="First Name"
            variant="outlined"
            type="text"
            readOnly={false}
            id="custom-css-outlined-input"
            name="firstName"
            value={state?.firstName}
            handleChange={handleChange}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.firstName && errorData.firstName[0]}
          </p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <InputField
            className=""
            label="Last Name"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="lastName"
            value={state?.lastName}
            handleChange={handleChange}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.lastName && errorData.lastName[0]}
          </p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <InputField
            label="E-Mail"
            variant="outlined"
            type="email"
            id="custom-css-outlined-input"
            name="email"
            value={state?.email}
            handleChange={handleChange}
          />
          <p className="errorMsg"> {errorData?.email && errorData.email[0]}</p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          {/* <InputField
            label="Phone"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="phone"
            value={state?.phone}
            handleChange={handleChange}
          /> */}
          <PhoneInput
            country={"us"}
            onlyCountries={["us"]}
            value={state?.phone}
            placeholder="Enter venue phone"
            inputClass="phoneInput"
            buttonClass="phoneButton"
            dropdownClass="dropdownClass"
            disableDropdown="true"
            onChange={(phone) => {
              let target = {
                name: "phone",
                value: phone,
              };
              let phoneE = {
                target,
              };
              handleChange(phoneE);
            }}
          />
          <p className="errorMsg"> {errorData?.phone && errorData.phone[0]}</p>
        </div>

        <div className="customerInfoInput d-flex flex-column col-md-12 col-lg-12">
          <InputField
            label="Street address"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="Street"
            value={state?.Street}
            handleChange={handleChange}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.Street && errorData.Street[0]}
          </p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-4">
          <InputField
            label="City"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="city"
            value={state?.city}
            handleChange={handleChange}
          />
          <p className="errorMsg"> {errorData?.city && errorData.city[0]}</p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-4">
          <select
            value={state?.state}
            style={{
              padding: "15px 10px",
              height: "50px",
              border: "1px solid",
            }}
            name="state"
            className="form-control"
            onChange={handleChange}
          >
            <option selected>Select State </option>
            {states &&
              states.map((data) => {
                return <option value={data.Code}>{data.Code}</option>;
              })}
          </select>
          <p className="errorMsg"> {errorData?.state && errorData.state[0]}</p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-4">
          <InputField
            label="Zip Code"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="zipCode"
            value={state?.zipCode}
            handleChange={handleChange}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.zipCode && errorData.zipCode[0]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FormSection;
