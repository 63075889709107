import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import "./style.css";
import Button from "../../button/Button";
import PhoneInput from "react-phone-input-2";
import { Divider } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { validateOnAddStaffMember } from "./util";
import { useDispatch } from "react-redux";
import { VenueAction } from "../../../redux/actions/Index";

const AddDepartment = (props) => {
  const [state, setState] = useState({ isPermitted: true });
  const [errorData, setErrorData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    department: "",
  });

  const dispatch = useDispatch();
  const { user, editStaff } = props;
  const handleChange = (e, type) => {
    const { name, value } = e.target;
    // console.log("ONCHANGE", value, type, type === 'option')

    setState((prev) => ({ ...prev, [name]: value }));
    if (type) {
      setState((prev) => ({ ...prev, [name]: e.target.checked }));
    }
  };

  useEffect(() => {
    console.log("edit staff", editStaff);
    if (!editStaff) {
      return;
    }

    setState(editStaff);
  }, [editStaff]);

  useEffect(() => {
    console.log("state change staff modal", state);
    if (state && state.firstName) {
      setErrorData((prevState) => ({ ...prevState, firstName: "" }));
    }
    if (state && state.email) {
      setErrorData((prevState) => ({ ...prevState, email: "" }));
    }
    if (state && state.department) {
      setErrorData((prevState) => ({ ...prevState, department: "" }));
    }
  }, [state]);

  const onAddStaffMember = async (e) => {
    const { error, formError } = validateOnAddStaffMember(state);
    setErrorData((prevError) => ({ ...prevError, ...formError }));
    if (error === 0) {
      if (!editStaff) {
        dispatch(
          VenueAction.addStaffMemberAction({
            payload: state,
            venueId: user._id,
          })
        );
      }
      // else if (editStaff) {
      //   console.log(
      //     "🚀 ~ file: adddbrand.js ~ line 67 ~ onEditStaffMember ~ editStaff",
      //     editStaff,
      //     state
      //   );

      //   // dispatch(
      //   //   VenueAction.updateStaffMember({
      //   //     staffId: editStaff._id,
      //   //     updateBody: {
      //   //       // isActive: !item.isActive,
      //   //     },
      //   //   })
      //   // );
      // }
      props.handleClose();
    }
  };

  return (
    <div className="addVendorWrapper">
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className="adSpaceModal"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div className="adSpacePopUp">
            <h1>{!editStaff ? "Add " : "Edit "}Staff Member</h1>
          </div>
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <Divider />
          <div className="d-flex">
            <div className="addSpaceInputs adSpace">
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">First Name</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  <input
                    label="First Name"
                    type="text"
                    id="custom-css-outlined-input"
                    name="firstName"
                    className="my-2"
                    value={state?.firstName || ""}
                    onChange={handleChange}
                    style={{ width: "80%" }}
                  />
                  {errorData?.firstName ? (
                    <p className="errorMsg"> {errorData.firstName}</p>
                  ) : null}
                </div>
              </div>
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">Last Name</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  <input
                    label="Last Name"
                    type="text"
                    id="custom-css-outlined-input"
                    name="lastName"
                    className="my-2"
                    // value={state}
                    value={state?.lastName || ""}
                    onChange={handleChange}
                    style={{ width: "80%" }}
                  />
                  {errorData?.lastName ? (
                    <p className="errorMsg"> {errorData.lastName}</p>
                  ) : null}
                </div>
              </div>
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">Email</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  <input
                    label="Email"
                    type="email"
                    id="custom-css-outlined-input"
                    name="email"
                    className="my-2"
                    // value={state}
                    value={state?.email || ""}
                    onChange={handleChange}
                    style={{ width: "80%" }}
                  />
                  {errorData?.email ? (
                    <p className="errorMsg"> {errorData.email}</p>
                  ) : null}
                </div>
              </div>

              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">Phone</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  <PhoneInput
                    country={"us"}
                    className="my-2"
                    onlyCountries={["us"]}
                    value={state?.phone}
                    inputStyle={{ width: "80%" }}
                    placeholder="Enter contact phone"
                    // inputClass="phoneInputSmall"
                    // buttonClass="phoneButton"
                    dropdownClass="dropdownClass"
                    disableDropdown="true"
                    onChange={(phone) => {
                      let target = {
                        name: "phone",
                        value: phone,
                      };
                      let phoneE = {
                        target,
                      };
                      handleChange(phoneE);
                    }}
                  />
                  {errorData?.phone ? (
                    <p className="errorMsg"> {errorData.phone}</p>
                  ) : null}
                </div>
              </div>

              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft2">Department</div>
                <div
                  className="addSpaceInputsRight"
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  <select
                    // value={state?.state}
                    value={state?.department || ""}
                    style={{
                      padding: "5px 10px",
                      height: "35px",
                      border: "1px solid",
                    }}
                    name="department"
                    defaultValue={""}
                    className="form-control "
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Department </option>
                    {props?.departments &&
                      props?.departments.length > 0 &&
                      props?.departments.map((data) => {
                        return <option value={data.title}>{data.title}</option>;
                      })}
                  </select>
                  {errorData?.department ? (
                    <p className="errorMsg"> {errorData.department}</p>
                  ) : null}
                </div>
              </div>

              <div>
                <Checkbox
                  checked={state?.isPermitted}
                  name="isPermitted"
                  onChange={(e) => handleChange(e, true)}
                  inputProps={{ "aria-label": "controlled" }}
                />{" "}
                <span>Is permitted to approve order?</span>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="adspacebuttonGroup">
          <Button
            background="secondary" // two types
            fontSize="15px"
            border="1px solid #fff"
            // disabled={item[1] == "Accepted" ? false : true}
            color="secondary" //two types
            name="Submit "
            loading={props.loading}
            handleClick={(e) => {
              onAddStaffMember(e);
            }}
          />
          <Button
            background="secondary"
            fontSize="15px"
            border="1px solid #fff"
            color="secondary" //two types
            name="Cancel"
            handleClick={() => {
              props.handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AddDepartment;
