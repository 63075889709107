import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import { ROUTES } from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAction } from "../../../redux/actions/Index";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./styles.css";
import Button from "../..//button/Button";
import { LensTwoTone } from "@material-ui/icons";
import { withRouter } from "react-router";
import { formatUsCurrency } from "../../../services/utills/helper";

const FormDialog = ({ adSpaceData, id, open, handleClose }) => {
	const [data, setdata] = useState({});

	console.log("props", adSpaceData, id);

	useEffect(() => {
		adSpaceData?.map((item) => {
			if (item._id === id) {
				setdata(item["adspace"]);
			}
		});
	}, [id]);

	console.log("DATA", data);

	return (
		<div>
			<Dialog
				open={open}
				className="details"
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title " className="text-center model-head">
					<div className="modelIcon2">
						<IconButton
							type="submit"
							className="adminSchoolsearchIcon"
							aria-label="search"
						>
							<CancelIcon onClick={handleClose} />
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent className="box ">
					<div className="left">
						<img src={data?.url} />
						{/* <img src={testImage} /> */}
					</div>
					<div className="modalRight mybox flex-column  ">
						<p
							style={{
								fontFamily: "Whitney Semibold",
								fontSize: "22px",
								marginBottom: "0",
								fontWeight: "800",
							}}
						>
							{data?.title}
						</p>
						<span style={{ fontFamily: "Whitney Book" }}>{data?.info}</span>
						{/* <h5 className="mb-3">
									<strong> {"Name "}:</strong>
									{data?.title || data?.name}
								</h5> */}
						<h5
							className="mb-3"
							style={{
								fontFamily: "Whitney Semibold",
								fontFamily: "Whitney Book",
							}}
						>
							<strong
								style={{
									fontSize: "18px",
									fontFamily: "Whitney Semibold",
									marginBottom: "0",
									fontWeight: "800",
								}}
							>
								{"Item ID :"}
							</strong>
							{/* <strong>
										</strong>{" "} */}
							<span
								style={{
									fontFamily: "Whitney Book",
									fontSize: "18px",
								}}
							>
								{" "}
								{data?.itemId}
							</span>
						</h5>
						<h5
							className="mb-3"
							style={{
								fontFamily: "Whitney Semibold",
								fontFamily: "Whitney Book",
							}}
						>
							<strong
								style={{
									fontSize: "18px",
									fontFamily: "Whitney Semibold",
									marginBottom: "0",
									fontWeight: "800",
								}}
							>
								{"Package : "}
							</strong>
							{/* <strong>
										</strong>{" "} */}
							<span
								style={{
									fontFamily: "Whitney Book",
									fontSize: "18px",
								}}
							>
								{" "}
								{data?.description}
							</span>
						</h5>

						<h5
							className="mt-2"
							style={{
								fontFamily: "Whitney Semibold",
								marginBottom: "0px",
							}}
						>
							<strong
								style={{
									fontSize: "18px",
									fontFamily: "Whitney Semibold",
									marginBottom: "0",
									fontWeight: "800",
								}}
							>
								Price:
							</strong>
							{/* <strong>
										</strong>{" "} */}
							<span
								style={{
									fontFamily: "Whitney Book",
									fontSize: "18px",
								}}
							>
								{" "}
								{/* ${Number(data?.price).toFixed(2)}{" "} */}
								{formatUsCurrency(data?.price)}
							</span>
						</h5>
						<span>Includes graphics support, print and install</span>

						{/* {type ===} */}
						{/* <div className="buttonM mt-5">
                
                    <Button
                      background="primary"
                      color="tertiary"
                      name="Add to Cart"
                      handleClick={handleAddToCart}
                    />
               
                </div> */}
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};
export default withRouter(FormDialog);

// const handleAddToCart = async () => {
//   // console.log("data :>> ", data);
//   let body = {
//     customerId: user._id ? user._id : null,
//     venueId,
//     price: data.price,
//     quantity: 1,
//     info: data.info,
//     url: data.url,
//     name: data.title,
//     id: data._id,
//     description: data?.description,
//     adSpaceId: data?._id,
//   };
//   let finalData;
//   if (cart?.length > 0) {
//     finalData = [...cart, body];
//   } else {
//     finalData = [body];
//   }

//   await dispatch(CustomerAction.addToCart(finalData));
//   // history.push({
//   //   pathname: ROUTES.CART,
//   // });
// };
