import React, { useState } from "react";
import Button from "../../../components/button/Button";
import { ValidateLoginForm } from "./Validation";
import "./style.css";
import InputField from "../../../components/input/Input";
import Header from "../Header";
import Toast from "../../../components/toast/Toast";
import { AuthAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { APPLICATION_CONSTANTS } from "../../../services/constants/Index";

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();

  const [state, setState] = useState();
  const [toast, setToast] = useState();
  const [msg, setMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = React.useState();
  const [role, setRole] = React.useState(APPLICATION_CONSTANTS.VENUE_ROLE);
  const { loadingState } = useSelector((state) => state.CommonReducer);

  const handleChange = (e) => {
    console.log("Values", e.target.name, e.target.value);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  async function onSubmit(event) {
    event.preventDefault();
    const result = await ValidateLoginForm(state);

    if (result?.isFormValid) {
      setError({});
      console.log("vvvvv11", state);

      const status = await dispatch(
        AuthAction.forgotPassword({
          ...state,
          type: type === "user" ? type : role,
        })
      );
      console.log("888888888888888 STATUS 888888888888888", status);
      //   setToastMessage(status.message);
      //   setToastError(status.status);
    } else if (result?.isFormValid === false) {
      setError(result?.error);
      console.log("vvvvv2", result?.error);
      return;
    }
    // setToast(true);
    console.log("vvvvv3", state);
  }

  // console.log(history?.goBack());

  return (
    <div className="AuthBg">
      <Header type="type1" />
      <div className="ForgotPassword">
        <div className="ForgotPasswordFormDiv ForgotPasswordmain">
          <div className="formDiv">
            <h1 className="ForgotPasswordHeading">Forgot Password</h1>
            {type === "venue-vendor" ? (
              <div className="setUser d-flex mb-3">
                <button
                  className={
                    role === APPLICATION_CONSTANTS.VENUE_ROLE
                      ? "roleLoginVenueButton active"
                      : "roleLoginVenueButton"
                  }
                  name="Venue"
                  onClick={() => setRole(APPLICATION_CONSTANTS.VENUE_ROLE)}
                >
                  Venue
                </button>
                <button
                  name="Vendor"
                  className={
                    role === APPLICATION_CONSTANTS.VENDOR_ROLE
                      ? "roleLoginVendorButton active"
                      : "roleLoginVendorButton"
                  }
                  onClick={() => setRole(APPLICATION_CONSTANTS.VENDOR_ROLE)}
                >
                  Vendor
                </button>
              </div>
            ) : null}
            <div className="randomName">
              <form
                className="ForgotPasswordForm"
                noValidate
                autoComplete="off"
              >
                <div className="ForgotPasswordEmail">
                  <InputField
                    label="E-Mail"
                    type="text"
                    id="custom-css-outlined-input"
                    name="email"
                    value={state?.email}
                    handleChange={handleChange}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.email[0]}
                  </p>
                </div>
                {/* <div className="detail-key">
									<div className="addVenueAddDataRight changeMaxWidth">
										<select
											// value={categoryList["Brand"]}
											style={{
												padding: "15px 10px",
												height: "50px",
												border: "1px solid",
											}}
											name="type"
											value={state?.type}
											className="form-control "
											onChange={handleChange}
										>
											<option value="" selected disabled>
												Role
											</option>
											<option value="venue">Venue</option>
											<option value="vendor">Vendor</option>
										</select>
									</div>
								</div> */}
                <div className="signInButton">
                  <Button
                    background="primary"
                    color="tertiary"
                    name="Send"
                    handleClick={onSubmit}
                    loading={loadingState}
                  />
                </div>
              </form>
            </div>
            {toast ? (
              <Toast
                open={toast}
                message={msg} //{toast.message}
                duration={10000}
                type="success"
                handleClose={() => setToast(false)}
              ></Toast>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
