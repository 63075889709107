import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router";
import HearderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import { ArrowBackSharp } from "@material-ui/icons";

import Button from "../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, VenueAction } from "../../../redux/actions/Index";

import {
  AdSpaceDetailModal,
  CustomerDetailModal,
  VendorDetailModal,
  VenueDetailModal,
} from "../../../components/ObjectModal";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import AdSpaceModal from "../../Story/modal/modal";
import { Typography } from "@material-ui/core";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { styled } from "@mui/material/styles";
import {
  AdSpaceAccordian,
  CustomerDetailAccordian,
  VendorDetailAccordian,
  VenueDetailAccordian,
} from "../../../components/ObjectAccordian";
import moment from "moment";
import { formatUsCurrency } from "../../../services/utills/helper";
import AddDiscount from "../../../components/Modals/AddDiscount";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "7px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
  margin: "12px 2px",
  padding: "8px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownwardIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255,0.5)"
      : // : "rgba(0, 0, 0, .03)",
        "rgba(255,255,255)",
  flexDirection: "row",
  // color: theme.palette.mode === "dark" ? "rgba(0,0,0)" : "red",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    color: "var(--main-bg-color)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "var(--main-bg-color)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    width: "70px",
    justifyContent: "space-between",
    margin: "20px auto 20px 20px",
  },
});

const VenueOrderDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [expanded, setExpanded] = React.useState({});

  const handleChange = (panel) => {
    setExpanded({
      ...expanded,
      [panel]: !expanded[panel] || false,
    });
  };

  const { orderDetails } = useSelector((state) => state.VenueReducer);
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);
  const { loadingState } = useSelector((state) => state.CommonReducer);

  const { orderId } = useParams();

  useEffect(() => {
    if (user._id && userUpdated)
      dispatch(VenueAction.getVenueOrderDetails(orderId));
  }, [user, userUpdated]);

  console.log("orderDetails", orderDetails);

  const handleAcceptRejectOrder = async (status) => {
    console.log("status :>> ", status);
    dispatch(
      VenueAction.orderAcceptReject(
        {
          status,
          orderId: orderDetails["_id"],
        },
        orderDetails,
        user._id
      )
    );
    // props.history.push("/order-management");
  };
  const [open, setOpen] = useState(false);
  const [openCustomerModal, setCustomerModal] = useState(false);
  const [detailsModel, setDetailsModel] = useState(false);
  const [openVendorModal, setVendorModal] = useState(false);

  const [id, setId] = useState();
  const [cart, setCart] = useState([]);

  const [openDiscountModal, setOpenDiscountModal] = useState(false);

  const [discountForm, setDiscountForm] = useState({
    discountType: "",
    discount: "",
  });

  const [discountModalErrorData, setDiscountModaErrorData] = useState({
    discountType: "",
    discount: "",
  });

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscountModalChange = (e) => {
    setDiscountForm({
      ...discountForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleDiscountModalSave = async () => {
    console.log("discountForm", discountForm);

    if (discountForm.discount == "" || discountForm.discountType == "") {
      setDiscountModaErrorData({
        discountType:
          discountForm.discountType == "" ? "Discount Type is required" : "",
        discount: discountForm.discount == "" ? "Discount is required" : "",
      });

      return;
    }

    if (isNaN(discountForm.discount)) {
      setDiscountModaErrorData({
        discountType: "",
        discount: "Discount should be a number",
      });
      return;
    }

    // Update discount in order table

    await dispatch(
      VenueAction.changeOrderDiscount({
        discount: discountForm.discount,
        discountType: discountForm.discountType,
        orderId: orderDetails["_id"],
      })
    );

    setOpenDiscountModal(false);

    // console.log(errorData);
  };

  useEffect(() => {
    initDiscountModal();
  }, [orderDetails]);

  const initDiscountModal = () => {
    setDiscountForm({
      ...discountForm,
      discountType: orderDetails?.discountType,
      discount: orderDetails?.discount,
    });
  };

  return (
    <div>
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HearderBar title="Order Management / Order Details" />
          <div className="venueDetailsWrapper">
            <div
              className={classes.arrow}
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackSharp /> Back
            </div>
          </div>

          <div className="adminOrderDetailsWrapper">
            <div className="adminOorderDetails">
              <div className="adminOrderDetailsLeft">
                <h3>Order ID</h3>
                <p>{orderDetails?.orderId}</p>
              </div>
              <div className="adminOrderDetailsMiddle">
                <h3>Price</h3>
                {/* <p>${Number(orderDetails?.price).toFixed(2)}</p> */}
                <p>{formatUsCurrency(orderDetails?.price)}</p>
              </div>
              <div className="adminOrderDetailsRight">
                <h3>Status</h3>
                <p>{orderDetails?.status}</p>
              </div>
            </div>
            <div>
              {orderDetails && orderDetails.eventData && (
                <div className="eventDataDetails">
                  {" "}
                  <div className="eventDataDetailsStart">
                    <p>Event Start date</p>
                    {moment(orderDetails.eventData.eventStartDate).format(
                      "MM/DD/YYYY"
                    )}
                  </div>
                  <div className="eventDataDetailsGname">
                    <p>Group Name</p>
                    {orderDetails.eventData.groupName}
                  </div>
                  <div className="eventDataDetailsEname">
                    <p>Event Name</p>
                    {orderDetails.eventData.eventName}
                  </div>
                </div>
              )}
            </div>
            <div className="adminOrderDetailsLower">
              <div
                className=" m-2 mr-3 align-self-end"
                style={{
                  cursor: "pointer",
                  borderBottom: " 1px solid #ff0000",
                  fontSize: "12px",
                  color: "#ff0000",
                }}
                onClick={() => {
                  setExpanded({
                    ...expanded,
                    panel1: true,
                    panel2: true,
                    panel3: true,
                    panel4: true,
                  });
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                  }}
                  // className=" "
                >
                  Expand all
                </span>
              </div>
              <div className="adminOrderDetailsLowerWrapper">
                <div />
                <Accordion
                  expanded={expanded["panel1"] === true}
                  onChange={() => handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      Venue {orderDetails?.venueId?.firstName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <VenueDetailAccordian orderDetails={orderDetails} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded["panel2"] === true}
                  onChange={() => handleChange("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>Ad Spaces Name</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AdSpaceAccordian orderDetails={orderDetails} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded["panel3"] === true}
                  onChange={() => handleChange("panel3")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      Customer {orderDetails?.customerInfo?.firstName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomerDetailAccordian orderDetails={orderDetails} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded["panel4"] === true}
                  onChange={() => handleChange("panel4")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      Vendor {orderDetails?.vendorId?.firstName}{" "}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <VendorDetailAccordian orderDetails={orderDetails} />
                  </AccordionDetails>
                </Accordion>
                <div />
                {/* <div className="adminOrderDetailsVenueName">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Venue Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p
											style={{ color: "blue", cursor: "pointer" }}
											onClick={handleClick}
											className="adminOrderDetailsLowerWrapperRightPHover"
										>
											{orderDetails?.venueId?.firstName}{" "}
											{orderDetails?.venueId?.lastName}{" "}
										</p>
									</div>
								</div> */}
                {/* <div className="adminOrderDetailsAdSpaces">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Ad Spaces Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p>
											{orderDetails?.adspaces?.map((data, index) => {
												return (
													<span
														className="adminOrderDetailsLowerWrapperRightPHover"
														style={{ color: "blue", cursor: "pointer" }}
														onClick={() => {
															console.log("data :>> ", data);
															setId(data._id);

															setDetailsModel(true);
														}}
													>
														{data?.adspace?.title}{" "}
													</span>
												);
											})}
										</p>
									</div>
								</div> */}
                {/* <div className="adminOrderDetailsCustomerName">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Customer Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p
											className="adminOrderDetailsLowerWrapperRightPHover"
											style={{ color: "blue", cursor: "pointer" }}
											onClick={() => setCustomerModal(!openCustomerModal)}
										>
											{orderDetails?.customerInfo?.firstName}{" "}
											{orderDetails?.customerInfo?.lastName}{" "}
										</p>
									</div>
								</div> */}
                {/* <div className="adminOrderDetailsVendorName">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Vendor Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p
											style={{ color: "blue", cursor: "pointer" }}
											onClick={() => setVendorModal(!openVendorModal)}
										>
											{" "}
											{orderDetails?.vendorId?.firstName}
										</p>
									</div>
								</div> */}

                {orderDetails?.status === "Proposed" ||
                orderDetails?.status === "Initiated" ? (
                  <div className="text-center mb-2 ">
                    <Button
                      background="secondary" // two types
                      fontSize="15px"
                      border="1px solid #fff"
                      color="secondary"
                      name="Change Discount"
                      loading={loadingState}
                      handleClick={() => {
                        setOpenDiscountModal(true);
                      }}
                    />
                  </div>
                ) : null}
                <AddDiscount
                  open={openDiscountModal}
                  title={"Change Discount"}
                  handleClose={() => {
                    setOpenDiscountModal(false);
                    initDiscountModal();
                  }}
                  handleChange={handleDiscountModalChange}
                  handleSave={handleDiscountModalSave}
                  state={discountForm}
                  errorData={discountModalErrorData}
                />
                {(orderDetails?.status === "Pending" ||
                  orderDetails?.status === "Approved" ||
                  orderDetails?.status === "Rejected") &&
                orderDetails?.status !== "Cancelled" ? (
                  <div className="adminOrderDetailsButtonGroup">
                    <Button
                      background="secondary" // two types
                      fontSize="15px"
                      border="1px solid #fff"
                      color="secondary"
                      name="Approve"
                      loading={loadingState}
                      handleClick={() => {
                        handleAcceptRejectOrder("Approved");
                      }}
                    />
                    <Button
                      background="secondary" // two types
                      fontSize="15px"
                      border="1px solid #fff"
                      // disabled={item[1] == "Accepted" ? false : true}
                      color="secondary" //two types
                      name="Reject"
                      handleClick={() => {
                        handleAcceptRejectOrder("Rejected");
                      }}
                    />

                    {/* <Button
                      background="secondary" // two types
                      fontSize="15px"
                      border="1px solid #fff"
                      // disabled={item[1] == "Accepted" ? false : true}
                      color="secondary" //two types
                      name="Cancel"
                      handleClick={() => {
                        handleAcceptRejectOrder("Cancelled");
                      }}
                    /> */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <VenueDetailModal
        open={open}
        title="Header Title"
        handleClose={handleClose}
        data={orderDetails.venueId}
      />
      <CustomerDetailModal
        open={openCustomerModal}
        title="Header Title"
        handleClose={() => setCustomerModal(false)}
        data={orderDetails.customerInfo}
      />
      <AdSpaceDetailModal
        id={id}
        open={detailsModel}
        // previousPage="orderDetails"
        handleClose={() => {
          setDetailsModel(false);
        }}
        adSpaceData={orderDetails?.adspaces}
      />
      <VendorDetailModal
        open={openVendorModal}
        title="Header Title"
        handleClose={() => setVendorModal(false)}
        data={orderDetails?.vendorId}
      />
    </div>
  );
};

export default VenueOrderDetails;
