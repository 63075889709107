import { ACTION_TYPE } from "../../services/constants/Index";

export const initialState = {
  orders: [],
  orderDetails: {},
  venues: [],
  venueDetails: {},
  vendors: [],
  vendorDetails: {},
  customers: [],
  customerDetails: {},
  categories: [],
  eventList: [],
  vendorList: [],
  maps: [],
  zones: [],
};
export const AdminReducer = (state = initialState, action) => {
  console.log("action", action);
  switch (action.type) {
    case ACTION_TYPE.ADMIN_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
      };

    case ACTION_TYPE.ADMIN_ORDER_FILTERS:
      return {
        ...state,
        vendorList: action.payload.vendors,
        eventList: action.payload.events,
      };
    case ACTION_TYPE.ADMIN_ALL_ORDERS_DETAILS:
      return {
        ...state,
        orderDetails: action.payload.data,
      };
    // Venue Mgmt
    case ACTION_TYPE.GET_VENUES:
      return {
        ...state,
        venues: action.payload,
      };

    case ACTION_TYPE.GET_VENUE_DETAILS:
      return {
        ...state,
        venueDetails: action.payload,
      };

    case ACTION_TYPE.GET_MAP_DETAILS_BY_VENUE:
      return {
        ...state,
        maps: action.payload,
      };

    case ACTION_TYPE.CREATE_VENUE:
      return {
        ...state,
        venueDetails: action.payload,
      };

    case ACTION_TYPE.IS_CONFIGURED_BEFORE:
      return {
        ...state,
      };

    case ACTION_TYPE.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    // Vendor Mgmt

    case ACTION_TYPE.GET_VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };

    case ACTION_TYPE.GET_VENDOR_DETAILS:
      return {
        ...state,
        vendorDetails: action.payload,
      };

    case ACTION_TYPE.CREATE_VENDOR:
      return {
        ...state,
        venueDetails: action.payload,
      };

    // Customer Management

    case ACTION_TYPE.GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };

    case ACTION_TYPE.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload,
      };

    // Common

    case ACTION_TYPE.UPDATE_VENUE_VENDOR: {
      if (action.payload.role === "venue") {
        const index = state.venues.findIndex(
          (venue) => venue._id === action.payload._id
        );

        return {
          ...state,
          venues: [
            ...state.venues.slice(0, index), // everything before current post
            {
              ...state.venues[index],
              status: action.payload.status,
            },
            ...state.venues.slice(index + 1), // everything after current post
          ],
        };
      } else if (action.payload.role === "vendor") {
        const index = state.vendors.findIndex(
          (vendor) => vendor._id === action.payload._id
        );

        return {
          ...state,
          vendors: [
            ...state.vendors.slice(0, index), // everything before current post
            {
              ...state.vendors[index],
              status: action.payload.status,
            },
            ...state.vendors.slice(index + 1), // everything after current post
          ],
        };
      }
    }

    default:
      return state;
  }
};
