import { ACTION_TYPE } from "../../services/constants/Index";

export const initialState = {
  faqContent: [],
  aboutUsContent: {},
  contactUsContent: {},
};
export const SiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_FAQ_CONTENT:
      return {
        ...state,
        faqContent: action.payload,
      };

    case ACTION_TYPE.GET_ABOUTUS_CONTENT:
      return {
        ...state,
        aboutUsContent: action.payload,
      };

    case ACTION_TYPE.GET_CONTACTUS_CONTENT:
      return {
        ...state,
        contactUsContent: action.payload,
      };
    default:
      return state;
  }
};
