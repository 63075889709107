import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import "./AdSpaceModal.css";
import Button from "../button/Button";

const Modal = (props) => {
  const inputEl = useRef(null);

  // const [state, setstate] = useState();
  const { handleChange, errorData, state, zones } = props;
  console.log("🚀 ~ file: AdSpaceModal.js ~ line 15 ~ Modal ~ zones", zones);
  console.log("state", state, state?.zone);
  // useEffect(() => {
  //   setstate(props.state);
  // }, [props.state]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className="adSpaceModal"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div className="adSpacePopUp">
            <h1>Add New Ad-Space</h1>
          </div>
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <div className="d-flex flexWrap">
            <div className="addSpaceInputs">
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft">Name</div>
                <div className="addSpaceInputsRight">
                  <input
                    label="Name"
                    type="text"
                    id="custom-css-outlined-input"
                    name="title"
                    className="my-2"
                    value={state?.title}
                    onChange={handleChange}
                    //   handleChange={(e) => props.handleChange(e, "name")}
                  />
                  {errorData?.title && errorData.title[0] ? (
                    <p className="errorMsg"> {errorData.title[0]}</p>
                  ) : null}
                </div>
              </div>
              <div className="addSpaceInputsIndividual">
                <div style={{ marginLeft: 30 }} className="addSpaceInputsLeft">
                  Description
                </div>
                <div className="addSpaceInputsRight">
                  <input
                    label="Description"
                    type="text"
                    id="custom-css-outlined-input"
                    name="info"
                    className="my-2"
                    value={state?.info}
                    onChange={handleChange}
                    //   handleChange={(e) => props.handleChange(e, "info")}
                  />
                  {errorData?.info && errorData.info[0] ? (
                    <p className="errorMsg"> {errorData.info[0]}</p>
                  ) : null}
                </div>
              </div>
              <div className="addSpaceInputsIndividual">
                <div style={{ marginLeft: 40 }} className="addSpaceInputsLeft">
                  Package
                </div>
                <div className="addSpaceInputsRight">
                  <input
                    label="E-Package"
                    type="text"
                    id="custom-css-outlined-input"
                    name="description"
                    className="my-2"
                    value={state?.description}
                    onChange={handleChange}

                    //   handleChange={handleChange}
                    //   handleChange={(e) => props.handleChange(e, "package")}
                  />
                  {errorData?.description && errorData.description[0] ? (
                    <p className="errorMsg"> {errorData.description[0]}</p>
                  ) : null}
                </div>
              </div>
              <div className="addSpaceInputsIndividual">
                <div style={{ marginLeft: 35 }} className="addSpaceInputsLeft">
                  Category
                </div>
                <div className="addSpaceInputsRight">
                  <input
                    label="Category"
                    type="text"
                    id="custom-css-outlined-input"
                    name="category"
                    className="my-2"
                    value={state?.category}
                    onChange={handleChange}

                    //   handleChange={handleChange}
                    //   handleChange={(e) => props.handleChange(e, "package")}
                  />
                  {errorData?.category && errorData.category[0] ? (
                    <p className="errorMsg"> {errorData.category[0]}</p>
                  ) : null}
                </div>
              </div>
              <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft">Price</div>
                <div className="addSpaceInputsRight">
                  <input
                    label="Price"
                    type="text"
                    id="custom-css-outlined-input"
                    name="price"
                    className="my-2"
                    value={state?.price}
                    onChange={handleChange}

                    //   handleChange={handleChange}
                    //   handleChange={(e) => props.handleChange(e, "price")}
                  />
                  {errorData?.price && errorData.price[0] ? (
                    <p className="errorMsg"> {errorData.price[0]}</p>
                  ) : null}
                </div>
              </div>

              <div className="addSpaceInputsIndividual">
                <div style={{ marginLeft: 5 }} className="addSpaceInputsLeft">
                  Vendor Cost
                </div>
                <div className="addSpaceInputsRight">
                  <input
                    label="Vendor Cost"
                    type="text"
                    id="custom-css-outlined-input"
                    name="vendorCost"
                    className="my-2"
                    value={state?.vendorCost}
                    onChange={handleChange}
                  />
                  {errorData?.vendorCost && errorData.vendorCost[0] ? (
                    <p className="errorMsg"> {errorData.vendorCost[0]}</p>
                  ) : null}
                </div>
              </div>
              {/* <div className="addSpaceInputsIndividual">
                <div className="addSpaceInputsLeft">Image</div>
                <div className="addSpaceInputsRight imageAdspace"></div>
              </div> */}
            </div>
            <div className="addSpaceInputs">
              <div className="addSpaceInputsIndividual">
                <div style={{ marginLeft: 35 }} className="addSpaceInputsLeft">
                  Sq Feet
                </div>
                <div className="addSpaceInputsRight">
                  <input
                    label="Sq Feet"
                    type="text"
                    id="custom-css-outlined-input"
                    name="sqFeet"
                    className="my-2"
                    value={state?.sqFeet}
                    onChange={handleChange}
                  />
                  {errorData?.sqFeet && errorData.sqFeet[0] ? (
                    <p className="errorMsg"> {errorData.sqFeet[0]}</p>
                  ) : null}
                </div>
              </div>

              <div className="addSpaceInputsIndividual">
                <div
                  style={{ marginLeft: "35px" }}
                  className="addSpaceInputsLeft2"
                >
                  Zone
                </div>

                <div style={{ marginLeft: "68px", width: "100%" }}>
                  <select
                    className={"form-control addSpaceSelect"}
                    value={state?.zoneId || ""}
                    style={{
                      padding: "5px 10px",
                      height: "35px",
                      border: "1px solid",
                      width: "92% ",
                    }}
                    name="zone"
                    defaultValue={""}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Zone </option>
                    {zones &&
                      zones.length > 0 &&
                      zones.map((data) => {
                        if (data?._id)
                          return <option value={data._id}>{data.name}</option>;
                      })}
                  </select>
                  {/* {errorData?.zone && errorData.zone[0] ? (
                    <p className="errorMsg"> {errorData.zone[0]}</p>
                  ) : null} */}
                </div>
              </div>
              <div style={{ marginLeft: 120 }} className="imgdiv">
                <img src={state?.tempUrl ? state?.tempUrl : state?.url} />
                <button
                  onClick={() => {
                    inputEl.current.click();
                  }}
                >
                  {" "}
                  Upload Image
                </button>
                <input
                  label="Image"
                  type="file"
                  id="custom-css-outlined-input"
                  name="url"
                  ref={inputEl}
                  style={{
                    color: "white",
                    display: "none",
                  }}
                  // value={state?.url}
                  className="my-2"
                  onChange={handleChange}
                  // handleChange={(e) => props.handleChange(e, "img")}
                />

                {errorData?.url && errorData.url[0] ? (
                  <p className="errorMsg text-center"> {errorData.url[0]}</p>
                ) : null}
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="adspacebuttonGroup">
          <Button
            background="secondary" // two types
            fontSize="15px"
            border="1px solid #fff"
            // disabled={item[1] == "Accepted" ? false : true}
            color="secondary" //two types
            name="Save"
            loading={props.loading}
            handleClick={() => {
              props.handleSave(props.id);
            }}
          />
          <Button
            background="secondary"
            fontSize="15px"
            border="1px solid #fff"
            color="secondary" //two types
            name="Cancel"
            handleClick={() => {
              props.handleClose();
            }}
          />
          {/* <Button
            background="secondary" // two types
            fontSize="15px"
            border="1px solid #fff"
            // disabled={item[1] == "Accepted" ? false : true}
            color="secondary" //two types
            name="Delete"
            handleClick={() => {
              props.handleDelete();
            }}
          /> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Modal;
