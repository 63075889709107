// /orders/get
import { ACTION_TYPE } from "../../services/constants/Index";
import Api from "../../services/utills/Axios";
import { CommonAction } from "./Index";

export const customerInfoSave = (state) => async (dispatch) => {
  try {
    console.log("custttt", state);

    await dispatch({
      type: ACTION_TYPE.SAVE_CUSTOMER_INFO,
      payload: { data: state },
    });

    return;
  } catch (error) {}
};

export const constSaveCustomerLink = (state) => async (dispatch) => {
  try {
    console.log("custttt", state);
    const res = await Api.post(`/staff/createLink`, state);
    console.log(
      "🚀 ~ file: StaffAction.js ~ line 23 ~ constSaveCustomerLink ~ res",
      res.data
    );
    if (res.data?.data && res.data?.data?.customerLink)
      await dispatch({
        type: ACTION_TYPE.SAVE_CUSTOMER_LINK,
        payload: { data: res.data?.data },
      });
    // await dispatch({
    //   type: ACTION_TYPE.SAVE_CUSTOMER_INFO,
    //   payload: { data: {} },
    // });

    return;
  } catch (error) {}
};

export const sendConfirmationEmail = (body) => async (dispatch) => {
  try {
    console.log("sendMail", body);
    const res = await Api.post(`/staff/sendMail`, body);
    console.log(
      "🚀 ~ file: StaffAction.js ~ line 23 ~ sendConfirmationEmail ~ res",
      res.data
    );
    // if (res.data?.data && res.data?.data?.customerLink)
    //   await dispatch({
    //     type: ACTION_TYPE.SAVE_CUSTOMER_LINK,
    //     payload: { data: res.data?.data },
    //   });
    // await dispatch({
    //   type: ACTION_TYPE.SAVE_CUSTOMER_INFO,
    //   payload: { data: {} },
    // });

    return;
  } catch (error) {}
};

export const getVenueMaps =
  ({ venueId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const { data } = await Api.get(`/staff/maps/${venueId}`);
      console.log("inside getVenueMaps", data);
      await dispatch({
        type: ACTION_TYPE.STAFF_VENUE_MAPS,
        payload: { data: data.data.maps },
      });

      return data;
    } catch (error) {}
  };

export const getMapById = (venueId, mapId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/staff/maps/details/${venueId}/${mapId}`);

    console.log("venue details ", res.data);
    // await dispatch({
    //   type: ACTION_TYPE.EMPTY_MAP_DATA,

    // });

    await dispatch({
      type: ACTION_TYPE.STAFF_MAP_DATA,
      payload: res.data,
    });

    return res.data;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const getStaffOrders =
  ({ filter, staffId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(`/staff/orders/get/${filter}`, { staffId });
      console.log("staff all order", res);
      await dispatch({
        type: ACTION_TYPE.STAFF_ALL_ORDERS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };

  export const getDiscountCoupons =
  () =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.get(``);
      console.log("coupons", res);
      await dispatch({
        type: ACTION_TYPE.GET_DISCOUNT_COUPONS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };

  export const createDiscountCoupon =
  (payload) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());
      const res = await Api.post(``, payload);
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };
// export const venueOrderDetails = (item) => async (dispatch) => {
//   try {
//     await dispatch({
//       type: ACTION_TYPE.VENUE_ALL_ORDERS_DETAILS,
//       payload: { data: item },
//     });
//   } catch (error) {}
// };

export const getStaffOrderDetails = (orderId) => async (dispatch) => {
  try {
    const res = await Api.get(`/staff/orders/details/get/${orderId}`);
    console.log("staff all order", res);
    await dispatch({
      type: ACTION_TYPE.STAFF_ALL_ORDERS_DETAILS,
      payload: { data: res.data.data.result },
    });
  } catch (error) {}
};

export const orderAcceptReject =
  (payload, orderDetails, venueId) => async (dispatch) => {
    try {
      console.log("payload :>> ", payload);
      dispatch(CommonAction.startLoading());

      const res = await Api.post(`/staff/orders/changeStatus`, payload);
      console.log("venueId :>> ", venueId);
      // dispatch(getVenueOrders({ filter: "all", venueId }));

      console.log("staff all order", res);
      // await dispatch({
      //   type: ACTION_TYPE.ADMIN_ALL_ORDERS,
      //   payload: { data: res.data.data.result },
      // });
      orderDetails["status"] = payload["status"];
      await dispatch({
        type: ACTION_TYPE.STAFF_ALL_ORDERS_DETAILS,
        payload: { data: orderDetails },
      });
      dispatch(CommonAction.completeLoading({}, false));
    } catch (error) {}
  };
