import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import elevator from "../../images/commonImage/elevator.png";
import "./Modal.css";
const FormDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        className="details newRender"
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title "
          className="text-center newModal-head model-head"
        >
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="space d-flex flex-column ">
          <img src={elevator} alt="logo" />
          <h4>
            <strong>Guest Room Elevators</strong>
            <br />
            (Door decal 4’ x 8’)
          </h4>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default FormDialog;
