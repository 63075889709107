import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../../components/sideNav/SideNav";
import "./AddNewVenue.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import { Link, useHistory } from "react-router-dom";
import {
  ACTION_TYPE,
  ROUTES,
  s3Bucket,
  s3UploadUrl,
} from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, CustomerAction } from "../../../redux/actions/Index";
import Button from "../../../components/button/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackSharp, ArrowLeft, PinDropSharp } from "@material-ui/icons";
import dummyImage from "../../../images/NewIcons/NewIcon3.png";
import InputField from "../../../components/input/Input";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ValidateAddVendorForm, ValidateAddVenueForm } from "./Validation";
import axios from "axios";
import {
  AddVendorModal,
  AddBrand,
  AddOwnerShip,
  AddManagement,
  AddContract,
  AddChain,
} from "../../../components/Modals";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
import states from "../../Story/CustomerInformation/data.json";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  arrow: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    width: "70px",
    justifyContent: "space-between",
    margin: "20px auto 0px 20px",
    cursor: "pointer",
    fontSize: "18px",
    ["@media (max-width: 460px)"]: {
      margin: "40px auto 20px 0px",
      border: "1px solid white",
      padding: "5px",
      justifyContent: "flex-end",
    },
  },
});

const AddNewVenue = (props) => {
  const venueImageUpload = useRef();
  const logoUpload = useRef();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const { vendors, categories } = useSelector((state) => state.AdminReducer);
  // const {  } = useSelector((state) => state.AdminReducer);

  const [venueDetails, setVenueDetails] = useState({});

  const [venueImageEdit, setVenueImageEdit] = useState(false);
  const [logoEdit, setLogoEdit] = useState(false);
  const [loading, setloading] = useState(false);
  const [phone, setPhone] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [errorData, setError] = useState();

  const [openAddVendorModal, setAddVendorModal] = useState(false);
  const [openAddBrandModal, setAddBrandModal] = useState(false);
  const [openAddOwnerShipModal, setAddOwnerShipModal] = useState(false);
  const [openAddManagementModal, setAddManagementModal] = useState(false);
  const [openContractModal, setAddContractModal] = useState(false);
  const [openChainModal, setAddChainModal] = useState(false);
  const [newVendorDetails, setNewVendorDetails] = useState({});
  const [categoryList, setCategoryList] = useState({});
  const [vendorCategoryList, setVendorCategoryList] = useState({});

  const [newDetails, setNewDetails] = useState({
    // newBrand: "",
    // newOwnership: "",
    // newManagement: "",
    // newChain: "",
    // newContract: "",
  });

  useEffect(() => {
    (async () => {
      if (user?._id) {
        dispatch(AdminAction.getAddVenueReqList());
      }
    })();
  }, [user?._id]);

  useEffect(() => {
    (async () => {
      console.log("categories", categories);
    })();
  }, [categories]);

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    console.log("name, value handleCategoryChange :>> ", name, value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name, value :>> ", name, value);
    // if (value == null) return;
    if (name === "realEstateSale" && value == "true") {
      console.log("in cond realEstate", venueDetails);
      setVenueDetails({
        ...venueDetails,
        vendorId: "null",
        [name]: value,
      });
    } else if (name === "venueImage" || name === "logo") {
      console.log("url state", e.target.files, e.target);
      setVenueDetails({
        ...venueDetails,
        [name]: e.target.files[0],
        [name + "Url"]: URL.createObjectURL(e.target.files[0]),
      });
      if (name === "venueImage") setVenueImageEdit(true);
      else if (name === "logo") setLogoEdit(true);
    } else if (
      name === "Brand" ||
      name === "Ownership" ||
      name === "Management" ||
      name === "Chain" ||
      name === "Contract"
    )
      setCategoryList({
        ...categoryList,
        [name]: value,
      });
    else
      setVenueDetails({
        ...venueDetails,
        [name]: value,
      });
  };

  const handleVendorFormChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "Brand" ||
      name === "Ownership" ||
      name === "Management" ||
      name === "Chain" ||
      name === "Contract"
    )
      setVendorCategoryList({
        ...vendorCategoryList,
        [name]: value,
      });
    else
      setNewVendorDetails({
        ...newVendorDetails,
        [name]: value,
      });
  };

  const uploadLogo = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${s3UploadUrl}?object_key=venue/venueLogo/${Date.now()}&bucket=${s3Bucket}`
        )
        .then((resp) => {
          var options = {
            headers: {
              "Content-Type": venueDetails?.logo?.type,
            },
          };
          axios
            .put(resp.data.presigned_url, venueDetails?.logo, options)
            .then(async (resps, err) => {
              resolve(resp.data.key);
            });
        });
    });
  };

  const uploadVenueImage = () => {
    const { venueImage, venueImageUrl } = venueDetails;
    console.log("in uploadVenue image", venueImage, venueImageUrl);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${s3UploadUrl}?object_key=venue/venueImage/${Date.now()}&bucket=${s3Bucket}`
        )
        .then((resp) => {
          console.log("resp :>> ", resp);

          var options = {
            headers: {
              "Content-Type": venueDetails?.venueImage?.type,
            },
          };
          axios
            .put(resp.data.presigned_url, venueDetails?.venueImage, options)
            .then(async (resps, err) => {
              if (err) console.log("err :>> ", err);

              resolve(resp.data.key);
            });
        });
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setloading(true);

    const result = await ValidateAddVenueForm({
      ...venueDetails,
      ...categoryList,
    });

    if (!result?.isFormValid) {
      setError(result?.error);
      setloading(false);
      console.log("result", result);
      return;
    }
    console.log("SAVED");
    setError({});

    const [venueImageKey, logoKey] = await Promise.all([
      uploadVenueImage(),
      uploadLogo(),
    ]);

    console.log("venueImageKey, logoKey :>> ", venueImageKey, logoKey);

    let params = {
      ...venueDetails,
      categoryList,
      venueImage: `https://${s3Bucket}.s3.us-east-2.amazonaws.com/${venueImageKey}`,
      logo: `https://${s3Bucket}.s3.us-east-2.amazonaws.com/${logoKey}`,
    };

    const respp = await dispatch(AdminAction.createVenue(params));
    console.log("respp", respp);
    setloading(false);

    if (respp) props.history.push(ROUTES.VENUE_MANAGEMENT);
  };

  const handleAddVendorSubmit = async (e) => {
    setloading(true);
    const result = await ValidateAddVendorForm({
      ...newVendorDetails,
      ...vendorCategoryList,
    });
    if (result?.isFormValid) {
      setError({});
      let params = {
        categoryList: vendorCategoryList,
        newVendorDetails,
      };
      await dispatch(AdminAction.createVendor(params));
      setAddVendorModal(false);
      setNewVendorDetails({});

      setloading(false);
    } else if (result?.isFormValid === false) {
      console.log("err", result.error);

      setError(result?.error);
      setloading(false);

      return;
    }
  };

  const handleSaveCategory = async (category) => {
    console.log("category", category, newDetails[category]);

    let params = {
      name: category,
      value: newDetails[category],
    };
    await dispatch(AdminAction.addCategory(params));
    switch (category) {
      case "Brand":
        setAddBrandModal(false);
        break;

      case "Ownership":
        setAddOwnerShipModal(false);
        break;

      case "Management":
        setAddManagementModal(false);
        break;

      case "Chain":
        setAddChainModal(false);
        break;

      case "Contract":
        setAddContractModal(false);
        break;

      default:
        break;
    }
  };

  const handleNewDetailsChange = (e) => {
    const { name, value } = e.target;

    setNewDetails({
      ...newDetails,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="start">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Venue Management / Add Venue " />
          <div className="venueDetailsWrapper">
            <div className={classes.arrow} onClick={() => history.goBack()}>
              <ArrowBackSharp /> Back
            </div>
            <div className="addNewVenueHeading">
              <p>Add New Venue</p>
            </div>
            <div className="addNewVenueWrapper">
              <div className="">
                <div className="">
                  <div className="addVenueReAlign">
                    <p>Venue Name</p>
                    <div className="">
                      <InputField
                        className=""
                        label=""
                        type="text"
                        pattern="[A-Za-z]"
                        id="custom-css-outlined-input"
                        name="firstName"
                        placeholder="Enter venue first name"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.firstName && errorData.firstName[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="addVenueReAlign">
                    <p>Venue Email</p>
                    <div className="">
                      <InputField
                        className=""
                        label=""
                        type="text"
                        pattern="[A-Za-z]"
                        id="custom-css-outlined-input"
                        name="email"
                        placeholder="Enter venue email"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.email && errorData.email[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div className="addVenueReAlign">
                    <p>Venue Phone</p>
                    <div className="">
                      <PhoneInput
                        // className="addvenueInput"
                        country={"us"}
                        onlyCountries={["us"]}
                        value={phone}
                        placeholder="Enter venue phone"
                        inputClass="phoneInput"
                        buttonClass="phoneButton"
                        dropdownClass="dropdownClass"
                        disableDropdown="true"
                        onChange={(phone) =>
                          setVenueDetails({
                            ...venueDetails,
                            phone,
                          })
                        }
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.phone && errorData.phone[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="addVenueReAlign">
                    <p>Street</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        pattern="[A-Za-z]"
                        id="custom-css-outlined-input"
                        name="street"
                        placeholder="Enter venue first name"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.street && errorData.street[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="addVenueRealignTwo">
                <div
                  className="addVenueRealignTwoFields"
                  style={{ width: "100%" }}
                >
                  <div className="">
                    <p>City</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="city"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.city && errorData.city[0]}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="addVenueRealignTwoFields"
                  style={{ width: "100%" }}
                >
                  <div className="">
                    <p>State</p>

                    <div className="addVenueAddDataRight changeMaxWidth">
                      <select
                        // value={state?.state}
                        style={{
                          padding: "15px 10px",
                          height: "56px",
                          border: "1px solid",
                        }}
                        name="state"
                        className="form-control "
                        onChange={handleChange}
                      >
                        <option selected>Select State </option>
                        {states &&
                          states.map((data) => {
                            return (
                              <option value={data.Code}>{data.Code}</option>
                            );
                          })}
                      </select>

                      <p className="errorMsg">
                        {" "}
                        {errorData?.state && errorData.state[0]}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="addVenueRealignTwoFields"
                  style={{ width: "100%" }}
                >
                  <div className="">
                    <p>Zip</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="zip"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.zip && errorData.zip[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="addVenueRealignTwo">
                {/* <div className="addVenueRealignTwoFields">
                  <div className="">
                    <p>Country</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="country"
                        placeholder="Enter venue location"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.country && errorData.country[0]}
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="addVenueRealignTwo">
                <div className="addVenueRealignTwoFields">
                  <div className="">
                    <p>Contact First Name</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="contactFirstName"
                        placeholder="Enter contact first name"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactfirstName &&
                          errorData.contactfirstName[0]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="addVenueRealignTwoFields">
                  <div className="">
                    <p>Contact Last Name</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="contactlastName"
                        placeholder="Enter contact last name"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactlastName &&
                          errorData.contactlastName[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="addVenueReAlign">
                    <p>Contact Email</p>
                    <div className="">
                      <InputField
                        className=""
                        label=""
                        type="text"
                        pattern="[A-Za-z]"
                        id="custom-css-outlined-input"
                        name="contactEmail"
                        placeholder="Enter contact email"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactEmail && errorData.contactEmail[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="addVenueReAlign">
                    <p>Contact Phone</p>
                    <div className="">
                      <PhoneInput
                        country={"us"}
                        onlyCountries={["us"]}
                        value={contactPhone}
                        placeholder="Enter venue phone"
                        inputClass="phoneInput"
                        buttonClass="phoneButton"
                        dropdownClass="dropdownClass"
                        disableDropdown="true"
                        onChange={(contactPhone) =>
                          setVenueDetails({
                            ...venueDetails,
                            contactPhone,
                          })
                        }
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.contactPhone && errorData.contactPhone[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="addVenueRealignTwo">
                <div className="addVenueRealignTwoFields">
                  <div className="">
                    <p>Service/Administration Charge</p>
                    <div className="">
                      <InputField
                        label=""
                        type="text"
                        id="custom-css-outlined-input"
                        name="serviceCharge"
                        placeholder="Enter Service/Administration Charge"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.serviceCharge && errorData.serviceCharge[0]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="addVenueRealignTwoFields">
                  <div className="">
                    <p>Sales Tax</p>
                    <div className="">
                      <InputField
                        label=""
                        type="number"
                        id="custom-css-outlined-input"
                        name="salesTax"
                        placeholder="Enter Sales Tax"
                        // value={state?.email}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.salesTax && errorData.salesTax[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <AddVendorModal
                open={openAddVendorModal}
                handleClose={() => {
                  setAddVendorModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                categories={categories}
                loading={loading}
                errorData={errorData}
                state={newVendorDetails}
                handleChange={handleVendorFormChange}
                handleSave={handleAddVendorSubmit}
                handleDelete={() => {
                  setAddVendorModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />

              <div className="addVenueRealignTwo">
                <div className="selectOuterWrapper selectBlock">
                  <div className="addVenueAddDataLeft ">
                    <p>Real Estate Sale</p>
                  </div>
                  <div className="addVenueAddDataRight changeMaxWidth">
                    <div className="selectaddVenueWrapper">
                      <div className="changeSelectWidth">
                        <select
                          // value={state?.state}
                          style={{
                            padding: "15px 10px",
                            height: "50px",
                            border: "1px solid",
                          }}
                          name="realEstateSale"
                          className="form-control"
                          onChange={handleChange}
                        >
                          <option value={true}>Yes</option>
                          <option selected value={false}>
                            No
                          </option>
                        </select>
                        <p className="errorMsg"></p>
                      </div>
                      {/* <span
                      onClick={() => setAddVendorModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New vendor
                    </span>{" "} */}
                    </div>
                  </div>
                </div>

                <div className="selectOuterWrapper selectBlock">
                  <div className="addVenueAddDataLeft ">
                    <p>Choose vendor</p>
                  </div>
                  <div className="addVenueAddDataRight changeMaxWidth">
                    <div className="selectaddVenueWrapper">
                      <div className="changeSelectWidth">
                        <select
                          value={venueDetails?.vendorId}
                          style={{
                            padding: "15px 10px",
                            height: "50px",
                            border: "1px solid",
                          }}
                          name="vendorId"
                          className="form-control"
                          onChange={handleChange}
                          disabled={venueDetails.realEstateSale == "true"}
                        >
                          <option selected value="null">
                            Select Vendor{" "}
                          </option>
                          {vendors &&
                            [
                              ...vendors,
                              //  { _id: "Other", path: ",Brand," }
                            ].map((data) => {
                              // if (data["path"] === ",Brand,")
                              return (
                                <option value={data._id}>
                                  {data.firstName}
                                </option>
                              );
                            })}
                        </select>
                        <p className="errorMsg">
                          {" "}
                          {errorData?.vendorId && errorData.vendorId[0]}
                        </p>
                      </div>
                      {/* <span
                      onClick={() => setAddVendorModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New vendor
                    </span>{" "} */}
                    </div>
                  </div>
                </div>
              </div>

              <AddBrand
                open={openAddBrandModal}
                handleClose={() => {
                  setAddBrandModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newDetails}
                id="Brand"
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddBrandModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />

              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft">
                  <p>Brand</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Brand}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Brand"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Brand{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Brand,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Brand && errorData.Brand[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddBrandModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Brand
                    </span>{" "}
                  </div>
                </div>
              </div>

              <AddOwnerShip
                open={openAddOwnerShipModal}
                handleClose={() => {
                  setAddOwnerShipModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                id="Ownership"
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newDetails.newOwnership}
                // handleChange={
                //   (e)=> setNewDetails({
                //     ...newDetails,
                //     newOwnership
                //   })
                // }
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddOwnerShipModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />

              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft">
                  <p>Ownership</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Ownership}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Ownership"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Ownership{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Ownership,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Ownership && errorData.Ownership[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddOwnerShipModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Ownership
                    </span>{" "}
                  </div>
                </div>
              </div>

              <AddManagement
                open={openAddManagementModal}
                handleClose={() => {
                  setAddManagementModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                id="Management"
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newVendorDetails}
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddManagementModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />
              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft">
                  <p>Management</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Management}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Management"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Management{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Management,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Management && errorData.Management[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddManagementModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Management
                    </span>{" "}
                  </div>
                </div>
              </div>
              <AddContract
                open={openContractModal}
                handleClose={() => {
                  setAddContractModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                id="Contract"
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newVendorDetails}
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddContractModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />
              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft ">
                  <p>Contract</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Contract}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Contract"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Contract{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Contract,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Contract && errorData.Contract[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddContractModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Contract
                    </span>{" "}
                  </div>
                </div>
              </div>
              <AddChain
                open={openChainModal}
                handleClose={() => {
                  setAddChainModal(false);
                  // setNewVendorDetails(null);
                  setError({});
                  // setData();
                }}
                // categories={categories}
                // loading={loading}
                // errorData={errorData}
                // state={newVendorDetails}
                id="Chain"
                handleChange={handleNewDetailsChange}
                handleSave={handleSaveCategory}
                handleDelete={() => {
                  setAddChainModal(false);
                  // setData();
                  setError({});

                  // setNewVendorDetails(null);
                }}
              />
              <div className="selectOuterWrapper">
                <div className="addVenueAddDataLeft">
                  <p>Chain</p>
                </div>
                <div className="addVenueAddDataRight changeMaxWidth">
                  <div className="selectaddVenueWrapper addNewVenue">
                    <div className="changeSelectWidth">
                      <select
                        value={categoryList?.Chain}
                        style={{
                          padding: "15px 10px",
                          height: "50px",
                          border: "1px solid",
                        }}
                        name="Chain"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option selected value="null">
                          Select Chain{" "}
                        </option>
                        {categories.length &&
                          categories.map((data) => {
                            if (data["path"] === ",Chain,")
                              return <option value={data.id}>{data.id}</option>;
                          })}
                      </select>
                      <p className="errorMsg">
                        {" "}
                        {errorData?.Chain && errorData.Chain[0]}
                      </p>
                    </div>
                    <span
                      onClick={() => setAddChainModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      or Add a New Chain
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="addVenueUploadData">
                  <div className="addVenue__upload">
                    <div
                      className={
                        venueDetails?.venueImageUrl
                          ? "newaddVenue__image"
                          : "addVenue__image"
                      }
                    >
                      <h1>Venue image</h1>
                      <div
                        className={
                          venueDetails?.venueImageUrl ? "imageWrapper" : ""
                        }
                      >
                        <img src={venueDetails?.venueImageUrl} alt="" />
                      </div>
                      <div className="mt-3">
                        <Button
                          background="secondary" // two types
                          fontSize="11px"
                          border="1px solid #fff"
                          // disabled={item[1] == "Accepted" ? false : true}
                          color="secondary" //two types
                          name={venueImageEdit ? "Change" : "Upload Image"}
                          handleClick={() => venueImageUpload.current.click()}
                        />
                        <p className="errorMsg">
                          {" "}
                          {errorData?.venueImage && errorData.venueImage[0]}
                        </p>
                      </div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      name="venueImage"
                      ref={venueImageUpload}
                      onChange={handleChange}
                      hidden
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                    <div
                      className={
                        venueDetails?.logoUrl
                          ? "addVenue__image"
                          : "addVenue__image"
                      }
                    >
                      <h1>Venue Logo</h1>
                      <div
                        className={venueDetails?.logoUrl ? "imageWrapper" : ""}
                      >
                        <img src={venueDetails?.logoUrl} alt="" />
                      </div>
                      <div className="mt-3">
                        <Button
                          background="secondary" // two types
                          fontSize="11px"
                          border="1px solid #fff"
                          // disabled={item[1] == "Accepted" ? false : true}
                          color="secondary" //two types
                          name={logoEdit ? "Change" : "Upload Logo"}
                          handleClick={() => logoUpload.current.click()}
                        />
                        <p className="errorMsg">
                          {" "}
                          {errorData?.logo && errorData.logo[0]}
                        </p>
                      </div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={logoUpload}
                      name="logo"
                      onChange={handleChange}
                      hidden
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="addVenueUploadButtonGroup mt-3">
                <div className="addVenueUploadDataButtons">
                  <Button
                    background="secondary" // two types
                    fontSize="15px"
                    border="1px solid #fff"
                    // disabled={item[1] == "Accepted" ? false : true}
                    color="secondary" //two types
                    name=" Cancel"
                    handleClick={() => {
                      props.history.goBack();
                    }}
                  />
                  <Button
                    background="secondary" // two types
                    fontSize="15px"
                    border="1px solid #fff"
                    // disabled={item[1] == "Accepted" ? false : true}
                    color="secondary" //two types
                    loading={loading}
                    name="Save"
                    handleClick={handleSave}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewVenue;
