import React, { useState, useEffect, Component } from "react";
import { fabric } from "fabric";
import { v4 } from "uuid";
import Handler from "../handlers/handler";
import Button from "../../../../components/button/Button";
import "./canvas.css";
import { ROUTES } from "../../../../services/constants/Index";
class Canvas extends Component {
  constructor(props) {
    super(props);
    this.handler = null;
    this.canvas = null;
    this.height = 0;
    this.width = 0;
    this.templateName = "";
    this.noTemplateName = null;
    this.resizeCanvas = this.resizeCanvas.bind(this);

    this.state = {
      id: v4(),
      ref: this.props.ref,
      prevZoom: null,
    };
  }

  componentDidMount() {
    const { id } = this.state;

    const {
      minZoom,
      maxZoom,
      ref,
      designNo,
      userId,
      templateName,
      onDeleteMap,
      jsonData,
      location,
      handleClose,
      setloading,
      ...rest
    } = this.props;
    console.log();

    // this.canvas = new fabric.Canvas(`canvas_${id}`, {
    // 	height: 480,
    // 	width: 760,
    // });

    // (async () => {
    //   if (!JSON.parse(jsonData.data).backgroundImage) return;
    //   else if (JSON.parse(jsonData.data).backgroundImage.scaleX > 1) {
    //     this.width =
    //       JSON.parse(jsonData.data).backgroundImage.width /
    //       JSON.parse(jsonData.data).backgroundImage.scaleX;
    //     this.height =
    //       JSON.parse(jsonData.data).backgroundImage.height /
    //       JSON.parse(jsonData.data).backgroundImage.scaleY;
    //   } else if (JSON.parse(jsonData.data).backgroundImage.scaleX <= 1) {
    //     this.width =
    //       JSON.parse(jsonData.data).backgroundImage.width *
    //       (1 - JSON.parse(jsonData.data).backgroundImage.scaleX);
    //     this.height =
    //       JSON.parse(jsonData.data).backgroundImage.height *
    //       (1 - JSON.parse(jsonData.data).backgroundImage.scalex);
    //   }
    // })();

    if (location === "new") {
      this.canvas = new fabric.Canvas(`canvas_${id}`, {
        height: 360,
        width: 740,
        backgroundColor: "white",
      });
      this.resizeCanvas({
        width: 360,
        height: 740,
      });

      // var rect = new fabric.Rect({
      //   originX: "top",
      //   originY: "top",
      //   width: 150,
      //   height: 40,
      //   fill: "#dfc5c5",
      // });
      // var text = new fabric.Text("hello world", {
      //   fontSize: 30,
      //   originX: "top",
      //   originY: "top",
      // });
      // var group = new fabric.Group([rect, text], {
      //   left: 0,
      //   top: 0,
      //   selectable: false,
      //   visible: false,
      // });
      // this.canvas.add(group);
      this.canvas.renderAll();
    } else if (location === "edit") {
      // this.canvas = new fabric.Canvas(`canvas_${id}`, {
      // 	height: 360,
      // 	width: 740,
      // });
      // this.canvas.clear();

      // this.canvas.loadFromJSON(jsonData.data, () => {
      // 	console.log("");
      // });
      // // this.canvas.add(group);
      // this.canvas.renderAll();

      // this.resizeCanvas({ width: false, height: false });
      console.log("jsonData", jsonData);
      let canvasRatioImage =
        JSON.parse(jsonData.data).backgroundImage.width /
        JSON.parse(jsonData.data).backgroundImage.height;

      if (!JSON.parse(jsonData.data).backgroundImage) return;
      else if (canvasRatioImage > 1) {
        console.log(`BG canvasRatioImage`, canvasRatioImage);
        this.canvas = new fabric.Canvas(`canvas_${id}`, {
          // width: 760,
          width:
            JSON.parse(jsonData.data).backgroundImage.width *
            JSON.parse(jsonData.data).backgroundImage.scaleX,
          // height: 467.712,
          height:
            JSON.parse(jsonData.data).backgroundImage.height *
            JSON.parse(jsonData.data).backgroundImage.scaleY,
          // * (1 - JSON.parse(jsonData.data).backgroundImage.scaleY),
        });
        this.canvas.clear();

        this.resizeCanvas({
          width:
            JSON.parse(jsonData.data).backgroundImage.width *
            JSON.parse(jsonData.data).backgroundImage.scaleX,

          height:
            JSON.parse(jsonData.data).backgroundImage.height *
            JSON.parse(jsonData.data).backgroundImage.scaleY,
        });

        this.canvas.loadFromJSON(jsonData.data, () => {
          console.log("UPLOADED");
        });

        this.canvas.renderAll();
      } else if (canvasRatioImage === 1) {
        this.canvas = new fabric.Canvas(`canvas_${id}`, {
          width:
            JSON.parse(jsonData.data).backgroundImage.width *
            JSON.parse(jsonData.data).backgroundImage.scaleX,

          height:
            JSON.parse(jsonData.data).backgroundImage.height *
            JSON.parse(jsonData.data).backgroundImage.scaleY,
          // * (1 - JSON.parse(jsonData.data).backgroundImage.scaleY),
        });
        this.canvas.clear();

        this.resizeCanvas({
          width:
            JSON.parse(jsonData.data).backgroundImage.width *
            JSON.parse(jsonData.data).backgroundImage.scaleX,
          height:
            JSON.parse(jsonData.data).backgroundImage.width *
            JSON.parse(jsonData.data).backgroundImage.scaleX,
        });

        this.canvas.loadFromJSON(jsonData.data, () => {
          console.log("UPLOADED");
        });

        this.canvas.renderAll();
      } else {
        console.log(`BG canvasRatioImage`, canvasRatioImage);
        this.canvas = new fabric.Canvas(`canvas_${id}`, {
          // width: 760,
          width:
            JSON.parse(jsonData.data).backgroundImage.width *
            JSON.parse(jsonData.data).backgroundImage.scaleX,
          // JSON.parse(jsonData.data).backgroundImage.width * 1 -
          // JSON.parse(jsonData.data).backgroundImage.scaleX,
          // height: 467.712,
          height:
            JSON.parse(jsonData.data).backgroundImage.height *
            JSON.parse(jsonData.data).backgroundImage.scaleY,
          // JSON.parse(jsonData.data).backgroundImage.height * 1 -
          // JSON.parse(jsonData.data).backgroundImage.scaleY,
          // * (1 - JSON.parse(jsonData.data).backgroundImage.scaleY),
          // width: 740,
          // height: 360,
        });
        this.canvas.clear();
        this.resizeCanvas({
          width:
            JSON.parse(jsonData.data).backgroundImage.width *
            JSON.parse(jsonData.data).backgroundImage.scaleX,
          height:
            JSON.parse(jsonData.data).backgroundImage.height *
            JSON.parse(jsonData.data).backgroundImage.scaleY,
        });

        this.canvas.loadFromJSON(jsonData.data, () => {
          console.log("UPLOADED");
        });

        this.canvas.renderAll();
      }
    }
    this.handler = new Handler({
      id,
      adSpaceData: this.props.adSpaceData,
      canvas: this.canvas,
      getDimension: (dim) => {
        let data = this.getDimension(dim);
        return data;
      },
      ...rest,
    });
    window.addEventListener("resize", this.resizeCanvas);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCanvas);
  }

  getDimension = (img) => {
    const imageWidth = img.width;
    const imageHeight = img.height;
    const ratioImage = imageWidth / imageHeight;

    console.log(`img in getDimension`, img);
    this.resizeCanvas({ width: imageWidth, height: imageHeight, type: "bg" });

    // if (ratioImage > 1) {
    // 	this.canvas.setDimensions({
    // 		// width: this.canvas.getWidth(),
    // 		width: 760,
    // 		height: this.canvas.getWidth() / ratioImage,
    // 		// height: 477.712,
    // 	});
    // } else if (ratioImage == 1) {
    // 	this.canvas.setDimensions({
    // 		width: this.canvas.getWidth() - 200,
    // 		height: this.canvas.getWidth() - 200,
    // 		// height: 477.712,
    // 	});
    // } else if (ratioImage < 1 && ratioImage >= 0.75) {
    // 	this.canvas.setDimensions({
    // 		width: this.canvas.getHeight() * ratioImage,
    // 		height: this.canvas.getHeight() + 50,
    // 	});
    // } else {
    // 	this.canvas.setDimensions({
    // 		width: this.canvas.getHeight() * ratioImage,
    // 		height: this.canvas.getHeight() + 100,
    // 	});
    // }
  };

  componentDidUpdate = (previousProps, previousState) => {
    if (previousProps.adSpaceData !== this.props.adSpaceData) {
      console.log(
        "in component did update",
        previousProps.adSpaceData,
        this.props.adSpaceData
      );
      const {
        minZoom,
        maxZoom,
        ref,
        designNo,
        userId,
        templateName,
        onDeleteMap,
        jsonData,
        location,
        handleClose,
        setloading,
        ...rest
      } = this.props;
      this.handler = new Handler({
        id: this.state.id,
        adSpaceData: this.props.adSpaceData,
        canvas: this.canvas,
        getDimension: (dim) => {
          let data = this.getDimension(dim);
          return data;
        },
        ...rest,
      });
    }
  };

  DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { mimeString });
  };

  resizeCanvas({ width, height, type }) {
    console.log("resize width, height");
    const outerCanvasContainer = document.getElementById("adminFabric");

    console.log("outer canvas container", outerCanvasContainer);
    console.log("this.canvas.getWidth()", this.canvas.getWidth());
    const ratio =
      (width || this.canvas.getWidth()) / (height || this.canvas.getHeight());
    // console.log("resize ratio", ratio);
    const containerWidth = outerCanvasContainer.clientWidth;
    const containerHeight = outerCanvasContainer.clientHeight;

    const scale = containerWidth / this.canvas.getWidth();
    let zoom;
    // if (type === "bg") zoom = this.state.prevZoom;
    // previous zoom
    // else
    zoom = this.canvas.getZoom() * scale;

    this.setState({
      prevZoom: zoom,
    });

    console.log("zoom", zoom);
    console.log("containerWidth", containerWidth);

    // this.canvas.setWidth(containerWidth);
    // this.canvas.setHeight(containerWidth / ratio);
    this.canvas.setDimensions(
      {
        width: containerWidth,
        height: containerWidth / ratio,
      }
      // { backstoreOnly: true }
    );
    this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    this.canvas.calcOffset();

    this.canvas.renderAll();
  }

  render() {
    const { id } = this.state;
    return (
      <div className="canvas-wrapper fabric " id={id}>
        <div
          className="bttnDiv"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            style={{
              display: "flex",
              // justifyContent: "end",
            }}
          >
            <Button
              background="primar"
              fontSize="15px"
              border="1px solid #fff"
              color="primary"
              name="Save"
              loading={this.props.loading}
              handleClick={() => {
                this.props.setloading(true);
                // if (this.props.backgroungImg === undefined) {
                //   this.setState({
                //     noTemplateName: true,
                //   });
                //   this.props.setloading(false);
                //   return;
                // }
                const { onSave, handleExit } = this.props;
                // handleExit();
                let option = { name: "New Image", format: "png", quality: 1 };
                const dataUrl = this.canvas.toDataURL(option);
                let svgSimple = this.canvas.toJSON();

                svgSimple.objects.forEach((element, index) => {
                  this.canvas._objects.map((item, i) => {
                    if (i === index) {
                      element.id = item.id;
                    }
                  });
                });

                const svgJson = JSON.stringify(svgSimple);
                if (dataUrl) {
                  const file = this.DataURIToBlob(dataUrl);
                  onSave(svgJson, file, this.state.templateName, svgSimple);
                }
              }}
            />
            <div className="ml-2">
              <Button
                background="primar"
                fontSize="15px"
                border="1px solid #fff"
                color="primary"
                name="Cancel"
                handleClick={() => {
                  this.props.handleExit();
                  setTimeout(this.props.handleClose(), 9000);
                }}
              />{" "}
            </div>
          </div>
          <div className="pb-2">
            <Button
              background="secondary"
              fontSize="15px"
              border="1px solid #fff"
              color="secondary"
              name="Delete Map"
              handleClick={this.props.onDeleteMap}
            />
          </div>
          {/* {this.state.noTemplateName === true && (
            <div style={{ textAlign: "center", color: "White" }}>
              Please Upload Map Image !
            </div>
          )} */}
        </div>

        <div style={{ width: "100%" }} id="adminFabric">
          <canvas className="grabCanvas" id={`canvas_${id}`} />
        </div>
      </div>
    );
  }
}

export default Canvas;
