import React, { useState } from "react";
import Button from "../../../components/button/Button";
import { ValidateLoginForm } from "./Validation";

import InputField from "../../../components/input/Input";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../Header";
import Toast from "../../../components/toast/Toast";
import { AuthAction } from "../../../redux/actions/Index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { CustomerAction } from "../../../redux/actions/Index";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  ROUTES,
  APPLICATION_CONSTANTS,
  SITE_KEY,
} from "../../../services/constants/Index";
import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCaptcha } from "../../../redux/actions/AuthAction";

const Login = (props) => {
  console.log("props login :>> ", props);
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = React.useState();
  const [state, setState] = useState();
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = React.useState();
  const [isShowPassword, setPasswordType] = React.useState(false);
  const [botCheck, setBotCheck] = React.useState({
    human: false,
    humanKey: null,
  });
  const [isDisabled, setDisabled] = useState(true);

  const { loadingState } = useSelector((state) => state.CommonReducer);
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // console.log("Captcha value:", state?.captcha);

  // async function onChange(value) {
  //   // const body = {
  //   //   secret: "6LcLzMUcAAAAAP4HPTA5Xx-uXIM9Dxd4Pq2MGChH",
  //   //   response: value,
  //   // };
  //   // const status = await dispatch(AuthAction.verifyCaptcha(body));
  //   console.log("captcha status", value);
  //   // setBotCheck(true);
  // }

  const onChange = async (res) => {
    if (res) {
      console.log("captcha status", res);

      // this.setState({ human: true, humanKey: res });
      setBotCheck({
        ...botCheck,
        human: true,
        humanKey: res,
      });
      setDisabled(false);
      const status = await dispatch(AuthAction.verifyCaptcha(res));

      // this.setState({ disabled: this.isDisabled() });
    }
  };

  // ReCAPTCHA Expired
  const expireCaptcha = () => {
    this.setState({ human: false, humanKey: null });
    setBotCheck({
      ...botCheck,
      human: false,
      humanKey: null,
    });
    setDisabled(true);
    // this.setState({ disabled: this.isDisabled() })
  };

  async function onSubmit(event) {
    event.preventDefault();
    console.log("isdisabled", isDisabled);
    // if (isDisabled) return;

    console.log("state in login", state);
    const result = await ValidateLoginForm(state);
    if (result?.isFormValid) {
      setError({});
      const status = await dispatch(AuthAction.login(state));
      console.log("LOGIN STATUS::", status);
      if (status && status.data && status.data.role) {
        const { role } = status.data;

        switch (role) {
          case "admin":
            history.push(ROUTES.VENUE_MANAGEMENT);
            break;
          case "customer":
            if (localStorage.getItem("venueId")) {
              if (props.location.state && props.location.state.prevRoute) {
                console.log("PROVEIOS", props.location.state.prevRoute);
                history.push(props.location.state.prevRoute);
              } else history.goBack();
            } else {
              history.push(ROUTES.MYORDER);
            }
            break;
          default:
            break;
        }
      }
    } else if (result?.isFormValid === false) {
      setError(result?.error);
      return;
    }
    setToast(true);
  }

  console.log("history", history);

  return (
    <div className="AuthBg">
      <Header type="type1" />
      {history.location.state?.previousPath === "Cart" ? (
        <>
          <div className="backarrow mt-5 ml-5 d-flex">
            <div className="backIcon mr-2" onClick={() => history.goBack()}>
              <ArrowBack className="icon" />
            </div>
            Ad-spaces
          </div>
          <div className="headCart mb-4 d-flex text-center ">
            <span className="hrline">
              <hr />
            </span>
            Registered User
            <span className="hrline">
              <hr />
            </span>
          </div>
        </>
      ) : (
        <div className="" style={{ marginTop: "50px" }}></div>
      )}

      <div className="Login mb-5 ">
        <div className="signInFormDiv signInmain">
          <div className="formDiv">
            <h1 className="signInHeading">Sign In </h1>
            <div className="randomName">
              <form className="signInForm" noValidate autoComplete="off">
                <div className="signInEmail">
                  <InputField
                    label="E-Mail"
                    type="text"
                    id="custom-css-outlined-input"
                    name="email"
                    value={state?.email}
                    handleChange={handleChange}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.email[0]}
                  </p>
                </div>
                <div className="signInPassword">
                  <InputField
                    label="Password"
                    type={isShowPassword == true ? "text" : "password"}
                    variant="outlined"
                    id="custom-css-outlined-input"
                    name="password"
                    value={state?.password}
                    handleChange={handleChange}
                    onSubmit={onSubmit}
                  />
                  {isShowPassword === true ? (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(false)}
                    >
                      <VisibilityIcon />
                    </a>
                  ) : (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(true)}
                    >
                      <VisibilityOffIcon />
                    </a>
                  )}

                  <p className="errorMsg">
                    {" "}
                    {errorData?.password && errorData.password[0]}
                  </p>
                </div>
                <div className="mb-2">
                  <ReCAPTCHA
                    sitekey={SITE_KEY}
                    onChange={onChange}
                    onExpired={expireCaptcha}
                  />
                </div>
                <div className="signInFormOptions">
                  <div className="rememberMeOption">
                    <input
                      type="checkbox"
                      onChange={(e) => setChecked(!checked)}
                    />
                    <label className="pt-1 mt-1">Remember Me</label>
                  </div>
                  <Link to={`/forgot-password/user`}>
                    <h6>Forgot password?</h6>
                  </Link>
                </div>
                <div class="haveaccount text-center py-2">
                  <p className="mb-0">
                    Don't have an account?{" "}
                    <Link to={ROUTES.REGISTER}>
                      <a>Sign up</a>
                    </Link>
                  </p>
                </div>
                <div class="haveaccount text-center py-2">
                  <p className="mb-0">
                    Login as Vendor/Venue/Staff?
                    <Link to={ROUTES.VENUE_VENDOR_LOGIN}>
                      <a>Login</a>
                    </Link>
                  </p>
                </div>
                <div className="signInButton">
                  <Button
                    background="primary"
                    color="tertiary"
                    name="Sign In"
                    handleClick={onSubmit}
                    // disabled={isDisabled}
                    loading={loadingState}
                    // disabled={botCheck ? false : true}
                  />
                </div>
              </form>
            </div>
            {toast?.open ? (
              <Toast
                open={toast.open}
                message={toast.message}
                duration={toast.duration}
                type={toast.type}
                handleClose={() =>
                  setToast({
                    ...toast,
                    open: false,
                  })
                }
              ></Toast>
            ) : null}
          </div>
        </div>
        {/* <div>
          <p className="loginBelowLine">
            Visit the Epeius Consulting corporate &nbsp;
            <a
              className="loginHyperLink"
              target="_blank"
              href="https://epeius-consulting.com/brandevent/"
            >
              website
            </a>
            &nbsp; to learn about brandevent and our other solutions
          </p>
        </div> */}
        <div>
          <p className="loginBelowLine">
            For suppor, please call or text us: (209) 32-BRAND, (209) 322-7263
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
