import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import "./PriceCard.css";
import DeleteIcon from "@material-ui/icons/Delete";
import testImage from "../../../../images/commonImage/elevator.png";
import { formatUsCurrency } from "../../../../services/utills/helper";
function ItemCard({
  data,
  handleRemoveFromCart,
  decreaseQuantity,
  increaseQuantity,
  loading,
  notThisOption,
}) {
  console.log("🚀 ~ file: ItemCard.js ~ line 16 ~ data", data);
  return (
    <div className="itemcard itemcard1 col-md-7">
      <div className="box">
        <div className="left">
          <img src={data?.url} />
          {/* <img src={testImage} /> */}
        </div>
        <div className="right flex-column mt-5">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "22px",
                fontFamily: "Whitney Semibold",
                marginBottom: "0",
                fontWeight: "800",
              }}
            >
              {data?.name}
            </p>
            {notThisOption ? null : (
              <button
                style={{
                  color: "red",
                }}
                className="deleteButton"
                onClick={() => handleRemoveFromCart(data)}
              >
                <DeleteIcon />
              </button>
            )}
          </div>
          <span style={{ fontFamily: "Whitney Book", fontSize: "16px" }}>
            {data?.info}
          </span>
          <h5
            // className="mt-2"
            style={{ fontFamily: "Whitney Book", fontSize: "16px" }}
          >
            <strong
              style={{
                fontFamily: "Whitney Book",
                fontSize: "18px",
                fontFamily: "Whitney Semibold",
                marginBottom: "0",
                fontWeight: "800",
              }}
            >
              Item ID :
            </strong>{" "}
            {data?.itemId}{" "}
          </h5>
          <h5
            style={{ fontFamily: "Whitney Book", fontSize: "16px" }}
            // className="mt-2"
          >
            <strong
              style={{
                fontFamily: "Whitney Book",
                fontSize: "18px",
                fontFamily: "Whitney Semibold",
                marginBottom: "0",
                fontWeight: "800",
              }}
            >
              {"Package : "}
            </strong>{" "}
            {data?.description}
          </h5>

          <h5
            // className="mt-2"
            style={{ fontFamily: "Whitney Book", fontSize: "16px" }}
          >
            {/* <strong>
							</strong>  */}
            <strong
              style={{
                fontFamily: "Whitney Book",
                fontSize: "18px",
                fontFamily: "Whitney Semibold",
                marginBottom: "0",
                fontWeight: "800",
              }}
            >
              Price :{" "}
            </strong>
            {formatUsCurrency(data?.price)}
            {/* ${Number(data?.price).toFixed(2)} */}
          </h5>
          {/* <h5
            // className="mt-2"
            style={{ fontFamily: "Whitney Book", fontSize: "16px" }}
          >
            <strong
              style={{
                fontFamily: "Whitney Book",
                fontSize: "18px",
                fontFamily: "Whitney Semibold",
                marginBottom: "0",
                fontWeight: "800",
              }}
            >
              Zone :{" "}
            </strong>
            {data?.zone || "-"}
          </h5> */}

          {/* <h5
            // className="mt-2"
            style={{ fontFamily: "Whitney Book", fontSize: "16px" }}
          > */}
          {/* <strong>
							</strong>  */}
          {/* <strong
              style={{
                fontFamily: "Whitney Book",
                fontSize: "18px",
                fontFamily: "Whitney Semibold",
                marginBottom: "0",
                fontWeight: "800",
              }}
            >
              Map ID :{" "}
            </strong>
            {data?.mapId} */}
          {/* </h5> */}

          {data.realEstateSale && data.realEstateSale === true ? null : (
            <span style={{ fontFamily: "Whitney Book", fontSize: "16px" }}>
              Includes graphics support, print and install
            </span>
          )}
          {notThisOption ? null : (
            <div className="inc__dec__qty">
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Button
                  className="btn1"
                  onClick={() => decreaseQuantity(data, "decrease")}
                  disabled={loading}
                >
                  -
                </Button>
                <Button className="btn2">{data.quantity}</Button>
                <Button
                  className="btn1"
                  onClick={() => increaseQuantity(data, "increase")}
                  disabled={loading}
                >
                  +
                </Button>
              </ButtonGroup>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ItemCard;
