import {
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
  ROUTES,
} from "../../services/constants/Index";
import Api from "../../services/utills/Axios";
import { toast } from "../../services/utills/toast";
import { handleError } from "./CommonAction";
import { CommonAction, CustomerAction } from "./Index";

export const getAllOrder = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/order/customer/${userId}`);

    await dispatch({
      type: ACTION_TYPE.ORDER_ALL,
      payload: { data: res.data.data.result },
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const getProfile = (userId, email) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/user/${userId}`, { email });

    await dispatch({
      type: ACTION_TYPE.PROFILE,
      payload: { data: res.data },
    });
    return res.data.data;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const updateProfile =
  ({ userId, updateBody, callback }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/user/editProfile/${userId}`, { updateBody });

      await dispatch({
        type: ACTION_TYPE.PROFILE,
        payload: { data: res.data },
      });
      callback({ data: res.data.data });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      // dispatch(handleError+(error));
    }
  };

export const getOrderDetails = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/order/${orderId}`);

    await dispatch({
      type: ACTION_TYPE.ORDER_DETAIL,
      payload: { data: res.data.data.result[0] },
    });

    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const getOrderDetailsFromToken = (token) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/order/token/get/${token}`);
    console.log("res", res);

    await dispatch({
      type: ACTION_TYPE.CHECKOUT_ORDER_DETAILS,
      payload: res?.data?.data,
    });

    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const getVenueById = (venueId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/venue/${venueId}`);

    console.log("venue details ", res.data);

    await dispatch({
      type: ACTION_TYPE.VENUE_DETAIL,
      payload: { data: res.data },
    });

    return res.data;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const getMapById = (venueId, mapId, orderId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/venue/map/${venueId}/${mapId}`, { orderId });

    console.log("venue details ", res.data);
    // await dispatch({
    //   type: ACTION_TYPE.EMPTY_MAP_DATA,

    // });

    await dispatch({
      type: ACTION_TYPE.MAP_DATA,
      payload: res.data,
    });

    return res.data;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const getAdSpaceById = (adSpaceId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/adspace/${adSpaceId}`);

    console.log("venue details ", res);

    await dispatch({
      type: ACTION_TYPE.ADSPACE_DETAIL,
      payload: { data: res.data.data.result[0] },
    });

    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const getCartItems = (customerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/cart/getCartItems/${customerId}`);

    console.log("cart api res", res);

    await dispatch({
      type: ACTION_TYPE.CART,
      payload: { data: res.data.data },
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const removeFromCart = (customerId, itemId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/cart/removeFromCart`, { customerId, itemId });

    await dispatch({
      type: ACTION_TYPE.CART,
      payload: { data: res.data.data },
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const clearCart = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    await dispatch({
      type: ACTION_TYPE.CLEAR_CART,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const changeQuantity =
  (customerId, itemId, quantity) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/cart/changeQuantity`, {
        customerId,
        itemId,
        quantity,
      });

      await dispatch({
        type: ACTION_TYPE.CART,
        payload: { data: res.data.data },
      });
      return res;
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      // dispatch(handleError+(error));
    }
  };

export const addToCart = (finalData) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("finalData", finalData);
    // return;

    await dispatch({
      type: ACTION_TYPE.ADD_TO_CART,
      // payload: { data: res.data.data },
      payload: {
        data: finalData,
      },
    });
    // return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};
export const addCustomerInfo = (customerInfo) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.ADD_CUSTOMER_INFO,
    // payload: { data: res.data.data },
    payload: customerInfo,
  });
};

export const sendProposal = (data) => async (dispatch) => {
  try {
    console.log("sendProposal action initiate");

    dispatch(CommonAction.startLoading());
    const res = await Api.post(`/order/sendProposal`, data);

    dispatch(CommonAction.completeLoading("Proposal sent successfully"));

    console.log("sendProposal action", res);
    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch({ type: ACTION_TYPE.API_CALL_FAILURE });
    // dispatch(handleError+(error));
  }
};

export const addEventData = (eventData) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("finalData", eventData);
    // return;

    await dispatch({
      type: ACTION_TYPE.EVENT_DATA,
      // payload: { data: res.data.data },
      payload: {
        data: eventData,
      },
    });
    // return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const placeOrder =
  ({ orderId, history }) =>
  async (dispatch) => {
    try {
      console.log("placeOrder action initiate");

      dispatch({ type: ACTION_TYPE.API_CALL_STARTED });
      Api.post(`/order/add`, { orderId })
        .then(async (res) => {
          await dispatch({
            type: ACTION_TYPE.PLACE_ORDER,
            payload: { data: res.data.data },
          });
          dispatch({ type: ACTION_TYPE.API_CALL_COMPLETED });
          console.log("placeOrder action", res);

          dispatch(clearCart());

          history.push({
            pathname: ROUTES.ORDERCONFIRMATION,
            search: `?id=${res?.data?.data?._id}`, //{ id: res.data._id },
          });
          // return res.data;
        })
        .catch((error) => {
          console.log("error login :>> ", error, error.response);

          if (error.response.data && error.response.data.data)
            dispatch(CommonAction.failedLoading(error.response.data.data));
          else toast.error("something went wrong");
        });
    } catch (error) {
      console.log("error login :>> ", error.response);

      if (error.response) toast.error(error.response.data.data);
      else toast.error("something went wrong");
      dispatch(handleError(error));
      // dispatch(CommonAction.failedLoading("somehting wong happened"))
      // dispatch({ type: "LOADING_FAILURE" });
      // dispatch({ type: ACTION_TYPE.API_CALL_FAILURE });
      // dispatch(handleError+(error));
    }
  };

export const getVenueName = (venueId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/venue/name/${venueId}`);
    console.log("inside get venue name", res);
    await dispatch({
      type: ACTION_TYPE.VENUE_NAME,
      payload: { data: res.data },
    });

    return res.data;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export function getOrderDetailsById(orderId) {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.get(`/venue/order/${orderId}`);
      console.log("inside get order name", res);
      await dispatch({
        type: ACTION_TYPE.ORDER_DETAILS_BY_ID,
        payload: { data: res.data },
      });

      return res.data;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      // dispatch(handleError+(error));
    }
  };
}
export const getVenueId = (venueId) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_TYPE.VENUE_ID,
      payload: venueId,
    });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};
