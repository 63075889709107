import { ACTION_TYPE } from "../../services/constants/Index";

export const initialState = {
  order: [],
  profile: {},
  orderDetail: {},
  venue: {},
  adSpace: {},
  cart: [],
  customerType: "",
  venueId: "",
  venueData: {},
  customerInfo: {},
  eventData: {},
  checkoutOrderDetails: {},
  mapData: [],
  orderDetailsById: null
};
export const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.ORDER_ALL:
      return {
        ...state,
        order: action.payload.data,
      };
    case ACTION_TYPE.ADD_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload,
      };

    case ACTION_TYPE.ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.payload.data,
      };

    case ACTION_TYPE.CHECKOUT_ORDER_DETAILS:
      return {
        ...state,
        checkoutOrderDetails: action.payload,
      };
    case ACTION_TYPE.PROFILE:
      return {
        ...state,
        profile: action.payload.data.data,
      };
    case ACTION_TYPE.VENUE_DETAIL:
      return {
        ...state,
        venue: action.payload.data,
        adSpace: action.payload.data.adSpace,
      };

    case ACTION_TYPE.EMPTY_MAP_DATA:
      return {
        ...state,
        mapData: [],
      };

    case ACTION_TYPE.MAP_DATA:
      console.log("map data reducer", action.payload);
      console.log("map data reducer", state.mapData);
      return {
        ...state,
        mapData: [...state.mapData, action.payload],
      };
    case ACTION_TYPE.ADSPACE_DETAIL:
      return {
        ...state,
        adSpace: action.payload.data.data,
      };
    case ACTION_TYPE.CART:
      return {
        ...state,
        cart: action.payload,
      };
    case ACTION_TYPE.CLEAR_ORDER_DETAILS:
      return {
        ...state,
        orderDetail: {},
      };
    case ACTION_TYPE.CLEAR_CART:
      return {
        ...state,
        cart: [],
        eventData: {},
      };
    case "CUSTOMER_TYPE":
      return {
        ...state,
        customerType: action.payload,
      };
    case ACTION_TYPE.ADD_TO_CART:
      return {
        ...state,
        cart: action.payload.data,
      };

    case ACTION_TYPE.VENUE_ID:
      return {
        ...state,
        venueId: action.payload,
      };
    case ACTION_TYPE.VENUE_NAME:
      return {
        ...state,
        venueData: action.payload.data.data,
      };
    case ACTION_TYPE.EVENT_DATA:
      return {
        ...state,
        eventData: action.payload.data,
      };
    case ACTION_TYPE.ORDER_DETAILS_BY_ID:
      return {
        ...state,
        orderDetailsById: action.payload.data.data,
      };


    default:
      return state;
  }
};
