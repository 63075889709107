import React, { useState } from "react";
import InputField from "../input/Input";

const DiscountSection = ({ state, handleChange, errorData, events }) => {
  console.log("state", state);

  return (
    <div>
      <div className="d-flex flex-row row">
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <select
            value={state?.discountType}
            style={{
              padding: "15px 10px",
              height: "56px",
              border: "1px solid",
            }}
            name="discountType"
            className="form-control"
            onChange={handleChange}
          >
            <option selected value="null">
              Select Discount Type{" "}
            </option>
            <option value={"percentage"}> Percentage </option>
            <option value={"fixed"}> Fixed </option>
          </select>
          <p className="errorMsg">
            {" "}
            {errorData?.discountType && errorData.discountType[0]}
          </p>
        </div>
        <div className="customerInfoInput d-flex flex-column col-md-6 col-lg-6">
          <InputField
            label="Discount"
            variant="outlined"
            type="text"
            id="custom-css-outlined-input"
            name="discount"
            value={state?.discount}
            handleChange={handleChange}
          />
          <p className="errorMsg">
            {" "}
            {errorData?.discount && errorData.discount[0]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DiscountSection;
