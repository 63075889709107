import React, { useState, useEffect } from "react";

import "./PriceCard.css";
import Button from "../button/Button";
import InputField from "../input/Input";
import { useSelector, useDispatch } from "react-redux";
import { CustomerAction } from "../../redux/actions/Index";
import { useLocation } from "react-router-dom";
import { formatUsCurrency, formatUSNumber } from "../../services/utills/helper";
import { Checkbox } from "@material-ui/core";

function PriceCard({
  cartLength,
  subTotal,
  serviceChargeTotal,
  salesTaxTotal,
  totalAmount,
  serviceCharge,
  salesTax,
  handleCheckout,
  notThisOption,
  title,
  errorData,
  handleOrder,
  buttonName,
  loading,
  venueData,
  eventData,
  isChecked,
  setChecked,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  // const [eventData, setEventData] = useState();
  // const { venueData } = useSelector((state) => state.CustomerReducer);
  // const event = useSelector((state) => state.CustomerReducer.eventData);

  // console.log("pricce props", location.pathname === "/cart", eventData);

  // useEffect(() => {
  //   setEventData({ ...event });
  // }, [event]);

  // const handleChange = (e) => {
  //   const { value, name } = e.target;
  //   setEventData({
  //     ...eventData,
  //     [name]: value,
  //   });
  // };

  return (
    <div className="priceCard priceCard1 col-md-4 text-center">
      {title ? (
        <h4 style={{ fontFamily: "Whiteney Semibold" }}>Order Summary</h4>
      ) : (
        <h4>Your Order</h4>
      )}
      <div className="box">
        <div className="row newRow d-flex flex-column">
          <h5
            style={{ fontFamily: "Whitney Book", fontWeight: 800 }}
            className="mt-2 mb-2"
          >
            Sub-total ({cartLength} {cartLength > 1 ? "items" : "item"})
          </h5>
          <h4>
            {/* <strong>${Number(totalAmount).toFixed(2)}</strong> */}
            <strong>{formatUsCurrency(subTotal)}</strong>
          </h4>
        </div>

        <div className="row newRow d-flex flex-column">
          <h5
            style={{ fontFamily: "Whitney Book", fontWeight: 800 }}
            className="mt-2 mb-2"
          >
            Service/Administration charge @ {serviceCharge}%
          </h5>
          <h4>
            {/* <strong>${Number(totalAmount).toFixed(2)}</strong> */}
            <strong>
              {formatUsCurrency(serviceChargeTotal && serviceChargeTotal) ||
                "$0"}
            </strong>
          </h4>
        </div>
        <div className="row newRow d-flex flex-column">
          <h5
            style={{ fontFamily: "Whitney Book", fontWeight: 800 }}
            className="mt-2 mb-2"
          >
            Sales Tax @ {salesTax}%
          </h5>
          <h4>
            {/* <strong>${Number(totalAmount).toFixed(2)}</strong> */}
            <strong>
              {formatUsCurrency(salesTaxTotal && salesTaxTotal) || "$0"}
            </strong>
          </h4>
        </div>
        <div className="row newRow d-flex flex-column">
          <h5
            style={{ fontFamily: "Whitney Book", fontWeight: 800 }}
            className="mt-2 mb-2"
          >
            Order Total
          </h5>
          <h4>
            {/* <strong>${Number(totalAmount).toFixed(2)}</strong> */}
            <strong>{formatUsCurrency(totalAmount && totalAmount)}</strong>
          </h4>
        </div>
      </div>
      <div className="priceCardOutside">
        <div className="priceCardInput">
          <label htmlFor="GroupName">Group name</label>
          <input
            id="GroupName"
            type="text"
            name="groupName"
            placeholder=""
            value={
              // location.pathname === "/cart"
              // ?
              eventData?.groupName
              // : event?.groupName
            }
            // onChange={handleChange}
            readOnly={location.pathname === "/cart" ? false : true}
          />
          {/* <p className="errorMsg">
            {" "}
            {errorData?.groupName && errorData.groupName[0]}
          </p> */}
        </div>
        <div className="priceCardInput">
          <label htmlFor="GroupName">Meeting or Event name(CODE)</label>
          <input
            type="text"
            name="eventName"
            placeholder=""
            value={
              // location.pathname === "/cart"
              // ?
              eventData?.eventName
              // : event?.eventName
            }
            // onChange={handleChange}
            readOnly={location.pathname === "/cart" ? false : true}
          />
          {/* <p className="errorMsg">
            {" "}
            {errorData?.eventName && errorData.eventName[0]}
          </p> */}
        </div>

        <div className="priceCardInput">
          <label htmlFor="GroupName">Meeting or Event start Date</label>
          <input
            type="date"
            name="eventStartDate"
            placeholder=""
            value={
              // location?.pathname === "/cart"
              //   ?
              eventData?.eventStartDate
              // : event?.eventStartDate
            }
            // onChange={handleChange}
            readOnly={location.pathname === "/cart" ? false : true}
          />
        </div>
        <img className="newLogo" src={venueData?.logo} alt="Hilton" />

        <div className="sec1 cartvenueDetails">
          {/* <img className="newLogo" src={venueData?.logo} alt="Hilton" /> */}
          <div className="venueDetails ">
            <p>{`${venueData?.street}, ${venueData?.city} ${venueData?.state} ${venueData?.zip}`}</p>
            <p>
              T : {formatUSNumber(venueData?.phone)} | E : {venueData?.email}
            </p>
          </div>
        </div>
        <div>
          <div>
            <Checkbox
              checked={isChecked}
              onChange={setChecked}
              inputProps={{ "aria-label": "controlled" }}
            />{" "}
            <span>
              I agree to the{" "}
              <a
                href="https://epeius-consulting.com/terms-and-conditions/"
                target="_blank"
                style={{ textDecoration: "underline", color: "blue" }}
              >
                {" "}
                Terms & Conditions{" "}
              </a>
            </span>
          </div>
          <p className="errorMsg">
            {" "}
            {errorData?.isChecked && errorData.isChecked[0]}
          </p>
        </div>
        <div className="text-center mt-4 mb-4 PriceCardBtn">
          {/* {notThisOption ? null : ( */}
          <Button
            handleClick={() => {
              const x = handleOrder
                ? handleOrder()
                : (handleCheckout(eventData),
                  dispatch(CustomerAction.addEventData(eventData)));
            }}
            background="primary"
            color="tertiary"
            loading={loading}
            disabled={loading}
            // name="Request Proposal"
            name={buttonName ? buttonName : "Request Proposal"}
          ></Button>
          <p>For Brandevent 24/7 support, call or text: (209) 322-7263</p>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default PriceCard;
