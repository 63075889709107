import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import { ValidateLoginForm } from "./Validation";
import "./style.css";
import InputField from "../../../components/input/Input";

import Header from "../Header";
import Toast from "../../../components/toast/Toast";
import queryString from "query-string";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AuthAction } from "../../../redux/actions/Index";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../services/constants/Index";
const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState();
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [isShowPassword, setPasswordType] = React.useState(false);
  const [isShowConfirmPassword, setConfirmPasswordType] = React.useState(false);
  const [errorData, setError] = React.useState();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  async function onSubmit(event) {
    event.preventDefault();
    const result = await ValidateLoginForm(state);

    if (result?.isFormValid) {
      setError({});
      console.log("vvvvv11", state);

      const status = await dispatch(AuthAction.resetPassword(state));
      console.log("STATATATA", status);
      if (status.status) {
        setMsg("Password Updated");
        setState({ password: "", confirmPassword: "" });
        setToast(true);
        setTimeout(() => {
          history.push({ pathname: ROUTES.LOGIN });
        }, 3000);
      } else {
        setMsg("Something went wrong!");

        setToast(true);
      }

      // setToastMessage(status.message);
      // setToastError(status.status);
      // setToast(true);
    } else if (result?.isFormValid === false) {
      setError(result?.error);
      console.log("vvvvv2", result?.error);
      return;
    }
    setToast(true);
    console.log("vvvvv3", state);
  }
  useEffect(() => {
    const resetKey = queryString.parse(props.location.search).accessKey;
    setState((prevState) => ({ ...prevState, resetKey: resetKey }));
  }, []);
  return (
    <div className="AuthBg">
      <Header type="type1" />
      <div className="ForgotPassword">
        <div className="ForgotPasswordFormDiv ForgotPasswordmain">
          <div className="formDiv">
            <h1 className="ForgotPasswordHeading">Reset Password</h1>
            <div className="randomName">
              <form
                className="ForgotPasswordForm"
                noValidate
                autoComplete="off"
              >
                <div className="d-flex flex-row row">
                  <div className=" d-flex flex-column col-md-6 col-lg-6">
                    <InputField
                      label="New Password"
                      type={isShowPassword == true ? "text" : "password"}
                      variant="outlined"
                      id="custom-css-outlined-input"
                      name="password"
                      value={state?.password}
                      handleChange={handleChange}
                    />
                    <p className="errorMsg">
                      {" "}
                      {errorData &&
                        errorData?.password &&
                        errorData?.password[0]}
                    </p>
                    {isShowPassword === true ? (
                      <a
                        className="eyeicon"
                        onClick={() => setPasswordType(false)}
                      >
                        <VisibilityIcon />
                      </a>
                    ) : (
                      <a
                        className="eyeicon"
                        onClick={() => setPasswordType(true)}
                      >
                        <VisibilityOffIcon />
                      </a>
                    )}
                  </div>
                  <div className=" d-flex flex-column col-md-6 col-lg-6">
                    <InputField
                      label=" Confirm Password"
                      type={isShowConfirmPassword == true ? "text" : "password"}
                      variant="outlined"
                      id="custom-css-outlined-input"
                      name="confirmPassword"
                      value={state?.confirmPassword}
                      handleChange={handleChange}
                    />
                    <p className="errorMsg">
                      {" "}
                      {errorData &&
                        errorData?.confirmPassword &&
                        errorData?.confirmPassword[0]}
                    </p>
                    {isShowConfirmPassword === true ? (
                      <a
                        className="eyeicon"
                        onClick={() => setConfirmPasswordType(false)}
                      >
                        <VisibilityIcon />
                      </a>
                    ) : (
                      <a
                        className="eyeicon"
                        onClick={() => setConfirmPasswordType(true)}
                      >
                        <VisibilityOffIcon />
                      </a>
                    )}
                  </div>
                </div>{" "}
                <div className="signInButton">
                  <Button
                    background="primary"
                    color="tertiary"
                    name="Confirm"
                    handleClick={onSubmit}
                    loading={loading}
                  />
                </div>
              </form>
            </div>
            {toast ? (
              <Toast
                open={toast}
                message={msg}
                duration={4000}
                type={toast.type}
                handleClose={() => setToast(false)}
              ></Toast>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
