import "./style.css";
import React, { useState, useEffect } from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import LOGO from "../../../images/commonImage/logo3.png";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
	ROUTES,
	APPLICATION_CONSTANTS,
} from "../../../services/constants/Index";
import { useSelector } from "react-redux";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { AccountCircle } from "@material-ui/icons";
import Account from "../../../components/account/Account";
import { calculateCartItems } from "../../../services/utills/helper";
import { useQuery } from "../../../hooks/useQueryHook";
const Header = (props) => {
	let location = useLocation();
	const history = useHistory();
	const getQuery = useQuery()
	const { cart, venueId } = useSelector((state) => state.CustomerReducer);
	const user = useSelector((state) => state.AuthReducer.userData);
	console.log("cart", user);
	const [badgeCount, setBadgeCount] = useState(0);
	const query = useQuery();
	const orderId = query.get('orderId')
	useEffect(() => {
		(async () => {
			const venueCartItems = await calculateCartItems(cart, venueId, orderId);
			setBadgeCount(venueCartItems.length);
		})();
	}, [cart]);

	return (
		<div className="headerWrapper">
			<div className="newHeaderNav">
				<Navbar className="navhdr" expand="lg">
					<Navbar.Brand className="homeLogo" href="/">
						<img alt="logo" className="headerLogo1" src={LOGO} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto listOfHead align-items-center ">
							<NavLink activeClassName="activeLink" to="/about-us">
								About
							</NavLink>
							<NavLink activeClassName="activeLink" to="/faq">
								FAQs
							</NavLink>
							<NavLink activeClassName="activeLink" to="/contact-us">
								Contact
							</NavLink>
							{/* {localStorage.getItem("token") ? ( */}
							{user && user._id ? (
								<h6
									style={{
										fontWeight: 600,
										marginBottom: 0,
										marginLeft: "10px",
									}}
								>
									<AccountCircle />
									{user?.firstName}
									<Account></Account>
								</h6>
							) : (
								<NavLink
									activeClassName=""
									to={{
										pathname: "/login",
										state: { prevRoute: history.location.pathname },
									}}
								>
									Sign In
								</NavLink>
							)}
							{location.pathname === ROUTES.LOGIN ||
								//   location.pathname === ROUTES.FAQ ||
								//   location.pathname === ROUTES.ABOUTUS ||
								location.pathname === ROUTES.REGISTER ||
								location.pathname === ROUTES.VENUE_VENDOR_LOGIN ||
								location.pathname === ROUTES.VENUE_VENDOR_REGISTER ? null : (
								<div
									className="left cartFix mr-5 cartWrapper "
									onClick={() => { getQuery.get('orderId') ? history.push(ROUTES.CART + `?orderId=${getQuery.get('orderId')}`) : history.push(ROUTES.CART) }}
								>
									<Badge
										// color="#FA460E"
										className="badgeIcon"
										// classes={{
										//   badge: {
										//     backgroundColor: "#FA460E",
										//     color: "#FA460E",
										//   },
										// }}
										badgeContent={badgeCount}
										showZero
									>
										<ShoppingCartIcon className="headerlogo" />
									</Badge>
								</div>
							)}
						</Nav>
					</Navbar.Collapse>
					{/* </Container> */}
				</Navbar>
			</div>
		</div>
	);
};

export default Header;
