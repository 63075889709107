import React, { useEffect, useState } from "react";
import "./styles.css";
import Table from "../../../components/table/Table";
import HeaderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import Button from "../../../components/button/Button";
import { useHistory } from "react-router-dom";
import {
  ACTION_TYPE,
  ROUTES,
  WEB_APP_URL,
} from "../../../services/constants/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerAction,
  AdminAction,
  StaffAction,
} from "../../../redux/actions/Index";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";

import AlertDialog from "../../../components/AlertDialog/alertDialog";
import moment from "moment";
import { customerInfoSave } from "../../../redux/actions/StaffAction";
const CustomerLinkManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.userData);
  const { userUpdated } = useSelector((state) => state.AuthReducer);

  const { orders } = useSelector((state) => state.StaffReducer);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState({
    state: false,
    id: null,
  });
  // const { order } = useSelector((state) => state.CustomerReducer);

  useEffect(() => {
    (async () => {
      if (user?._id && userUpdated) {
        dispatch(
          StaffAction.getStaffOrders({
            filter: "all",
            staffId: user._id,
          })
        );
      }
    })();
  }, [user, userUpdated]);

  useEffect(() => {
    getTableData();
  }, [orders]);

  useEffect(() => {
    dispatch(customerInfoSave({}));
  }, []);

  const getTableData = () => {
    console.log("results getTableData :>> ", orders);

    let finalData = orders.map((data) => {
      return {
        id: data["_id"],
        // venueId: data["venueId"],
        "Customer Name":
          data.customerId?.firstName + " " + data.customerId?.lastName,
        "Customer Link": `${WEB_APP_URL}/venue/${data.venueId.venueId}?orderId=${data.orderId}`,
        "Order Date": moment(data.CreatedAt).format("MM/DD/YYYY"),
        // Status: data["status"],
      };
      // return [data["_id"], data["firstName"], data["adSpace"]?.length];
    });

    setTableData(finalData);
  };

  const handleClick = () => {
    history.push(`${ROUTES.STAFF_CUSTOMER_INFO}`);
  };
  const handleClickIcon = (item) => {
    console.log("itewm ,", item);
    setOpen({
      state: true,
      id: item.id,
    });
  };
  return (
    <div>
      <div className="start venueM">
        {user?.role && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
        <div className="screen">
          <HeaderBar title="Venue Management" />
          <div className="addNewVendorVenMgmt">
            <Button
              // className="col-lg-3"
              background="primar"
              color="primary"
              name="Add New Customer Link"
              handleClick={handleClick}
            ></Button>
          </div>
          <div className="tabelOrder">
            <Table
              tableHead={["Customer Name", "Customer Link", "Order Date"]}
              // button="yes"
              tableData={tableData}
              // button="yes"
              // buttonTwo="yes"
              // handleBtnClick1={async (item) => {
              //   console.log("item :>> ", item);
              //   history.push(`${ROUTES.MAP_LIST}/${item.id}`);
              // }}
            />
            <AlertDialog
              open={open.state}
              handleClose={() =>
                setOpen({
                  state: false,
                  id: null,
                })
              }
              message="Are you sure you want to delete?"
              button1="Yes"
              button2="No"
              handleDelete={() => {
                dispatch(AdminAction.deleteVenue(open.id));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerLinkManagement;
