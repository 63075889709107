import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import HearderBar from "../../../components/headerBar/HeaderBar";
import SideNav from "../../../components/sideNav/SideNav";
import { ArrowBackSharp } from "@material-ui/icons";
import "./styles.css";
import Button from "../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction, CustomerAction } from "../../../redux/actions/Index";

import {
	AdSpaceDetailModal,
	CustomerDetailModal,
	VendorDetailModal,
	VenueDetailModal,
} from "../../../components/ObjectModal";
import RBAC from "../../../components/RBAC/RBAC";
import { initList } from "../../../components/sideNav/InitList";
import AdminAdSpaceDetails from "../../../components/ObjectModal/AdminAdSpaceDetails";
import { formatUsCurrency } from "../../../services/utills/helper";

const useStyles = makeStyles({
	root: {
		display: "flex",
	},
	arrow: {
		display: "flex",
		color: "#fff",
		alignItems: "center",
		width: "70px",
		justifyContent: "space-between",
		cursor: "pointer",
		fontSize: "18px",
		margin: "20px auto 20px 20px",
		["@media (max-width: 460px)"]: {
			margin: "40px auto 20px 0px",
			border: "1px solid white",
			padding: "5px",
			justifyContent: "flex-end",
		},
	},
});

const CustomerOrderDetails = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [id, setId] = useState();
	const [detailsModel, setDetailsModel] = useState(false);
	const { orderDetails } = useSelector((state) => state.AdminReducer);
	const user = useSelector((state) => state.AuthReducer.userData);

	console.log("orderDetails", orderDetails);

	useEffect(() => {
		(async () => {
			console.log("props.match.params", props.match.params);
			if (props.match.params.orderId) {
				const res = await dispatch(
					AdminAction.getCustomerOrderDetails(props.match.params.orderId)
				);
			}
		})();
	}, []);
	const [open, setOpen] = useState(false);
	const [openCustomerModal, setCustomerModal] = useState(false);
	const [openVendorModal, setVendorModal] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<div className="start">
				{user?.role && (
					<RBAC
						userRole={user?.role} // User roles that are given to user in the backend
						initList={initList}
						renderNoAccess={() => <SideNav />}
					>
						<SideNav />
					</RBAC>
				)}
				<div className="screen">
					<HearderBar title="Customer Management / Order Details" />
					<div className="venueDetailsWrapper">
						<div
							className={classes.arrow}
							onClick={() => {
								history.goBack();
							}}
						>
							<ArrowBackSharp /> Back
						</div>
					</div>

					<div className="adminOrderDetailsWrapper">
						<div className="adminOorderDetails">
							<div className="adminOrderDetailsLeft">
								<h3>Order ID</h3>
								<p>{orderDetails?._id}</p>
								{/* {orderDetails &&
									orderDetails?.OrderId &&
									setId(orderDetails?.OrderId)} */}
							</div>
							<div className="adminOrderDetailsMiddle">
								<h3>Price</h3>
								{/* <p>${Number(orderDetails?.price).toFixed(2)}</p> */}
								<p>{formatUsCurrency(orderDetails?.price)}</p>
							</div>
							<div className="adminOrderDetailsRight">
								<h3>Status</h3>
								<p>{orderDetails?.status}</p>
							</div>
						</div>
						<div>
							{orderDetails && orderDetails.eventData && (
								<div className="eventDataDetails">
									{" "}
									<div className="eventDataDetailsStart">
										<p>Event Start date</p>
										{orderDetails.eventData.eventStartDate}
									</div>
									<div className="eventDataDetailsGname">
										<p>Group Name</p>
										{orderDetails.eventData.groupName}
									</div>
									<div className="eventDataDetailsEname">
										<p>Event Name</p>
										{orderDetails.eventData.eventName}
									</div>
								</div>
							)}
						</div>
						<div className="adminOrderDetailsLower">
							<div className="adminOrderDetailsLowerWrapper">
								<div className="adminOrderDetailsVenueName">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Venue Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p
											style={{ color: "blue", cursor: "pointer" }}
											onClick={handleClick}
											className="adminOrderDetailsLowerWrapperRightPHover"
										>
											{orderDetails?.venueId?.firstName}{" "}
											{orderDetails?.venueId?.lastName}{" "}
										</p>
									</div>
								</div>
								<div className="adminOrderDetailsAdSpaces">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Ad Spaces Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p onClick={() => setDetailsModel(!detailsModel)}>
											{orderDetails?.adspaces?.map((data, index) => {
												return (
													<span
														className="adminOrderDetailsLowerWrapperRightPHover"
														style={{ color: "blue", cursor: "pointer" }}
														onClick={() => setId(data._id)}
													>
														{" "}
														{data?.adspace?.title}
													</span>
												);
											})}
										</p>
									</div>
								</div>
								<div className="adminOrderDetailsCustomerName">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Customer Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p
											className="adminOrderDetailsLowerWrapperRightPHover"
											style={{ color: "blue", cursor: "pointer" }}
											onClick={() => setCustomerModal(!openCustomerModal)}
										>
											{orderDetails?.customerInfo?.firstName}{" "}
											{orderDetails?.customerInfo?.lastName}{" "}
										</p>
									</div>
								</div>
								<div className="adminOrderDetailsVendorName">
									<div className="adminOrderDetailsLowerWrapperLeft">
										<p>Vendor Name</p>
									</div>
									<div className="adminOrderDetailsLowerWrapperRight">
										<p
											style={{ color: "blue", cursor: "pointer" }}
											onClick={() => setVendorModal(!openVendorModal)}
										>
											{orderDetails?.vendorId?.firstName}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<VenueDetailModal
				open={open}
				title="Header Title"
				handleClose={handleClose}
				data={orderDetails?.venueId}
			/>
			<VendorDetailModal
				open={openVendorModal}
				title="Header Title"
				handleClose={() => setVendorModal(false)}
				data={orderDetails?.vendorId}
			/>
			<CustomerDetailModal
				open={openCustomerModal}
				title="Header Title"
				handleClose={() => setCustomerModal(false)}
				data={orderDetails?.customerInfo}
			/>
			<AdminAdSpaceDetails
				id={id}
				// orderId={orderId}
				open={detailsModel}
				// previousPage="orderDetails"
				handleClose={() => {
					setDetailsModel(false);
				}}
				adSpaceData={orderDetails?.adspaces}
			/>
		</div>
	);
};

export default CustomerOrderDetails;
