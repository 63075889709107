import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AuthAction } from "./redux/actions/Index";
import Footer from "./components/Footer/Footer";
import Routes from "./Routes";
import { ROUTES } from "./services/constants/Index";
import { withRouter } from "react-router-dom";
import "./App.css";
function App(props) {
  const dispatch = useDispatch();
  const [ipadPro, setIpadPro] = useState(false);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("token")) {
        dispatch(AuthAction.matchToken()).then((status) => {
          console.log("status", status);
          if (status === false) {
            dispatch({ type: "LOGOUT_USER" });
          }
        });
      }
      if (window.innerHeight > 1000) {
        setIpadPro(true);
      }
      console.log(window.innerHeight);
      // else dispatch(AuthAction.logOut());
    })();
  }, []);

  return (
    <div className="App">
      {/* <div className="allpages"> */}
      <div className={ipadPro === true ? "ipadProPages" : "allpages"}>
        <Routes />
      </div>
      {props?.location?.pathname == ROUTES.CART ||
      props?.location?.pathname == ROUTES.ORDERDETAIL ? null : (
        <Footer />
      )}
    </div>
  );
}

export default withRouter(App);
