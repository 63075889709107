// /orders/get
import { ACTION_TYPE } from "../../services/constants/Index";
import Api from "../../services/utills/Axios";
import { CommonAction } from "./Index";

export const getVendorOrders =
  ({ filter, vendorId }) =>
  async (dispatch) => {
    try {
      dispatch(CommonAction.startLoading());

      const res = await Api.post(`/vendor/orders/get/${filter}`, { vendorId });
      console.log("Vendor all order", res);
      await dispatch({
        type: ACTION_TYPE.VENDOR_ALL_ORDERS,
        payload: { data: res.data.data.result },
      });
      dispatch(CommonAction.completeLoading("", false));
    } catch (error) {}
  };

export const vendorOrderDetails = (item) => async (dispatch) => {
  try {
    await dispatch({
      type: ACTION_TYPE.VENDOR_ALL_ORDERS_DETAILS,
      payload: { data: item },
    });
  } catch (error) {}
};

export const getVendorOrderDetails = (orderId) => async (dispatch) => {
  try {
    const res = await Api.get(`/vendor/orders/details/get/${orderId}`);
    console.log("Vendor all order", res);
    await dispatch({
      type: ACTION_TYPE.VENDOR_ALL_ORDERS_DETAILS,
      payload: { data: res.data.data.result },
    });
  } catch (error) {}
};

export const markCompleted =
  (payload, orderDetails, vendorId) => async (dispatch) => {
    try {
      console.log("payload :>> ", payload);

      dispatch(CommonAction.startLoading());
      const res = await Api.post(`/vendor/orders/changeStatus`, payload);
      console.log("vendorId :>> ", vendorId);
      // dispatch(getVenueOrders({ filter: "all", venueId }));

      console.log("Vendor all order", res);
      // await dispatch({
      //   type: ACTION_TYPE.ADMIN_ALL_ORDERS,
      //   payload: { data: res.data.data.result },
      // });
      orderDetails["status"] = payload["status"];
      await dispatch({
        type: ACTION_TYPE.VENDOR_ALL_ORDERS_DETAILS,
        payload: { data: orderDetails },
      });

      dispatch(CommonAction.completeLoading({}, false));
    } catch (error) {}
  };
