function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const validateOnAddStaffMember = (data) => {
  let error = 0;
  let formError = {};
  if (!data?.firstName) {
    error += 1;
    formError["firstName"] = "Please enter first name";
  }
  if (!data?.email) {
    error += 1;
    formError["email"] = "Please enter email";
  }
  if (
    !/^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,8})$/.test(
      data?.email || ""
    )
  ) {
    error += 1;
    formError["email"] = "Please enter valid email";
  }
  if (!data?.department) {
    error += 1;
    formError["department"] = "Please select department";
  }

  if (isBlank(data?.phone) === true) {
    error += 1;
    formError["phone"] = "This field is required.";
  } else if (isNumber(data?.phone) !== true) {
    error += 1;
    formError["phone"] = "This field should be number.";
  } else if (data?.phone?.length < 11) {
    error += 1;
    formError["phone"] = "Please enter a valid phone number.";
  }
  return { error, formError };
};
