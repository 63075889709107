import React from "react";
import Header from "../../Authentication/Header";
import Button from "../../../components/button/Button";
import "./LoginRegister.css";
import { ROUTES } from "../../../services/constants/Index";
import { useHistory } from "react-router-dom";

const LoginRegister = () => {
  const history = useHistory();

  return (
    <div>
      <Header type="type1" />
      <div className="loginRegister">
        <div className="loginRegisterBox">
          <h2>You are logged out of your account.</h2>
          <div className="loginRegisterButton">
            <Button
              background="primary"
              color="#fff"
              transition="background 0.3s"
              name="Login"
              handleClick={() => {
                history.push(ROUTES.LOGIN);
              }}
            ></Button>
            <span>or</span>
            <Button
              background="primary"
              borderRadius="15px"
              color="#fff"
              transition="background 0.3s"
              display="inline-flex"
              alignItem="center"
              name="Register"
              handleClick={() => {
                history.push(ROUTES.REGISTER);
              }}
            ></Button>
          </div>
          <p>to Continue.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;
