import React, { Component } from "react";
import { fabric } from "fabric";
import { v4 } from "uuid";
import Handler from "../handlers/handler";
import "./canvas.css";
class Canvas extends Component {
  constructor(props) {
    super(props);

    this.handler = null;
    this.canvas = null;
    this.height = 0;
    this.width = 0;
    this.templateName = "";
    this.noTemplateName = null;
    this.resizeCanvas = this.resizeCanvas.bind(this);

    this.state = {
      id: v4(),
      ref: this.props.ref,
      shrink: true,
      canvasWidth: "95%",
      img: "",
      toRefresh: false,
    };
  }

  componentDidMount() {
    const { id } = this.state;
    const {
      minZoom,
      maxZoom,
      ref,
      designNo,
      userId,
      state,
      jsonData,
      ...rest
    } = this.props;
    const outerCanvasContainer = document.getElementById(
      `fabri${this.state.id}`
    );
    const containerWidth = outerCanvasContainer.clientWidth;

    this.canvas = new fabric.Canvas(`${id}`, {
      // height: 480,
      width: containerWidth,
    });
    this.canvas.clear();

    console.log("jsondata", jsonData.data);

    let src;

    if (jsonData.data.backgroundImage) {
      src = jsonData.data.backgroundImage.src;
      let bgImg = jsonData.data.backgroundImage;

      delete jsonData.data.backgroundImage;

      const image = new fabric.Image.fromURL(
        src,
        (img) => {
          let scaledImg = {
            width: bgImg.width * bgImg.scaleX,
            height: bgImg.height * bgImg.scaleY,
          };
          this.setState({
            img,
          });

          if (img.width < containerWidth) {
            this.setState({
              canvasWidth: img.width,
            });
          }

          console.log(
            "img dims",
            img.width,

            bgImg.width,
            containerWidth
          );

          this.canvas.setDimensions(scaledImg);

          img.set({
            originX: "left",
            scaleX: scaledImg.width / img.width, //new update
            scaleY: scaledImg.height / img.height, //new update
            transparentCorners: false,
            originY: "top",
          });
          this.canvas.setBackgroundImage(
            img,
            this.canvas.renderAll.bind(this.canvas)
          );

          console.log(
            "image loadeddd",
            this.canvas.getWidth(),
            img.width,
            this.canvas.getWidth() / img.width
          );
          this.resizeCanvas({
            width: scaledImg.width,
            height: scaledImg.height,
            isMount: true,
          });
          this.canvas.renderAll();

          this.resizeCanvas({ width: false, height: false, isMount: true });
        },
        { crossOrigin: "Anonymous" }
      );
    }

    console.log("jsondata", jsonData.data);

    fabric.Object.NUM_FRACTION_DIGITS = 16;

    let loaded = this.canvas
      .loadFromJSON(
        jsonData.data,
        this.canvas.renderAll.bind(this.canvas),
        (o, object) => {
          this.resizeCanvas({ width: false, height: false, isMount: true });

          this.canvas.renderAll();
        }
      )
      .getWidth();

    console.log("loaded", loaded);

    this.canvas.renderAll();

    this.handler = new Handler({
      id,
      canvas: this.canvas,
      adSpaceData: this.props.jsonData.adSpaces,
      someFunc: () => {},
      ...rest,
    });

    window.addEventListener("resize", this.resizeCanvas);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCanvas);
  }

  resizeCanvas({ width, height, isMount }) {
    const { canvasWidth, img, toRefresh } = this.state;
    const outerCanvasContainer = document.getElementById(
      `fabri${this.state.id}`
    );

    console.log("isMount", isMount);

    // this.setState({
    //   toRefresh: img.width > window.innerWidth,
    // });

    // if (img.width > window.innerWidth && !isMount) {
    //   window.location.reload(false);
    //   this.setState({
    //     toRefresh: false,
    //   });
    // }
    if (outerCanvasContainer) {
      console.log("outer canvas container", outerCanvasContainer);
      console.log("this.canvas.getWidth()", this.canvas.getWidth());
      const ratio =
        (width || this.canvas.getWidth()) / (height || this.canvas.getHeight());
      // console.log("resize ratio", ratio);
      const containerWidth = outerCanvasContainer.clientWidth;
      const containerHeight = outerCanvasContainer.clientHeight;

      const scale = containerWidth / (width || this.canvas.getWidth());
      const zoom = this.canvas.getZoom() * scale;

      console.log("resize width", width, canvasWidth, zoom);

      console.log("containerWidth", containerWidth);

      // this.canvas.setWidth(containerWidth);
      // this.canvas.setHeight(containerWidth / ratio);
      this.canvas.setDimensions(
        {
          width: containerWidth,
          height: containerWidth / ratio,
        }
        // { backstoreOnly: true }
      );
      this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
      this.canvas.renderAll();
    }
  }

  render() {
    const { id, canvasWidth } = this.state;
    return (
      <div className="" id={`fabri${id}`} style={{ width: canvasWidth }}>
        <canvas
          id={id}
          // width={500}
          // height={500}
        />
      </div>
    );
  }
}

export default Canvas;
