import React from "react";
import { WEB_APP_URL } from "../../../services/constants/Index";
import { formatUSNumber } from "../../../services/utills/helper";

const index = ({ orderDetails }) => {
  return (
    <div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>Name </p>
          <p>{orderDetails?.venueId?.firstName}</p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Email</p>
          <p>{orderDetails?.venueId?.email}</p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>Phone</p>
          <p>{formatUSNumber(orderDetails?.venueId?.phone)}</p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>Street</p>
          <p>{orderDetails?.venueId?.street}</p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>City</p>
          <p>{orderDetails?.venueId?.city}</p>
        </div>
        <div className="venueDetailsAccordianRightNew">
          <p>State</p>
          <p>{orderDetails?.venueId?.state}</p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>Zip</p>
          <p>{orderDetails?.venueId?.zip}</p>
        </div>
        {/* <div className="venueDetailsAccordianRightNew">
          <p>Country</p>
          <p>{orderDetails?.venueId?.country}</p>
        </div> */}
        <div className="venueDetailsAccordianRightNew">
          <p>Venue ID</p>
          <p>{orderDetails?.venueId?.venueId}</p>
        </div>
      </div>
      <div className="venueDetailsAccordian">
        <div className="venueDetailsAccordianLeftNew">
          <p>No. of AdSpaces</p>
          <p>{String(orderDetails?.adspaces?.length)}</p>
        </div>

        <div className="venueDetailsAccordianRightNew">
          <p>Venue Link</p>
          <p>{`${WEB_APP_URL}/venue/${orderDetails?.venueId?.venueId}`}</p>
        </div>
      </div>

      {/* <div className="venueDetailsAccordian">
       
      </div> */}
    </div>
  );
};

export default index;
