import axios from "axios";
import {
  ACTION_TYPE,
  ENDPOINTS,
  APPLICATION_CONSTANTS,
  BASE_URL,
} from "../../services/constants/Index";
import Api from "../../services/utills/Axios";
import { formatDateForCsv } from "../../services/utills/helper";
import { CommonAction } from "./Index";

// Order Management
export const getAllOrder = (filter) => async (dispatch) => {
  try {
    dispatch(CommonAction.startLoading());
    const res = await Api.get(`/admin/allOrders/${filter}`);
    console.log("Admin all order", res);
    await dispatch({
      type: ACTION_TYPE.ADMIN_ALL_ORDERS,
      payload: { data: res.data.data.result },
    });

    dispatch(CommonAction.completeLoading("", false));
  } catch (error) {}
};

export const getOrderFilters = () => async (dispatch) => {
  try {
    const res = await Api.get(`/admin/orderFilters`);
    console.log("Admin all order", res);
    await dispatch({
      type: ACTION_TYPE.ADMIN_ORDER_FILTERS,
      payload: res.data.data,
    });
  } catch (error) {}
};

export const getOrderDetails = (orderId) => async (dispatch) => {
  try {
    dispatch(CommonAction.startLoading());

    const res = await Api.get(`/admin/order/details/${orderId}`);
    console.log("Venue all order", res);
    await dispatch({
      type: ACTION_TYPE.ADMIN_ALL_ORDERS_DETAILS,
      payload: { data: res.data.data },
    });
    dispatch(CommonAction.completeLoading({}, false));
  } catch (error) {}
};

export const orderDetails = (item) => async (dispatch) => {
  try {
    await dispatch({
      type: ACTION_TYPE.ADMIN_ALL_ORDERS_DETAILS,
      payload: { data: item },
    });
  } catch (error) {}
};

export const orderAcceptReject =
  (payload, orderDetails) => async (dispatch) => {
    try {
      const res = await Api.post(`/admin/acceptReject`, payload);
      dispatch(getAllOrder("all"));

      console.log("Admin all order", res);
      // await dispatch({
      //   type: ACTION_TYPE.ADMIN_ALL_ORDERS,
      //   payload: { data: res.data.data.result },
      // });
      orderDetails["status"] = payload["status"];
      await dispatch({
        type: ACTION_TYPE.ADMIN_ALL_ORDERS_DETAILS,
        payload: { data: orderDetails },
      });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
    }
  };

// Venue Management
export const getAllVenues = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/venue/all`);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_VENUES,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const createVenue = (venueBody) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/venue/create`, venueBody);
    // name, info, package, price, url, info, id, venueId
    console.log("res", res);

    if (res.data.status === false)
      return dispatch(CommonAction.failedLoading(res.data.message));

    await dispatch({
      type: ACTION_TYPE.GET_VENUES,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    console.log("error", error.response);

    dispatch(CommonAction.failedLoading("something went wrong"));

    console.log("error", error);
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const updateVenue = (venueBody, venueId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/venue/update/${venueId}`, venueBody);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_VENUE_DETAILS,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const updateVenueMedia =
  (mediaType, mediaUrl, venueId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/admin/venue/updateMedia/${venueId}`, {
        mediaType,
        mediaUrl,
      });
      // name, info, package, price, url, info, id, venueId
      await dispatch({
        type: ACTION_TYPE.GET_VENUE_DETAILS,
        payload: res.data.data,
      });
      return res;
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
    }
  };

export const getVenueDetails = (venueId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/venue/details/${venueId}`);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_VENUE_DETAILS,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getMapDetails = (venueId, mapId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/maps/details/${venueId}/${mapId}`);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_VENUE_DETAILS,
      payload: res.data.data[0],
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getMapList = (venueId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/maps/list/${venueId}`);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_MAP_DETAILS_BY_VENUE,
      payload: res.data.data.maps,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const deleteMap = (venueId, mapId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/map/delete/${venueId}/${mapId}`);
    // name, info, package, price, url, info, id, venueId
    // await dispatch({
    //   type: ACTION_TYPE.SAVE_ADSPACE,
    //   payload: { data: res.data.data.result },
    // });
    return res.data;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const deleteVenue = (venueId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/venue/delete/${venueId}`);

    console.log("res0, res", res);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.DELETE_VENUE,
      payload: res.data.data.maps,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getAddVenueReqList = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/venue/reqList`);
    console.log("res", res.data.data.vendors);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_VENDORS,
      payload: res.data.data.vendors,
    });

    await dispatch({
      type: ACTION_TYPE.GET_CATEGORIES,
      payload: res.data.data.categories,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const addCategory = (params) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/category/add`, params);
    console.log("res", res.data.data.vendors);
    // name, info, package, price, url, info, id, venueId
    // await dispatch({
    //   type: ACTION_TYPE.GET_VENDORS,
    //   payload: res.data.data.vendors,
    // });

    await dispatch({
      type: ACTION_TYPE.GET_CATEGORIES,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

// AdSpace Management

export const saveAddSpace = (body) => async (dispatch) => {
  console.log(
    "🚀 ~ file: AdminAction.js ~ line 290 ~ saveAddSpace ~ body",
    body
  );
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/adSpace/save`, body);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.SAVE_ADSPACE,
      payload: { data: res.data.data.result },
    });
    return res.data;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const deleteAddSpace = (item) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/adSpace/delete`, item);
    // name, info, package, price, url, info, id, venueId
    // await dispatch({
    //   type: ACTION_TYPE.SAVE_ADSPACE,
    //   payload: { data: res.data.data.result },
    // });
    return res.data;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const configureVenue = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/venue/configure`, body);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.Configure_Venue,
      payload: { data: res.data.data.result },
    });
    return res;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const configuredBefore = (venueId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/venue/details/isConfigured/${venueId}`);
    await dispatch({
      type: ACTION_TYPE.IS_CONFIGURED_BEFORE,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const unSaveAdSpace = (Body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    // const res = await Api.get(`/admin/venue/details/${venueId}`);
    // name, info, package, price, url, info, id, venueId
    // await dispatch({
    //   type: ACTION_TYPE.GET_VENUE_DETAILS,
    //   payload: res.data.data,
    // });
    // return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const saveZone =
  ({ name, venueId, mapId }) =>
  async (dispatch) => {
    console.log("🚀 ~ file: AdminAction.js ~ line 290 ~ saveZone ~ body", {
      name,
      venueId,
      mapId,
    });
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/admin/venue/zone/create`, {
        name,
        venueId,
        mapId,
      });
      // name, info, package, price, url, info, id, venueId
      // await dispatch({
      //   type: ACTION_TYPE.SAVE_ADSPACE,
      //   payload: { data: res.data.data.result },
      // });
      return res.data;
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
    }
  };

// Customer Management

export const getAllCustomers = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/customer/all`);

    await dispatch({
      type: ACTION_TYPE.GET_CUSTOMERS,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getCustomerDetails = (customerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/customer/details/${customerId}`);
    console.log("res", res);
    await dispatch({
      type: ACTION_TYPE.GET_CUSTOMER_DETAILS,
      payload: res.data.data,
    });

    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const getCustomerOrderDetails = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/customer/order/details/${orderId}`);
    console.log("res", res);
    await dispatch({
      type: ACTION_TYPE.ADMIN_ALL_ORDERS_DETAILS,
      payload: res.data,
    });

    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const updateCustomer =
  (customerBody, customerId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(
        `/admin/customer/update/${customerId}`,
        customerBody
      );

      await dispatch({
        type: ACTION_TYPE.GET_CUSTOMER_DETAILS,
        payload: res.data.data,
      });
      return res;
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
    }
  };

// Vendor Management

export const getAllVendors = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/vendor/all`);
    // console.log("res", res.data.data.vendors);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_VENDORS,
      payload: res.data.data.vendors,
    });

    await dispatch({
      type: ACTION_TYPE.GET_CATEGORIES,
      payload: res.data.data.categories,
    });

    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const createVendor = (vendorBody) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/vendor/create`, vendorBody);
    // name, info, package, price, url, info, id, venueId

    if (res.data.status === false)
      return dispatch(CommonAction.failedLoading(res.data.message));

    await dispatch({
      type: ACTION_TYPE.GET_VENDORS,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    console.log("error", error);
    dispatch(CommonAction.failedLoading(error.response.data.message));
  }
};

export const getVendorDetails = (vendorId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/vendor/details/${vendorId}`);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.GET_VENDOR_DETAILS,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};
export const deleteVendor = (vendorId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/vendor/delete/${vendorId}`);
    // name, info, package, price, url, info, id, venueId
    await dispatch({
      type: ACTION_TYPE.DELETE_VENDOR,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

export const updateVendor = (vendorBody, vendorId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/admin/vendor/update/${vendorId}`, vendorBody);

    await dispatch({
      type: ACTION_TYPE.GET_VENDOR_DETAILS,
      payload: res.data.data,
    });
    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

// Categories

export const getCategoriesByRole = (role) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`/admin/category/get/${role}`);
    console.log("res", res.data.data);
    // name, info, package, price, url, info, id, venueId

    // await dispatch({
    //   type: ACTION_TYPE.GET_CATEGORIES,
    //   payload: res.data.data,
    // });

    return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};

// Common

export const changeVenueVendorStatus =
  ({ id, status, type }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/admin/venue-vendor/change-status/${id}`, {
        status,
        type,
      });

      await dispatch({
        type: ACTION_TYPE.UPDATE_VENUE_VENDOR,
        payload: res.data.data,
      });
      return res;
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      console.log("error", error);
      dispatch({ type: "LOADING_FAILURE" });
    }
  };

export const changeOrderDiscount =
  ({ orderId, discount, discountType }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(`/admin/order/changeDiscount`, {
        orderId,
        discount,
        discountType,
      });

      // await dispatch({
      //   type: ACTION_TYPE.UPDATE_VENUE_VENDOR,
      //   payload: res.data.data,
      // });
      return res;
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      console.log("error", error);
      dispatch({ type: "LOADING_FAILURE" });
    }
  };

export const exportOrders = (params) => async (dispatch) => {
  try {
    // dispatch(CommonAction.startLoading());

    const res = await axios({
      method: "post",
      url: `${BASE_URL}/admin/order/export`,
      data: params,

      // data: bodyFormData,
      // headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    });
    // dispatch(CommonAction.completeLoading("", false));

    console.log("res", res);

    if (res.status === 200) {
      let formattedFileName = formatDateForCsv(params);

      console.log("res.data", res.data);
      const blob = await res.data;
      const file = new Blob([blob], {
        type: "text/csv",
      });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", formattedFileName);
      document.body.appendChild(link);
      link.click();
      //Open the URL on new Window
      // window.open(fileURL);
    }
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(err));
  }
};
