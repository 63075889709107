import { ACTION_TYPE, ENDPOINTS } from "../../services/constants/Index";
import Api from "../../services/utills/Axios";
import axios from "axios";
// import { toast } from "../../services/utills/toast";
import { CommonAction } from "./Index";

export const login = (body) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPE.API_CALL_STARTED });
    const res = await Api.post(ENDPOINTS.LOGIN, body);
    console.log("res success", res.data);

    if (!res.data.data._id)
      return dispatch(CommonAction.failedLoading(res.data.message));

    await dispatch({
      type: ACTION_TYPE.LOGIN,
      payload: { data: res.data },
    });

    dispatch(CommonAction.completeLoading(res?.data?.message));
    dispatch({ type: ACTION_TYPE.API_CALL_COMPLETED });
    // dispatch({ type: "LOADING_COMPLETED" });

    return res.data;
  } catch (error) {
    console.log("error :>> ", error, error.data, error.message);
    // toast.error(error.response.data.message);
    dispatch(
      CommonAction.failedLoading(
        "Something went wrong. Please try again later!"
      )
    );
    // dispatch({ type: ACTION_TYPE.API_CALL_FAILURE });
    return error;
    // dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};
export const forgotPassword = (body) => async (dispatch) => {
  try {
    dispatch(CommonAction.startLoading());
    console.log("vvvv4", body);
    const res = await Api.post(ENDPOINTS.FORGOT_PASSWORD, body);

    // await dispatch({
    //   type: ACTION_TYPE.LOGIN,
    //   payload: { data: res.data },
    // });
    dispatch(CommonAction.completeLoading(res?.data?.message));
  } catch (error) {
    dispatch(CommonAction.failedLoading(error.response.data.message));

    // dispatch(handleError+(error));
  }
};
export const resetPassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("vvvv4", body);
    const res = await Api.post(ENDPOINTS.RESET_PASSWORD, body);

    // await dispatch({
    //   type: ACTION_TYPE.LOGIN,
    //   payload: { data: res.data },
    // });
    dispatch({ type: "LOADING_COMPLETED" });

    return new Promise(function (resolve, reject) {
      resolve(res.data);
    });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const signUp = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.SIGNUP, body);
    console.log(res);
    dispatch({
      type: ACTION_TYPE.SIGNUP,
      payload: res.data,
    });

    dispatch(CommonAction.completeLoading(res?.data?.message));
    return res?.data?.status;
  } catch (err) {
    dispatch(CommonAction.failedLoading(err.response.data.message));
    // dispatch(handleError(err));
  }
};
export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    sessionStorage.clear();
    dispatch({
      type: ACTION_TYPE.LOGOUT_USER,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(err));
  }
};
export const matchToken = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    // try {

    let token = localStorage.getItem("token");
    var myHeaders = new Headers(); // Currently empty
    myHeaders.append("Authorization", "Bearer" + token);
    Api.post(ENDPOINTS.MATCH_TOKEN)
      .then(async (res) => {
        if (res.data.status === false) {
          dispatch({ type: "LOGOUT_USER" });
          resolve(res?.data?.status);
        }
        console.log(" res.data ", res.data.data.data);

        await dispatch({
          type: ACTION_TYPE.MATCHTOKEN,
          payload: res.data.data,
        });

        resolve(res?.data?.status);
      })
      .catch((err) => {
        dispatch(logOut());
        // dispatch(handleError(err));
        // // const history = useHistory();
        // // history.push(ROUTES.Login);
        // dispatch({ type: "LOGOUT_USER" });
      });
  });
};
export const signUpVenueVendor = (body) => async (dispatch) => {
  try {
    const res = await Api.post(ENDPOINTS.SIGNUP_VENUE_VENDOR, body);
    console.log("res", res);

    if (!res.data.data._id)
      return dispatch(CommonAction.failedLoading(res.data.message));

    dispatch({
      type: ACTION_TYPE.SIGNUP,
      payload: res.data,
    });
    dispatch(CommonAction.completeLoading(res?.data?.message));

    return res?.data?.status;
  } catch (err) {
    console.log("rsult", err.message);
    dispatch(CommonAction.failedLoading(err.response.data.message));

    // dispatch(handleError(err));
  }
};

export const loginVenueVendor = (body) => async (dispatch) => {
  try {
    dispatch(CommonAction.startLoading());

    const res = await Api.post(ENDPOINTS.LOGIN_VENUE_VENDOR, body);
    console.log("res", res.data);

    if (!res.data.data._id)
      return dispatch(CommonAction.failedLoading(res.data.message));

    await dispatch({
      type: ACTION_TYPE.LOGIN,
      payload: { data: res.data },
    });
    dispatch(CommonAction.completeLoading(res?.data?.message));

    // dispatch({ type: "LOADING_COMPLETED" });

    return res.data;
  } catch (error) {
    dispatch(
      CommonAction.failedLoading(
        "Something went wrong. Please try again later!"
      )
    );
    return error;
    // dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const checkEmailVerified = (body) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPE.API_CALL_STARTED });
    const res = await Api.post(ENDPOINTS.CHECK_EMAIL_VALIDATE, body);
    console.log("CHEKC EMAIL VALIDATE", res.data);
    dispatch({ type: ACTION_TYPE.API_CALL_COMPLETED });
    // dispatch({ type: "LOADING_COMPLETED" });

    return res.data;
  } catch (error) {
    console.log("error :>> ", error.response);
    // toast.error(error.response.data.message);
    dispatch(CommonAction.failedLoading(error.response.data.message));
    dispatch({ type: ACTION_TYPE.API_CALL_FAILURE });
    // dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};

export const resentValidationEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPE.API_CALL_STARTED });
    const res = await Api.post(ENDPOINTS.RESENT_VALIDATE_EMAIL, body);
    console.log("CHEKC EMAIL VALIDATE", res.data);
    dispatch({ type: ACTION_TYPE.API_CALL_COMPLETED });
    // dispatch({ type: "LOADING_COMPLETED" });

    return res.data;
  } catch (error) {
    console.log("error :>> ", error.response);
    // toast.error(error.response.data.message);
    dispatch(CommonAction.failedLoading(error.response.data.message));
    dispatch({ type: ACTION_TYPE.API_CALL_FAILURE });
    // dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError+(error));
  }
};
// export const contactUs = (body) => async (dispatch) => {
//   try {
//     dispatch({ type: "LOADING_STARTED" });
//     const res = await Api.post(ENDPOINTS.ContactUs, body);

//     dispatch({ type: "LOADING_COMPLETED" });
//     if (res?.data?.status) {
//       return res?.data?.status;
//     }
//   } catch (err) {
//     dispatch({ type: "LOADING_FAILURE" });
//     dispatch(handleError(err));
//   }
// };
// // export const signUp = (body) => async (dispatch) => {

// export const logOut = () => async (dispatch) => {
//   try {
//     dispatch({ type: "LOADING_STARTED" });

//     await signOut();

//     dispatch({
//       type: ACTION_TYPE.LOGOUT_USER,
//     });
//     dispatch({ type: "LOADING_COMPLETED" });
//   } catch (err) {
//     dispatch({ type: "LOADING_FAILURE" });
//     dispatch(handleError(err));
//   }
// };

// export const matchToken = () => async (dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     // try {
//     let userData = await JSON.parse(
//       localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
//     );
//     let token = .getItem(APPLICATION_CONSTANTS.TOKEN);
//     let idToken = localStorage.getItem(APPLICATION_CONSTANTS.ID_TOKEN);

//     Api.get(
//       ENDPOINTS.MATCH_TOKEN +
//         `?email=${userData?.email}&token=${token}&idToken=${idToken}&id=${userData?.id}`
//     )
//       .then(async (res) => {
//         if (res.data.status === false) {
//           dispatch({ type: "LOGOUT_USER" });

//           resolve(res?.data?.status);
//         }
//         let fbToken = res.data.user.fbToken;
//         let newIdToken = await authWithCustomToken(fbToken);

//         await dispatch({
//           type: "LOGIN",
//           payload: { data: res.data, idToken: newIdToken },
//         });

//         resolve(res?.data?.status);
//       })
//       .catch((err) => {
//         dispatch(handleError(err));
//         // // const history = useHistory();
//         // // history.push(ROUTES.Login);
//         // dispatch({ type: "LOGOUT_USER" });
//       });
//   });
// };

export const verifyCaptcha = (humanKey) => async (dispatch) => {
  try {
    // dispatch({ type: ACTION_TYPE.API_CALL_STARTED });
    // const res = await Api.post(ENDPOINTS.CAPTCHA_VALIDATE, body);
    // const url = `http://www.google.com/recaptcha/api/siteverify?secret=${body.secret}&response=${body.response}`;
    // const options = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   url,
    // };
    // const res = await axios(options);
    const res = await Api.post(ENDPOINTS.CAPTCHA_VALIDATE, { humanKey });

    console.log("CHECK CAPTCHA VALIDATE", res);
    // dispatch({ type: ACTION_TYPE.API_CALL_COMPLETED });

    return res;
  } catch (error) {
    console.log("error :>> ", error.response);
    // dispatch(CommonAction.failedLoading(error.response.data.message));
    dispatch({ type: ACTION_TYPE.API_CALL_FAILURE });
  }
};
