import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import "./style.css";
import { Divider } from "@material-ui/core";

const Modal = (props) => {
  const { data } = props;
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title " className="text-center">
          <div>
            <h1>Vendor Details</h1>
          </div>
          <div className="modelIcon2">
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>

          <Divider />
          <div className="addSpaceInputs vendordetailPopup">
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">First Name</div>
              <div className="addSpaceInputsRight">{data?.firstName}</div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">Last Name</div>
              <div className="addSpaceInputsRight">{data?.lastName}</div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">ID</div>
              <div className="addSpaceInputsRight">{data?._id}</div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">Email</div>
              <div className="addSpaceInputsRight">{data?.email}</div>
            </div>
            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">Phone</div>
              <div className="addSpaceInputsRight">{data?.phone}</div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">Brand</div>
              <div className="addSpaceInputsRight">
                {" "}
                {data?.categories?.map((category) => {
                  if (category.split("/")[1] === "Brand")
                    return category.split("/")[2];
                })}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">Management</div>
              <div className="addSpaceInputsRight">
                {" "}
                {data?.categories?.map((category) => {
                  if (category.split("/")[1] === "Management")
                    return category.split("/")[2];
                })}
              </div>
            </div>

            <div className="addSpaceInputsIndividual">
              <div className="addSpaceInputsLeft">Contract</div>
              <div className="addSpaceInputsRight">
                {" "}
                {data?.categories?.map((category) => {
                  if (category.split("/")[1] === "Contract")
                    return category.split("/")[2];
                })}
              </div>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};
export default Modal;
